import { useAppStore } from "MobxStores/context";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { projectDashboardSocket } from "Utils/socketApi";
import {
  messageHandlers,
  MESSAGE_TYPES,
} from "../Utils/DepotSocketMessageActions";
import { getSocketConnection } from "../Utils/GetSocketConnection";

/**
 * It creates a socket connections and assigns listeners to the various messages.
 * @param {string} projectId
 * @param {string} depotId
 * @param {boolean} debugMode - If true, hook returns array of messages received from socket.
 */
export const useDepotSocketData = (projectId, depotId, debugMode = false) => {
  const { depotStore } = useAppStore();
  const [socketMessages, setSocketMessages] = useState([]);
  // PROJ_%s_DEPOT_%s_DASHBOARD
  const depotSocket = useRef(null);
  const projectSocket = useRef(null);
  const depotProjectSocket = useRef(null);
  const token = useRef(localStorage.getItem("accessToken"));

  useEffect(() => {
    depotSocket.current = getSocketConnection(token.current, projectId);
    projectSocket.current = projectDashboardSocket(true, token, projectId);
    depotProjectSocket.current = getSocketConnection(
      token.current,
      projectId,
      depotId
    );

    const socketConnections = [
      {
        socket: depotSocket.current,
        messageTypes: Object.values(MESSAGE_TYPES.DEPOT),
        // url: `DEPOT_${projectId}_DASHBOARD`,
        url: `PROJ_${projectId}_DEPOT_DASHBOARD`,
      },
      {
        socket: projectSocket.current,
        messageTypes: Object.values(MESSAGE_TYPES.PROJECT),
        url: `PROJECT_${projectId}_DASHBOARD`,
      },
      {
        socket: depotProjectSocket.current,
        messageTypes: Object.values(MESSAGE_TYPES.DEPOT_PROJECT),
        url: `PROJ_${projectId}_DEPOT_${depotId}_DASHBOARD`,
      },
    ];

    socketConnections.forEach(({ socket, messageTypes, url }) => {
      socket.on("connect", () => {
        console.log(`--> connected to ${url} socket`);
      });

      messageTypes.forEach((messageType) => {
        socket.on(messageType, (data) => {
          messageHandlers[messageType](data, depotStore);
          if (debugMode) {
            setSocketMessages((messages) => [
              ...messages,
              {
                message: messageType,
                timeStamp: new Date().toLocaleTimeString(),
                payload: data,
              },
            ]);
          }
        });
      });
    });

    return () => {
      socketConnections.forEach(({ socket, url }) => {
        console.info("closing socket for ", url);
        socket.close();
      });
    };
  }, [projectId, debugMode, depotStore]);

  if (debugMode) return { socketMessages };
};
