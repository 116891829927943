import React, { useContext } from "react";
import PropTypes from "prop-types";

//Components
import Image from "Components/Image/Image";

//Context
import SocketContext from "Contexts/SocketContext";

//Utils
import lockedByMeIcon from "Utils/Images/ProjectMaintanance/locked-by-me-icon.svg";
import lockedIcon from "Utils/Images/ProjectMaintanance/locked-icon.svg";
import useLockState from "CustomHooks/useLockState";

const MU = ({
  isMUSelected,
  id,
  name,
  onMUClick,
  tooltip,
  muDevice,
  locTimeoutInstances,
  isDeviceConnected,
}) => {
  const { HPStateSocket, chargingStateSocket, devicesInitialStateSocket } =
    useContext(SocketContext);

  const { treeMap } = Object.keys(devicesInitialStateSocket).length // From initial state devices message
    ? devicesInitialStateSocket["socketMessageData"]
    : { treeMap: {} };

  const initialChargingStatus =
    treeMap && id && treeMap[id] ? treeMap[id]["chargingState"] : 0;

  const initialHPStatus =
    treeMap && id && treeMap[id] ? treeMap[id]["hpState"] : 0;

  const {
    chargingStatus,
  } = // From chargingMessage
    chargingStateSocket && id && chargingStateSocket[id]
      ? chargingStateSocket[id]["socketMessageData"]
      : { chargingStatus: initialChargingStatus };

  const { hpState } =
    HPStateSocket && id && HPStateSocket[id]
      ? HPStateSocket[id]["socketMessageData"]
      : { hpState: initialHPStatus };

  const { isLockedByMe, isLocked, lockedBy } = useLockState(
    muDevice,
    locTimeoutInstances
  );

  return (
    <div
      className={`mu-list-item ${isMUSelected ? "selected" : ""} ${
        isDeviceConnected ? "connected" : "disconnected"
      }`}
      onClick={() => onMUClick()}
    >
      <div
        className={`list-item-name ${
          isDeviceConnected && chargingStatus ? "charging" : ""
        }`}
        title={tooltip}
      >
        {name}
      </div>

      <div className="list-item-icons">
        <div
          className={`connection-status ${
            isDeviceConnected ? "connected" : "disconnected"
          }`}
        />

        {isDeviceConnected && !!hpState ? (
          <div className="contactor-on">HP</div>
        ) : (
          ""
        )}

        {isDeviceConnected && (isLockedByMe || isLocked) ? (
          <Image
            src={isLockedByMe ? lockedByMeIcon : isLocked ? lockedIcon : ""}
            alt="lock-icon"
            title={
              !isLocked
                ? ""
                : isLockedByMe
                ? ""
                : `Device is locked by ${lockedBy}`
            }
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MU;

MU.propTypes = {
  isMUSelected: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onMUClick: PropTypes.func,
  tooltip: PropTypes.string,
  muDevice: PropTypes.object,
  locTimeoutInstances: PropTypes.object,
  isDeviceConnected: PropTypes.bool,
};
