import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "Components/Recievers/ReceiversView.scss";
import PropTypes from "prop-types";

//Components
import DataSummary from "Components/DataSummary/DataSummary";
import SwitchButton from "Components/Buttons/SwitchButton/SwitchButton";
import NumericInput from "Components/NumericInput/NumerInput";
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import Table from "Components/Tables/Table";
import ActivityIndicator from "Components/ActivityIndicator/ActivityIndicator";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import Image from "Components/Image/Image";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";

//Hooks
import useRole from "CustomHooks/useRole";

//Utils
import fanIcon from "Utils/Images/ProjectMaintanance/fan-icon.svg";
import forceRFICon from "Utils/Images/ProjectMaintanance/segment-force-rf-icon.svg";
import {
  getIsDeviceDisconnected,
  getVuRfStatusInd,
} from "Utils/UtilsFunctions";
import {
  postReceiversDebugMode,
  postReceiverControlStatus,
  postVuRf,
  getSessionIdProject,
} from "Utils/APIUtils";
import {
  defaultRecievers,
  defaultReceiverFan,
  defaultReceiversSetstate,
  defaultReceiversState,
} from "Utils/DefaultValues/ReceiversViewDefaultValues";
import { shouldRemoveReceiver } from "Utils/featureFlags";
import VuActivationMode from "./VuActivationMode";

const ReceiversView = ({ isLocked, avgCurrentLiveValue }) => {
  const [rfBoostLevel, setRfBoostLevel] = useState(0);

  const { userRole, selectedDevice } = useContext(MainContext);
  const { connectionState, receiversFans, receivers } =
    useContext(SocketContext);
  const { canControlFans, canChangeConfig, canControlReceivers, canDebug } =
    useRole(userRole);

  const sessionId = getSessionIdProject();
  const { activationMode } = selectedDevice || { activationMode: "" };
  const isDeviceOffline = getIsDeviceDisconnected(
    connectionState,
    selectedDevice
  );

  const {
    Charging_Work_Mode,
    RF_Boost_Level,
    Receiver_1,
    Receiver_2,
    Receiver_3,
    Receiver_4,
    Receiver_5,
  } = receivers[selectedDevice.id]?.socketMessageData || defaultRecievers;

  const {
    receiver_fan_1,
    receiver_fan_2,
    receiver_fan_3,
    receiver_fan_4,
    receiver_fan_5,
  } = receiversFans[selectedDevice.id]?.socketMessageData || defaultReceiverFan;

  const debugMode = useMemo(() => !!Charging_Work_Mode, [Charging_Work_Mode]);

  const currentDisplay = useMemo(() => {
    return avgCurrentLiveValue
      ? avgCurrentLiveValue < 10
        ? avgCurrentLiveValue.toFixed(2)
        : avgCurrentLiveValue.toFixed(1)
      : 0;
  }, [avgCurrentLiveValue]);

  const toggleControlBtn = useCallback(
    (receiverNumber, controlStatus) => {
      postReceiverControlStatus({
        sessionId,
        vehicleUnitId: selectedDevice && selectedDevice.id,
        receiver_id: receiverNumber,
        state: controlStatus,
      });
    },
    [selectedDevice && selectedDevice.id, debugMode, sessionId]
  );

  const toggleDebug = useCallback(() => {
    postReceiversDebugMode({
      sessionId: sessionId,
      vehicleUnitId: selectedDevice && selectedDevice.id,
      mode: Number(!debugMode),
    }).then((res) => {
      if (res && res.status !== 200) {
        //(res, "possible error");
        return;
      }
    });
  }, [sessionId, selectedDevice?.id, debugMode]);

  const tableData = useMemo(() => {
    return [
      {
        receiverName: "R1",
        master: Receiver_1 && Receiver_1["Allowed_Ind"],
        control: Receiver_1 && Receiver_1["Set_State"],
        set:
          activationMode === "VOLTAGE_TRACKING"
            ? Receiver_1 && Receiver_1["Set_State"]
            : Receiver_1 && Receiver_1["State"], // state == 1 || state == 2  => on
        rf: Receiver_1 && Receiver_1["State"], // state == 2 =>  on
        i: currentDisplay,
        capacitor: Receiver_1 && [
          Receiver_1["Temperature"][0] < 0
            ? "--"
            : Receiver_1["Temperature"][0],
          Receiver_1["Temperature"][1] < 0
            ? "--"
            : Receiver_1["Temperature"][1],
        ],
        heatSync: Receiver_1 && [
          Receiver_1["Temperature"][2] < 0
            ? "--"
            : Receiver_1["Temperature"][2],
          Receiver_1["Temperature"][3] < 0
            ? "--"
            : Receiver_1["Temperature"][3],
        ],
        fan: receiver_fan_1,
      },
      {
        receiverName: "R2",
        master: Receiver_2 && Receiver_2["Allowed_Ind"],
        control: Receiver_2 && Receiver_2["Set_State"],
        set:
          activationMode === "VOLTAGE_TRACKING"
            ? Receiver_2 && Receiver_2["Set_State"]
            : Receiver_2 && Receiver_2["State"],
        rf: Receiver_2 && Receiver_2["State"],
        i: currentDisplay,
        capacitor: Receiver_2 && [
          Receiver_2["Temperature"][0] < 0
            ? "--"
            : Receiver_2["Temperature"][0],
          Receiver_2["Temperature"][1] < 0
            ? "--"
            : Receiver_2["Temperature"][1],
        ],
        heatSync: Receiver_2 && [
          Receiver_2["Temperature"][2] < 0
            ? "--"
            : Receiver_2["Temperature"][2],
          Receiver_2["Temperature"][3] < 0
            ? "--"
            : Receiver_2["Temperature"][3],
        ],
        fan: receiver_fan_2,
      },
      {
        receiverName: "R3",
        master: Receiver_3 && Receiver_3["Allowed_Ind"],
        control: Receiver_3 && Receiver_3["Set_State"],
        set:
          activationMode === "VOLTAGE_TRACKING"
            ? Receiver_3 && Receiver_3["Set_State"]
            : Receiver_3 && Receiver_3["State"],
        rf: Receiver_3 && Receiver_3["State"],
        i: currentDisplay,
        capacitor: Receiver_3 && [
          Receiver_3["Temperature"][0] < 0
            ? "--"
            : Receiver_3["Temperature"][0],
          Receiver_3["Temperature"][1] < 0
            ? "--"
            : Receiver_3["Temperature"][1],
        ],
        heatSync: Receiver_3 && [
          Receiver_3["Temperature"][2] < 0
            ? "--"
            : Receiver_3["Temperature"][2],
          Receiver_3["Temperature"][3] < 0
            ? "--"
            : Receiver_3["Temperature"][3],
        ],
        fan: receiver_fan_3,
      },
      {
        receiverName: "R4",
        master: Receiver_4 && Receiver_4["Allowed_Ind"],
        control: Receiver_4 && Receiver_4["Set_State"],
        set:
          activationMode === "VOLTAGE_TRACKING"
            ? Receiver_4 && Receiver_4["Set_State"]
            : Receiver_4 && Receiver_4["State"],
        rf: Receiver_4 && Receiver_4["State"],
        i: currentDisplay,
        capacitor: Receiver_4 && [
          Receiver_4["Temperature"][0] < 0
            ? "--"
            : Receiver_4["Temperature"][0],
          Receiver_4["Temperature"][1] < 0
            ? "--"
            : Receiver_4["Temperature"][1],
        ],
        heatSync: Receiver_4 && [
          Receiver_4["Temperature"][2] < 0
            ? "--"
            : Receiver_4["Temperature"][2],
          Receiver_4["Temperature"][3] < 0
            ? "--"
            : Receiver_4["Temperature"][3],
        ],
        fan: receiver_fan_4,
      },
      {
        receiverName: "R5",
        master: Receiver_5 && Receiver_5["Allowed_Ind"],
        control: Receiver_5 && Receiver_5["Set_State"],
        set:
          activationMode === "VOLTAGE_TRACKING"
            ? Receiver_5 && Receiver_5["Set_State"]
            : Receiver_5 && Receiver_5["State"],
        rf: Receiver_5 && Receiver_5["State"],
        i: currentDisplay,
        capacitor: Receiver_5 && [
          Receiver_5["Temperature"][0] < 0
            ? "--"
            : Receiver_5["Temperature"][0],
          Receiver_5["Temperature"][1] < 0
            ? "--"
            : Receiver_5["Temperature"][1],
        ],
        heatSync: Receiver_5 && [
          Receiver_5["Temperature"][2] < 0
            ? "--"
            : Receiver_5["Temperature"][2],
          Receiver_5["Temperature"][3] < 0
            ? "--"
            : Receiver_5["Temperature"][3],
        ],
        fan: receiver_fan_5,
      },
    ];
  }, [
    Receiver_1,
    Receiver_2,
    Receiver_3,
    Receiver_4,
    Receiver_5,
    receiver_fan_1,
    receiver_fan_2,
    receiver_fan_3,
    receiver_fan_4,
    receiver_fan_5,
    currentDisplay,
    Receiver_1?.Temperature,
    Receiver_2?.Temperature,
    Receiver_3?.Temperature,
    Receiver_4?.Temperature,
    Receiver_5?.Temperature,
  ]);

  const tableColumns = useMemo(() => {
    return [
      {
        Header: "",
        accessor: "receiverName",
        Cell: ({
          value,
          row: {
            original: { rf },
          },
        }) => {
          if (
            rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          return value;
        },
      },
      {
        Header: "Master",
        accessor: "master",
        Cell: ({
          value,
          row: {
            original: { rf },
          },
        }) => {
          if (
            rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          return (
            <div
              className="hover"
              title={value === 1 ? "Charging ALLOWED" : "Charging NOT ALLOWED"}
            >
              <ActivityIndicator color={!value ? "#bfbfbf" : "#00FFFF"} />
            </div>
          );
        },
      },
      {
        Header: "Control",
        accessor: "control",
        Cell: ({ row, value }) => {
          if (
            row.original.rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }
          let buttonText = "OFF";

          const receiverNumber = Number(
            row &&
              row.original &&
              row.original.receiverName &&
              row.original.receiverName[1]
          );

          const receiverNewStatus =
            value === defaultReceiversSetstate.autoRegular
              ? defaultReceiversSetstate.offRegular
              : value === defaultReceiversSetstate.offDebug
              ? defaultReceiversSetstate.reqDebugOn
              : value === defaultReceiversSetstate.offRegular
              ? defaultReceiversSetstate.autoRegular
              : value === defaultReceiversSetstate.reqDebugOn
              ? defaultReceiversSetstate.offDebug
              : defaultReceiversSetstate.autoRegular;

          return (
            <div
              className={`table-receiver-control-button ${
                activationMode === "VOLTAGE_TRACKING"
                  ? "voltage-tracking"
                  : "normal-tracking"
              } ${debugMode ? "debug-mode" : ""}`}
            >
              <SwitchButton
                isDisabled={isLocked || !canControlReceivers}
                label={buttonText}
                isChecked={
                  !debugMode
                    ? value === defaultReceiversSetstate.autoRegular
                    : value === defaultReceiversSetstate.reqDebugOn
                }
                onChange={() => {
                  return toggleControlBtn(receiverNumber, receiverNewStatus);
                }}
              />
              <div></div>
            </div>
          );
        },
      },
      {
        Header: "Set",
        accessor: "set",
        Cell: ({
          value,
          row: {
            original: { rf },
          },
        }) => {
          if (
            rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          return (
            <div
              className="hover"
              title={
                value === defaultReceiversState.commSetOn ||
                value === defaultReceiversState.commIndOn
                  ? "Set Receiver ON STATUS"
                  : "Set Receiver OFF STATUS"
              }
            >
              <ActivityIndicator
                color={
                  value === defaultReceiversState.commSetOn ||
                  value === defaultReceiversState.commIndOn
                    ? "#00FFFF"
                    : "#bfbfbf"
                }
              />
            </div>
          );
        },
      },
      {
        Header: "RF",
        accessor: "rf",
        Cell: ({ value }) => {
          if (
            value === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          return (
            <div className="hover" title={getVuRfStatusInd(value).message}>
              <Image
                src={forceRFICon}
                style={{
                  padding: "3px",
                  backgroundColor: getVuRfStatusInd(value).color,
                  borderRadius: "15px",
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "I",
        accessor: "i",
        Cell: ({
          value,
          row: {
            original: { rf },
          },
        }) => {
          if (
            rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          return (
            <div className="vu-receivers-table-current">
              <DataSummary
                value={value}
                units="A"
                subtitle="Current"
                isLeftBorder={false}
              />
            </div>
          );
        },
      },
      {
        Header: "Capacitor",
        accessor: "capacitor",
        Cell: ({
          value,
          row: {
            original: { rf },
          },
        }) => {
          if (
            rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          return (
            <div className="vu-receivers-table-temperature">
              <DataSummary
                value={
                  Array.isArray(value) &&
                  value[0] &&
                  typeof value[0] === "number"
                    ? value[0].toFixed(1)
                    : "--"
                }
                units="°C"
                subtitle="T1"
                isLeftBorder={false}
              />
              <DataSummary
                value={
                  Array.isArray(value) &&
                  value[1] &&
                  typeof value[1] === "number"
                    ? value[1].toFixed(1)
                    : "--"
                }
                units="°C"
                subtitle="T2"
                isLeftBorder={false}
              />
            </div>
          );
        },
      },
      {
        Header: "Heat Sync",
        accessor: "heatSync",
        Cell: ({
          value,
          row: {
            original: { rf },
          },
        }) => {
          if (
            rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          return (
            <div className="vu-receivers-table-temperature">
              <DataSummary
                value={
                  Array.isArray(value) &&
                  value[0] &&
                  typeof value[0] === "number"
                    ? value[0].toFixed(1)
                    : "--"
                }
                units="°C"
                subtitle="T1"
                isLeftBorder={false}
              />
              <DataSummary
                value={
                  Array.isArray(value) &&
                  value[1] &&
                  typeof value[1] === "number"
                    ? value[1].toFixed(1)
                    : "--"
                }
                units="°C"
                subtitle="T2"
                isLeftBorder={false}
              />
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "fan",
        Cell: ({
          value,
          row: {
            original: { rf },
          },
        }) => {
          if (
            rf === defaultReceiversState.cableDisconnected &&
            activationMode === "VOLTAGE_TRACKING" &&
            shouldRemoveReceiver
          ) {
            return null;
          }

          let buttonText = "AUTO";
          if (value === 2) {
            buttonText = "ON";
          }

          return (
            <div className="table-receiver-fan">
              <Image src={fanIcon} />
              <IndicationButton
                title="Fan Control"
                isDisabled={isLocked || !canControlFans}
                onClick={() => {
                  // TODO: implement fans' control in vu
                }}
                text={buttonText}
                width={67}
                height={36}
              />
              <div></div>
            </div>
          );
        },
      },
    ];
  }, [debugMode, isLocked, toggleControlBtn]);

  useEffect(() => setRfBoostLevel(RF_Boost_Level), [RF_Boost_Level]);

  return (
    <div className={`receiver-view ${isDeviceOffline ? "gray-layout" : ""}`}>
      <div className="vu-receivers-header-wrapper">
        <ProjectDeviceHeader title="Receivers" />
        <VuActivationMode
          canDebug={canDebug}
          debugMode={debugMode}
          isLocked={isLocked}
          toggleDebug={toggleDebug}
        />
        <div className="vu-resonance-freq">
          <NumericInput
            readOnly
            isDisabled={isLocked || !canChangeConfig}
            isArrows
            value={rfBoostLevel}
            buttonsOnTheRight
            onClickMinusButton={() => {
              if (rfBoostLevel <= 5) {
                return;
              }
              postVuRf({
                sessionId: sessionId,
                vehicleUnitId: selectedDevice && selectedDevice.id,
                val: rfBoostLevel - 1,
              }).then((res) => {
                if (res && res.status !== "200") {
                  //(res, "possible error");
                  return;
                }
              });
            }}
            onClickPlusButton={() => {
              if (rfBoostLevel >= 16) {
                return;
              }
              postVuRf({
                sessionId: sessionId,
                vehicleUnitId: selectedDevice && selectedDevice.id,
                val: rfBoostLevel + 1,
              }).then((res) => {
                if (res && res.status !== "200") {
                  //(res, "possible error");
                  return;
                }
              });
            }}
            name="resonance-freq"
            label="RF"
            unit="V"
          />
        </div>
      </div>
      <Table data={tableData} columns={tableColumns} />
    </div>
  );
};

export default ReceiversView;

ReceiversView.propTypes = {
  isLocked: PropTypes.bool,
  avgCurrentLiveValue: PropTypes.number,
};
