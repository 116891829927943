import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";

//Components
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import StatusIndication from "Components/StatusIndication/StatusIndication";
import DataSummary from "Components/DataSummary/DataSummary";
import Image from "Components/Image/Image";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import DcSamplerSummary from "Components/DcSampler/DcSamplerSummary";

//Context
import MainContext from "Contexts/MainContext";

//Hooks
import useRole from "CustomHooks/useRole";
import SocketContext from "Contexts/SocketContext";

//Utils
import fanIcon from "Utils/Images/ProjectMaintanance/fan-icon.svg";
import {
  getIsDeviceDisconnected,
  getVUChargingStatus,
} from "Utils/UtilsFunctions";
import "Components/ControlUnit/ControlUnit.scss";

const ControlUnit = ({ avgCurrentLiveValue, isLocked }) => {
  const { userRole, selectedDevice } = useContext(MainContext);
  const { connectionState, cuUnitTemp, receiversFans, receivers } =
    useContext(SocketContext);
  const { canControlFans } = useRole(userRole);

  const isDeviceOffline = getIsDeviceDisconnected(
    connectionState,
    selectedDevice
  );

  const { cuTemp } = cuUnitTemp[selectedDevice.id]?.socketMessageData || [];

  const cuTemp1 =
    cuTemp && cuTemp[0] && cuTemp[0] > -40 ? cuTemp[0].toFixed(1) : "--";
  const cuTemp2 =
    cuTemp && cuTemp[1] && cuTemp[1] > -40 ? cuTemp[1].toFixed(1) : "--";

  const { cu_main_fan } = receiversFans[selectedDevice.id]
    ?.socketMessageData || { cu_main_fan: 0 };

  let fanButtonText = useMemo(() => {
    return cu_main_fan === 2 ? "ON" : "AUTO";
  }, [cu_main_fan]);

  const chargingStatus = useMemo(
    () => getVUChargingStatus(receivers, selectedDevice, avgCurrentLiveValue),
    [receivers, selectedDevice, avgCurrentLiveValue]
  );

  return (
    <div className={`control-unit ${isDeviceOffline ? "gray-layout" : ""}`}>
      <div className="control-unit-header">
        <ProjectDeviceHeader title="Control Unit" />
        <StatusIndication
          icon={chargingStatus.icon}
          statusText={chargingStatus.text}
          borderStyle="solid thin #CCDDFF"
          backgroundColor={chargingStatus.color}
          borderRadius="30px"
        />
      </div>
      <div className="control-unit-content">
        <div>
          <DcSamplerSummary />
        </div>
        <div className="control-unit-data cellular-data">
          <DataSummary subtitle="Cellular" value={0} units="dBm" />
        </div>
        <div className="control-unit-data cpu-cuhs-data">
          <DataSummary
            subtitle="CUHS-T1"
            value={cuTemp1}
            units="°C"
            isLeftBorder={false}
          />
          <DataSummary
            subtitle="CUHS-T2"
            value={cuTemp2}
            units="°C"
            isLeftBorder={false}
          />
          <div className="control-unit-fan">
            <Image src={fanIcon} />
            <IndicationButton
              title="Fan Control"
              isDisabled={isLocked || !canControlFans}
              onClick={() => {
                // TODO: implement fans' control in vu
              }}
              text={fanButtonText}
              width={67}
              height={36}
              buttonIconUrl=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlUnit;

ControlUnit.propTypes = {
  avgCurrentLiveValue: PropTypes.number,
  isLocked: PropTypes.bool,
};
