import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Dashboard } from "@electreoncloud/dashboard";

import useMultiCuOnSingleVehicle from "Screens/ProjectDashboard/lib/hooks/useMultiCuOnSingleVehicle";

import { getBlobImgPath } from "Utils/APIUtils";

import "@electreoncloud/dashboard/dist/style.css";

// this is a temporary hack to dynamically control project config
// and should be removed once debugging the dashboard for performance issues is complete
let projectConfigOverride = {};
try {
  projectConfigOverride = JSON.parse(localStorage.projectConfigOverride);
} catch (e) {}

const DashboardWrapper: React.FC<any> = ({
  projectId,
  projectSettings,
  vehicleData,
  roadPath,
  segmentsPath,
  projectSegments,
  gpsPositions,
  managementUnits,
  backgroundGeoJson,
}) => {
  const path = (relPath: string) => {
    return getBlobImgPath(projectId, relPath);
  };

  const [managementUnitsArr, setManagementUnitsArr] = useState(
    Object.values(managementUnits)
  );

  const { newVehicleData, newGpsPositions } = useMultiCuOnSingleVehicle(
    vehicleData,
    gpsPositions
  );

  useEffect(() => {
    setManagementUnitsArr(Object.values(managementUnits));
  }, [managementUnits]);

  return (
    <Dashboard.DashboardMain
      layout={projectSettings.layout}
      config={{ ...projectSettings.config, ...projectConfigOverride }}
    >
      <Dashboard.MainSection
        header={path(projectSettings.images.header)}
        vehiclesData={Object.values(newVehicleData)}
        headerBgStyle={projectSettings.styles.headerBgStyle}
        mapProps={{
          data: {
            segments: Object.values(projectSegments),
            mUnits: managementUnitsArr,
            roadPath,
            segmentsPath,
            gpsPositions: Object.values(newGpsPositions),
            backgroundGeoJson: backgroundGeoJson,
          },
          config: projectSettings.map,
          images: {
            roadOutlineImg: {
              path: path(projectSettings.images.mapBg),
              ...projectSettings.map.images.roadOutlineImg,
            },
          },
          mapLogoStyle: projectSettings.styles.mapLogoStyle,
        }}
      />
    </Dashboard.DashboardMain>
  );
};

export default DashboardWrapper;

DashboardWrapper.propTypes = {
  projectId: PropTypes.string,
  projectSettings: PropTypes.object,
  vehicleData: PropTypes.object,
  roadPath: PropTypes.array,
  segmentsPath: PropTypes.array,
  projectSegments: PropTypes.object,
  gpsPositions: PropTypes.object,
  managementUnits: PropTypes.object,
  backgroundGeoJson: PropTypes.object,
};
