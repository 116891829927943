import { useAppStore } from "MobxStores/context";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function useInitDepotStore() {
  const { depotId, projectId } = useParams();
  const { depotStore } = useAppStore();

  useEffect(() => {
    depotStore.init(projectId, depotId);
    return () => {
      depotStore.resetStore();
    };
  }, [depotId, projectId]);

  return depotStore;
}
