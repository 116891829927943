import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import arrowRight from "Utils/Images/GlobalIcons/arrow-down-blue.svg";
import "./DeviceList.scss";
import { useAppStore } from "MobxStores/context";

export const DeviceList = observer(
  ({
    devices,
    parameterTypes,
    defaultCheckedParameterIds = ["VU_DC_METER_POWER", "MU_DC_METER_POWER"],
    store,
  }) => {
    const { userStore } = useAppStore();
    const getDeviceParameterTypes = (device) => {
      // TODO: get specific device parameter types and add to device object
      return parameterTypes.filter(
        (parameterType) => device.deviceType === parameterType.deviceType
      );
    };
    const theme = useTheme();
    const { canViewAdvancedParameters } = userStore.getUserPermissions();

    return (
      <Stack className="list-wrapper">
        {devices.map((device) => (
          <Accordion key={"device" + device.id}>
            <AccordionSummary
              expandIcon={
                canViewAdvancedParameters && (
                  <img src={arrowRight} alt="arrow" />
                )
              }
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
                onClick={(e) => {
                  e.stopPropagation();
                  store.toggleDeviceSelection(device);
                }}
              >
                <Typography variant="label">{device.name}</Typography>
                <Switch checked={store.isDeviceSelected(device.id)} />
              </Stack>
            </AccordionSummary>
            {canViewAdvancedParameters && (
              <AccordionDetails>
                <Grid container spacing={2} width={"100%"}>
                  {getDeviceParameterTypes(device).map((parameterType) => (
                    <Grid item key={parameterType.id}>
                      <FormControlLabel
                        label={
                          <Typography variant="labelSmall">
                            {parameterType.displayName}
                          </Typography>
                        }
                        sx={{
                          height: "100%",
                        }}
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: theme.palette.accent.primary.main,
                              },
                            }}
                            checked={store.isParameterSelected(
                              device.id,
                              parameterType.id
                            )}
                            onClick={(e) => {
                              store.toggleParameterSelection(
                                device,
                                parameterType.id
                              );
                            }}
                            id={parameterType.displayName}
                            name={parameterType.displayName}
                          />
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
            )}
          </Accordion>
        ))}
      </Stack>
    );
  }
);

DeviceList.propTypes = {
  devices: PropTypes.array,
  store: PropTypes.object,
  parameterTypes: PropTypes.arrayOf(
    PropTypes.shape({
      chartType: PropTypes.string.isRequired,
      deviceType: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      supportedFromFwVersion: PropTypes.any,
      supportedUpToFwVersion: PropTypes.any,
    }).isRequired
  ).isRequired,
};
