// Receives a user object and returns a string with the user's role.
// Scope types are:
// - SINGLE: user has access to a single project
// - GLOBAL: user has access to all projects
// - MULTI: user has access to multiple projects
// - NONE: user has no specific scope type

import { ScopeTypes } from "Utils/constants";

export const getUserScopeType = (user) => {
  const isGlobalScoped = user?.idTokenClaims?.extension_ProjectScope === "*";
  const isSingleScoped =
    user?.idTokenClaims?.extension_ProjectScope?.split(",").length === 1 &&
    !isGlobalScoped;
  const userScopeType = isGlobalScoped
    ? ScopeTypes.GLOBAL
    : isSingleScoped
    ? ScopeTypes.SINGLE
    : ScopeTypes.MULTI;

  return userScopeType;
};
