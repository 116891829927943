import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ReactComponent as DrawerCloseIcon } from "Utils/Images/GlobalIcons/drawer-close-icon.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Button,
  Stack,
  IconButton,
  Divider,
  CssBaseline,
  Drawer,
  Box,
  alpha,
} from "@mui/material";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";

const drawerWidth = 256;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    width: `calc(100% - 256px)`,
    // transition: theme.transitions.create("margin", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginRight: -drawerWidth,
    // ...(open && {
    //   transition: theme.transitions.create("margin", {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   marginRight: 0,
    // }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing("14px", 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

export default function PersistentDrawerRight({
  children,
  title,
  body,
  footer,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiDrawer-paper": {
            height: "auto",
            marginRight: "15px",
            top: "10%",
            boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.14)",
            borderRadius: "4px",
          },
        }}
      />
      {/* <Box
        sx={{
          position: "fixed",
          bottom: "0",
          right: "0",
          zIndex: "1000",
          margin: "1rem",
        }}
      >
        {!open && (
          <Button
            onClick={handleDrawerOpen}
            size="small"
            sx={{
              border: "none",
              background: "none",
            }}
          >
            <DrawerCloseIcon />
          </Button>
        )}
      </Box> */}
      <Main open={open}>{children}</Main>
      <Drawer
        sx={{
          width: `${drawerWidth + 10}px`,
          // flexShrink: 0,
          flexGrow: 1,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            overflowX: "hidden",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>{title}</DrawerHeader>
        <Divider />
        {body}
        <Divider />
        {footer && (
          <Stack
            direction="row"
            justifyContent={"space-between"}
            margin={"15px"}
          >
            {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
            {footer}
          </Stack>
        )}
      </Drawer>
    </Box>
  );
}

PersistentDrawerRight.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  footer: PropTypes.node,
};
