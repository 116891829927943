export const MuMessages = new Map([
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_NO_FPGA",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_NO_FPGA",
      messageType: "error",
      description: "Contactor sequence failed - No FPGA detected",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_DISCHARGE_SENSOR",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_DISCHARGE_SENSOR",
      messageType: "error",
      description:
        "Before turning the contactor ON - Discharge sensor is ON (DC MUs only)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_IN_RUSH_SENSOR",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_IN_RUSH_SENSOR",
      messageType: "error",
      description:
        "Before turning the contactor ON - In-Rush sensor is ON (DC MUs only)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_HP_CONTACTOR_ON_SENSOR",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_HP_CONTACTOR_ON_SENSOR",
      messageType: "error",
      description:
        "Before turning the contactor ON - Contactor sensor itself is ON",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_RECTIFIER_SENSOR",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_UNSTABLE_STATE_RECTIFIER_SENSOR",
      messageType: "error",
      description:
        "Before turning the contactor ON - The rectifier sensor is ON (AC MUs only)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_FPGA_CONNECTION",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_FPGA_CONNECTION",
      messageType: "error",
      description:
        "Before turning the contactor ON - The FPGAs were not reachable",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_VERIFICATE_FORCE_OFF",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_VERIFICATE_FORCE_OFF",
      messageType: "error",
      description:
        "Before turning the contactor ON - The Segment Drivers could not be turned OFF",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_GPIO_SET_CONTACTOR_ON",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_GPIO_SET_CONTACTOR_ON",
      messageType: "error",
      description:
        "When turning the contactor ON - The contactor itself could not be turned ON",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_GPIO_SET_CONTACTOR_2_ON",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_GPIO_SET_CONTACTOR_2_ON",
      messageType: "error",
      description:
        "When turning the contactor ON - The SECOND contactor itself could not be turned ON (DC MUs only)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_DISCHARGE_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_DISCHARGE_SENSOR_UNABLE",
      messageType: "error",
      description:
        "When turning the contactor ON - The the discharge sensor could not be turned ON (DC MUs)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_IN_RUSH_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_IN_RUSH_SENSOR_UNABLE",
      messageType: "error",
      description:
        "When turning the contactor ON - The the In-Rush sensor could not be turned ON (DC MUs)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_HP_CONTACTOR_ON_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_HP_CONTACTOR_ON_SENSOR_UNABLE",
      messageType: "error",
      description:
        "When turning the contactor ON - The the contactor sensor itself could not be turned ON",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_RECTIFIER_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ERR_RECTIFIER_SENSOR_UNABLE",
      messageType: "error",
      description:
        "When turning the contactor ON - The the rectifier sensor could not be turned ON (AC MUs)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ABORTED",
    {
      text: "IOT_HUB_TASK_EVENT_CONTACTOR_SEQ_ABORTED",
      messageType: "wraning",
      description: "The user aborted the contactor ON sequence",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_SET_CONTACTOR_OFF_ERR",
    {
      text: "IOT_HUB_TASK_EVENT_SET_CONTACTOR_OFF_ERR",
      messageType: "error",
      description: "Contactor OFF sequence failed",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_APPLICATION_STARTED",
    {
      text: "IOT_HUB_TASK_EVENT_APPLICATION_STARTED",
      messageType: "info",
      progress: "0",
      description: "MU restarted",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_ABORT_CONTACTOR_SEQ_DONE",
    {
      text: "IOT_HUB_TASK_EVENT_ABORT_CONTACTOR_SEQ_DONE",
      messageType: "info",
      description: "Abort contactor ON ended successfully",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_ABORT_CONTACTOR_SEQ_ERR",
    {
      text: "IOT_HUB_TASK_EVENT_ABORT_CONTACTOR_SEQ_ERR",
      messageType: "error",
      description: "General error while aborting contactor ON sequence",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_FPGA_TX_ERR_QUEUE_MAIL_GET",
    {
      text: "IOT_HUB_TASK_EVENT_FPGA_TX_ERR_QUEUE_MAIL_GET",
      messageType: "error",
      description: "Command failed (Get from Queue)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_FPGA_TX_ERR_OS_MAIL_PUT",
    {
      text: "IOT_HUB_TASK_EVENT_FPGA_TX_ERR_OS_MAIL_PUT",
      messageType: "error",
      description: "Command failed (Put into Queue)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_FPGA_TX_ERR_OS_MAIL_ALLOC",
    {
      text: "IOT_HUB_TASK_EVENT_FPGA_TX_ERR_OS_MAIL_ALLOC",
      messageType: "error",
      description: "Command failed (no memory on device)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_DISCHARGE_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_DISCHARGE_SENSOR_UNABLE",
      messageType: "error",
      description:
        "While contactor is ON - Discharge sensor turned to OFF (DC MUs only)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_IN_RUSH_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_IN_RUSH_SENSOR_UNABLE",
      messageType: "error",
      description:
        "While contactor is ON - In-Rush sensor turned to OFF (DC MUs only)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_HP_CONTACTOR_ON_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_HP_CONTACTOR_ON_SENSOR_UNABLE",
      messageType: "error",
      description: "While contactor is ON - Contactor sensor turned to OFF",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_RECTIFIER_SENSOR_UNABLE",
    {
      text: "IOT_HUB_TASK_EVENT_GPIO_HP_CHECK_ERR_RECTIFIER_SENSOR_UNABLE",
      messageType: "error",
      description:
        "While contactor is ON - Rectifier sensor turned to OFF (AC MUs only)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_STARTED",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_STARTED",
      messageType: "info",
      description: "File upload started",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_DONE",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_DONE",
      messageType: "info",
      description: "File upload done",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_ERR_FILE_SIZE",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_ERR_FILE_SIZE",
      messageType: "error",
      description: "File upload error (size)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_ERR_OPEN_FILE",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_ERR_FILE_SIZE",
      messageType: "error",
      description: "File upload error (open file)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_ERR_READ_DATA",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_ERR_READ_DATA",
      messageType: "error",
      description: "File upload error (read file)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_ERR_CONNECTION",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_ERR_READ_DATA",
      messageType: "error",
      description: "File upload error (connection)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_DOWNLOAD_STARTED",
    {
      text: "IOT_HUB_TASK_EVENT_DOWNLOAD_STARTED",
      messageType: "info",
      description: "File download started",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_DOWNLOAD_DONE",
    {
      text: "IOT_HUB_TASK_EVENT_DOWNLOAD_DONE",
      messageType: "info",
      description: "File download done",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_OPEN_FILE",
    {
      text: "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_OPEN_FILE",
      messageType: "error",
      description: "File download error (open file)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_URL",
    {
      text: "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_URL",
      messageType: "error",
      description: "File download error (URL)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_CONNECTION",
    {
      text: "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_CONNECTION",
      messageType: "error",
      description: "File download error (connection)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_HTTP_GET",
    {
      text: "IOT_HUB_TASK_EVENT_DOWNLOAD_ERR_HTTP_GET",
      messageType: "error",
      description: "File download error (HTTP GET)",
    },
  ],
  //ST FOTA progress
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_0_TO_19",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_0_TO_19",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 10,
      description: "Show the 10% Icon - will not show on logs panel",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_20_TO_39",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_20_TO_39",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 30,
      description: "Show the 30% Icon - will not show on logs panel",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_40_TO_59",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_40_TO_59",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 50,
      description: "Show the 50% Icon - will not show on logs panel",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_60_TO_79",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_60_TO_79",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 70,
      description: "Show the 70% Icon - will not show on logs panel",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_80_TO_99",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_80_TO_99",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 90,
      description: "Show the 90% Icon - will not show on logs panel",
    },
  ],
  //ST FOTA progress - in list - do not remove
  [
    "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_100",
    {
      text: "IOT_HUB_TASK_EVENT_UPLOAD_DOWNLOAD_PERCENTAGES_OF_PROGRESS_100",
      messageType: "info",
      eventType: "show-progress",
      progress: 100,
      description: "FOTA complete, device will reset",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_FOTA_STARTED",
    {
      text: "IOT_HUB_TASK_EVENT_FOTA_STARTED",
      messageType: "info",
      eventType: "show-progress",
      progress: 2,
      description: "FOTA started",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_FOTA_DONE_RESETING_NOW",
    {
      text: "IOT_HUB_TASK_EVENT_FOTA_DONE_RESETING_NOW",
      messageType: "info",
      eventType: "show-progress",
      progress: 100,
      description: "Resetting...",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_FOTA_ERR_DOWNLOAD_FILE",
    {
      text: "IOT_HUB_TASK_EVENT_FOTA_ERR_DOWNLOAD_FILE",
      messageType: "error",
      description: "FOTA failed",
    },
  ],

  //VuMessages

  [
    "IOT_HUB_TASK_EVENT_APPLICATION_STARTED_WDG",
    {
      text: "IOT_HUB_TASK_EVENT_APPLICATION_STARTED_WDG",
      messageType: "info",
      description: "Application restarted (WDG)",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_TURNED_TO_REGULAR_MODE",
    {
      text: "IOT_HUB_TASK_EVENT_TURNED_TO_REGULAR_MODE",
      messageType: "info",
      description: "Unit in Regular mode",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_TURNED_TO_DEBUG_MODE",
    {
      text: "IOT_HUB_TASK_EVENT_TURNED_TO_DEBUG_MODE",
      messageType: "info",
      description: "Unit in Debug mode",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CHARGING_ALLOWED_IND_TURNED_OFF_BY_CAN_BUS",
    {
      text: "IOT_HUB_TASK_EVENT_CHARGING_ALLOWED_IND_TURNED_OFF_BY_CAN_BUS",
      messageType: "info",
      description: "Charging turned off by CAN Bus",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CHARGING_ALLOWED_IND_TURNED_OFF_BY_CAN_BUS_ERROR",
    {
      text: "IOT_HUB_TASK_EVENT_CHARGING_ALLOWED_IND_TURNED_OFF_BY_CAN_BUS_ERROR",
      messageType: "error",
      description: "Charging turned off by CAN Bus due to error",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_CHARGING_ALLOWED_IND_TURNED_ON_BY_CAN_BUS",
    {
      text: "IOT_HUB_TASK_EVENT_CHARGING_ALLOWED_IND_TURNED_ON_BY_CAN_BUS",
      messageType: "info",
      description: "Charging turned on by CAN Bus",
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_RECEIVER_1_STATE_TURNED_TO_ERROR",
    {
      text: "IOT_HUB_TASK_EVENT_RECEIVER_1_STATE_TURNED_TO_ERROR",
      messageType: "error",
      description: "Receiver 1 in error state",
      reciever: 1,
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_RECEIVER_2_STATE_TURNED_TO_ERROR",
    {
      text: "IOT_HUB_TASK_EVENT_RECEIVER_2_STATE_TURNED_TO_ERROR",
      messageType: "error",
      description: "Receiver 2 in error state",
      reciever: 2,
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_RECEIVER_3_STATE_TURNED_TO_ERROR",
    {
      text: "IOT_HUB_TASK_EVENT_RECEIVER_3_STATE_TURNED_TO_ERROR",
      messageType: "error",
      description: "Receiver 3 in error state",
      reciever: 3,
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_RECEIVER_4_STATE_TURNED_TO_ERROR",
    {
      text: "IOT_HUB_TASK_EVENT_RECEIVER_4_STATE_TURNED_TO_ERROR",
      messageType: "error",
      description: "Receiver 4 in error state",
      reciever: 4,
    },
  ],
  [
    "IOT_HUB_TASK_EVENT_RECEIVER_5_STATE_TURNED_TO_ERROR",
    {
      text: "IOT_HUB_TASK_EVENT_RECEIVER_5_STATE_TURNED_TO_ERROR",
      messageType: "error",
      description: "Receiver 5 in error state",
      reciever: 5,
    },
  ],
]);
