const MUsReducer = (state, action) => {
  switch (action.type) {
    case "MUS_EVENTS_MESSAGES":
      return {
        ...state,
        musEventsMessages: [
          action.payload.musEventsMessages,
          ...state.musEventsMessages,
        ],
      };
    case "CLEAR_MUS_EVENTS_MESSAGES_AND_FOTA":
      return {
        ...state,
        musEventsMessages: [],
        fotaProgress: [],
      };
    case "FPGA_PROGRESS":
      return {
        ...state,
        fpgaProgress: {
          ...state.fpgaProgress,
          [action.payload.fpgaDeviceId]: action.payload.fpgaProgress,
        },
        fpgaProgressDone: action.payload.fpgaProgressDone,
        fpgaIsInProgress: action.payload.fpgaIsInProgress,
      };
    case "CLEAR_FPGA_PROGRESS":
      return {
        ...state,
        fpgaProgress: [],
      };
    case "FOTA_PROGRESS":
      return {
        ...state,
        fotaProgress: {
          ...state.fotaProgress,
          [action.payload.deviceId]: action.payload.fotaProgress,
        },
      };
    case "MUS_EVENTS_MESSAGES_POP":
      return {
        ...state,
        musEventsMessages: state.musEventsMessages.filter(
          (_, i) => i !== state.musEventsMessages.length - 1
        ),
      };
    default:
      return state;
  }
};

export default MUsReducer;
