const bookmarksReducer = (state, action) => {
  switch (action.type) {
    case "SET_BOOKMARK_LIST":
      return {
        ...state,
        bookmarks: action.payload.bookmarks,
      };
    case "ADD_BOOKMARK":
      return {
        ...state,
        bookmarks: [...state.bookmarks, action.payload.bookmark],
      };
    case "SET_BOOKMARK_INIT_DATA":
      return {
        ...state,
        bookmarkData: action.payload.bookmarkData,
      };

    default:
      return state;
  }
};

export default bookmarksReducer;
