import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { GoEyeClosed } from "react-icons/go";
import { MdCloudUpload } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import "Components/Dropzone/Dropzone.scss";

const thumbsContainer = {
  display: "flex",
  position: "relative",
  border: "1px black dotted",
  flexDirection: "column",
  textAlign: "center",
  width: "180px",
  height: "120px",
  justifyContent: "center",
  alignItems: "center",
  margin: "5px auto",
  padding: "10px",
};

const renderPreview = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const imageContainer = {
  overflow: "hidden",
  borderRadius: "2%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const img = {
  width: 200,
  height: 139,
};

const imageButton = {
  position: "absolute",
  right: 0,
  top: 0,
  border: 0,
  fontSize: 20,
  borderRadius: ".325em",
  cursor: "pointer",
};

const closeError = {
  position: "absolute",
  right: "-8%",
  top: "-10%",
  background: "rgba(230,230,230, 0.99)",
  borderRadius: "50%",
  cursor: "pointer",
  fontSize: "18px",
  border: "0.5px solid #d83b01",
};

function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const [fileErrors, setfileErrors] = useState([]);
  const MAX_SIZE = 107180;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    maxSize: MAX_SIZE,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      const convertBase64 = (blob) => {
        if (blob) {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(blob);

            fileReader.onload = () => {
              resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        } else {
          setFiles([]);
        }
      };

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      const image = acceptedFiles.find((f) => f);
      const result = await convertBase64(image);

      if (props.onChange && acceptedFiles.length > 0) {
        props.onChange(result);
      } else {
        props.onChange("");
      }

      setfileErrors(rejectedFiles.length > 0 ? rejectedFiles[0].errors : []);
      showText = files.length === 0;
    },

    saccess: (files) => {
      files
        .find((f) => {
          removeFile(f);
        })
        .then(() => {
          setFiles([]);
        });
    },

    onDragEnter: () => {
      setFiles([]);
      setfileErrors([]);
    },
  });

  let showText = files.length === 0;
  let classes = "dropzone";

  const additionalClass = isDragAccept
    ? `${classes} accept`
    : isDragReject
    ? `${classes} reject`
    : classes;

  const revokeDataUri = (files) => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  };

  const Thumbs = files.map((file) => (
    <div style={renderPreview} key={file.name}>
      <div style={imageContainer}>
        <img src={file.preview} style={img} alt={file.name} />
      </div>
    </div>
  ));

  useEffect(
    () => () => {
      revokeDataUri(files);
    },
    [files]
  );

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    revokeDataUri(files);
    alert("This will remove image preview and logo from farm");
    if (props.onChange) {
      props.onChange("");
    }
  };

  const onClickHandler = () => {
    revokeDataUri(files);
    setFiles([]);
    setfileErrors([]);
    if (props.onChange) {
      props.onChange("");
    }
  };

  const errors = {
    FILESIZE: "File too large-click again",
    FILETYPE: "Not an image file",
  };

  const getErrorMessage = () => {
    switch (fileErrors[0].code) {
      case "file-invalid-type":
        return (
          <div>
            <IoMdClose style={closeError} onClick={onClickHandler} />
            <p className={"error"}>{errors.FILETYPE}</p>
            <MdCloudUpload style={{ fontSize: 50 }} />
          </div>
        );
      case "file-too-large":
        return (
          <span>
            <IoMdClose style={closeError} onClick={onClickHandler} />
            <p className={"error"}>{errors.FILESIZE}</p>
            <MdCloudUpload style={{ fontSize: 50 }} />
          </span>
        );
      default:
        return (
          <span>
            <IoMdClose style={closeError} onClick={onClickHandler} />
            <p className={"error"}>File Erorr</p>
            <MdCloudUpload style={{ fontSize: 50 }} />
          </span>
        );
    }
  };

  return (
    <section style={thumbsContainer}>
      <div {...getRootProps({ className: `${additionalClass}` })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          isDragReject ? (
            <p>Not an image file</p>
          ) : (
            <p>Drop file here ...</p>
          )
        ) : (
          showText &&
          (fileErrors.length > 0 ? (
            getErrorMessage()
          ) : (
            <MdCloudUpload style={{ fontSize: 50 }} />
          ))
        )}
        {files.map((file) => (
          <>
            {Thumbs}
            <GoEyeClosed style={imageButton} onClick={onClickHandler} />
          </>
        ))}
      </div>
    </section>
  );
}

export default Dropzone;
