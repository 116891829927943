import moment from "moment";

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { muColorsForGraph } from "Utils/AnalyticUtils";
import { DiscreteGraphProps } from "Components/Graphs/AdvancedParamsGraphs";

const DiscreteGraph: DiscreteGraphProps = ({
  timeSeries,
  index,
  id,
  domainStart = "dataMin",
  domainEnd = "dataMax",
  tooltip = true,
  strokeWidth = 20,
}) => {
  return (
    <ResponsiveContainer height={70} className="discrete-graph">
      <LineChart data={timeSeries} syncId={id} syncMethod="value">
        <CartesianGrid vertical={false} stroke="#0000001f" />
        <XAxis
          axisLine={false}
          tick={false}
          dataKey="timestamp"
          type="number"
          domain={[
            moment(domainStart.toString()).valueOf(),
            moment(domainEnd.toString()).valueOf(),
          ]}
          allowDataOverflow
        />
        <YAxis hide domain={[0, 2]} tickCount={3} />
        <Line
          type="monotone"
          strokeWidth={strokeWidth}
          dataKey={(data) => (data.value ? 1 : null)}
          stroke={muColorsForGraph[index]}
          opacity={0.6}
          dot={false}
          name="value"
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DiscreteGraph;
