import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";

const DepotMainTable = ({ rows, columns }) => {
  return (
    <DataGrid
      getRowHeight={() => "auto"}
      sx={{ border: 0, width: "100%" }}
      rows={rows}
      columns={columns}
      autoHeight
      hideFooter
      hideborder
    />
  );
};
export default DepotMainTable;

DepotMainTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      state: PropTypes.shape({
        batteryLevel: PropTypes.number,
        charging: PropTypes.bool,
      }),
      connectionStatus: PropTypes.string,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      width: PropTypes.number,
    })
  ),
};
