import {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
  useContext,
} from "react";
import _ from "lodash";
import moment from "moment";

import useSelectedProjectData from "CustomHooks/useSelectedProject";
import VUsContext from "Contexts/VUsContext";

import {
  defaultZoomArea,
  getHistoryFormattedData,
  formatDataForGraph,
  getZoomTicks,
} from "Utils/GraphUtils";
import {
  getSessionIdProject,
  getHistory,
  getAnalyticsGraphData,
} from "Utils/APIUtils";
import { formatDataForAnalyticsGraph } from "Utils/AnalyticUtils";

const useGraphZoom = (
  graphData,
  messageKeys,
  selectedDevice,
  AnalyticsVuList,
  handleGraphClick,
  graphType,
  graphPowerFormatter,
  graphRef
) => {
  const [zoomData, setZoomData] = useState(graphData);
  const [zoomArea, setZoomArea] = useState(defaultZoomArea);
  const [isZooming, setIsZooming] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isZoomLoading, setIsZoomLoading] = useState(false);

  const { timezoneStr } = useSelectedProjectData();
  const { activeVU } = useContext(VUsContext);

  const counterRef = useRef(0);
  const isZoomOutRef = useRef(true);
  const isCTRLPressedRef = useRef(false);

  useEffect(() => {
    setIsZoomed(false);
    handleZoomOut();
  }, [Object.keys(graphData).length]);

  const showZoomBox =
    isZooming &&
    !(Math.abs(zoomArea.x1 - zoomArea.x2) < 2) &&
    !(Math.abs(zoomArea.y1 - zoomArea.y2) < 2);

  const historyZoomSkip = (dataToLoad) => {
    setIsZoomLoading(true);
    const mappedZoomData = {};
    return Promise.all([
      getHistory({
        ...dataToLoad,
        deviceType: selectedDevice?.deviceType,
        deviceId: selectedDevice?.id,
      }),

      // check if needs to send for activeVU
      // if not, resolve to null.
      selectedDevice?.deviceType === "MU" && activeVU
        ? getHistory({
            ...dataToLoad,
            deviceType: activeVU?.deviceType,
            deviceId: activeVU?.id,
          })
        : null,
    ])
      .then((values) => {
        const [selectedDeviceHistoryRes, activeVUHistoryRes] = values;
        if (selectedDeviceHistoryRes?.status !== 200) {
          throw new Error();
        }
        if (selectedDeviceHistoryRes) {
          const { data } = selectedDeviceHistoryRes;
          if (data?.timeSeries.length) {
            mappedZoomData[data.deviceId] = getHistoryFormattedData(data);
          }
        }
        if (activeVUHistoryRes) {
          const { data } = activeVUHistoryRes;
          if (data?.timeSeries.length) {
            mappedZoomData[data.deviceId] = getHistoryFormattedData(data);
          }
        }

        let min = Infinity,
          max = 0;
        const { data } = mappedZoomData[selectedDevice.id];
        for (let i = 0; i < data.length; i++) {
          if (data[i].timestamp < min) min = data[i].timestamp;
          if (data[i].timestamp > max) max = data[i].timestamp;
        }
        const formattedZoomData = formatDataForGraph(
          mappedZoomData,
          messageKeys,
          graphType,
          max,
          max - min,
          graphPowerFormatter
        );
        setZoomData(formattedZoomData);
        setIsZoomLoading(false);
        setIsZoomed(true);
      })
      .catch(({ err }) => {
        console.log("error is: ", err);
        setIsZoomLoading(false);
      });
  };

  const analyticsZoomSkip = (dataToLoad) => {
    setIsZoomLoading(true);
    const startTime = dataToLoad.startSec;
    const endTime = moment(
      new Date(startTime).getTime() + dataToLoad.rangeSec * 1000
    )
      .tz(timezoneStr)
      .format("YYYY-MM-DDTHH:mm:ss");

    getAnalyticsGraphData(
      selectedDevice,
      AnalyticsVuList,
      startTime,
      endTime,
      timezoneStr
    ).then((res) => {
      if (res?.data) {
        const resKeys = Object.keys(res.data);
        setIsZoomed(true);
        setIsZoomLoading(false);
        setZoomData(formatDataForAnalyticsGraph(res.data, resKeys, "power"));
      }
    });
  };

  const handleZoomOut = () => {
    setZoomData(graphData);
    setZoomArea(defaultZoomArea);
    setIsZoomed(false);
    counterRef.current = 0;
  };

  const handleMouseDown = (e) => {
    setIsZooming(true);
    setZoomArea({ x1: e?.activeLabel, x2: e?.activeLabel });
    isZoomOutRef.current = false;
  };

  const handleMouseMove = (e) => {
    if (e?.activeLabel) {
      if (!zoomArea.x1) {
        setZoomArea({ x1: e?.activeLabel, x2: e?.activeLabel });
      }
      setZoomArea((prev) => ({ ...prev, x2: e?.activeLabel }));
    }
  };

  const handleMouseUp = (e) => {
    if (isZooming) {
      setIsZooming(false);
      let { x1, x2 } = zoomArea;

      if (x1 === x2) {
        if (handleGraphClick) handleGraphClick();
        return;
      }

      if (x1 > x2) [x1, x2] = [x2, x1];

      if (Math.round((x2 - x1) / 1000) === 0) {
        console.log("span must be greater than 0");
        return;
      }

      const dataToLoad = {
        sessionId: getSessionIdProject(),
        startSec: moment(x1).tz(timezoneStr).format("YYYY-MM-DDTHH:mm:ssZ"),
        rangeSec: Math.round((x2 - x1) / 1000),
        projectTz: timezoneStr,
      };
      if (AnalyticsVuList) {
        analyticsZoomSkip(dataToLoad);
      } else {
        historyZoomSkip(dataToLoad);
      }
    }
  };

  // useEffect fetch history data after scroll zoom
  useEffect(() => {
    if (counterRef.current > 3600) return;
    if (!isZooming && counterRef.current !== 0) {
      const counterInMs = counterRef.current * 1000;
      //zoom out edge timestamp
      const minTimestamp = graphData?.[0]?.timestamp - counterInMs / 2;
      const maxTimestamp = graphData?.at(-1)?.timestamp + counterInMs / 2;

      //block zoom counter
      if (counterRef.current === 3600) {
        counterRef.current = 4000;
      }

      const dataToLoad = {
        sessionId: getSessionIdProject(),
        startSec: moment(minTimestamp)
          .tz(timezoneStr)
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        rangeSec: Math.round((maxTimestamp - minTimestamp) / 1000),
        projectTz: timezoneStr,
      };
      if (AnalyticsVuList) {
        analyticsZoomSkip(dataToLoad);
      } else {
        historyZoomSkip(dataToLoad);
      }
    }
  }, [isZooming]);

  // debounce function. will run 200ms after last scroll event on graph
  const debounceForScroll = _.debounce(() => {
    // check if CTRL key was pressed during scroll
    if (isCTRLPressedRef.current) {
      setIsZooming(false);
    } else {
      setIsZoomLoading(false);
    }
    isCTRLPressedRef.current = false;
    isZoomOutRef.current = false;
  }, 200);

  // mouse wheel event handler
  const handleMouseScrollZoomOut = useCallback(
    (e) => {
      isZoomOutRef.current = true;
      if (!isZooming) {
        setIsZooming(true);
      }

      //check if CTRL key was pressed during scroll.
      if (e.ctrlKey) {
        e.preventDefault();
        isCTRLPressedRef.current = true;
      }

      //if ctrl key not pressed but was pressed when scroll started
      if (!e.ctrlKey && !isCTRLPressedRef.current) {
        setIsZoomLoading(false);
        return;
      }

      switch (counterRef.current) {
        case 0:
          counterRef.current = 1;
          return;
        case 1:
          counterRef.current = 2;
          return;
        case 2:
          counterRef.current = 5;
          return;
        case 5:
          counterRef.current = 10;
          return;
        case 10:
          counterRef.current = 30;
          return;
        case 30:
          counterRef.current = 60;
          return;
        case 60:
          counterRef.current = 120;
          return;
        case 120:
          counterRef.current = 600;
          return;
        case 600:
          counterRef.current = 1800;
          return;
        case 1800:
          counterRef.current = 3600;
          return;
      }

      debounceForScroll();
    },
    [debounceForScroll, isZooming]
  );

  const zoomTicksArray = useMemo(() => {
    if (isZoomed) {
      return getZoomTicks(zoomData);
    } else {
      return [];
    }
  }, [isZoomed, zoomData]);

  // useEffect to add mouse wheel event listener to graph
  // disable scroll on ctrl pressed to prevent conflict with graph zoom
  useEffect(() => {
    if (graphRef?.current) {
      const graphElement = graphRef.current.container;

      graphElement.onmousewheel = (e) => handleMouseScrollZoomOut(e);
    }
  }, [graphRef?.current]);

  return {
    showZoomBox,
    zoomArea,
    isZoomed,
    isZooming,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleZoomOut,
    zoomData,
    zoomTicksArray,
    setZoomData,
    historyZoomSkip,
    isZoomLoading,
    setIsZoomed,
    isZoomOutRef,
  };
};
export default useGraphZoom;
