import { Device } from "GlobalTypes";
import { computed, makeAutoObservable, runInAction } from "mobx";
import {
  AdvancedParamsData,
  DataToExport,
  EmptyGraphs,
  ProjectAdvancedParam,
  SelectedDevices,
  SummaryTableData,
  TimeSeries,
  GetSummaryTableData,
  GetDataForSelectedTimespan,
  SetSelectedTimespan,
  TimeRulerBorder,
} from "MobxStores/Types";
import { isDataRequest } from "MobxStores/utils/typeCheckers";
import moment, { Moment } from "moment";
import {
  createTimeSeries,
  getFormattedTableData,
  getStartAndEndTime,
  normalizeTimeSeriesLengths,
  // parseAdvancedParamsDataForExport,
} from "Utils/AnalyticUtils";
import {
  fetchAdvancedParamsData,
  getAnalyticsDeviceActivity,
  getTableData,
} from "Utils/APIUtils";
import { convertFormattedTimeToMiliseconds } from "Utils/UtilsFunctions";

class ProjectAnalyticsStore {
  timezoneStr: string = "";
  selectedDevices: SelectedDevices = {}; // { deviceId: { device, selectedParameters: Set()}}
  projectAdvancedParams: ProjectAdvancedParam[] = [];
  projectMuParams: ProjectAdvancedParam[] = [];
  projectVuParams: ProjectAdvancedParam[] = [];
  projectAnalyticsSummaryTableData: SummaryTableData = {};
  dataToExport: DataToExport = [];
  isLoadingDataFromServer: boolean = false;
  isLoadingGraphData: boolean = false;
  advancedParamsData: AdvancedParamsData = [];
  selectedDate: string = moment().format("YYYY-MM-DD");
  deviceActivityData: TimeSeries = [];
  selectedActivityDevice: string = "";
  selectedTimespanIndices = { startIndex: 0, endIndex: 0 };
  selectedTimespanValues = { startTime: moment(), endTime: moment() };
  selectedTimeRange: TimeSeries = [];
  emptyGraphs: EmptyGraphs = [];
  deviceActivityLoaded = false;
  defaultStartHour: number = 9;
  defaultEndHour: number = 9;
  defaultStartMinute: number = 0;
  defaultEndMinute: number = 30;
  maxPowerGraphValue: number = 0;
  selectedGraphDevices: SelectedDevices = {};
  showTimeRulerBorder: TimeRulerBorder = {
    left: true,
    right: true,
  };
  isZooming: boolean = false;
  timeValuesBeforeZoom: {
    start: Moment;
    end: Moment;
  } = { start: moment(), end: moment() };
  dataRequestHistory: Array<Parameters<GetDataForSelectedTimespan>> = [];
  historyTraversal: { stepsBack: number } = { stepsBack: 0 };

  constructor() {
    makeAutoObservable(this, {
      projectAdvancedParams: true,
      deviceForActivityGraph: computed,
      selectedParamCount: computed,
      selectedMuIds: computed,
      selectedVuIds: computed,
    });
  }

  stepBackInHistory = () => {
    const preventStepBack =
      this.historyTraversal.stepsBack >= this.dataRequestHistory.length - 1 ||
      this.dataRequestHistory.length < 2;
    if (preventStepBack) return;
    this.historyTraversal.stepsBack++;
    const previousRequest =
      this.dataRequestHistory[
        this.dataRequestHistory.length - this.historyTraversal.stepsBack - 1
      ];
    if (isDataRequest(previousRequest)) {
      const [startTime, endTime, timezoneStr, zoomStart, zoomEnd] =
        previousRequest;
      this.setSelectedTimespan(startTime, endTime, zoomStart, zoomEnd);
      this.getDataForSelectedTimespan(...previousRequest);
      this.getSummaryTableData(timezoneStr);
    }
  };

  stepForwardInHistory = () => {
    const preventStepForward = this.historyTraversal.stepsBack === 0;
    if (preventStepForward) return;
    this.historyTraversal.stepsBack--;
    const nextRequest =
      this.dataRequestHistory[
        this.dataRequestHistory.length - this.historyTraversal.stepsBack - 1
      ];
    if (isDataRequest(nextRequest)) {
      const [startTime, endTime, timezoneStr, zoomStart, zoomEnd] = nextRequest;
      this.setSelectedTimespan(startTime, endTime, zoomStart, zoomEnd);
      this.getDataForSelectedTimespan(...nextRequest);
      this.getSummaryTableData(timezoneStr);
    }
  };

  get selectedMuIds() {
    return Object.values(this.selectedDevices)
      .reduce<string[]>((acc, { device }) => {
        if (device.deviceType === "MU") {
          acc.push(device.id);
        }
        return acc;
      }, [])
      .sort((a, b) => +a.slice(2) - +b.slice(2));
  }

  get selectedVuIds() {
    return Object.values(this.selectedDevices)
      .reduce<string[]>((acc, { device }) => {
        if (device.deviceType === "VU") {
          acc.push(device.id);
        }
        return acc;
      }, [])
      .sort((a, b) => +a.slice(2) - +b.slice(2));
  }

  addDeviceToSelection(device: Device) {
    if (this.selectedDevices[device.id]) return;
    const defaultSelectedparameter =
      device.deviceType === "MU" ? "MU_DC_METER_POWER" : "VU_DC_METER_POWER";
    this.selectedDevices[device.id] = {
      device,
      selectedParameters: new Set([defaultSelectedparameter]),
    };
  }

  removeDeviceFromSelection(deviceId: string) {
    if (!this.selectedDevices[deviceId]) return;
    delete this.selectedDevices[deviceId];
  }

  toggleDeviceSelection = (device: Device) => {
    if (this.selectedDevices[device.id]) {
      this.removeDeviceFromSelection(device.id);
    } else {
      this.addDeviceToSelection(device);
    }
  };

  addParameterToSelection(device: Device, parameter: string) {
    if (!this.selectedDevices[device.id]) {
      this.addDeviceToSelection(device);
    }
    this.selectedDevices[device.id].selectedParameters.add(parameter);
  }

  removeParameterFromSelection = (deviceId: string, parameter: string) => {
    if (!this.selectedDevices[deviceId]) return;
    this.selectedDevices[deviceId].selectedParameters.delete(parameter);
    if (this.selectedDevices[deviceId].selectedParameters.size === 0) {
      this.removeDeviceFromSelection(deviceId);
    }
  };

  toggleParameterSelection(device: Device, parameter: string) {
    const isSelected = this.isParameterSelected(device.id, parameter);
    if (!isSelected && !this.selectedDevices[device.id]) {
      this.addDeviceToSelection(device);
    }

    if (isSelected) {
      this.removeParameterFromSelection(device.id, parameter);
    } else {
      this.addParameterToSelection(device, parameter);
    }
  }

  isDeviceSelected(deviceId: string) {
    return Boolean(this.selectedDevices[deviceId]);
  }

  isParameterSelected(deviceId: string, parameter: string) {
    if (!this.selectedDevices[deviceId]) return false;
    return this.selectedDevices[deviceId].selectedParameters.has(parameter);
  }

  setProjectAdvancedParams(projectAdvancedParams: ProjectAdvancedParam[]) {
    this.projectAdvancedParams = projectAdvancedParams;
    this.projectMuParams = projectAdvancedParams.filter(
      (param) => param.deviceType === "MU"
    );
    this.projectVuParams = projectAdvancedParams.filter(
      (param) => param.deviceType === "VU"
    );
  }

  setProjectAnalyticsSummaryTableData(tableData: SummaryTableData) {
    this.projectAnalyticsSummaryTableData = tableData;
  }

  setIsLoadingDataFromServer = (isLoading: boolean) =>
    (this.isLoadingDataFromServer = isLoading);

  setIsLoadingGraphData = (isLoading: boolean) =>
    (this.isLoadingGraphData = isLoading);

  setDataToExport(dataToExport: DataToExport) {
    this.dataToExport = dataToExport;
  }

  setAdvancedParamsData = (data: AdvancedParamsData) => {
    this.advancedParamsData = data;
  };

  clearAdvancedParamsData = () => (this.advancedParamsData = []);

  setSelectedDate = (date: Moment) => {
    this.historyTraversal.stepsBack = 0;
    this.dataRequestHistory = [];
    this.selectedDate = date.format("YYYY-MM-DD");
  };

  setDeviceActivityData = (data: TimeSeries) => {
    this.deviceActivityData = data;
  };

  clearDeviceActivityData = () => (this.deviceActivityData = []);

  setSelectedActivityDevice = (deviceId: string) =>
    (this.selectedActivityDevice = deviceId);

  getDeviceActivityData = async (
    timezoneStr: string,
    selectedDeviceId: string
  ) => {
    this.deviceActivityLoaded = false;
    this.setIsLoadingDataFromServer(true);
    this.setIsLoadingGraphData(true);
    const { startTime } = getStartAndEndTime(this.selectedDate);
    const device =
      this.selectedDevices[selectedDeviceId]?.device ||
      this.deviceForActivityGraph;
    const { id, deviceType } = device;
    this.setSelectedActivityDevice(id);

    const deviceActivityData = await getAnalyticsDeviceActivity(
      deviceType,
      id,
      this.selectedDate,
      6,
      timezoneStr || this.timezoneStr
    );
    this.deviceActivityLoaded = true;

    // fills missing data points to create a series of 36 hours with minute intervals
    const {
      filledTimeSeries,
      initialActivityIndex,
      finalActivityIndex,
      fallbackStartIndex,
      fallbackEndIndex,
    } = createTimeSeries(
      timezoneStr,
      deviceActivityData,
      id,
      startTime,
      {
        // default start time to get data from - provides index of fallback start time if no activity is found
        defaultStartTime: moment(this.selectedDate)
          .hours(this.defaultStartHour)
          .minutes(this.defaultStartMinute || 0),

        // default end time to get data from - provides index of fallback end time if no activity is found
        defaultEndTime: moment(this.selectedDate)
          .hours(this.defaultEndHour)
          .minutes(this.defaultEndMinute || 0),
      },
      this.selectedDate
    );

    if (
      device &&
      this.selectedTimespanValues &&
      this.deviceActivityData.length
    ) {
      runInAction(async () => {
        await this.getDataForSelectedTimespan(
          null,
          null,
          timezoneStr,
          this.selectedTimespanValues.startTime,
          this.selectedTimespanValues.endTime
        );
        this.setIsLoadingGraphData(false);
        this.setIsLoadingDataFromServer(false);
      });
      return;
    }

    this.setDeviceActivityData(filledTimeSeries);

    runInAction(async () => {
      if (initialActivityIndex) {
        this.setSelectedTimespan(initialActivityIndex, finalActivityIndex!);
        await this.getDataForSelectedTimespan(
          initialActivityIndex,
          finalActivityIndex || null,
          timezoneStr
        );
      } else {
        // get data for default timespan
        console.info(`No activity found for ${id} on ${this.selectedDate}`);
        console.info(
          `Retreiving data for default timespan indexes: ${fallbackStartIndex} - ${fallbackEndIndex}`
        );
        if (!fallbackStartIndex || !fallbackEndIndex) {
          console.info(`No fallback indexes found, applying full timespan`);
        }

        this.setSelectedTimespan(
          fallbackStartIndex || 0,
          fallbackEndIndex || filledTimeSeries.length - 1
        );

        await this.getDataForSelectedTimespan(null, null, timezoneStr);
      }
      runInAction(() => {
        this.setIsLoadingGraphData(false);
        this.setIsLoadingDataFromServer(false);
      });
    });
  };

  getPreviousActivityDay = async (timezoneStr: string) => {
    this.setIsLoadingDataFromServer(true);
    this.setIsLoadingGraphData(true);
    const previousDay = moment(this.selectedDate).subtract(1, "days");
    this.clearAdvancedParamsData();
    this.clearDeviceActivityData();
    this.setSelectedDate(previousDay);
    await this.getDeviceActivityData(timezoneStr, this.selectedActivityDevice);
    await this.getSummaryTableData(timezoneStr);
    runInAction(() => {
      this.setIsLoadingDataFromServer(false);
      this.setIsLoadingGraphData(false);
    });
  };

  getNextActivityDay = async (timezoneStr: string) => {
    this.setIsLoadingDataFromServer(true);
    this.setIsLoadingGraphData(true);
    const nextDay = moment(this.selectedDate).add(1, "days");
    this.clearAdvancedParamsData();
    this.clearDeviceActivityData();
    this.setSelectedDate(nextDay);
    await this.getDeviceActivityData(timezoneStr, this.selectedActivityDevice);
    await this.getSummaryTableData(timezoneStr);
    runInAction(() => {
      this.setIsLoadingDataFromServer(false);
      this.setIsLoadingGraphData(false);
    });
  };

  setSelectedTimespan: SetSelectedTimespan = (
    startIndex,
    endIndex,
    zoomStart = null,
    zoomEnd = null
  ) => {
    if (!startIndex || !endIndex) {
      console.error('No "startIndex" or "endIndex" provided');
      return;
    }

    this.selectedTimespanIndices = { startIndex, endIndex };

    let startTime = moment(this.deviceActivityData[startIndex]?.timestamp);
    let endTime = moment(this.deviceActivityData[endIndex]?.timestamp);

    if (zoomStart && zoomEnd) {
      startTime = moment(zoomStart);
      endTime = moment(zoomEnd);
    }

    console.info(`Setting selected timespan for ${startTime} to ${endTime}`);

    this.selectedTimespanValues = {
      startTime,
      endTime,
    };
  };

  get deviceForActivityGraph() {
    const sortedDevices = Object.values(this.selectedDevices).sort((a, b) =>
      a.device.id.slice(2) > b.device.id.slice(2) ? 1 : -1
    );
    const firstVu = sortedDevices.find(
      ({ device }) => device.deviceType === "VU"
    );
    if (firstVu) return firstVu.device;
    const firstMu = sortedDevices.find(
      ({ device }) => device.deviceType === "MU"
    );
    if (firstMu) return firstMu.device;
    return null;
  }

  get selectedParamCount() {
    let count = 0;
    Object.values(this.selectedDevices).forEach((device) => {
      count += device.selectedParameters.size;
    });
    return count;
  }

  removeAdvancedParam = (id: string, deviceId: string) => {
    const filteredParams = this.advancedParamsData.filter((el) =>
      el.deviceId === deviceId ? el.param.id !== id : el
    );

    this.setAdvancedParamsData(filteredParams);
  };

  removeEmptyParam = (id: string, deviceId: string) => {
    const filteredParams = this.emptyGraphs.filter((el) =>
      el.deviceId === deviceId ? el.param !== id : el
    );

    this.emptyGraphs = filteredParams;
  };

  getDataForSelectedTimespan: GetDataForSelectedTimespan = async (
    startIndex,
    endIndex,
    timezoneStr,
    zoomStart,
    zoomEnd,
    isHistoryTraversing
  ) => {
    if (!isHistoryTraversing) {
      this.dataRequestHistory.push([
        startIndex,
        endIndex,
        timezoneStr,
        zoomStart,
        zoomEnd,
        true,
      ]);
    }
    const initialStartTime = moment(
      this.deviceActivityData[startIndex || 0]?.timestamp
    );
    const initialEndTime = moment(
      this.deviceActivityData[endIndex || this.deviceActivityData.length - 1]
        ?.timestamp
    );
    const timezoneOffsetInMs =
      moment(this.deviceActivityData[startIndex || 0].timestamp).utcOffset() *
      60 *
      1000;

    let startTime = initialStartTime
      .add(timezoneOffsetInMs, "milliseconds")
      .toISOString();
    let endTime = initialEndTime
      .add(timezoneOffsetInMs, "milliseconds")
      .toISOString();

    if (zoomStart && zoomEnd) {
      startTime = moment(zoomStart)
        .add(timezoneOffsetInMs, "milliseconds")
        .toISOString();
      endTime = moment(zoomEnd)
        .add(timezoneOffsetInMs, "milliseconds")
        .toISOString();
    }

    const advancedParamsRequestData: {
      deviceId: string;
      param: string;
      displayName?: string;
    }[] = Object.entries(this.selectedDevices).reduce<
      { deviceId: string; param: string }[]
    >((requestBody, [deviceId, deviceObj]) => {
      const { selectedParameters } = deviceObj;
      selectedParameters.forEach((param) => {
        requestBody.push({ deviceId, param });
      });
      return requestBody;
    }, []);

    if (advancedParamsRequestData.length === 0) return;

    this.setIsLoadingGraphData(true);

    console.info(`Getting data for ${startTime} to ${endTime}`);

    const data: AdvancedParamsData = await fetchAdvancedParamsData(
      advancedParamsRequestData,
      startTime,
      endTime,
      timezoneStr
    );

    const paramsWithoutGraphData = advancedParamsRequestData.filter(
      (params) =>
        !data.some(
          (el) =>
            params.deviceId === el.deviceId && params.param === el.param.id
        )
    );

    const emptyGraphs: EmptyGraphs = paramsWithoutGraphData.map((params) => {
      const matchingParam = this.projectAdvancedParams.find(
        (el) => el.id === params.param
      );
      if (matchingParam) {
        params.displayName = matchingParam.displayName;
      }
      return params;
    });

    this.dataToExport = [];
    // this.dataToExport.push(parseAdvancedParamsDataForExport(data));
    this.maxPowerGraphValue = 0;
    this.isZooming = false;
    this.showTimeRulerBorder = { left: true, right: true };

    const { normalizedData, timestampsArray, maxPowerValue } =
      normalizeTimeSeriesLengths(data, this.selectedTimespanValues);

    runInAction(() => {
      this.selectedTimeRange = timestampsArray.map((v) => ({ timestamp: v }));
      this.setAdvancedParamsData(normalizedData);
      this.setIsLoadingGraphData(false);
      this.emptyGraphs = emptyGraphs;
      this.setMaxPowerGraphValue(maxPowerValue);
    });
  };

  getSummaryTableData: GetSummaryTableData = async (
    timezoneStr,
    deviceId,
    zoomStart,
    zoomEnd
  ) => {
    const deviceIdToDisplay =
      deviceId || this.selectedActivityDevice || this.selectedVuIds[0];
    const deviceNameToDisplay =
      this.selectedDevices[deviceIdToDisplay].device.name;
    const selectedMuIds = this.selectedMuIds.length ? this.selectedMuIds : null;

    const zoomStartTime = zoomStart
      ? moment(zoomStart).format("YYYY-MM-DDTHH:mm:ss.SSS")
      : null;
    const zoomEndTime = zoomEnd
      ? moment(zoomEnd).format("YYYY-MM-DDTHH:mm:ss.SSS")
      : null;

    if (deviceIdToDisplay) {
      this.setIsLoadingDataFromServer(true);

      const startTime =
        zoomStartTime ||
        moment(this.selectedTimespanValues.startTime).format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        );
      const endTime =
        zoomEndTime ||
        moment(this.selectedTimespanValues.endTime).format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        );

      const { data: tableData } = await getTableData(
        selectedMuIds,
        deviceIdToDisplay,
        startTime,
        endTime,
        timezoneStr
      );

      runInAction(() => {
        this.setProjectAnalyticsSummaryTableData(
          getFormattedTableData(
            tableData,
            deviceIdToDisplay,
            convertFormattedTimeToMiliseconds(
              tableData[deviceIdToDisplay].timeSpan
            ) / 1000,
            deviceNameToDisplay
          )
        );
        this.setIsLoadingDataFromServer(false);
      });
    }
  };

  zoomTimespan = (startTime: Moment, endTime: Moment) => {
    this.selectedTimespanValues = {
      startTime,
      endTime,
    };
  };

  setTimezone = (timezoneStr: string) => (this.timezoneStr = timezoneStr);

  setSelectedDeviceIds = (devices: SelectedDevices) => {
    this.selectedGraphDevices = devices;
  };

  setMaxPowerGraphValue = (value: number) => {
    if (value > this.maxPowerGraphValue) {
      this.maxPowerGraphValue = value;
    }
  };

  setTimeRulerBorder = (left: boolean, right: boolean) => {
    this.showTimeRulerBorder.left = left;
    this.showTimeRulerBorder.right = right;
  };

  setIsZooming = (zoom: boolean) => {
    this.isZooming = zoom;
  };

  setTimeValuesBeforeZoom = (start: Moment, end: Moment) => {
    this.timeValuesBeforeZoom.start = start;
    this.timeValuesBeforeZoom.end = end;
  };

  updateActivityData = async (timezoneStr?: string) => {
    if (this.deviceForActivityGraph) {
      this.deviceActivityLoaded = false;
      const { startTime } = getStartAndEndTime(this.selectedDate);

      const deviceActivityData = await getAnalyticsDeviceActivity(
        this.deviceForActivityGraph.deviceType,
        this.deviceForActivityGraph.id,
        this.selectedDate,
        6,
        timezoneStr || this.timezoneStr
      );
      this.deviceActivityLoaded = true;

      const { filledTimeSeries } = createTimeSeries(
        timezoneStr,
        deviceActivityData,
        this.deviceForActivityGraph.id,
        startTime,
        {
          defaultStartTime: moment(this.selectedDate)
            .hours(this.defaultStartHour)
            .minutes(0),

          defaultEndTime: moment(this.selectedDate)
            .hours(this.defaultEndHour)
            .minutes(0),
        },
        this.selectedDate,
        false
      );

      this.setDeviceActivityData(filledTimeSeries);
    }
  };

  cleanup() {
    this.selectedDevices = {};
    this.projectAdvancedParams = [];
    this.projectMuParams = [];
    this.projectVuParams = [];
    this.projectAnalyticsSummaryTableData = {};
    this.dataToExport = [];
    this.isLoadingDataFromServer = false;
    this.isLoadingGraphData = false;
    this.advancedParamsData = [];
    this.selectedDate = moment().format("YYYY-MM-DD");
    this.deviceActivityData = [];
    this.selectedActivityDevice = "";
    this.selectedTimespanIndices = { startIndex: 0, endIndex: 0 };
    this.selectedTimespanValues = {
      startTime: moment(),
      endTime: moment(),
    };
    this.selectedTimeRange = [];
    this.emptyGraphs = [];
    this.deviceActivityLoaded = false;
    this.timezoneStr = "";
  }
}

export default ProjectAnalyticsStore;
