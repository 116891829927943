import React, { useMemo, useRef } from "react";

function useDebounce(func, thenFunc, delay) {
  let timer = useRef(null);

  return useMemo(
    () => (...args) => {
      if (timer && timer.current) {
        clearTimeout(timer.current);
      }
      if (timer) {
        timer.current = setTimeout(() => {
          if (args) {
            func(...args).then((res) => thenFunc(res));
          }
        }, delay);
      }
    },
    [func]
  );
}
export default useDebounce;
