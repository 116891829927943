import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

export const DeviceFilters = ({
  filters,
  selectedFilter,
  setSelectedFilter,
  orientation,
}) => {
  const borderHighlight =
    orientation === "horizontal" ? "borderLeft" : "borderBottom";
  const shouldPadLabel = orientation === "horizontal";
  return (
    <Stack
      direction={orientation === "horizontal" ? "column" : "row"}
      spacing={2}
      alignItems="flex-start"
    >
      {filters.map((filter, i) => (
        <Typography
          key={i}
          variant="label"
          sx={{
            paddingLeft: shouldPadLabel ? 1 : 0,
            [borderHighlight]:
              selectedFilter === filter ? "3px solid #1A73E8" : "none",
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
          onClick={() => setSelectedFilter(filter)}
        >
          {filter}
        </Typography>
      ))}
    </Stack>
  );
};

DeviceFilters.propTypes = {
  filters: PropTypes.array,
  selectedFilter: PropTypes.string,
  setSelectedFilter: PropTypes.func,
};
