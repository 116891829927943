import React from "react";
import "Screens/DepotDashboard/DepotSummary/DepotSummary.scss";

import { DataGrid } from "@mui/x-data-grid";
import { columns } from "Screens/DepotDashboard/DepotSummary/ChargingSpotSummaryData";

import Image from "Components/Image/Image";
import VacantChargeSpotIcon from "Utils/Images/DepotIcons/vacant-charging-spot-icon.svg";
import OccupiedChargingSpotIcon from "Utils/Images/DepotIcons/occupied-charging-spot-icon.svg";
import ChargingSpotIcon from "Utils/Images/DepotIcons/active-charging-spot-icon.svg";
import NomOperationalChargeSpotIcon from "Utils/Images/DepotIcons/non-operational-charging-spot-icon.svg";
import SeparatorLine from "Utils/Images/GlobalIcons/separator_line_icon.svg";
import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";

const ChargingSpotsSummaryTable = ({ store }) => {
  const rows = [
    {
      id: 1,
      statusIcon: VacantChargeSpotIcon,
      type: "Vacant",
      count: store.vacantParkingSpots.length,
    },
    {
      id: 2,
      statusIcon: OccupiedChargingSpotIcon,
      type: "Occupied",
      count: store.occupiedParkingSpots.length,
    },
    {
      id: 3,
      statusIcon: ChargingSpotIcon,
      type: "charging",
      count: store.chargingParkingSpots.length,
    },
    {
      id: 4,
      statusIcon: NomOperationalChargeSpotIcon,
      type: "Non operational",
      count: store.nonOperationalParkingSpots.length,
    },
  ];

  return (
    <div className="charging-stations-wrapper">
      <div className="charging-station">
        <span className="charging-stations-title">Charging Spot</span>
        <span className="charging-stations-amount">
          {store.allParkingSpots.length}
        </span>
      </div>

      <div className="charging-depot-table spots-summary">
        <DataGrid
          getRowId={(row) => row.id}
          getRowHeight={() => "auto"}
          sx={{ border: 0 }}
          rows={rows}
          columns={columns}
          autoHeight
          headerHeight={1}
          hideFooter
        />
      </div>
      <Image className="separator-line" src={SeparatorLine} />
    </div>
  );
};
export default observer(ChargingSpotsSummaryTable);

ChargingSpotsSummaryTable.propTypes = {
  store: PropTypes.shape({
    vacantParkingSpots: PropTypes.arrayOf(PropTypes.object),
    occupiedParkingSpots: PropTypes.arrayOf(PropTypes.object),
    chargingParkingSpots: PropTypes.arrayOf(PropTypes.object),
    nonOperationalParkingSpots: PropTypes.arrayOf(PropTypes.object),
    allParkingSpots: PropTypes.arrayOf(PropTypes.object),
  }),
};
