import React, { useContext } from "react";
import PropTypes from "prop-types";

//Components
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";

//Context
import MainContext from "Contexts/MainContext";
import useRole from "CustomHooks/useRole";
import SocketContext from "Contexts/SocketContext";

//Utils
import ForceRFIcon from "Utils/Images/ProjectMaintanance/segment-force-rf-icon.svg";
import ForcePWMIcon from "Utils/Images/ProjectMaintanance/segment-force-pwm-icon.svg";
import ForceOnIcon from "Utils/Images/ProjectMaintanance/segment-force-on-icon.svg";
import { defaultModalState } from "Utils/DefaultValues/ProjectViewDefaultValues";
import "Components/Buttons/ForceButtons/ForceButtons.scss";

const ForceButtons = ({
  setModalState,
  sendCommMessage,
  selectedDrawer,
  selectedSegmentIndex,
  sendPWMMessage,
  sendForceOnMessage,
  selectedDrawerSegmentDataPresent,
  isDisabled,
}) => {
  const { discretes } = useContext(SocketContext);
  const { userRole, selectedDevice } = useContext(MainContext);
  const { canControlForceBtns } = useRole(userRole);

  const { contactor_control } =
    discretes[selectedDevice.id]?.socketMessageData || {};

  return (
    <div
      className={`forces-buttons-contactor-warning ${
        contactor_control ? "contactor-on" : ""
      }`}
    >
      <IndicationButton
        title="Force Comm"
        isDisabled={isDisabled || !canControlForceBtns}
        onClick={() => {
          if (
            contactor_control &&
            !(
              selectedDrawerSegmentDataPresent &&
              selectedDrawerSegmentDataPresent.forceComm
            )
          ) {
            setModalState({
              isModalOpened: true,
              modalMessage: `Contactor is on. Are you sure you want to proceed?`,
              executeOnCancel: () => {
                return setModalState(defaultModalState);
              },
              executeOnSubmit: () => {
                sendCommMessage(
                  selectedDrawerSegmentDataPresent,
                  selectedDrawer,
                  selectedSegmentIndex
                );
              },
            });
          } else {
            sendCommMessage(
              selectedDrawerSegmentDataPresent,
              selectedDrawer,
              selectedSegmentIndex
            );
          }
        }}
        text=""
        isPressed={
          !!(
            selectedDrawerSegmentDataPresent &&
            selectedDrawerSegmentDataPresent.forceComm
          )
        }
        buttonIconUrl={ForceRFIcon}
      />

      <IndicationButton
        title="Force PWM"
        isDisabled={isDisabled || !canControlForceBtns}
        text=""
        onClick={() => {
          if (
            contactor_control &&
            !(
              selectedDrawerSegmentDataPresent &&
              selectedDrawerSegmentDataPresent.forcePwm
            )
          ) {
            setModalState({
              isModalOpened: true,
              executeOnCancel: () => {
                return setModalState(defaultModalState);
              },
              executeOnSubmit: () => {
                return sendPWMMessage(
                  selectedDrawerSegmentDataPresent,
                  selectedDrawer,
                  selectedSegmentIndex
                );
              },
              modalMessage: `Contactor is on. Are you sure you want to proceed?`,
            });
          } else {
            sendPWMMessage(
              selectedDrawerSegmentDataPresent,
              selectedDrawer,
              selectedSegmentIndex
            );
          }
        }}
        isPressed={
          !!(
            selectedDrawerSegmentDataPresent &&
            selectedDrawerSegmentDataPresent.forcePwm
          )
        }
        buttonIconUrl={ForcePWMIcon}
      />
      <IndicationButton
        title="Force On"
        isDisabled={isDisabled || !canControlForceBtns}
        text=""
        onClick={() => {
          if (
            contactor_control &&
            !(
              selectedDrawerSegmentDataPresent &&
              selectedDrawerSegmentDataPresent.forceOn
            )
          ) {
            setModalState({
              isModalOpened: true,
              executeOnCancel: () => {
                return setModalState(defaultModalState);
              },
              executeOnSubmit: () => {
                sendForceOnMessage(
                  selectedDrawerSegmentDataPresent,
                  selectedDrawer,
                  selectedSegmentIndex
                );
              },
              modalMessage: `Contactor is on. Are you sure you want to proceed?`,
            });
          } else {
            sendForceOnMessage(
              selectedDrawerSegmentDataPresent,
              selectedDrawer,
              selectedSegmentIndex
            );
          }
        }}
        isPressed={
          !!(
            selectedDrawerSegmentDataPresent &&
            selectedDrawerSegmentDataPresent.forceOn
          )
        }
        buttonIconUrl={ForceOnIcon}
      />
    </div>
  );
};

export default ForceButtons;

ForceButtons.propTypes = {
  setModalState: PropTypes.func,
  sendCommMessage: PropTypes.func,
  selectedDrawer: PropTypes.number,
  selectedSegmentIndex: PropTypes.number,
  sendPWMMessage: PropTypes.func,
  sendForceOnMessage: PropTypes.func,
  selectedDrawerSegmentDataPresent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  isDisabled: PropTypes.bool,
};
