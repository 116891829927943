import React, { useContext, useReducer } from "react";
import "App.scss";
import MainContext from "Contexts/MainContext";
import MainReducer from "Reducers/MainReducer";
import MUsContext from "Contexts/MUsContext";
import MUsReducer from "Reducers/MUsReducer";
import VUsContext from "Contexts/VUsContext";
import VUsReducer from "Reducers/VUsReducer";
import SocketContext from "Contexts/SocketContext";
import SocketReducer from "Reducers/SocketReducer";
import AppMain from "AppMain";
import { BrowserRouter as Router } from "react-router-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import SegmentsContext from "Contexts/SegmentsContext";
import SegmentsReducer from "Reducers/SegmentsReducer";
import { AppContext } from "MobxStores/context";
import RootStore from "MobxStores/RootStore";
import GlobalThemeProvider from "Themes/globalTheme";

LogRocket.init("qaac7g/smart-road");
setupLogRocketReact(LogRocket);

const store = new RootStore();

function App() {
  const initialMainState = useContext(MainContext);
  const [MainState, mainDispatch] = useReducer(
    MainReducer,
    initialMainState,
    undefined
  );

  const initialMUsState = useContext(MUsContext);
  const [MUsState, MUsDispatch] = useReducer(
    MUsReducer,
    initialMUsState,
    undefined
  );

  const initialVUsState = useContext(VUsContext);
  const [VUsState, VUsDispatch] = useReducer(
    VUsReducer,
    initialVUsState,
    undefined
  );

  const initialSocketState = useContext(SocketContext);
  const [SocketState, socketDispatch] = useReducer(
    SocketReducer,
    initialSocketState,
    undefined
  );

  const initialSegmentsState = useContext(SegmentsContext);
  const [SegmentsState, segmentsDispatch] = useReducer(
    SegmentsReducer,
    initialSegmentsState,
    undefined
  );

  return (
    <div className="App">
      <GlobalThemeProvider>
        <AppContext.Provider value={store}>
          <MainContext.Provider value={{ ...MainState, mainDispatch }}>
            <MUsContext.Provider value={{ ...MUsState, MUsDispatch }}>
              <VUsContext.Provider value={{ ...VUsState, VUsDispatch }}>
                <SocketContext.Provider
                  value={{ ...SocketState, socketDispatch }}
                >
                  <SegmentsContext.Provider
                    value={{ ...SegmentsState, segmentsDispatch }}
                  >
                    <Router>
                      <AppMain />
                    </Router>
                  </SegmentsContext.Provider>
                </SocketContext.Provider>
              </VUsContext.Provider>
            </MUsContext.Provider>
          </MainContext.Provider>
        </AppContext.Provider>
      </GlobalThemeProvider>
    </div>
  );
}

export default App;
