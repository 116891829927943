import React from "react";
import PropTypes from "prop-types";
import "Components/Buttons/IndicationButton/IndicationButton.scss";

const IndicationButton = ({
  text,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isPressed,
  indication,
  buttonIconUrl,
  width,
  height,
  className,
  isDisabled,
  title,
  id = "",
}) => {
  width = width || (text ? "64px" : "");
  return (
    <button
      id={id}
      className={`indication-button ${className} ${
        isDisabled ? "disabled" : ""
      } ${isPressed ? "pressed" : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={indication}
      style={{
        width: width,
        height: height,
      }}
      title={title}
    >
      {buttonIconUrl && <img src={buttonIconUrl} alt="icon-button" />}
      <span
        className={"indication-button-text"}
        style={text ? { paddingLeft: "8px" } : {}}
      >
        {" "}
        {text}{" "}
      </span>
    </button>
  );
};

export default IndicationButton;

IndicationButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseEnter: PropTypes.func,
  isPressed: PropTypes.bool,
  indication: PropTypes.bool,
  buttonIconUrl: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  id: PropTypes.string,
};
