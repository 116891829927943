import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import ReactTooltip from "react-tooltip";
import BookmarksContext from "Contexts/BookmarksContext";
import MUsContext from "Contexts/MUsContext";
import CreateBookmark from "Components/CreateBookmark/CreateBookmark";
import "Screens/ProjectView/PerformanceTab/PerformanceTab.scss";
import SwitchButton from "Components/Buttons/SwitchButton/SwitchButton";
import SelectButton from "Components/Buttons/SelectButton/SelectButton";
import LockButton from "Components/Buttons/LockButton/LockButton";
import LogsSidePanel from "Screens/ProjectView/LogsSidePanel/LogsSidePanel";
import MuPerformance from "Screens/ProjectView/PerformanceTab/MuPerformance/MuPerformance";
import VuPerformance from "Screens/ProjectView/PerformanceTab/VuPerformance/VuPerformance";
import HistoryGraphOptions from "Screens/ProjectView/PerformanceTab/PerformanceGraphs/HistoryGraphOptions";
import LiveGraphOptions from "Screens/ProjectView/PerformanceTab/PerformanceGraphs/LiveGraphOptions";
import useLockState from "CustomHooks/useLockState";
import LivePerformanceGraph from "Screens/ProjectView/PerformanceTab/PerformanceGraphs/LivePerformanceGraph";
import HistoryPerformanceGraph from "Screens/ProjectView/PerformanceTab/PerformanceGraphs/HistoryPerformanceGraph";
import AppModal from "Components/AppModal/AppModal";
import useBookmarks from "CustomHooks/useBookmark";
import RoundedProgress from "Components/ProgressComponents/RoundedProgress";
import SocketContext from "Contexts/SocketContext";
import useExportAsPng from "CustomHooks/useExportAsPng";
import MainContext from "Contexts/MainContext";

//Utils
import { defaultModalState } from "Utils/DefaultValues/ProjectViewDefaultValues";
import { getIsDeviceDisconnected } from "Utils/UtilsFunctions";
import { GRAPH_SCALE } from "Utils/GraphUtils";
import "./PerformanceTab.scss";

//flags to hide/show features
const isBookmarkDropdownActive = false;
const selectGraphTypeOptions = [
  { value: "power", label: "Power (W)" },
  { value: "current", label: "Current" },
  { value: "voltage", label: "Voltage" },
];

const PerformanceTab = ({
  graphScale,
  setGraphScale,
  selectedDrawer,
  setSelectedDrawer,
  selectedSegmentIndex,
  setSelectedSegmentIndex,
  sendCommMessage,
  sendPWMMessage,
  sendForceOnMessage,
  setModalState,
  handleAddingVU,
  setLocIntervalInstances,
  locIntervalInstances,
  locTimeoutInstances,
}) => {
  const [graphType, setGraphType] = useState("power");
  const [disableLiveMode, setDisableLiveMode] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);
  const [frozenGraphData, setFrozenGraphData] = useState({});
  const [historyGraphData, setHistoryGraphData] = useState({});
  const [historySpan, setHistorySpan] = useState(GRAPH_SCALE[5]);
  const [historyDataModal, setHistoryDataModal] = useState(defaultModalState);
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
  const [xAxisMinSpanTimestamp, setXAxisMinSpanTimestamp] = useState(null);
  const [isHistoryOn, setIsHistoryOn] = useState(false);

  const { connectionState, socketMUMessageData } = useContext(SocketContext);
  const { fotaProgress } = useContext(MUsContext);
  const { selectedDevice } = useContext(MainContext);
  const { BookmarksState, bookmarksDispatch, handleGraphClick, isBMOpen } =
    useBookmarks();
  const { handleDownloadAsPng, ref, isLoading } = useExportAsPng();
  const isDeviceOffline = getIsDeviceDisconnected(
    connectionState,
    selectedDevice
  );
  const lockState = useLockState(selectedDevice, locTimeoutInstances);
  const { setupDisabled } = lockState;

  const graphToggleHistory = () => {
    if (disableLiveMode) {
      return;
    }
    if (isHistoryLoaded) {
      setIsHistoryLoaded(false);
      setXAxisMinSpanTimestamp(null);
    }
    setIsHistoryOn(!isHistoryOn);
    setHistoryGraphData({});
    setHistorySpan(GRAPH_SCALE[5]);
  };

  const handleFreezeGraph = () => {
    !isFrozen && setFrozenGraphData(_.cloneDeep(socketMUMessageData));
    setIsFrozen(!isFrozen);
  };

  useEffect(() => {
    setIsHistoryOn(isDeviceOffline);
    setDisableLiveMode(isDeviceOffline);
  }, [isDeviceOffline]);

  return (
    <div className="project-device-graph-wrapper">
      <div className="project-device-graph">
        <div className="project-device-header-wrapper">
          <ProjectDeviceHeader
            title={selectedDevice ? selectedDevice.name : ""}
            className="project-device-header-i"
            infoIconDataFor="device-tip"
          />
          <ReactTooltip
            id="device-tip"
            className="device-tip"
            getContent={(dataTip) => (
              <div>
                <div className="st-version">
                  ST FW Version:{" "}
                  {selectedDevice ? selectedDevice["softwareVersion"] : 0}
                </div>
                {selectedDevice && selectedDevice.hardwareVersion && (
                  <div className="hw-version">
                    Version: {selectedDevice.hardwareVersion}
                  </div>
                )}
              </div>
            )}
            effect="solid"
            delayHide={0}
            delayShow={0}
            delayUpdate={0}
            place="right"
            border
            type="light"
          />
          <LockButton
            device={selectedDevice}
            locIntervalInstances={locIntervalInstances}
            setLocIntervalInstances={setLocIntervalInstances}
            lockState={lockState}
          />

          {selectedDevice.deviceType === "VU" &&
            selectedDevice &&
            fotaProgress[selectedDevice.id] && (
              <RoundedProgress
                valueMin="0"
                valueMax="100"
                valueNow={fotaProgress[selectedDevice.id]}
              />
            )}
        </div>
        <div className="project-view-main-graph-drawers-wrapper">
          <div className="project-view-main-graph-drawers">
            <div className="project-view-main-graph open">
              <div className="graph-view">
                <div className="graph-options">
                  <SelectButton
                    className="graph-type-select"
                    options={selectGraphTypeOptions}
                    defaultValue={selectGraphTypeOptions[0]}
                    onChange={setGraphType}
                  />
                  <SwitchButton
                    isDisabled={isDeviceOffline}
                    className="history-live-switch-btn"
                    label="Live"
                    isChecked={isHistoryOn}
                    onChange={graphToggleHistory}
                  />
                  {isBookmarkDropdownActive && (
                    <SelectButton
                      className="bookmarks-select"
                      placeholder="Bookmarks"
                      noOptionsMessage="no Bookmarks found!"
                    />
                  )}
                  {!isHistoryOn ? (
                    <LiveGraphOptions
                      isFrozen={isFrozen}
                      setGraphScale={setGraphScale}
                      graphScale={graphScale}
                      handleFreezeGraph={handleFreezeGraph}
                    />
                  ) : (
                    <HistoryGraphOptions
                      setHistorySpan={setHistorySpan} // todo: change to setHistoryDisplaySpan
                      setHistoryGraphData={setHistoryGraphData}
                      setHistoryDataModal={setHistoryDataModal}
                      setIsHistoryLoaded={setIsHistoryLoaded}
                      setXAxisMinSpanTimestamp={setXAxisMinSpanTimestamp}
                      handleDownloadAsPng={handleDownloadAsPng}
                      isDownloading={isLoading}
                    />
                  )}
                </div>
                {isBMOpen ? (
                  <BookmarksContext.Provider
                    value={{ ...BookmarksState, bookmarksDispatch }}
                  >
                    <CreateBookmark graphData={socketMUMessageData} />
                  </BookmarksContext.Provider>
                ) : (
                  ""
                )}
                {isHistoryOn ? (
                  <HistoryPerformanceGraph
                    graphType={graphType}
                    historyGraphData={historyGraphData}
                    graphScale={historySpan}
                    selectedDevice={selectedDevice}
                    handleGraphClick={handleGraphClick}
                    isHistoryOn={isHistoryOn}
                    isHistoryLoaded={isHistoryLoaded}
                    xAxisMinSpanTimestamp={xAxisMinSpanTimestamp}
                    graphPngExportRef={ref}
                  />
                ) : (
                  <LivePerformanceGraph
                    graphType={graphType}
                    isFrozen={isFrozen}
                    frozenGraphData={frozenGraphData}
                    graphScale={graphScale}
                    selectedDevice={selectedDevice}
                    socketMUMessageData={socketMUMessageData}
                    handleGraphClick={handleGraphClick}
                  />
                )}
              </div>
            </div>
            <div className="project-view-drawers-map">
              {selectedDevice.deviceType === "MU" ? (
                <MuPerformance
                  selectedDrawer={selectedDrawer}
                  setSelectedDrawer={setSelectedDrawer}
                  setModalState={setModalState}
                  selectedSegmentIndex={selectedSegmentIndex}
                  setSelectedSegmentIndex={setSelectedSegmentIndex}
                  sendCommMessage={sendCommMessage}
                  sendPWMMessage={sendPWMMessage}
                  sendForceOnMessage={sendForceOnMessage}
                  handleAddingVU={handleAddingVU}
                  locIntervalInstances={locIntervalInstances}
                  setLocIntervalInstances={setLocIntervalInstances}
                  locTimeoutInstances={locTimeoutInstances}
                  isHistoryOn={isHistoryOn}
                />
              ) : (
                <VuPerformance setupDisabled={setupDisabled} />
              )}
            </div>
          </div>

          <LogsSidePanel buttonTitle="LOGS" panelTitle="Performance Log" />
        </div>
      </div>
      <AppModal
        isOpened={historyDataModal.isModalOpened}
        message={historyDataModal.modalMessage}
        onCancelModal={historyDataModal.executeOnCancel}
        onSubmitModal={historyDataModal.executeOnSubmit}
        cancelText="CANCEL"
        submitText="OK"
      />
    </div>
  );
};

export default PerformanceTab;

PerformanceTab.propTypes = {
  graphScale: PropTypes.string,
  setGraphScale: PropTypes.func,
  selectedDrawer: PropTypes.number,
  setSelectedDrawer: PropTypes.func,
  selectedSegmentIndex: PropTypes.number,
  setSelectedSegmentIndex: PropTypes.func,
  sendCommMessage: PropTypes.func,
  sendPWMMessage: PropTypes.func,
  sendForceOnMessage: PropTypes.func,
  setModalState: PropTypes.func,
  handleAddingVU: PropTypes.func,
  setLocIntervalInstances: PropTypes.func,
  locIntervalInstances: PropTypes.object,
  locTimeoutInstances: PropTypes.object,
};
