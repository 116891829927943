import { useAppStore } from "MobxStores/context"
import { useEffect } from "react";

export const useRequestHistory = () => {
  const { projectAnalyticsStore } = useAppStore();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "z") {
        projectAnalyticsStore.stepBackInHistory();

      } else if (e.ctrlKey && e.key === "y") {
        projectAnalyticsStore.stepForwardInHistory();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [projectAnalyticsStore]);
}