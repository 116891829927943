import React from "react";
import moment from "moment";

import AppButton from "Components/Buttons/AppButton/AppButton";
import SpanRangeButton from "Components/Buttons/SpanRangeButtons/SpanRangeButton";
import CustomLoader from "Components/Loader/Loader";

import useSelectedProjectData from "CustomHooks/useSelectedProject";

type ZoomInfoProps = {
  isLoading: boolean;
  startTimestamp: number;
  endTimestamp: number;
  handleZoomOut: () => void;
  skipSpanForZoom: () => void;
};

const ZoomInfoDisplay: React.FC<ZoomInfoProps> = ({
  isLoading,
  startTimestamp,
  endTimestamp,
  handleZoomOut,
  skipSpanForZoom,
}) => {
  const { timezoneStr } = useSelectedProjectData();

  if (!timezoneStr) return null;

  const zoomSpan = ((endTimestamp - startTimestamp) / 1000).toFixed(0);

  const loadedZoomSpan = {
    date: moment(startTimestamp).tz(timezoneStr).format("YYYY-MM-DD"),
    startTime: moment(startTimestamp).tz(timezoneStr).format("HH:mm:ss"),
    span: zoomSpan,
  };

  return (
    <div className="zoom-info">
      <AppButton
        onClick={handleZoomOut}
        text="Reset Zoom"
        className="zoom-reset-btn"
      />
      <p>
        {" "}
        Displayed Range:{" "}
        {moment(startTimestamp).tz(timezoneStr).format("YYYY-MM-DD HH:mm:ss")},
        Span: {zoomSpan} Sec
      </p>
      <SpanRangeButton
        loadedTimeRange={loadedZoomSpan}
        onClick={skipSpanForZoom}
      />
      <CustomLoader
        className={`${isLoading ? "visible" : "hidden"} zoom_loader`}
        width={20}
        height={20}
      />
    </div>
  );
};

export default ZoomInfoDisplay;
