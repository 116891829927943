import AquariumChargingIcon from "Utils/Images/DepotIcons/blue_triangle_up.svg";
import AquariumDisChargingIcon from "Utils/Images/DepotIcons/dark_triangle_down.svg";
import AquariumWPTErrorIcon from "Utils/Images/DepotIcons/alert-triangle-down.svg";
import PropTypes from "prop-types";

const AquariumIcon = ({ vehicle }) => {
  const getIconPosition = (vehicleData) =>
    `${vehicleData.state.batteryLevel || 0}%`;

  const isCharging = (vehicleData) => vehicleData.state.charging;

  const hasWPTError = (vehicleData) => vehicleData.state.wptError;

  const getTriangleSide = (isCharging) => (isCharging ? "75%" : "10%");

  const getIcon = (vehicleData) => {
    if (isCharging(vehicleData)) {
      return AquariumChargingIcon;
    } else if (hasWPTError(vehicleData)) {
      return AquariumWPTErrorIcon;
    } else {
      return AquariumDisChargingIcon;
    }
  };

  return (
    <img
      className="charging-icon"
      alt="triangle"
      src={getIcon(vehicle)}
      style={{
        bottom: getIconPosition(vehicle),
        left: getTriangleSide(isCharging(vehicle)),
      }}
    />
  );
};

export default AquariumIcon;

AquariumIcon.propTypes = {
  vehicle: PropTypes.shape({
    state: PropTypes.shape({
      batteryLevel: PropTypes.number,
      charging: PropTypes.bool,
    }),
    connectionStatus: PropTypes.string,
  }),
};
