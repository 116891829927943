import React from "react";

const SegmentsContext = React.createContext({
  segments: {},
  selectedMUSegment: undefined,
  selectedSegmentPanel: {
    selectedSegmentData: null,
  },
  segmentsDispatch: () => {},
});

export default SegmentsContext;
