import React, { useContext } from "react";
import PropTypes from "prop-types";

//Components
import SwitchButton from "Components/Buttons/SwitchButton/SwitchButton";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";

//Hooks
import useLockState from "CustomHooks/useLockState";

//Utils
import { defaultModalState } from "Utils/DefaultValues/ProjectViewDefaultValues";
import { getSessionIdProject, setContactorStatus } from "Utils/APIUtils";
import "Components/ContactorControl/ContactorControl.scss";

const ContactorControl = ({ setModalState }) => {
  const { selectedDevice } = useContext(MainContext);
  const { discretes } = useContext(SocketContext);
  const sessionId = getSessionIdProject();
  const lockState = useLockState(selectedDevice, null);
  const { setupDisabled } = lockState;

  const { contactor_control } =
    discretes[selectedDevice.id]?.socketMessageData || {};

  return (
    <div className="contactors-buttons">
      {!contactor_control ? (
        <SwitchButton
          isChecked={!!contactor_control}
          onChange={(e) => {
            if (sessionId) {
              setModalState({
                isModalOpened: true,
                modalMessage: `This action will turn HIGH POWER ON. Are you sure you want to proceed?`,
                executeOnCancel: () => {
                  return setModalState(defaultModalState);
                },
                executeOnSubmit: () => {
                  setContactorStatus({
                    sessionId,
                    deviceId: selectedDevice?.id,
                    body: {
                      cmd_type: 4,
                      val: 1,
                    },
                  });
                  return setModalState(defaultModalState);
                },
              });
            }
          }}
          isDisabled={setupDisabled}
        />
      ) : null}
      {contactor_control ? (
        <div className="abort-button-wrapper">
          <IndicationButton
            text="Abort HP mode"
            onClick={() => {
              if (sessionId) {
                setContactorStatus({
                  sessionId,
                  deviceId: selectedDevice?.id,
                  body: {
                    cmd_type: 4, // todo: check what are the value meaning and add a constant in the default values file.
                    val: 2, // todo: check what are the value meaning and add a constant in the default values file.
                  },
                });
              }
            }}
            className="abort-contactor"
            width={80}
            height={36}
          />
        </div>
      ) : null}
    </div>
  );
};
export default ContactorControl;

ContactorControl.propTypes = {
  setModalState: PropTypes.func,
};
