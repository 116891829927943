import openSocket from "socket.io-client";
import { nodeEnv } from "azure/policies";
import {
  USE_LOCAL_REQUESTS_FOR_DASHBOARD,
  USE_LOCAL_SOCKET,
} from "Screens/ProjectDashboard/params";
import { v4 as uuidv4 } from "uuid";

const socketJoin = (
  socketInstance,
  roomName,
  roomNumber,
  accessToken,
  roomType
) => {
  socketInstance.emit("join", {
    roomName,
    roomNumber,
    accessToken,
    roomType,
  });
};

const subscribeToTimer = (
  socketInstance,
  cb,
  roomName,
  roomNumber,
  accessToken,
  roomType,
  device,
  setMessageData
) => {
  if (socketInstance) {
    socketJoin(socketInstance, roomName, roomNumber, accessToken, roomType);
    socketInstance.on("discretesMessage", (messageData) => {
      cb(null, messageData, setMessageData);
    });
    socketInstance.on("fansMessage", (messageData) => {
      cb(null, messageData, setMessageData);
    });
    socketInstance.on("error", (messageData) => {
      cb(null, messageData, setMessageData);
    });
    socketInstance.on("dcSamplerMessage", (messageData) => {
      cb(null, { ...messageData, MUId: roomNumber }, setMessageData);
    });
    socketInstance.on("lockMessage", (messageData) => {
      cb(null, { ...messageData, MUId: roomNumber }, setMessageData);
    });
    socketInstance.on("receiversMessage", (messageData) => {
      cb(null, { ...messageData, MUId: roomNumber }, setMessageData);
    });
    socketInstance.on("cuUnitTempMessage", (messageData) => {
      cb(null, { ...messageData, MUId: roomNumber }, setMessageData);
    });
    socketInstance.on("gpsMessage", (messageData) => {
      cb(null, { ...messageData, MUId: roomNumber }, setMessageData);
    });
    socketInstance.on("batteryDataMessage", (messageData) => {
      cb(null, { ...messageData, MUId: roomNumber }, setMessageData);
    });
    socketInstance.on("deviceTwinChanges", (messageData) => {
      cb(
        null,
        {
          ...messageData,
          MUId: roomNumber,
          eventName: "deviceTwinChanges",
        },
        setMessageData
      );
    });
    socketInstance.on("fpgaMessage", (messageData) => {
      cb(
        null,
        {
          ...messageData,
          MUId: roomNumber,
          eventName: "fpgaMessage",
        },
        setMessageData
      );
    });

    socketInstance.on("eventsMessage", (messageData) => {
      cb(
        null,
        {
          ...messageData,
          deviceId: roomNumber,
          eventName: "eventsMessage",
        },
        setMessageData
      );
    });
    socketInstance.on("connectionStateChanges", (messageData) => {
      cb(
        null,
        {
          ...messageData,
          MUId: roomNumber,
          eventName: "connectionStateChanges",
        },
        setMessageData
      );
    });
    socketInstance.on("devicesStatesMessage", (messageData) => {
      //("connectionStateChanges");
      cb(
        null,
        {
          ...messageData,
          MUId: roomNumber,
          eventName: "devicesStatesMessage",
        },
        setMessageData
      );
    });
    socketInstance.on("hpMessage", (messageData) => {
      cb(
        null,
        {
          ...messageData,
          MUId: roomNumber,
          eventName: "hpMessage",
        },
        setMessageData
      );
    });
    socketInstance.on("chargingMessage", (messageData) => {
      cb(
        null,
        {
          ...messageData,
          MUId: roomNumber,
          eventName: "chargingMessage",
        },
        setMessageData
      );
    });
    socketInstance.on("disconnect", (messageData) => {
      if (messageData === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        //socketInstance.connect();
      }
      //("reconnected ----------------------");
      // else the socket will automatically try to reconnect
      cb(
        null,
        {
          ...messageData,
          MUId: roomNumber,
          eventName: "reconnect",
          deviceSocketCallback: device,
        },
        setMessageData
      );

      socketInstance.on("connect_error", (messageData) => {
        if (
          messageData &&
          messageData.message &&
          messageData.message.indexOf("ERROR: JWT is invalid!") > -1
        ) {
          cb(
            null,
            { ...messageData, MUId: roomNumber, eventName: "JwtError" },
            setMessageData
          );
          socketInstance.disconnect();
        }
      });
      socketJoin(socketInstance, roomName, roomNumber, accessToken, roomType);
    });
  }
};

const clearRunningInterval = (socket, cb, roomName) => {
  if (socket) {
    socket.emit("clearRunningInterval", roomName);
    socket.on("intervalCleared", () => cb());
  }
};

const closeSocket = (socket, roomName) => {
  const { auth } = socket || { auth: {} };
  const { roomNumber, roomType } = auth || { roomNumber: 0, roomType: "" };
  if (socket && typeof socket.emit === "function") {
    socket.emit("closeSocket", {
      roomName: roomName || `${roomType}-${roomNumber}`,
      roomNumber,
      roomType,
    });
  }
};

const subscribeSocketMessages = (
  socketLocal,
  device,
  isActiveVU,
  isProjectSocketDashboard,
  isProjectSocketTree,
  accessToken,
  setMessageData
) => {
  const { id, deviceType } = device || { id: "", deviceType: "" };
  const roomName = isProjectSocketDashboard
    ? `PROJ_${id}_DASHBOARD`
    : isProjectSocketTree
    ? `PROJ_${id}_TREE`
    : `${deviceType}-${id}`;
  const roomNumber = isProjectSocketDashboard
    ? `PROJ_${id}_DASHBOARD`
    : isProjectSocketTree
    ? `PROJ_${id}_TREE`
    : id;

  return subscribeToTimer(
    socketLocal,
    (err, messageData, setMessageData) => {
      if (messageData && typeof setMessageData === "function") {
        setMessageData({
          socketMessageData: messageData["msg"] || messageData,
          projectId: id,
          deviceId: messageData["id"] || id,
          deviceType,
          isActiveVU,
          eventName: messageData["eventName"] || "",
          isProjectSocket: isProjectSocketDashboard || isProjectSocketTree,
          entityId: id,
          entity: device,
          isProjectMessage: !!messageData["msg"],
        });
      }
    },
    roomName,
    roomNumber,
    accessToken,
    deviceType,
    device,
    setMessageData
  );
};

const socketLocalExec = (room, accessToken, projectId) => {
  if (room) {
    const connectionuuid = uuidv4();
    return openSocket(
      `https://${nodeEnv === "dev" ? "dev." : ""}cloud.electreon.com`,
      {
        extraHeaders: {
          Authorization: `${accessToken}`,
          projectId,
          connectionuuid,
        },
        protocols: [connectionuuid],
      }
    );
  }
};

const projectDashboardSocket = (project, accessTokenRef, projectId) => {
  if (USE_LOCAL_REQUESTS_FOR_DASHBOARD || USE_LOCAL_SOCKET) {
    return openSocket(`http://localhost:8868`);
  } else {
    if (!projectId && !accessTokenRef && !project) {
      return;
    }
    const socket = socketLocalExec(project, accessTokenRef.current, projectId);
    socket.emit("join", {
      roomName: `PROJ_${projectId}_DASHBOARD`,
      roomNumber: `PROJ_${projectId}_DASHBOARD`,
      accessToken: accessTokenRef.current,
      roomType: "",
    });

    return socket;
  }
};

const serverSocket = (accessTokenRef, projectId) => {
  return openSocket(
    `https://${nodeEnv === "dev" ? "dev." : ""}cloud.electreon.com`,
    {
      extraHeaders: {
        Authorization: `${accessTokenRef.current}`,
        projectId,
      },
    }
  );
};

export {
  subscribeToTimer,
  clearRunningInterval,
  closeSocket,
  projectDashboardSocket,
  serverSocket,
  socketLocalExec,
  subscribeSocketMessages,
};
