// Enter here the user flows and custom policies for your B2C application
// To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
// To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
export const appClientId =
  process.env.REACT_APP_ENV_NAME === "dev"
    ? process.env.REACT_APP_DEV_APP_CLIENT_ID
    : process.env.REACT_APP_ENV_NAME === "staging"
    ? process.env.REACT_APP_STAGE_APP_CLIENT_ID
    : process.env.REACT_APP_PROD_APP_CLIENT_ID;
export const nodeEnv =
  process.env.REACT_APP_ENV_NAME === "dev"
    ? "dev"
    : process.env.REACT_APP_ENV_NAME === "staging"
    ? "staging"
    : "prod";
export const tenantName = "electreoncloudb2cdev";
export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_signupsignin1",
    signIn: "B2C_1_signin",
    forgotPassword: "b2c_1_reset",
    editProfile: "b2c_1_edit_profile",
    localAccounts: "b2c_1_signin_local_accounts",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://electreoncloudb2c${nodeEnv}.b2clogin.com/tfp/electreoncloudb2c${nodeEnv}.onmicrosoft.com/B2C_1_signupsignin1`,
    },
    signIn: {
      authority: `https://electreoncloudb2c${nodeEnv}.b2clogin.com/tfp/electreoncloudb2c${nodeEnv}.onmicrosoft.com/B2C_1_signin`,
    },
    forgotPassword: {
      authority: `https://electreoncloudb2c${nodeEnv}.b2clogin.com/tfp/electreoncloudb2c${nodeEnv}.onmicrosoft.com/B2C_1_reset`,
    },
    editProfile: {
      authority: `https://electreoncloudb2c${nodeEnv}.b2clogin.com/tfp/electreoncloudb2c${nodeEnv}.onmicrosoft.com/B2C_1_edit_profile`,
    },
  },
};
