import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "Components/ProgressComponents/RoundedProgress.scss";

const RoundedProgress = ({ valueNow = 0, valueMin = 0, valueMax = 100 }) => {
  const [opacity, setOpacity] = useState();

  useEffect(() => {
    if (valueNow === 0 || !valueNow) {
      setOpacity("0");
    }
    if (valueNow === 100) {
      setTimeout(function () {
        setOpacity("0");
      }, 4000);
    }
    if (valueNow > 0 && valueNow < 100) {
      setOpacity("1");
    }
  }, [valueNow]);

  const progressStyle = {
    "--value": valueNow,
    opacity: opacity,
  };

  return (
    <div className="progressbar-wrapper">
      <div
        role="progressbar"
        aria-valuenow={valueNow}
        aria-valuemin={valueMin}
        aria-valuemax={valueMax}
        style={progressStyle}
      ></div>
    </div>
  );
};

export default RoundedProgress;

RoundedProgress.propTypes = {
  valueNow: PropTypes.string,
  valueMin: PropTypes.string,
  valueMax: PropTypes.string,
};
