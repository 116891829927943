import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 15,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "rgba(50, 50, 50, 1)",
  },
}));

export default function ProgressBarWithLabel({
  value,
  labelSpacing,
  barMaxWidth = "90%",
}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%", mr: labelSpacing, maxWidth: barMaxWidth }}>
          <BorderLinearProgress variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

ProgressBarWithLabel.propTypes = {
  value: PropTypes.number,
  labelSpacing: PropTypes.number,
  barMaxWidth: PropTypes.string,
};
