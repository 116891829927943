import openSocket from "socket.io-client";
import { nodeEnv } from "azure/policies";

/**
 * It opens a socket connection to the server, and then emits a join event to the server.
 * @param accessToken - The access token of the user
 * @param projectId - The project ID of the project you want to connect to.
 * @returns A socket object.
 */
export const getSocketConnection = (accessToken, projectId, depotId) => {
  if (!accessToken && !projectId) {
    console.info(`Connection data at getSocketConnection is missing`);
    return;
  }
  const socket = openSocket(
    `https://${nodeEnv === "dev" ? "dev." : ""}cloud.electreon.com`,
    {
      extraHeaders: {
        Authorization: `${accessToken}`,
        projectId,
      },
    }
  );

  const roomName = depotId
    ? `PROJ_${projectId}_DEPOT_${depotId}_DASHBOARD`
    : `PROJ_${projectId}_DEPOT_DASHBOARD`;

  socket.emit("join", {
    roomName: roomName,
    roomNumber: roomName,
    accessToken: accessToken,
    roomType: "",
  });

  return socket;
};
