import { buildFlatSegmentObj } from "Screens/ProjectDashboard/lib/helpers/segmentHelpers";
import { actions } from "Screens/ProjectDashboard/lib/context/actionTypes";

export const reducerInitialValue = {
  initialDataLoaded: false,
  projectSettings: {},
  vehicleData: {},
  chargingStatuses: {},
  roadPath: [],
  gpsPositions: {},
  projectSegments: {},
  segmentsPath: [],
  backgroundGeojson: undefined,
  managementUnits: {},
};

export function dashboardReducer(state, action) {
  try {
    switch (action.type) {
      case actions.SET_REST_DATA: {
        return {
          ...state,
          projectSettings: action.data.projectSettings,
          vehicleData: action.data.vehicleData,
          chargingStatuses: action.data.chargingStatuses,
          projectSegments: action.data.projectSegments,
          backgroundGeojson: action.data.backgroundGeojson,
          managementUnits: action.data.managementUnits,
          segmentsPath: action.data.segmentsPath,
          roadPath: action.data.roadPath,
          initialDataLoaded: true,
        };
      }

      case actions.BATTERY_DATA_MESSAGE: {
        if (!state.vehicleData[action.payload.id]) return state;

        // handle message typo
        const percentage =
          action.payload.msg.Percentage || action.payload.msg.Percetage;

        return {
          ...state,
          vehicleData: {
            ...state.vehicleData,
            [action.payload.id]: {
              ...state.vehicleData[action.payload.id],
              barVal:
                action.payload.id === "VU0000123" ? 70 : Math.round(percentage),
              isOffline: false,
            },
          },
        };
      }

      case actions.GPS_MESSAGE: {
        if (!state.vehicleData[action.payload.id]) return state;

        return {
          ...state,
          vehicleData: {
            ...state.vehicleData,
            [action.payload.id]: {
              ...state.vehicleData[action.payload.id],
              speedValue: Math.round(action.payload.msg.speed.kmh),
              isOffline: false,
            },
          },
          gpsPositions: {
            ...state.gpsPositions,
            [action.payload.id]: {
              name: state.vehicleData[action.payload.id].name,
              label: state.vehicleData[action.payload.id].label,
              vehicleLogo: state.vehicleData[action.payload.id].vehicleLogo,
              vuId: action.payload.id,
              lng: action.payload.msg.loc.geojson.coordinates[0],
              lat: action.payload.msg.loc.geojson.coordinates[1],
              isCharging: state.chargingStatuses[action.payload.id].isCharging,
            },
          },
        };
      }

      case actions.DC_SAMPLER_MESSAGE: {
        if (!state.vehicleData[action.payload.id]) return state;

        return {
          ...state,
          vehicleData: {
            ...state.vehicleData,
            [action.payload.id]: {
              ...state.vehicleData[action.payload.id],
              chargeValue: Math.round(action.payload.msg.avgPower / 1000),
              powerGraphValue: action.payload.msg.avgPower / 1000,
              isOffline: false,
            },
          },
        };
      }

      case actions.CHARGING_MESSAGE: {
        if (!state.vehicleData[action.payload.id]) return state;

        return {
          ...state,
          vehicleData: {
            ...state.vehicleData,
            [action.payload.id]: {
              ...state.vehicleData[action.payload.id],
              isCharging: Math.round(action.payload.msg.chargingStatus),
              isOffline: false,
            },
          },
          chargingStatuses: {
            ...state.chargingStatuses,
            [action.payload.id]: {
              ...state.chargingStatuses[action.payload.id],
              isCharging: Math.round(action.payload.msg.chargingStatus),
            },
          },
        };
      }

      case actions.DEVICES_STATES_MESSAGE: {
        return state;
      }

      case actions.FPGA_MESSAGE: {
        const muId = action.payload.id;
        const fpgaMsg = action.payload.msg;
        const segmentsFlatObj = buildFlatSegmentObj(muId, fpgaMsg);

        const newProjectSegments = { ...state.projectSegments };

        Object.entries(segmentsFlatObj).forEach(([segmentId, isSegmentOn]) => {
          if (newProjectSegments[segmentId]) {
            newProjectSegments[segmentId] = {
              ...newProjectSegments[segmentId],
              on: isSegmentOn,
            };
          }
        });

        const newSegmentsPath = state.segmentsPath.map((sp) => {
          if (sp.connectedMus.includes(muId)) {
            return { ...sp, on: !!action.payload.msg.chargingStatus };
          } else {
            return sp;
          }
        });

        let newManagementUnits = state.managementUnits;

        if (state.managementUnits[muId]) {
          newManagementUnits = {
            ...state.managementUnits,
            [muId]: {
              ...state.managementUnits[muId],
              enabled: !!action.payload.msg.chargingStatus,
            },
          };
        } else {
          console.warn(`trying to update non-existing MU ${muId}`);
        }

        return {
          ...state,
          managementUnits: newManagementUnits,
          projectSegments: newProjectSegments,
          segmentsPath: newSegmentsPath,
        };
      }

      case actions.CONNECTION_STATE_CHANGE: {
        console.log(
          "connectionStateChanges message. Payload: ",
          action.payload
        );

        const id = action.payload.msg.id;

        if (action.payload.msg.opertionState === "deviceConnected") {
          if (action.payload.msg.deviceType === "MU") {
            return {
              ...state,
              managementUnits: {
                ...state.managementUnits,
                [id]: {
                  ...state.managementUnits[id],
                  offline: false,
                },
              },
            };
          } else {
            return state;
          }
        }

        if (action.payload.msg.deviceType === "VU") {
          // remove gps if there are any
          const newGpsPositions = { ...state.gpsPositions };
          delete newGpsPositions[id];

          // reset live vehicle data if vehicle is defined
          const newVehicleData = { ...state.vehicleData };
          if (newVehicleData[id]) {
            newVehicleData[id] = {
              ...newVehicleData[id],
              barVal: 0,
              chargeValue: 0,
              speedValue: 0,
              isOffline: true,
            };
          } else {
            console.warn(
              "received a connection state change for a non-existing VU"
            );
          }

          return {
            ...state,
            gpsPositions: newGpsPositions,
            vehicleData: newVehicleData,
          };
        } else if (action.payload.msg.deviceType === "MU") {
          // turn off mu
          const newManagementUnits = {
            ...state.managementUnits,
            [id]: {
              ...state.managementUnits[id],
              enabled: false,
              offline: true,
            },
          };

          const newProjectSegments = { ...state.projectSegments };

          // turn off all segments connected to the mu
          Object.values(newProjectSegments).forEach((segment) => {
            if (segment.muId === id) {
              newProjectSegments[segment.id] = {
                ...newProjectSegments[segment.id],
                on: false,
              };
            }
          });

          return {
            ...state,
            managementUnits: newManagementUnits,
            projectSegments: newProjectSegments,
          };
        } else return state;
      }

      default: {
        console.warn(`Unhandled action type: ${action.type}`);
        // TODO - log with datadog
        return state;
      }
    }
  } catch (e) {
    console.error(
      `Unknown error in project dashboard reducer: ${action.type}`,
      e
    );
    // TODO - log with datadog
    return state;
  }
}
