import React, { useState, memo, useMemo } from "react";
import Popup from "reactjs-popup";
import { Form } from "react-final-form";
import createDecorator from "final-form-focus";
import { FaWindowClose } from "react-icons/fa";
import { Field } from "react-final-form-html5-validation";
import TimezoneSelect from "react-timezone-select";

//Components
import Dropzone from "Components/Dropzone/Dropzone";

//Context
import { useAppStore } from "MobxStores/context";

//Utils
import plusIcon from "Utils/Images/GlobalIcons/plus-icon-dark.svg";
import { postProject, getProjects } from "Utils/APIUtils";
import "Components/CreateProject/CreateProject.scss";

const CreateProject = () => {
  const focusOnErrors = useMemo(() => createDecorator(), [createDecorator]);
  const [selectedTimezone, setSelectedTimezone] = useState({
    value: "Asia/Jerusalem",
    abbrev: "IST",
  });
  const { projectStore } = useAppStore();

  const countryC = selectedTimezone.value || "";
  const tz = selectedTimezone.abbrev || "";

  const onSubmit = async (values) => {
    postProject({
      name: values.projectName,
      countryCode: values.countryCode,
      timezoneStr: values.timezone,
      projectImage: values.projectImage,
    })
      .then((res) => {
        return getProjects();
      })
      .then((res) => {
        if (res.data) {
          projectStore.setProjectsList(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="create-new-project-wrapper">
      <Popup
        trigger={
          <img
            src={plusIcon}
            alt="plus icon for create project"
            className="add"
          />
        }
        modal
        nested
        closeOnDocumentClick={false}
        className="popup"
      >
        {(close) => (
          <div className="modal">
            <Form
              onSubmit={onSubmit}
              decorators={[focusOnErrors]}
              initialValues={{
                projectName: "",
                countryCode: tz,
                timezone: countryC,
                projectImage: "",
              }}
              render={({ handleSubmit, form, submitting, pristine }) => (
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    const promise = handleSubmit(event);
                    promise &&
                      promise
                        .then(() => {
                          form.reset();
                        })
                        .then(() => {
                          close();
                        });
                    return promise;
                  }}
                  className="create-project-form"
                >
                  <div className="close-icon" onClick={close}>
                    <FaWindowClose className="close" />
                  </div>
                  <h3>New project</h3>
                  <section className="form-info">
                    <div className="select-wrapper">
                      <label className="label">
                        Country Timezone Selection
                      </label>
                      <TimezoneSelect
                        value={selectedTimezone}
                        onChange={setSelectedTimezone}
                        labelStyle="altName"
                      />
                    </div>
                    <Field
                      name="countryCode"
                      type="text"
                      placeholder="E.g: IL"
                      valueMissing="Where the country code???"
                      minLength={2}
                      tooShort="You need a longer code"
                      pattern="[A-Z].+"
                      patternMismatch="Capitalize Your Input!"
                      required
                    >
                      {({ input, meta: { error, touched }, ...rest }) => (
                        <div className="proCode">
                          <label className="label">Country Code:</label>
                          <input {...input} {...rest} />
                          {/* {error && touched && <span>{error}</span>} */}
                        </div>
                      )}
                    </Field>
                    <Field
                      name="timezone"
                      type="text"
                      component="input"
                      placeholder="E.g: Asia/Jerusalem"
                      valueMissing="Where the country T.M.Z???"
                      pattern="[A-Z].+"
                      patternMismatch="Capitalize Your Input!"
                      minlength={2}
                      tooShort="You need a longer TMZ"
                      required
                    >
                      {({ input, meta: { error, touched }, ...rest }) => (
                        <div className="timezone">
                          <label className="label">Time Zone:</label>
                          <input {...input} {...rest} />
                          {/* {error && touched && <span>{error}</span>} */}
                        </div>
                      )}
                    </Field>
                    <Field
                      name="projectName"
                      type="text"
                      placeholder="E.g: Tel Aviv University"
                      pattern="[A-Za-z].+"
                      patternMismatch="Only lettars in the name!"
                      required
                      minLength={4}
                      tooShort="You need a longer name"
                    >
                      {({ input, meta: { error, touched }, ...rest }) => (
                        <div className="projectName">
                          <label className="label"> Name:</label>
                          <input {...input} {...rest} />
                          {/* {error && touched && <span>{error}</span>} */}
                        </div>
                      )}
                    </Field>
                    <Field name="projectImage" required>
                      {(props) => (
                        <div className="projectImage">
                          <label className="label"> Project Icon </label>
                          <Dropzone {...props.input} />
                        </div>
                      )}
                    </Field>
                    <div className="buttons">
                      <button
                        className="btn submitBtn"
                        type="submit"
                        disabled={submitting || pristine}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        onClick={form.reset}
                        disabled={submitting || pristine}
                        className="btn resetBtn"
                      >
                        Reset
                      </button>
                    </div>
                  </section>
                </form>
              )}
            />
          </div>
        )}
      </Popup>
    </div>
  );
};

export default memo(CreateProject);
