import { useCallback, useContext, useReducer } from "react";
import BookmarksContext from "Contexts/BookmarksContext";
import bookmarksReducer from "Reducers/BookmarkReducer";

const useBookmarks = () => {
  const initialBookmarksState = useContext(BookmarksContext);
  const [BookmarksState, bookmarksDispatch] = useReducer(
    bookmarksReducer,
    initialBookmarksState,
    undefined
  );

  const {
    bookmarkData: { isBMOpen },
  } = BookmarksState;

  const handleGraphClick = useCallback((data) => {
    bookmarksDispatch({
      type: "SET_BOOKMARK_INIT_DATA",
      payload: {
        bookmarkData: {
          isBMOpen: true,
          BMStartTime: data ? data.activeLabel : Date.now(),
          BMEndTime: data ? data.activeLabel : Date.now(),
        },
      },
    });
  }, []);

  return {
    BookmarksState,
    bookmarksDispatch,
    handleGraphClick,
    isBMOpen,
  };
};
export default useBookmarks;
