import React, { useContext } from "react";
import "moment-timezone";
import { observer } from "mobx-react-lite";

//Components
import Bookmarks from "Components/Bookmarks/Bookmarks"; // don't remove this import because styles shared between other component
import Header from "Components/Header/Header";
import AppModal from "Components/AppModal/AppModal";
import PerformanceTab from "Screens/ProjectView//PerformanceTab/PerformanceTab";
import SetupTab from "Screens/ProjectView/SetupTab/SetupTab";
import SideBar from "Screens/ProjectView/SideBar/SideBar";

//Hooks
import useProjectViewData from "CustomHooks/projectViewLogic/useProjectViewData";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";
import { useAppStore } from "MobxStores/context";

//Utils
import { projectDeviceTabs } from "Utils/DefaultValues/ProjectViewDefaultValues";
import { getIsDeviceDisconnected } from "Utils/UtilsFunctions";
import { MaintenanceTabs } from "Utils/constants";

import "Screens/ProjectView/SetupTab/ControlPanel/SegmentControlPanel.scss";
import "Screens/ProjectView/ProjectView.scss";

const ProjectView = () => {
  const {
    setLocIntervalInstances,
    locIntervalInstances,
    selectedDrawer,
    setSelectedDrawer,
    setSelectedSegmentIndex,
    setModalState,
    socketInstanceProject,
    selectedSegmentIndex,
    sendCommMessage,
    sendForceOnMessage,
    sendPWMMessage,
    graphScale,
    setGraphScale,
    handleAddingVU,
    modalState,
    locTimeoutInstances,
  } = useProjectViewData();

  const { selectedDevice } = useContext(MainContext);
  const { connectionState } = useContext(SocketContext);
  const { projectStore } = useAppStore();
  const { selectedMaintenanceTab } = projectStore;

  const isDeviceOffline = getIsDeviceDisconnected(
    connectionState,
    selectedDevice
  );

  const handleTabClick = (projectTab) => {
    if (!isDeviceOffline) {
      projectStore.setSelectedTab(projectTab.title);
    }
  };

  return (
    <div className="project-view-wrapper">
      <Header />
      <div className="project-view">
        <div className="project-view-side-bar">
          <div className="project-view-side-bar-lists">
            <SideBar locTimeoutInstances={locTimeoutInstances} />
          </div>
        </div>
        {selectedDevice ? (
          <div className="project-view-main">
            <div className="project-tabs">
              {projectDeviceTabs.map((projectTab, projectTabIndex) => {
                if (
                  projectTab.title === MaintenanceTabs.SETUP &&
                  selectedDevice.deviceType === "VU"
                ) {
                  return "";
                }
                return (
                  <div
                    className={`project-tab ${
                      selectedMaintenanceTab === projectTab.title
                        ? "selected"
                        : ""
                    } ${
                      isDeviceOffline &&
                      projectTab.title !== MaintenanceTabs.PERFORMANCE
                        ? "gray-layout"
                        : ""
                    }`}
                    key={projectTabIndex}
                    onClick={() => handleTabClick(projectTab)}
                  >
                    <img
                      src={
                        selectedMaintenanceTab === projectTab.title
                          ? projectTab.activeIcon
                          : projectTab.nonActiveIcon
                      }
                      alt=""
                    />
                    <div className="project-tab-name">{projectTab.title}</div>
                  </div>
                );
              })}
            </div>
            {selectedMaintenanceTab === MaintenanceTabs.SETUP ? (
              <div>
                <SetupTab
                  setLocIntervalInstances={setLocIntervalInstances}
                  locIntervalInstances={locIntervalInstances}
                  selectedDrawer={selectedDrawer}
                  setSelectedDrawer={setSelectedDrawer}
                  setModalState={setModalState}
                  selectedSegmentIndex={selectedSegmentIndex}
                  setSelectedSegmentIndex={setSelectedSegmentIndex}
                  sendCommMessage={sendCommMessage}
                  sendForceOnMessage={sendForceOnMessage}
                  sendPWMMessage={sendPWMMessage}
                  locTimeoutInstances={locTimeoutInstances}
                />
              </div>
            ) : selectedMaintenanceTab === MaintenanceTabs.PERFORMANCE ? (
              <PerformanceTab
                graphScale={graphScale}
                setGraphScale={setGraphScale}
                selectedDrawer={selectedDrawer}
                setSelectedDrawer={setSelectedDrawer}
                selectedSegmentIndex={selectedSegmentIndex}
                setSelectedSegmentIndex={setSelectedSegmentIndex}
                sendCommMessage={sendCommMessage}
                sendPWMMessage={sendPWMMessage}
                sendForceOnMessage={sendForceOnMessage}
                setModalState={setModalState}
                handleAddingVU={handleAddingVU}
                socketInstanceProject={socketInstanceProject}
                setLocIntervalInstances={setLocIntervalInstances}
                locIntervalInstances={locIntervalInstances}
                locTimeoutInstances={locTimeoutInstances}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <AppModal
        isOpened={modalState.isModalOpened}
        message={modalState.modalMessage}
        onCancelModal={modalState.executeOnCancel}
        onSubmitModal={modalState.executeOnSubmit}
        cancelText="CANCEL"
        submitText="OK"
      />
    </div>
  );
};

export default observer(ProjectView);
