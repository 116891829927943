import Image from "Components/Image/Image";
import VacantIcon from "Utils/Images/DepotIcons/vacant-charging-spot-icon.svg";
import ActiveChargingIcon from "Utils/Images/DepotIcons/active-charging-spot-icon.svg";
import OccupiedChargingIcon from "Utils/Images/DepotIcons/occupied-charging-spot-icon.svg";
import PropTypes from "prop-types";
import { DepotTableIconContainer } from "../DepotTableIconContainer/DepotTableIconContainer";

const DepotDeviceIcon = ({ device }) => {
  return (
    <DepotTableIconContainer height={"25px"}>
      {device?.state?.charging ? (
        <Image
          src={ActiveChargingIcon}
          alt="Vacant charging spot icon"
          className="vehicle-status-icon"
        />
      ) : device?.state?.occupied ? (
        <Image
          src={OccupiedChargingIcon}
          alt="Occupied charging spot icon"
          className="vehicle-status-icon"
        />
      ) : (
        <Image src={VacantIcon} className="vehicle-status-icon" />
      )}
    </DepotTableIconContainer>
  );
};

export default DepotDeviceIcon;

DepotDeviceIcon.propTypes = {
  device: PropTypes.shape({
    state: PropTypes.shape({
      charging: PropTypes.bool,
      occupied: PropTypes.bool,
    }),
  }),
};
