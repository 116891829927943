import React from "react";
import AzureAuthenticationContext from "azure/azure-authentication-context";

const MainContext = React.createContext({
  musData: null, // todo move to MUsContext
  continuationToken: null,
  authenticationModule: new AzureAuthenticationContext(),
  currentUser: { name: "", username: "", userId: "" },
  userRole: "",
  userScope: "",
  shouldAcquireToken: false,
  countryCode: "ISR",
  authenticated: false,
  isLoading: true,
  currentScope: "projects",
  appQuery: "",
  isSearchOpened: false,
  searchResults: null,
  isLiveView: true,
  isMaintenance: true,
  selectedDevice: undefined,
  selectedProject: undefined,
  mainDispatch: () => {},
  intervalIds: [],
  timeoutIds: [],
  intervalInstance: null,
  isNewTokenReceived: false,
});

export default MainContext;
