import { PublicClientApplication } from "@azure/msal-browser";
import { apiConfig } from "azure/apiConfig";
import axios from "axios";
import { MSAL_CONFIG } from "azure/azure-authentication-config";
import { appClientId, b2cPolicies, tenantName } from "azure/policies";
import jwt_decode from "jwt-decode";

export class AzureAuthenticationContext {
  myMSALObj = new PublicClientApplication(MSAL_CONFIG);
  account;
  loginRedirectRequest;
  loginRequest;
  tokenRequest;
  isAuthenticationConfigured = false;

  constructor() {
    this.account = null;
    this.setRequestObjects();
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  setRequestObjects() {
    this.loginRequest = {
      scopes: ["openid", "profile", appClientId],
      prompt: "select_account",
    };

    this.tokenRequest = {
      scopes: apiConfig.b2cScopes,
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };

    this.acquireTokenSilent = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
      prompt: this.myMSALObj.getActiveAccount(),
    };
  }

  findLocalItems = (query) => {
    //TODO: check if should use it in handleLoginResponseROPC for refreshtoken and localAccount..
    let i,
      results = [];
    for (i in localStorage) {
      if (localStorage.hasOwnProperty(i)) {
        if (i.match(query) || (!query && typeof i === "string")) {
          const value = JSON.parse(localStorage.getItem(i));
          results.push({ key: i, val: value });
        }
      }
    }
    return results;
  };

  logout(account) {
    // TODO: check if needed.
    const logOutRequest = {
      account,
    };
    this.myMSALObj.logout(logOutRequest);
  }

  login(signInType, setUser, userLoginData) {
    const activeAccount = this.getAccount();
    const localAccount = this.getLocalAccount();

    switch (signInType) {
      case "loginRedirect":
        return this.myMSALObj.loginRedirect(this.loginRedirectRequest);
      case "loginROPC":
        return this.authenticateUserROPC(userLoginData)
          .then((response) => {
            return this.handleLoginResponseROPC(response, setUser);
          })
          .catch((err) => {
            return err;
          });
      //TODO: check if we need both "acquireTokenSilent" and "acquireTokenSilentNewAccessToken"
      case "acquireTokenSilent": {
        if (localAccount) {
          return this.acquireTokenSilentROPC()
            .then((response) => {
              return this.handleSilentLoginResponseROPC(response, setUser);
            })
            .catch((err) => {
              return err; //should be redirected to login page
            });
        }
        return this.myMSALObj
          .acquireTokenSilent({
            account: activeAccount,
            scopes: ["openid", "profile"],
          })
          .then((resp) => {
            return this.handleResponse(resp, setUser);
          })
          .catch((err) => {
            return err;
          });
      }
      case "acquireTokenSilentNewAccessToken": {
        if (localAccount) {
          return this.acquireTokenSilentROPC()
            .then((response) => {
              return this.handleSilentLoginResponseROPC(response, setUser);
            })
            .catch((err) => {
              return err; //should be redirected to login page
            });
        }
        return this.myMSALObj
          .acquireTokenSilent({
            account: activeAccount,
            scopes: ["openid", "profile", appClientId],
          })
          .then((resp) => {
            return this.handleResponse(resp, setUser);
          })
          .catch((err) => {
            return err;
          });
      }
      default:
        return ""; // TODO: should return some error?
    }
  }

  getAccount() {
    const account = this.myMSALObj.getActiveAccount();
    return account;
  }

  setAccount(account, setUserInfo) {
    localStorage && localStorage.setItem("localAccount", "");
    this.account = account;
    this.myMSALObj.setActiveAccount(account);

    if (this.account) {
      setUserInfo(account);
    }
  }

  handleResponse(response, incomingFunction) {
    const refreshToken = this.findLocalItems("refreshtoken");

    if (refreshToken && !!refreshToken.length && refreshToken[0].val) {
      localStorage.setItem("refreshToken", refreshToken[0].val.secret);
    }

    if (response !== null) {
      if (localStorage && response && response.accessToken) {
        localStorage.setItem("accessToken", response.accessToken);
        const bearer = "Bearer " + response.accessToken;
        axios.defaults.headers.common["Authorization"] = bearer; //set Authorization header to all requests
      }

      if (response.account) {
        this.myMSALObj.setActiveAccount(response.account);
        localStorage && localStorage.setItem("localAccount", "");
        this.account = response.account;
      }
    } else {
      this.account = this.getAccount(); // TODO: check when this happens..
    }

    if (this.account) {
      incomingFunction(this.account);
    }
    return response;
  }

  authenticateUserROPC = ({ username, password }) => {
    const body =
      `username=${username}&password=${password}&grant_type=password&scope=openid+${appClientId}+offline_access` +
      `&client_id=${appClientId}&response_type=token id_token`;

    return axios.post(
      `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicies.names.localAccounts}/oauth2/v2.0/token`,
      body,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };

  acquireTokenSilentROPC = () => {
    const refreshToken = localStorage && localStorage.getItem("refreshToken");
    const body = `grant_type=refresh_token&refresh_token=${refreshToken}`;

    return axios.post(
      `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicies.names.localAccounts}/oauth2/v2.0/token`,
      body,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  };

  handleLoginResponseROPC(response, setUser) {
    if (response && response.data) {
      const accessToken = response.data.access_token;
      const idToken = response.data.id_token;
      const refreshToken = response.data.refresh_token;

      const newAccount = this.createLocalAccount({ accessToken, idToken });
      this.setLocalAccount(newAccount, "", setUser);

      if (localStorage) {
        accessToken && localStorage.setItem("accessToken", accessToken);
        refreshToken && localStorage.setItem("refreshToken", refreshToken);
      }

      const bearer = "Bearer " + accessToken;
      axios.defaults.headers.common["Authorization"] = bearer;
    }
    return response;
  }

  handleSilentLoginResponseROPC(response, incomingFunction) {
    //store the new access token and refresh token axios headers.. maybe do something else as well
    if (
      response &&
      response.data &&
      response.data.access_token &&
      localStorage
    ) {
      localStorage.setItem("refreshToken", response.data.refresh_token);
      localStorage.setItem("accessToken", response.data.access_token);
      const bearer = "Bearer " + response.data.access_token;
      axios.defaults.headers.common["Authorization"] = bearer; //set Authorization header to all requests
      const account = this.getLocalAccount();
      incomingFunction(account);
    }
    return response;
  }

  createLocalAccount({ accessToken, idToken }) {
    if (!accessToken || !idToken) {
      return "";
    }

    const accessTokenClaims = this.getClaimsFromToken(accessToken);
    const idTokenClaims = this.getClaimsFromToken(idToken);
    let environment =
      idTokenClaims && idTokenClaims.iss && new URL(idTokenClaims.iss);
    environment = environment ? environment.hostname : "";

    let newLocalAccount = {
      name: accessTokenClaims && accessTokenClaims.given_name,
      username:
        accessTokenClaims &&
        accessTokenClaims.emails &&
        accessTokenClaims.emails[0],

      idTokenClaims: idTokenClaims,
      environment: environment,
      tenantId: "",
      localAccountId: idTokenClaims
        ? idTokenClaims.oid || idTokenClaims.sub
        : undefined, // idTokenClaims.oid || idTokenClaims.sub || undefined
      homeAccountId: idTokenClaims ? idTokenClaims.sub : "", // "uniqueId.tenantId" || idTokenClaims.sub || ""
    };
    return newLocalAccount;
  }

  setLocalAccount(account, mainDispatch, setUser = "") {
    this.myMSALObj.setActiveAccount(null);
    localStorage &&
      localStorage.setItem("localAccount", JSON.stringify(account));
    this.account = account;

    if (this.account) {
      setUser && setUser(account);
      mainDispatch &&
        mainDispatch({
          type: "SET_USER",
          payload: {
            currentUser: account,
            authenticated: !!(account && account.username),
            userRole: account?.idTokenClaims?.extension_UserRole?.trim(),
            userScope: account?.idTokenClaims?.extension_ProjectScope?.trim(),
          },
          isLoading: false,
        });
    }
  }

  getLocalAccount() {
    let localAccount = localStorage.getItem("localAccount");
    return localAccount ? JSON.parse(localAccount) : "";
  }

  getClaimsFromToken = (token) => {
    if (!token) {
      return "";
    }

    let decoded = jwt_decode(token);
    return decoded;
  };
}

export default AzureAuthenticationContext;
