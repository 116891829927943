import {
  getProjectSegments,
  getProjectSegmentsPath,
  getVehicleSettings,
  getProjectUnitList,
} from "Utils/APIUtils";
import { limitMUList } from "Utils/DefaultValues/ProjectViewDefaultValues";
import { constructSegmentId } from "Screens/ProjectDashboard/lib/helpers/segmentHelpers";
import { getVehicleInitialData } from "Screens/ProjectDashboard/lib/helpers/vehicleHelpers";

const _getMuLabel = (muName) => {
  if (muName === "East Dynamic") return "ED";
  if (muName === "West Dynamic") return "WD";
  if (muName === "West Static") return "WS";

  return muName.slice(-2).replace("-", "0");
};

async function _getMUs(projectId, continuationToken) {
  const mus = {};
  const devices = (
    await getProjectUnitList({
      unitType: "mu",
      projectId,
      continuationToken,
      limit: 100,
    })
  ).data.devices;

  devices.forEach((mu) => {
    mus[mu.id] = {
      id: mu.id,
      name: mu.name,
      label: _getMuLabel(mu.name), // usually name is in the format mu-1 or mu-12. change the label to be 01 and 12 respectively
      lng: mu.longitude,
      lat: mu.latitude,
      enabled: false, // all MUs are disabled by default
      offline: mu.connectionStatus === "CONNECTED" ? false : true,
    };
  });

  return mus;
}

async function _getSegments(projectId) {
  const segments = (await getProjectSegments(projectId)).data;

  const segmentObj = {};

  segments.forEach((segment) => {
    const id = constructSegmentId(
      segment.muId,
      segment.drawerNum,
      segment.segmentNum
    );
    segmentObj[id] = {
      id,
      drawerNum: segment.drawerNum,
      segmentNum: segment.segmentNum,
      muId: segment.muId,
      lat: segment.latitude,
      lng: segment.longitude,
      on: false, // all segments are off by default
    };
  });
  return segmentObj;
}

async function _getSgementsPath(projectId) {
  const segmentsPath = await getProjectSegmentsPath(projectId);

  return segmentsPath.map((segment) => ({
    ...segment,
    on: false,
  }));
}

async function _getVehicles(projectId, continuationToken, activeVehicles) {
  const vehicleRes = await getProjectUnitList({
    projectId,
    limit: limitMUList,
    continuationToken: continuationToken,
    unitType: "vu",
  });

  const vehicleData = {};
  const filteredDevices = vehicleRes.data.devices.filter((device) =>
    activeVehicles.includes(device.id)
  );

  for (const vehicle of filteredDevices) {
    const { id } = vehicle;

    vehicleData[id] = getVehicleInitialData(vehicle);
    const vehicleMetadata = await getVehicleSettings(id);

    vehicleData[id].numberOfReciveres = vehicleMetadata.numberOfReciveres;
    vehicleData[id].powerPerReciever = vehicleMetadata.powerPerReciever;
    vehicleData[id].powerGaugeShowPercentage =
      !!vehicleMetadata.powerGaugeShowPercentage;
    vehicleData[id].chargeMaxValue =
      vehicleData[id].numberOfReciveres * vehicleData[id].powerPerReciever;
    vehicleData[id].name = vehicleMetadata.name;
  }

  return vehicleData;
}

const helpers = {
  _getMUs,
  _getSegments,
  _getSgementsPath,
  _getVehicles,
};

export default helpers;
