import React from "react";
import PropTypes from "prop-types";

const ElectreonLoginButton = ({ logIn }) => {
  return (
    <button
      id="electreon-authenticationButton"
      className="electreon-submit-login"
      type="submit"
      onClick={logIn}
    >
      Employee Login
    </button>
  );
};

export default ElectreonLoginButton;

ElectreonLoginButton.propTypes = {
  logIn: PropTypes.func,
};
