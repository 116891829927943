import { Puff } from "react-loader-spinner";
import "Components/Loader/Loader.scss";

type CustomLoaderProps = {
  className?: string;
  width?: number;
  height?: number;
};

const CustomLoader: React.FC<CustomLoaderProps> = ({
  className,
  width,
  height,
}) => (
  // if no className is passed, the default full screen Loader
  <div className={className || "loader"}>
    <Puff color="#00BFFF" height={height} width={width} />
  </div>
);

export default CustomLoader;
