import ActivityIndicator from "Components/ActivityIndicator/ActivityIndicator";
import React, { useContext } from "react";
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";

const MuIndicationLeds = () => {
  const { selectedDevice } = useContext(MainContext);
  const { discretes } = useContext(SocketContext);
  const socketMessageDataDiscretes =
    (selectedDevice &&
      discretes &&
      discretes[selectedDevice.id] &&
      discretes[selectedDevice.id]["socketMessageData"]) ||
    {};
  const { rectifier, contactor_control, discharge_mechanism } =
    socketMessageDataDiscretes;

  return (
    <div className="mu-leds-indication">
      <ActivityIndicator
        color={!!contactor_control ? "#00ffff" : "#B3B3B3"}
        label="C"
        height={5}
        width={5}
        padding={0}
      />
      <ActivityIndicator
        color={rectifier ? "#00ffff" : "#B3B3B3"}
        label="IR"
        height={5}
        width={5}
        padding={0}
      />
      <ActivityIndicator
        color={discharge_mechanism ? "#00ffff" : "#B3B3B3"}
        label="DIS"
        height={5}
        width={5}
        padding={0}
      />
    </div>
  );
};

export default MuIndicationLeds;
