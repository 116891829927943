import axios from "axios";
import { USE_LOCAL_REQUESTS_FOR_DASHBOARD } from "Screens/ProjectDashboard/params";
import LogRocket from "logrocket";
import moment from "moment";

export const urlNodeEnv =
  process.env.REACT_APP_ENV_NAME === "dev"
    ? "dev."
    : process.env.REACT_APP_ENV_NAME === "staging"
    ? "staging."
    : "";

const localPort = 8868;

export const localDomain =
  process.env.REACT_APP_RUN_LOCAL === "true" && USE_LOCAL_REQUESTS_FOR_DASHBOARD
    ? `http://localhost:${localPort}`
    : "";

const apiCallDomain = localDomain || `https://${urlNodeEnv}cloud.electreon.com`;

// blue (new deployment) or green (stable deployment)
const CURRENT_DEPLOYMENT = "blue";

export const projectDashboardLogoBlobPath = `https://dev-electreon-frontend.s3.eu-west-1.amazonaws.com/projects`;
export const projectDashboardMockApiPath = `http://localhost:8868`;
export const projectDashboardBlobPath = USE_LOCAL_REQUESTS_FOR_DASHBOARD
  ? `http://localhost:8868/blob_assets`
  : `https://dev-electreon-frontend.s3.eu-west-1.amazonaws.com/static-assets/${CURRENT_DEPLOYMENT}`;

export const setLoader = (dispatch, isLoading) => {
  return dispatch({ type: "SET_IS_LOADING", payload: { isLoading } });
};

export const getAccessToken = () => localStorage.getItem("accessToken");

export const getSessionIdProject = () =>
  localStorage.getItem("sessionIdProject");

export const handleSignOut = (
  mainDispatch,
  history,
  authenticationModule,
  projectStore
) => {
  history.push("/");
  mainDispatch({
    type: "SET_USER",
    payload: {
      currentUser: { name: "", username: "" },
      authenticated: false,
      userRole: "",
      userScope: "",
      isLoading: false,
    },
  });
  projectStore.resetStore();
  if (
    authenticationModule &&
    typeof authenticationModule.setLocalAccount === "function" &&
    typeof authenticationModule.setAccount === "function"
  ) {
    // TODO: check if should use authenticationModule.logout.
    authenticationModule.setLocalAccount("");
    authenticationModule.setAccount("");
  }
  localStorage.setItem("prevAccessToken", getAccessToken());
  localStorage.setItem("accessToken", "");
  localStorage.setItem("localAccount", "");
  localStorage.setItem("refreshToken", "");
  axios.defaults.headers.common["Authorization"] = "";
};

export const execLogIn = (
  method,
  authenticationModule,
  setUserInfo,
  userLoginCredentials = ""
) => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  const isIE = msie > 0 || msie11 > 0;

  const returnedAccountInfo = (user) => {
    setUserInfo(user);

    //LogRocket Configurations
    LogRocket.identify(user?.localAccountId, {
      name: user?.idTokenClaims?.name || user?.name,
      email: user?.username,
      userRole: user?.idTokenClaims?.extension_UserRole?.trim(),
      userScope: user?.idTokenClaims?.extension_ProjectScope?.trim(),
      accountType: user?.idTokenClaims?.idp,
    });
  };
  const typeName = method || "acquireTokenSilent"; // "loginPopup";
  const logInType = isIE ? "loginRedirect" : typeName;

  // Azure Login
  return authenticationModule.login(
    logInType,
    returnedAccountInfo,
    userLoginCredentials
  );
};

export const getSearchResults = ({ searchText }) => {
  return axios
    .all([
      axios.get(`${apiCallDomain}/mu/search?searchText=${searchText}`),
      axios.get(`${apiCallDomain}/project/search?searchText=${searchText}`),
      axios.get(`${apiCallDomain}/vu/search?searchText=${searchText}`),
    ])
    .then(
      axios.spread((res1, res2, res3) => {
        return [res1.data, res2.data, res3.data];
      })
    )
    .catch((err) => {
      return err;
    });
};

export const getProjectUnitList = ({
  projectId,
  limit,
  continuationToken,
  unitType,
}) => {
  return axios
    .get(
      `${apiCallDomain}/${unitType}/list/project/${projectId}?limit=${limit}&continuationToken=${continuationToken}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getProject = (projectId) => {
  return axios
    .get(`${apiCallDomain}/project/${projectId}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectSegments = (projectId) => {
  return axios
    .get(`${apiCallDomain}/project/${projectId}/segments`)
    .then((res) => {
      //(res, "res");
      return res;
    })
    .catch((error) => {
      //(error);
      return error;
    });
};

export const getProjects = () => {
  return axios
    .get(`${apiCallDomain}/project/list`)
    .then((res) => {
      //(res, "res");
      return res;
    })
    .catch((error) => {
      //(error);
      return error;
    });
};

export const postBookmark = ({
  name,
  description,
  startTime,
  endTime,
  projectId,
  deviceIds,
}) => {
  return axios
    .post(`${apiCallDomain}/project/bookmark/create`, {
      name,
      description,
      startTime,
      endTime,
      projectId,
      deviceIds,
    })
    .then((res) => {
      //(res, "res");
      return res;
    })

    .catch((error) => {
      //(error);
      return error;
    });
};

export const getBookmarkList = ({ projectId, startTime, endTime }) => {
  return axios
    .get(
      `${apiCallDomain}/project/bookmark/list/${projectId}/${startTime}/${endTime}`
    )

    .then((res) => {
      //(res, "res");
      return res;
    })

    .catch((err) => {
      //(err);
      return err;
    });
};

export const deleteBookmark = ({ bookmarkId }) => {
  return axios
    .delete(`${apiCallDomain}/project/bookmark/${bookmarkId}`)

    .then((res) => {
      //(res, "res");
      return res;
    })

    .catch((err) => {
      //(err);
      return err;
    });
};

export const postProject = ({
  id,
  name,
  countryCode,
  timezoneStr,
  projectImage,
  managementUnits,
  vehicleUnits,
}) => {
  return axios
    .post(`${apiCallDomain}/project/create`, {
      id,
      name,
      countryCode,
      timezoneStr,
      projectImage,
      managementUnits,
      vehicleUnits,
    })
    .then((res) => {
      //(res, "res");
      return res;
    })

    .catch((error) => {
      //(error);
      return error;
    });
};

export const deleteProject = ({ projectId }) => {
  return axios
    .delete(`${apiCallDomain}/project/${projectId}`)

    .then((res) => {
      //(res, "res");
      return res;
    })

    .catch((err) => {
      //(err);
      return err;
    });
};
export const setFpgaCommand = ({
  MUId,
  shelfNumber,
  segmentNumber,
  cmdType,
  val,
  sessionId,
}) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/mu/control/fpga/${MUId}`,
      {
        shelf_number: shelfNumber,
        segment_number: segmentNumber,
        cmd_type: cmdType,
        val: val,
      },
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const setAllShelfFans = ({
  MUId,
  fan_drawer,
  fan_cmd_type,
  sessionId,
}) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/mu/control/fans/drawer/${fan_drawer}/${MUId}`,
      {
        fan_drawer: fan_drawer,
        fan_cmd_type: fan_cmd_type,
        fan_segment_pair_id: 0,
      },
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const setAllMuFans = ({ MUId, fan_cmd_type, sessionId }) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/mu/control/fans/all/${MUId}`,
      {
        fan_drawer: 0,
        fan_cmd_type: fan_cmd_type,
        fan_segment_pair_id: 0,
      },
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const setFanOn = ({
  MUId,
  fan_drawer,
  fan_cmd_type,
  fan_segment_pair_id,
  sessionId,
}) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/mu/control/fans/${MUId}`,
      {
        fan_drawer: fan_drawer,
        segment_number: 0,
        fan_cmd_type: fan_cmd_type,
        fan_segment_pair_id: fan_segment_pair_id,
      },
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const setFpgaCommandForceOffAll = ({
  MUId,
  shelfNumber,
  cmdType,
  val,
  sessionId,
}) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/mu/control/fpga/forceOffAll/${MUId}`,
      {
        shelf_number: shelfNumber,
        segment_number: 0,
        cmd_type: cmdType,
        val: val,
      },
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const setShelfConfigurationCommand = ({
  MUId,
  shelf_number,
  band0,
  band1,
  band2,
  band3,
  band4,
  band5,
  currentOff,
  pwmOn,
  pwmOffD,
  pwmOffS,
  pwmMax,
  sessionId,
}) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `https://${urlNodeEnv}cloud.electreon.com/mu/control/fpga/${MUId}/shelf/config`,
      {
        shelf_number,
        band0,
        band1,
        band2,
        band3,
        band4,
        band5,
        pwmOn,
        pwmOffD,
        pwmOffS,
        pwmMax,
        currentOff,
      },
      config
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const lockDevice = ({ deviceId, sessionId }) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers: headers,
  };
  return axios
    .post(`${apiCallDomain}/mu/control/lock/${deviceId}`, null, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
export const unlockDevice = ({ deviceId, sessionId }) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers: headers,
  };
  return axios
    .post(`${apiCallDomain}/mu/control/unlock/${deviceId}`, null, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const postReceiversDebugMode = ({ sessionId, vehicleUnitId, mode }) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/vu/control/set/indication/charging/${vehicleUnitId}`,
      {
        mode,
      },
      config
    )
    .then((res) => {
      //(res, "res");
      return res;
    })
    .catch((error) => {
      //(error);
      return error;
    });
};

export const postReceiverControlStatus = ({
  sessionId,
  vehicleUnitId,
  receiver_id,
  state,
}) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/vu/control/set/indication/receiver/state/${vehicleUnitId}`,
      {
        receiver_id,
        state,
      },
      config
    )
    .then((res) => {
      //(res, "res");
      return res;
    })
    .catch((error) => {
      //(error);
      return error;
    });
};

export const postVuRf = ({ sessionId, vehicleUnitId, val }) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  return axios
    .post(
      `${apiCallDomain}/vu/control/update/battery/charger/rf/boost/${vehicleUnitId}`,
      { val },
      config
    )
    .then((res) => {
      //(res, "res");
      return res;
    })
    .catch((error) => {
      //(error);
      return error;
    });
};

export const setContactorStatus = ({ sessionId, deviceId, body }) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };

  return axios
    .post(`${apiCallDomain}/mu/control/discrete/${deviceId}`, body, config)
    .then((res) => {
      //(res, "res");
      return res;
    })
    .catch((error) => {
      //(error);
      return error;
    });
};

export const getHistory = ({
  deviceType,
  sessionId,
  deviceId,
  startSec,
  rangeSec,
  projectTz,
}) => {
  const headers = {
    "x-lock-sessionId": sessionId,
    "Content-Type": "application/json",
  };
  const config = {
    headers,
  };
  deviceType = deviceType?.toLowerCase();
  return axios
    .get(
      `${apiCallDomain}/${deviceType}/telemetry/sampler/${deviceId}/from/${startSec}/range/${rangeSec}?tz=${projectTz}`,
      config
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const putRawData = (body) => {
  return axios
    .put(`${apiCallDomain}/data/export`, body)
    .then((res) => res)
    .catch((error) => error);
};

export const getRawData = (operationId, timezone) => {
  return axios
    .get(`${apiCallDomain}/data/export/track/${operationId}?tz=${timezone}`)
    .then((res) => res)
    .catch((error) => error);
};

export const getTableData = (
  managementUnitIds,
  vehicleUnitIds,
  startDateTimeFull,
  endDateTimeFull,
  timezone
) => {
  return axios
    .get(
      `${apiCallDomain}/analytics/telemetry/summaryTable/muIds/${managementUnitIds}/vuIds/${vehicleUnitIds}/from/${startDateTimeFull}/until/${endDateTimeFull}?tz=${timezone}
    `
    )
    .then((res) => res)
    .catch((error) => error);
};

// project dashboad specific requests

export const getBlobImgPath = (projectId, relativeImgPath) => {
  return `${projectDashboardBlobPath}/projects/_${projectId}/images/${relativeImgPath}`;
};

export const getVehicleImagePath = (vehicleId) => {
  return `${projectDashboardBlobPath}/vehicles/${vehicleId}/image.png`;
};

export const getVehicleSmallImagePath = (vehicleId) => {
  return `${projectDashboardBlobPath}/vehicles/${vehicleId}/small_vehicle.png`;
};

export const getVehicleLogoImagePath = (vehicleId, fileExt) => {
  if (fileExt === "svg" || vehicleId === "VU0000129") {
    return `${projectDashboardBlobPath}/vehicles/${vehicleId}/logo.svg`;
  }
  return `${projectDashboardBlobPath}/vehicles/${vehicleId}/logo.png`;
};

function removeAuthFromRequest(data, headers) {
  delete headers.common["Authorization"];
  return data;
}

export const getProjectDashboardSettings = async (projectId) => {
  const res = await axios.get(
    `${projectDashboardBlobPath}/projects/_${projectId}/metadata.json`,
    { transformRequest: removeAuthFromRequest }
  );
  return res.data;
};

export const getProjectSegmentsPath = async (projectId) => {
  try {
    const res = await axios.get(
      `${projectDashboardBlobPath}/projects/_${projectId}/segmentsPath.json`,
      { transformRequest: removeAuthFromRequest }
    );
    return res.data;
  } catch (e) {
    return [];
  }
};

export const getVehicleSettings = async (vuId) => {
  const res = await axios.get(
    `${projectDashboardBlobPath}/vehicles/${vuId}/metadata.json`,
    { transformRequest: removeAuthFromRequest }
  );
  return res.data;
};

export const getRoadPath = async (projectId) => {
  const res = await axios.get(
    `${projectDashboardBlobPath}/projects/_${projectId}/roadPath.json`,
    { transformRequest: removeAuthFromRequest }
  );
  return res.data;
};

export const getMapBackgroundPath = async (projectId) => {
  const res = await axios.get(
    `${projectDashboardBlobPath}/projects/_${projectId}/background.geojson.json`,
    { transformRequest: removeAuthFromRequest }
  );
  return res.data;
};

export const getAnalyticsGraphData = (
  managementUnitIds,
  vehicleUnitIds,
  startDateTimeFull,
  endDateTimeFull,
  timezone
) => {
  return axios.get(
    `${apiCallDomain}/analytics/telemetry/dataGraph/muIds/${
      managementUnitIds.length ? managementUnitIds : ""
    }/vuIds/${
      vehicleUnitIds.length ? vehicleUnitIds : ""
    }/from/${startDateTimeFull}/until/${endDateTimeFull}?tz=${timezone}`
  );
};

export const getMuList = (id) => {
  return axios
    .get(`${apiCallDomain}/project/${id}/deployment`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getDepotParkingSpots = (depotId) => {
  return axios
    .get(`${apiCallDomain}/parkingSpot/list/depot/${depotId}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getDepotVehicles = (depotProjectId) => {
  return axios
    .get(`${apiCallDomain}/vu/list/project/${depotProjectId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getAnalyticsDataGraphParams = () => {
  return axios
    .get(`${apiCallDomain}/analytics/telemetry/parameters`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getProjectLogoImgPath = async (id, type) => {
  try {
    const res = await axios.get(
      `${projectDashboardLogoBlobPath}/${id}/logo-${type}.svg`,
      { transformRequest: removeAuthFromRequest }
    );
    if (res.status === 200) {
      return res.config.url;
    } else {
      return null;
    }
  } catch (e) {
    return "";
  }
};

/**
 * @param {{deviceId: string, param: string}[]} devicesAndParams - array of objects with deviceId and param
 * @param {string} start - start date in ms
 * @param {string} end - end date in ms
 * @param {string} timezone - timezone identifier
 */

export const fetchAdvancedParamsData = async (
  devicesAndParams,
  start,
  end,
  timeZoneStr
) => {
  try {
    const res = await axios.post(
      `${apiCallDomain}/analytics/telemetry/timeSeries/from/${start}/until/${end}?tz=${timeZoneStr}`,
      devicesAndParams
    );
    return res.data;
  } catch (e) {
    console.error(`Error fetching advanced params data: ${e}`);
    return [];
  }
};

/**
 * It makes an API call to get the activity of a device for a given day.
 * @param {"VU" | "MU"} deviceType - the type of the device
 * @param {String} deviceId - id of the device
 * @param {String} date
 * @param {Number} padHours - number of hours to pad the start and end of the day
 * @param {String} timezone - e.g. Asia/Jerusalem
 * @returns {Promise<[{timeStamp: number, deviceId: string, deviceType: string, chargeActive: boolean}]>}
 */
export const getAnalyticsDeviceActivity = async (
  deviceType,
  deviceId,
  date,
  padHours,
  timezone
) => {
  const formattedDate = moment(date).format("YYYY-MM-DD");
  try {
    const res = await axios.get(
      `${apiCallDomain}/analytics/telemetry/device/activity/${deviceType}/${deviceId}/at/${formattedDate}/pad/${padHours}?tz=${timezone}`
    );
    return res.data;
  } catch (e) {
    console.error(`Error fetching device activity: ${e}`);
    return [];
  }
};
