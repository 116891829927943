import { MuMessages } from "Utils/EventsMessages/MessagesMap";
import { FpgaMessages } from "Utils/EventsMessages/FpgaMessagesMap";

export const saveHubMessages = (projectHubMessage, MUsDispatch) => {
  const eventMessageValues = Object.values(projectHubMessage);
  let messageTime = new Date();

  eventMessageValues.forEach((eventMessageValue) => {
    let messageObject = MuMessages.get(eventMessageValue);

    if (messageObject && messageObject.messageType !== "info-progress") {
      MUsDispatch({
        type: "MUS_EVENTS_MESSAGES",
        payload: {
          musEventsMessages: {
            messageObject,
            messageTime: messageTime,
            messageShelf: false,
            reciever: messageObject.reciever ? messageObject.reciever : null,
          },
        },
      });
    }
    if (messageObject && messageObject.eventType === "show-progress") {
      MUsDispatch({
        type: "FOTA_PROGRESS",
        payload: {
          deviceId: projectHubMessage.deviceId
            ? projectHubMessage.deviceId
            : "0",
          fotaProgress: messageObject.progress ? messageObject.progress : 0,
        },
      });
    }
  });
};

export const saveFpgaMessages = (
  projectFpgaMessage,
  messageShelf,
  MUsDispatch,
  fpgaProgressDone,
  deviceId
) => {
  const fpgaMessageValues = Object.values(projectFpgaMessage);
  let stopMessage = false;
  let messageTime = new Date();

  fpgaMessageValues.forEach((fpgaMessageValue) => {
    let messageObject = FpgaMessages.get(fpgaMessageValue);

    if (messageObject) {
      if (messageObject.messageType !== "info-progress") {
        MUsDispatch({
          type: "MUS_EVENTS_MESSAGES",
          payload: {
            musEventsMessages: {
              messageObject,
              messageTime: messageTime,
              messageShelf: messageShelf,
            },
          },
        });
      }

      if (stopMessage) return;
      stopMessage = messageObject.progressDone;

      if (messageObject.eventType === "show-progress" && !fpgaProgressDone) {
        MUsDispatch({
          type: "FPGA_PROGRESS",
          payload: {
            fpgaProgress: messageObject.progress ? messageObject.progress : 0,
            fpgaProgressDone: messageObject.progressDone,
            fpgaIsInProgress: true,
            fpgaDeviceId: deviceId,
          },
        });
        if (messageObject.progressDone) {
          setTimeout(() => {
            MUsDispatch({
              type: "FPGA_PROGRESS",
              payload: {
                fpgaProgress: 0,
                fpgaProgressDone: true,
                fpgaIsInProgress: false,
                fpgaDeviceId: null,
              },
            });
            MUsDispatch({
              type: "CLEAR_FPGA_PROGRESS",
            });
          }, 3000);
        }
      }
    }
  });
};

export const clearMuMessages = (MUsDispatch) => {
  MUsDispatch({
    type: "CLEAR_MUS_EVENTS_MESSAGES_AND_FOTA",
  });
  MUsDispatch({
    type: "CLEAR_FPGA_PROGRESS",
  });
};
