import { enableDownloadGraphAsPNG } from "Utils/featureFlags";
import AppButton from "Components/Buttons/AppButton/AppButton";
import React, { useMemo } from "react";
import useExportAsPng from "CustomHooks/useExportAsPng";
import { useAppStore } from "MobxStores/context";

const ExportGraphAsPngButton = () => {
  const { projectAnalyticsStore } = useAppStore();
  const { projectAnalyticsGraphData } = projectAnalyticsStore;
  const graphData = projectAnalyticsGraphData || {};
  const { ref, isLoading, handleDownloadAsPng } = useExportAsPng();
  const imgFileName = "graph-image.png";

  const shouldDisablePngExport = useMemo(() => {
    let shouldDisable = true;
    Object.keys(graphData).forEach((key) => {
      if (graphData[key]?.timeSeries?.length) {
        shouldDisable = false;
      }
    });
    return shouldDisable;
  }, [graphData]);

  return enableDownloadGraphAsPNG ? (
    <AppButton
      onClick={() => handleDownloadAsPng(imgFileName)}
      text={isLoading ? "Downloading..." : "Download PNG"}
      isDisabled={shouldDisablePngExport || isLoading}
    />
  ) : null;
};
export default ExportGraphAsPngButton;
