export const MaintenanceTabs = {
  SETUP: "SETUP",
  PERFORMANCE: "PERFORMANCE",
  REPORTS: "REPORTS",
  ACTIVITY_LOG: "ACTIVITY LOG",
};

export const ScopeTypes = {
  SINGLE: "SINGLE",
  GLOBAL: "GLOBAL",
  MULTI: "MULTI",
  NONE: "NONE",
};

export const RoleTypes = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  INTEGRATOR: "INTEGRATOR",
  OPERATOR: "OPERATOR",
  PROJECT_USER: "PROJECT_USER",
  PROJECT_VIEWER: "PROJECT_VIEWER",
};
