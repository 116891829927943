import moment from "moment";

export const GRAPH_SCALE = {
  5: "5 Sec",
  15: "15 Sec",
  30: "30 Sec",
};

export const defaultColorsForGraphs = {
  area: "#FFF",
  line: "#FFF",
  analytics: "#f7f8ff",
  defaultVU: "#00FF00",
  analyticsVUSpeed: "#8884d8",
};

export const roundToNearest100 = (num) => Math.round((num + 150) / 100) * 100;
export const defaultZoomArea = {
  x1: 0,
  x2: 0,
};
export const formatDataForGraph = (
  data,
  keys,
  type,
  maxStamp,
  graphScale = "5 Sec",
  formatter,
  isHistoryOn
) => {
  const tempObjectData = {};
  const timeSpanInSeconds =
    typeof graphScale === "number"
      ? graphScale
      : graphScale?.split(" ")[0] * 1000;

  if (!data || !keys?.length) {
    return [];
  }

  if (isHistoryOn || keys.length === 1) {
    keys.forEach((key) => {
      if (data[key]?.data?.length) {
        for (let i = 0; i < data[key].data.length; i++) {
          const dataPoint = data[key].data[i];
          if (!tempObjectData[dataPoint.timestamp]) {
            tempObjectData[dataPoint.timestamp] = {};
            tempObjectData[dataPoint.timestamp]["timestamp"] =
              data[key].data[i].timestamp;
          }
          tempObjectData[dataPoint.timestamp][`${key}`] = withFormatter(
            data[key].data[i][type],
            formatter
          );
        }
      }
    });

    const dataArray = Object.keys(tempObjectData)
      .map((timestamp) => {
        return { ...tempObjectData[timestamp] };
      })
      .filter(
        (item) =>
          calcTimeDiffAsMiliseconds(item.timestamp, maxStamp) >
          -timeSpanInSeconds
      );

    return dataArray.sort((a, b) => a.timestamp - b.timestamp);
  } else {
    // format multiple keys for live graph
    let dataMU = [];
    let dataVU = [];
    keys.forEach((key, index) => {
      //if has multiple keys and at least one of them is VU.
      if (key.includes("VU")) {
        dataVU = data[key]["data"].sort((a, b) => a.timestamp - b.timestamp);
      } else {
        dataMU = data[key]["data"]
          .map((item) => ({
            [key]: withFormatter(item[type], formatter),
            timestamp: item["timestamp"],
          }))
          .sort((a, b) => a.timestamp - b.timestamp);
      }
    });

    const vuKey = keys.find((key) => key.includes("VU"));
    const allDataSorted = [...dataMU];
    if (keys.length > 1) {
      for (let i = 0; i < allDataSorted?.length; i++) {
        if (dataVU[i]) {
          allDataSorted[i] = {
            ...allDataSorted[i],
            [vuKey]: withFormatter(dataVU[i][type], formatter),
          };
        } else {
          allDataSorted[i] = { ...allDataSorted[i], [vuKey]: 0 };
        }
      }
    }
    const sortedAndFilteredData = allDataSorted
      .filter(
        (item) =>
          calcTimeDiffAsMiliseconds(item.timestamp, maxStamp) >
          -timeSpanInSeconds
      )
      .sort((a, b) => a.timestamp - b.timestamp);
    sortedAndFilteredData.unshift({
      ...sortedAndFilteredData[0],
      timestamp: maxStamp - timeSpanInSeconds,
    });
    return sortedAndFilteredData;
  }
};
const calcTimeDiffAsMiliseconds = (time, maxTime) => {
  return moment.duration(moment(time).diff(maxTime));
};
export const withFormatter = (value, formatter) => {
  return formatter ? formatter(value) : value.toFixed(1);
};

export const getHistoryFormattedData = (data) => ({
  isSelected: true,
  isActiveVU: data.deviceId.includes("VU"),
  data: data.timeSeries.length ? data.timeSeries.map((ts) => ({ ...ts })) : [],
});

export const getHistoryErrorMsg = (
  selectedDevice,
  activeVU,
  selectedDataLength,
  activeVUDataLength
) => {
  if (activeVU) {
    if (!selectedDataLength && !activeVUDataLength) {
      return "No Data Found For Devices.";
    } else if (!selectedDataLength) {
      return `No Data Found For ${selectedDevice?.deviceType} ${selectedDevice?.id}.`;
    } else if (!activeVUDataLength) {
      return `No Data Found For ${activeVU?.deviceType} ${activeVU?.id}.`;
    }
  } else {
    if (!selectedDataLength) {
      return `No Data Found For ${selectedDevice?.deviceType} ${selectedDevice?.id}.`;
    }
  }

  return null;
};

export const getZoomTicks = (zoomData) => {
  const array = [];
  if (zoomData.length) {
    const graphScaleSec =
      zoomData[zoomData.length - 1].timestamp - zoomData[0].timestamp;
    for (let i = 0; i <= 8; i++) {
      array.push(
        zoomData[zoomData.length - 1].timestamp - i * (graphScaleSec / 8)
      );
    }
  }

  return array;
};

export const getMemoScale = (graphScale) => {
  switch (graphScale) {
    case "5 Sec":
      return 138;
    case "15 Sec":
      return 425;
    case "30 Sec":
      return 833;
    default:
      return 138;
  }
};

export const formatMSToHMS = (ms) => {
  let seconds = ms / 1000;
  const hours = parseInt(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = parseInt(seconds / 60);
  seconds = seconds % 60;
  return hours + ":" + minutes + ":" + seconds;
};

export const getMissingDataXsArray = (
  data,
  xAxisMinValue,
  graphMaxTimestamp,
  isHistoryOn,
  isHistoryLoaded
) => {
  let array = [];
  if (xAxisMinValue !== data[0]?.timestamp) {
    array.push({ min: xAxisMinValue, max: data[0]?.timestamp - 1 });
  }
  for (let i = 0; i < data?.length - 1; i++) {
    const j = i + 1;
    let minTime = data[i]?.timestamp;
    let maxTime = data[j]?.timestamp;
    if (isHistoryOn && isHistoryLoaded) {
      const diff = Math.round((graphMaxTimestamp - data[0]?.timestamp) / 1000);
      if (maxTime - minTime > Math.round(diff / 2) + 1) {
        minTime++;
        maxTime--;
        array.push({ min: minTime, max: maxTime });
      }
    } else {
      if (maxTime - minTime > 40) {
        minTime++;
        maxTime--;
        array.push({ min: minTime, max: maxTime });
      }
    }
  }
  return array;
};
