import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { DeviceFilters } from "./DeviceFilters/DeviceFilters";
import { DeviceList } from "./DeviceList/DeviceList";

export const DeviceAndParamSelection = ({
  store,
  devices,
  filters,
  parameterTypes,
  spacing = 2,
  maxWidth = "600px",
  height = "60vh",
  orientation = "horizontal",
}) => {
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const [filteredDevices, setFilteredDevices] = useState(devices);

  useEffect(() => {
    if (selectedFilter === "All") {
      setFilteredDevices(devices);
    }
    if (selectedFilter === "Vehicles") {
      setFilteredDevices(
        devices.filter((device) => device.deviceType === "VU")
      );
    }
    if (selectedFilter === "Charging Units") {
      setFilteredDevices(
        devices.filter((device) => device.deviceType === "MU")
      );
    }
  }, [selectedFilter, devices]);

  return (
    <Stack
      direction={orientation === "horizontal" ? "row" : "column"}
      spacing={spacing}
      maxWidth={maxWidth}
      position="relative"
      overflow="hidden"
      height={height}
    >
      <DeviceFilters
        filters={filters}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <DeviceList
        devices={filteredDevices}
        store={store}
        parameterTypes={parameterTypes}
      />
    </Stack>
  );
};

DeviceAndParamSelection.propTypes = {
  devices: PropTypes.array,
  filters: PropTypes.array,
  parameterTypes: PropTypes.arrayOf(
    PropTypes.shape({
      chartType: PropTypes.string.isRequired,
      deviceType: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      supportedFromFwVersion: PropTypes.any,
      supportedUpToFwVersion: PropTypes.any,
    }).isRequired
  ).isRequired,
  spacing: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
};
