import TruckSideView from "Utils/Images/GlobalIcons/truck-side-icon.svg";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import MainContext from "Contexts/MainContext";

export const GinafDashboardButton = ({ project }) => {
  const history = useHistory();
  const { mainDispatch } = useContext(MainContext);
  return (
    <IndicationButton
      onClick={() => {
        history.push(`dashboard/live/project/${project.id}/GINAF`);
        mainDispatch({
          type: "SET_SELECTED_PROJECT",
          payload: { selectedProject: project, isLoading: false },
        });
      }}
      className="analytics-tool-button"
      buttonIconUrl={TruckSideView}
      title="GINAF Dashboard"
    />
  );
};
