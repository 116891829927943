import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  combinedVehiclesData,
  combinedGpsData,
} from "Screens/ProjectDashboard/lib/helpers/vehicleHelpers";

const useMultiCuOnSingleVehicle = (vehicleData, gpsPositions) => {
  const [newVehicleData, setNewVehicleData] = useState(vehicleData);
  const [newGpsPositions, setNewGpsPositions] = useState(gpsPositions);
  let { id: projectId, variant } = useParams();

  useEffect(() => {
    if (projectId === "2") {
      if (variant === "GINAF") {
        const filteredVehicleKeys = Object.keys(vehicleData).filter(
          (key) => key !== "VU0000122"
        );
        const filteredGpsKeys = Object.keys(gpsPositions).filter(
          (key) => key !== "VU0000122"
        );

        const vehicleInfo = combinedVehiclesData(
          vehicleData,
          filteredVehicleKeys
        );
        const gpsPos = combinedGpsData(gpsPositions, filteredGpsKeys);

        setNewVehicleData(vehicleInfo);
        setNewGpsPositions(gpsPos);
      } else {
        for (const [key, value] of Object.entries(vehicleData)) {
          if (key === "VU0000122") {
            setNewVehicleData({ [key]: value });
          }
        }
        for (const [key, value] of Object.entries(gpsPositions)) {
          if (key === "VU0000122") {
            setNewGpsPositions({ [key]: value });
          }
        }
      }
    } else {
      setNewVehicleData(vehicleData);
      setNewGpsPositions(gpsPositions);
    }
  }, [variant, vehicleData, projectId, gpsPositions]);

  return { newVehicleData, newGpsPositions };
};

export default useMultiCuOnSingleVehicle;
