import { useContext, useEffect, useRef } from "react";
import MUsContext from "Contexts/MUsContext";
import {
  getMapBackgroundPath,
  getProject,
  getProjectDashboardSettings,
  getRoadPath,
} from "Utils/APIUtils";

import dataLoadHelpers from "Screens/ProjectDashboard/lib/hooks/restDataloadHelpers";
import { actions } from "Screens/ProjectDashboard/lib/context/actionTypes";
import { useDashboardContext } from "Screens/ProjectDashboard/lib/context/dashboardContext";

/**
 *
 * @param {from projects settings metadata - will always be the same for the same project} activeVehicles
 * @param {from url query string - dynamic} requestedVehicles
 */
function deriveVehiclesToGet(activeVehicles, requestedVehicles) {
  // if no requested vehicles, return all active vehicles
  if (requestedVehicles.length === 0) {
    return activeVehicles;
  }
  return activeVehicles.filter((vuId) => requestedVehicles.includes(vuId));
}

export const useDashboardRestDataLoader = (projectId, requestedVuIds) => {
  const { continuationToken } = useContext(MUsContext);
  const timezoneStrRef = useRef("");
  const projectSettingsRef = useRef({});

  const { dispatch } = useDashboardContext();

  const loadInitialData = async (_continuationToken) => {
    const data = {};

    const project = (await getProject(projectId)).data;

    try {
      const { timezoneStr } = project;
      timezoneStrRef.current = timezoneStr;

      data.projectSettings = await getProjectDashboardSettings(projectId);
      projectSettingsRef.current = data.projectSettings;

      data.roadPath = await getRoadPath(projectId);
      data.vehicleData = await dataLoadHelpers._getVehicles(
        projectId,
        _continuationToken,
        deriveVehiclesToGet(
          data.projectSettings.activeVehicles,
          requestedVuIds
        ),
        timezoneStrRef.current
      );

      data.backgroundGeojson = projectSettingsRef.current.map.assets
        ?.backgroundGeojson
        ? await getMapBackgroundPath(projectId)
        : undefined;

      data.projectSegments = await dataLoadHelpers._getSegments(projectId);
      const ob = {};
      Object.values(data.projectSegments).forEach((elm) => {
        const key = elm.lat + "_" + elm.lng;
        if (ob[key]) {
          console.warn(
            "duplicate segment",
            elm,
            "original segment is",
            ob[key]
          );
        } else {
          ob[key] = elm;
        }
      });

      data.managementUnits = await dataLoadHelpers._getMUs(
        projectId,
        _continuationToken
      );

      data.segmentsPath = await dataLoadHelpers._getSgementsPath(projectId);

      projectSettingsRef.current.activeVehicles.map((vehicleId) => {
        data.chargingStatuses = {
          ...data.chargingStatuses,
          [vehicleId]: { isCharging: 0 },
        };
      });

      dispatch({ type: actions.SET_REST_DATA, data });
    } catch (e) {
      // todo - catch error in another component and throw exception
      console.error("error in rest data loader", e);
      dispatch({ type: actions.ERROR_LOADING_DATA, payload: { error: e } });
    }
  };

  useEffect(() => {
    loadInitialData(continuationToken);
  }, [continuationToken]);
};
