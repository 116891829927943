import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PopupButton from "Components/Buttons/PopupButton/PopupButton";
import PropTypes from "prop-types";

const Popup = ({
  isOpen,
  handleClose,
  handleSuccess,
  title,
  content,
  onlyOKButton = false,
}) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false}>
      {title && (
        <DialogTitle sx={{ backgroundColor: "#0000000A" }}>{title}</DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <PopupButton text="OK" onClick={handleSuccess} primary />
        {!onlyOKButton && <PopupButton text="Cancel" onClick={handleClose} />}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;

Popup.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSuccess: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.object,
  onlyOKButton: PropTypes.bool,
};
