import React, { useContext } from "react";
import PropTypes from "prop-types";

//Components
import MUDrawers from "Screens/ProjectView/Devices/MU/MUDrawers";
import DcSamplerSummary from "Components/DcSampler/DcSamplerSummary";
import ContactorControl from "Components/ContactorControl/ContactorControl";
import MuIndicationLeds from "Components/StatusIndication/MuIndicationLeds";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";

//Utils
import { getIsDeviceDisconnected } from "Utils/UtilsFunctions";
import "Components/MUMainView/MUMainView.scss";

const MUMainView = ({
  selectedDrawer,
  setSelectedDrawer,
  socketMessageDataSegments,
  setModalState,
}) => {
  const { selectedDevice } = useContext(MainContext);
  const { connectionState, discretes } = useContext(SocketContext);

  const isDeviceOffline = getIsDeviceDisconnected(
    connectionState,
    selectedDevice
  );

  const { contactor_control } =
    discretes[selectedDevice.id]?.socketMessageData || {};

  return (
    <div
      className={`drawers-contactors-buttons-wrapper ${
        !!contactor_control ? "contactor-on" : ""
      } ${isDeviceOffline ? "gray-layout" : ""} `}
    >
      <div className="contactor-buttons-mu-data-summary">
        <ContactorControl setModalState={setModalState} />
        <MuIndicationLeds />
        <div className="mu-data-summary">
          <DcSamplerSummary />
        </div>
      </div>
      <MUDrawers
        selectedDrawer={selectedDrawer}
        isSettingButtons
        setSelectedDrawer={setSelectedDrawer}
        socketMessageDataSegments={socketMessageDataSegments}
      />
    </div>
  );
};

export default MUMainView;

MUMainView.propTypes = {
  selectedDrawer: PropTypes.number,
  setSelectedDrawer: PropTypes.func,
  socketMessageDataSegments: PropTypes.object,
  setModalState: PropTypes.func,
};
