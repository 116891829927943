import React, { useEffect, useContext } from "react";
import AppRouter from "AppRouter/AppRouter";
import axios from "axios";
import { useHistory } from "react-router-dom";

//Components
import Loader from "Components/Loader/Loader";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";
import { useAppStore } from "MobxStores/context";

//Utils
import {
  getAccessToken,
  getSessionIdProject,
  handleSignOut,
} from "Utils/APIUtils";
import { acquireTokenSilent } from "Utils/UtilsFunctions";
import { handleLockOnAcquireToken } from "Utils/LockUtils";

const AppMain = () => {
  const history = useHistory();
  const {
    authenticationModule,
    isLoading,
    mainDispatch,
    currentUser,
    shouldAcquireToken,
    intervalIds,
    intervalInstance,
    timeoutIds,
  } = useContext(MainContext);
  const { lockInfo } = useContext(SocketContext);
  const socketId = getSessionIdProject();

  const { userStore, projectStore } = useAppStore();

  // todo: this function should be moved, its used in login and in appMain
  const setUserInfo = (user) => {
    console.log(
      `setUserInfo from AppMain to ${user?.idTokenClaims?.extension_UserRole?.trim()}`
    );
    userStore.setUserData(user);
    userStore.updateRole(user?.idTokenClaims?.extension_UserRole?.trim());
    mainDispatch({
      type: "SET_USER",
      payload: {
        currentUser: user,
        authenticated: !!user?.username,
        userRole: user?.idTokenClaims?.extension_UserRole?.trim(),
        userScope: user?.idTokenClaims?.extension_ProjectScope?.trim(),
      },
      isLoading: false,
    });
  };

  const updateUser = () => {
    if (typeof authenticationModule.setLocalAccount !== "function") {
      return;
    }
    const localAccount = authenticationModule.getLocalAccount();
    localAccount &&
      authenticationModule.setLocalAccount(localAccount, mainDispatch);
    const account = authenticationModule.getAccount();
    account && authenticationModule.setAccount(account, setUserInfo);
    if (account || localAccount) userStore.setUserData(account || localAccount);
  };

  // this happens if one of the axios request failed with 401 OR the any socket got JWT Error.
  useEffect(() => {
    if (shouldAcquireToken) {
      if (intervalIds && intervalIds.length) {
        intervalIds.map((id) => clearInterval(id));

        mainDispatch({
          type: "DELETE_INTERVAL_ID",
        });
      }
      if (timeoutIds && timeoutIds.length) {
        timeoutIds.map((id) => clearTimeout(id));

        mainDispatch({
          type: "DELETE_TIMEOUT_ID",
        });
      }
      acquireTokenSilent(
        "acquireTokenSilentNewAccessToken",
        mainDispatch,
        authenticationModule,
        history,
        setUserInfo,
        projectStore
      );
    }
    if (!shouldAcquireToken && Object.keys(lockInfo).length) {
      for (const [deviceId, lockProps] of Object.entries(lockInfo)) {
        const isLockByMe = Object.values(lockProps).find(
          (sessionId) => sessionId === socketId
        );
        if (isLockByMe) {
          handleLockOnAcquireToken(
            deviceId,
            socketId,
            intervalInstance,
            mainDispatch
          );
        }
      }
    }
  }, [shouldAcquireToken]);

  useEffect(() => {
    // if (location.pathname !== "/" && location.pathname !== "")
    authenticationModule.myMSALObj
      .handleRedirectPromise()
      .then((res) => {
        if (
          res &&
          res.accessToken &&
          res.accessToken !== localStorage.getItem("prevAccessToken")
        ) {
          const accessToken = res.accessToken;
          localStorage.setItem("accessToken", accessToken);
          const bearer = "Bearer " + accessToken;
          axios.defaults.headers.common["Authorization"] = bearer;
          authenticationModule.setAccount(res.account, setUserInfo);
          //todo: why not use the function setAuthenticatedUser??
        }
      })
      .catch((err) => console.log(err, "handleRedirectPromise error"));

    axios.interceptors.response.use(
      (response) => {
        //handle 401 (unauthorized) for all axios responses
        return response;
      },
      (error) => {
        if (error && error.response && error.response.status === 401) {
          !shouldAcquireToken &&
            mainDispatch({
              type: "SET_SHOULD_ACQUIRE_TOKEN",
              payload: { shouldAcquireToken: true },
            });
        }
        return error;
      }
    );
    const accessToken = getAccessToken();
    if (!accessToken) {
      handleSignOut(mainDispatch, history, authenticationModule, projectStore);
    } else {
      const bearer = "Bearer " + accessToken;
      axios.defaults.headers.common["Authorization"] = bearer; //set Authorization header to all requests
      !currentUser.username && updateUser();
    }
  }, []);

  useEffect(() => {
    mainDispatch({
      type: "SET_IS_LOADING",
      payload: { isLoading: false },
    });
  }, [isLoading]);

  return (
    <>
      {(isLoading || shouldAcquireToken) && <Loader />}
      <AppRouter />
    </>
  );
};

export default AppMain;
