import React, {
  useContext,
  useCallback,
  useRef,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Flag from "react-flagpack";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { startCase } from "lodash";
import PropTypes from "prop-types";

//Components
import SearchBox from "Components/SearchComponents/SearchBox";
import Image from "Components/Image/Image";
import Logo from "Components/Logo/Logo";
import SearchDropdown from "Components/SearchComponents/SearchDropdown";
import HeaderMenu from "Components/HeaderMenu/HeaderMenu";

//Hooks
import useSelectedProjectData from "CustomHooks/useSelectedProject";

//Context
import MainContext from "Contexts/MainContext";
import { useAppStore } from "MobxStores/context";

//Utils
import User from "Utils/Images/GlobalIcons/user_icon.svg";
import Dots from "Utils/Images/GlobalIcons/header-three-dots-menu-icon.svg";
import NotificationBell from "Utils/Images/GlobalIcons/notifications_bell.svg";
import VerticalLineSeparator from "Utils/Images/GlobalIcons/vertical-line-separator.svg";
import Logout from "Utils/Images/GlobalIcons/logout.svg";
import { handleSignOut, getProject } from "Utils/APIUtils";
import { getProjectLogo } from "Utils/ProjectUtils";
import ElectreonSmallLogo from "Utils/Images/GlobalIcons/electreon-logo-small.svg";
import "Components/Header/Header.scss";

const Header = ({ transparentBackground }) => {
  const [projectLogo, setProjectLogo] = useState();

  const {
    userRole,
    currentUser,
    appQuery,
    isSearchOpened,
    selectedProject,
    mainDispatch,
    authenticationModule,
    shouldAcquireToken,
  } = useContext(MainContext);
  const { projectStore } = useAppStore();
  const { timezoneStr } = useSelectedProjectData();

  const searchRef = useRef(null);
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const projectName = selectedProject?.name || "";
  const projectLocationNum = pathname.match(RegExp("[0-9]+", "g"));
  const projectId = projectLocationNum && projectLocationNum[0];
  const { username } = currentUser || {
    username: "",
  };
  const isDepotDashboard = pathname.includes("dashboard/live/depot/");
  const firstNameLetter =
    currentUser?.idTokenClaims?.given_name?.charAt(0) || "";
  const familyNameLetter =
    currentUser?.idTokenClaims?.family_name?.charAt(0) || "";
  let loggedUser = `${firstNameLetter}${familyNameLetter}`;

  const { userStore } = useAppStore();
  const { userData } = userStore;
  const shouldShowSearch =
    userData && !userData.isSingleScoped() && !userData.isSingleProjectViewer();

  const loggedUserIcon = () => {
    if (loggedUser === "") {
      return <Image src={User} />;
    }
    return <div className="user-icon-letters">{loggedUser}</div>;
  };

  const handleLogout = useCallback(() => {
    return handleSignOut(
      mainDispatch,
      history,
      authenticationModule,
      projectStore
    );
  }, [handleSignOut, history, authenticationModule, projectStore]);

  const handleClickOutsideSearch = useCallback((e) => {
    if (
      searchRef &&
      searchRef.current &&
      !searchRef.current.contains(e.target)
    ) {
      mainDispatch({
        type: "TOGGLE_SEARCH_BOX",
        payload: { isSearchOpened: false },
      });
    }
  }, []);

  const handleMainLogoClick = useCallback(() => {
    if (userData?.isSingleScoped() || userData?.isSingleProjectViewer()) {
      return;
    } else {
      history.push("/dashboard");
    }
  }, [history, userData]);

  useEffect(() => {
    if (isSearchOpened)
      document.addEventListener("mousedown", handleClickOutsideSearch);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSearch);
    };
  });

  useEffect(() => {
    if (projectId) {
      const getLogo = async () => {
        const logo = await getProjectLogo(projectId);
        setProjectLogo(logo?.main || ElectreonSmallLogo);
      };
      getLogo();
    }
  }, [projectId]);

  useEffect(() => {
    // get project on refresh
    if (
      projectId &&
      !selectedProject &&
      !shouldAcquireToken &&
      !isDepotDashboard
    ) {
      getProject(projectId).then((res) => {
        if (res && res.status === 200) {
          mainDispatch({
            type: "SET_SELECTED_PROJECT",
            payload: { selectedProject: res.data, isLoading: false },
          });
        } else {
          mainDispatch({
            type: "SET_SELECTED_PROJECT",
            payload: { selectedProject: undefined, isLoading: true },
          });
        }
      });
    }
  }, [selectedProject, projectId, shouldAcquireToken, isDepotDashboard]);

  const countryName = (name) => {
    switch (name) {
      case "IST":
        return "IL";
      case "UT":
        return "US";
      default:
        return name;
    }
  };

  return (
    <div
      className={`app-header ${
        transparentBackground ? "transparent-background" : ""
      }`}
    >
      <div className="app-header-left">
        <Logo
          alt="header-logo"
          onClickLogo={handleMainLogoClick}
          className="electreon-main-logo"
        />

        <div className="navigation-indication">
          {projectName && (
            <>
              <Image src={VerticalLineSeparator} />
              {projectLogo && (
                <Logo
                  logo={projectLogo}
                  className="project-header-logo"
                  alt="project-logo"
                  height={48}
                />
              )}
              <div className="breadcrumb navigation-indication-project-name">
                {` ${startCase(projectName)}`}
              </div>
            </>
          )}
        </div>
      </div>
      <HeaderMenu />
      <div className="app-header-right">
        {shouldShowSearch && (
          <div className="search-header" ref={searchRef}>
            <SearchBox
              dispatch={mainDispatch}
              query={appQuery}
              isSearchOpened={isSearchOpened}
            />
            <SearchDropdown />
          </div>
        )}
        {userRole !== "PROJECT_VIEWER" && (
          <div className="notification-bell-icon-wrapper">
            <Image src={NotificationBell} />
          </div>
        )}
        <div className="header-date-time">
          <div className="header-date">{moment().format("DD/MM/YYYY")}</div>
          <div className="header-time">
            {moment().tz(timezoneStr).format("HH:mm")}
          </div>
        </div>
        {selectedProject && (
          <Flag code={countryName(selectedProject.countryCode)} size="m" />
        )}
        <div className="user-icon-wrapper" data-for="yoda-tip" data-tip>
          {loggedUserIcon()}
        </div>
        <div>
          <Image width={42} src={Dots} />
        </div>
        <ReactTooltip
          clickable
          id="yoda-tip" //TODO: CHANGE TO user-id-tooltip
          className="yoda-tip"
          getContent={(dataTip) => (
            <div className="header-tooltip-content">
              <button className="logout" onClick={handleLogout}>
                <Image src={Logout} height={15} width={15} alt="logout" />
              </button>
              <div className="tooltip-user-data">
                <span>{username}</span>
                <span>{userRole}</span>
              </div>
            </div>
          )}
          effect="solid"
          delayHide={500}
          delayShow={0}
          delayUpdate={0}
          place="bottom"
          border
          type="light"
        />
      </div>
    </div>
  );
};

export default withRouter(Header);

Header.propTypes = {
  transparentBackground: PropTypes.bool,
};
