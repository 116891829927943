import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import PropTypes from "prop-types";

const SliderWrapper = ({
  min,
  max,
  className,
  value,
  step,
  onChange,
  defaultValue,
  onFocus,
  onBlur,
  reverse,
  handleStyle,
  trackStyle,
  railStyle,
  isDisabled,
  onAfterChange,
}) => {
  return (
    <>
      <Slider
        onFocus={onFocus}
        onBlur={onBlur}
        min={min}
        max={max}
        className={className}
        value={value}
        step={step}
        onChange={onChange}
        defaultValue={defaultValue}
        handleStyle={handleStyle}
        trackStyle={trackStyle}
        railStyle={railStyle}
        reverse={reverse}
        disabled={isDisabled}
        onAfterChange={onAfterChange}
      />
    </>
  );
};

export default SliderWrapper;

SliderWrapper.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  value: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
  defaultValue: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  reverse: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onAfterChange: PropTypes.func,
};
