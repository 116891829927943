import moment from "moment";
import PropTypes from "prop-types";
import useSelectedProjectData from "CustomHooks/useSelectedProject";
import "Components/Tooltip/CustomTooltip.scss";
import CustomTooltip from "./CustomTooltip";

const GraphTooltip = ({
  active,
  payload,
  label,
  graphType,
  graphUnits,
  isHistory,
}) => {
  const { timezoneStr } = useSelectedProjectData();
  if (active && payload && payload?.length) {
    const decimalPlaces = 2;
    return (
      <CustomTooltip
        header={
          <>
            <p className="unit-type">{`${graphType}`}</p>
            <p>{moment(label).tz(timezoneStr).format("HH:mm:ss.SSSS")}</p>
          </>
        }
        body={
          <div className="tooltip-device-info">
            {
              // reverse to show VU data first on analytics.
              // no reverse needed for history.
              !isHistory
                ? payload.reverse().map((data, index) => {
                    return (
                      <div
                        className={`tooltip-device-data ${
                          data.unit === "VU" ? "primary-unit" : ""
                        }`}
                        key={index}
                      >
                        <div
                          className={`color-index`}
                          style={{ backgroundColor: `${data.color}` }}
                        ></div>
                        <div className="name-container">
                          <p className="unit-name">{`${data?.name} `}</p>
                        </div>
                        <p className="data-value">{`${Number(
                          data?.value
                        ).toFixed(decimalPlaces)} ${
                          data?.dataKey?.includes("speed") ? "km/h" : graphUnits
                        }`}</p>
                      </div>
                    );
                  })
                : payload.map((data) => {
                    return (
                      <p className="label" key={data.name}>{`${data?.name}
                      ${data?.value?.toFixed(decimalPlaces)} ${graphUnits}`}</p>
                    );
                  })
            }
          </div>
        }
      />
    );
  }
  return null;
};
export default GraphTooltip;

GraphTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.number,
  graphType: PropTypes.string,
  graphUnits: PropTypes.string,
  isHistory: PropTypes.bool,
};
