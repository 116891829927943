export const FpgaMessages = new Map([
  [
    "FPGA_EVENT_UPDATE_FAILED",
    {
      text: "FPGA_EVENT_UPDATE_FAILED",
      messageType: "error",
      eventType: "fpga",
      description: "Failed to update the FPGA (FOTA)",
    },
  ],
  [
    "FPGA_EVENT_COMMAND_FAILED",
    {
      text: "FPGA_EVENT_COMMAND_FAILED",
      messageType: "error",
      eventType: "fpga",
      description: "Failed to execute command on FPGA",
    },
  ],
  [
    "FPGA_EVENT_CALIBRATION_PERCENTAGES_OF_PROGRESS_0_TO_33",
    {
      text: "FPGA_EVENT_CALIBRATION_PERCENTAGES_OF_PROGRESS_0_TO_33",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 20,
      progressDone: false,
      description:
        "Show 20% (progress on button), button disabled, percentage tooltip - test only",
    },
  ],
  [
    "FPGA_EVENT_CALIBRATION_PERCENTAGES_OF_PROGRESS_34_TO_66",
    {
      text: "FPGA_EVENT_CALIBRATION_PERCENTAGES_OF_PROGRESS_34_TO_66",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 40,
      progressDone: false,
      description:
        "Show 40% (progress on button), button disabled, percentage tooltip - test only",
    },
  ],
  [
    "FPGA_EVENT_CALIBRATION_PERCENTAGES_OF_PROGRESS_67_TO_100",
    {
      text: "FPGA_EVENT_CALIBRATION_PERCENTAGES_OF_PROGRESS_67_TO_100",
      messageType: "info-progress",
      eventType: "show-progress",
      progress: 80,
      progressDone: false,
      description:
        "Show 80% (progress on button), button disabled, percentage tooltip - test only",
    },
  ],
  [
    "FPGA_EVENT_CALIBRATION_DONE",
    {
      text: "FPGA_EVENT_CALIBRATION_DONE",
      messageType: "info",
      eventType: "show-progress",
      progress: 100,
      progressDone: true,
      description: "Segment calibration done (success)",
    },
  ],
]);
