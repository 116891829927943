import React, { useContext } from "react";
import "Components/SearchComponents/SearchDropdown.scss";
import MainContext from "Contexts/MainContext";
import FilteredSearchResults from "Components/SearchComponents/FilteredSearchResults";

import StaticCharging from "Utils/Images/GlobalIcons/static-icon.svg";
import DynamicCharging from "Utils/Images/GlobalIcons/dynamic-search-result-icon.svg";
import CarIcon from "Utils/Images/GlobalIcons/car-search-result-icon.svg";
import TruckIcon from "Utils/Images/GlobalIcons/truck-icon.svg";
import BusIcon from "Utils/Images/ProjectMaintanance/bus-icon.svg";

const muImageFn = (mu) => {
  switch (mu?.chargingType) {
    case "STATIC":
      return StaticCharging;
    case "DYNAMIC":
      return DynamicCharging;
    default:
      return null;
  }
};

const vuImageFn = (vu) => {
  switch (vu?.vehicleType) {
    case "BUS":
      return BusIcon;
    case "TRUCK":
      return TruckIcon;
    case "PRIVATE":
      return CarIcon;
    default:
      return null;
  }
};

const SearchDropdown = () => {
  const { appQuery, searchResults, isSearchOpened } = useContext(MainContext);

  const itemMUs =
    searchResults &&
    Array.isArray(searchResults) &&
    searchResults.find((item) => item?.itemsType === "MU");
  const itemVUs =
    searchResults &&
    Array.isArray(searchResults) &&
    searchResults.find((item) => item?.itemsType === "VU");
  const itemProjects =
    searchResults &&
    Array.isArray(searchResults) &&
    searchResults.find((item) => item?.itemsType === "projects");

  const devicesMU = itemMUs?.items;
  const devicesVU = itemVUs?.items;
  const projects = itemProjects?.items;

  const projectsExists = projects && !!projects.length;
  const devicesMUExists = devicesMU && !!devicesMU.length;
  const devicesVUExists = devicesVU && !!devicesVU.length;
  return isSearchOpened && appQuery ? (
    projectsExists || devicesMUExists || devicesVUExists ? (
      <div className="search-drop-down">
        {projectsExists && (
          <FilteredSearchResults
          filteredData={projects}
          filteredProperty="name"
          filteredDataName="projects"
          total={itemProjects.itemsTotal}
          itemImageFn={(proj) => proj.projectImage}
          />
        )}
        {devicesMUExists && (
          <FilteredSearchResults
            filteredData={devicesMU}
            filteredProperty="id"
            filteredDataName="management units"
            total={itemMUs.itemsTotal}
            secondaryProperty="name"
            itemImageFn={(mu) => muImageFn(mu)}
          />
        )}
        {devicesVUExists && (
          <FilteredSearchResults
            filteredData={devicesVU}
            filteredProperty="id"
            filteredDataName="vehicles units"
            total={itemVUs.itemsTotal}
            secondaryProperty="name"
            itemImageFn={(vu) => vuImageFn(vu)}
          />
        )}
      </div>
    ) : (
      <div className="no-result-message">
        <FilteredSearchResults filteredDataName="no matches found" />
      </div>
    )
  ) : (
    ""
  );
};

export default SearchDropdown;
