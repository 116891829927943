import { useState, useRef, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTheme } from "@mui/material";
import moment from "moment";

import {
  LineChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Line,
  ReferenceArea,
  AreaChart,
  Area,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import AnalyticGraphTooltip from "Components/Tooltip/AnalyticGraphTooltip";
import {
  LineGraphProps,
  TickProps,
} from "Components/Graphs/AdvancedParamsGraphs";

import {
  muColorsForGraph,
  geFilteredIndexes,
  padTimeTo,
  getGraphUnit,
} from "Utils/AnalyticUtils";

const CustomizedTick: TickProps = ({
  y,
  x,
  payload: { value },
  verticalAnchor,
  visibleTicksCount,
  tickFormatter,
  graphUnits,
  isAccumulatedGraph,
  ...rest
}) => {
  const label = isAccumulatedGraph
    ? `${Math.round(value / 1000)} ${graphUnits}`
    : value > 1000
    ? `${Math.trunc(value / 1000)} k${graphUnits}`
    : `${value} ${graphUnits}`;

  const calcLabelWidth = useCallback((label: string) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.font = "10px Urbanist";
      return ctx.measureText(label).width;
    }
    return 0;
  }, []);

  const newY = y === 12 ? y - 10 : y - 5;
  const newX = x - calcLabelWidth(label) - 15;

  return (
    <text y={newY} x={newX} {...rest}>
      {label}
    </text>
  );
};

const LineGraph: LineGraphProps = ({
  timeSeries,
  index,
  id,
  graphId,
  domainStart = "dataMin",
  domainEnd = "dataMax",
  yDomainFromDataMin = false,
  timezoneStr,
  getDataForSelectedTimespan,
  setSelectedTimespan,
  deviceActivityData,
  zoomTimespan,
  isZooming,
  startTime,
  endTime,
  mouseCoordsToGraph,
  getSummaryTableData,
  displayName,
  maxPowerGraphValue,
  setTimeRulerBorder,
  updateActivityData,
}) => {
  const [areaRef, setAreaRef] = useState<{
    dataStart?: number | moment.Moment | null;
    dataEnd?: number | moment.Moment | null;
  }>({ dataStart: null, dataEnd: null });
  const mouseCoords = useRef<any>(null);
  const theme = useTheme();

  const isVuPowerGraph = graphId === "VU_DC_METER_POWER";
  const isMuPowerGraph = graphId === "MU_DC_METER_POWER";
  const isAccumulatedGraph = graphId.includes("ACCUMULATED");
  const isHeatSyncTempGraph = graphId.includes("SYNC");

  const graphUnits = useMemo(() => getGraphUnit(displayName), [displayName]);
  const offset =
    moment(deviceActivityData[0].timestamp).utcOffset() * 60 * 1000;
  const projectOffset = moment(deviceActivityData[0].timestamp)
    .tz(timezoneStr)
    .utcOffset();

  const getZoomData = () => {
    updateActivityData(timezoneStr);

    mouseCoords.current = null;
    let { dataStart, dataEnd } = areaRef;
    if (!dataStart || !dataEnd || dataStart === dataEnd) {
      return setAreaRef({ dataStart: null, dataEnd: null });
    }

    if (dataStart > dataEnd) [dataStart, dataEnd] = [dataEnd, dataStart];

    let paddedStart;
    let paddedEnd;
    if (moment(dataEnd).diff(dataStart, "seconds") < 1) {
      const { newStart, newEnd } = padTimeTo(
        "second",
        dataStart.valueOf(),
        dataEnd.valueOf()
      );
      paddedStart = newStart;
      paddedEnd = newEnd;
    }
    const filteredTimerange = geFilteredIndexes(
      deviceActivityData,
      paddedStart || dataStart,
      paddedEnd || dataEnd,
      timezoneStr
    );

    setSelectedTimespan(
      filteredTimerange.startIndex,
      filteredTimerange.endIndex,
      paddedStart || dataStart,
      paddedEnd || dataEnd
    );
    getDataForSelectedTimespan(
      filteredTimerange.startIndex,
      filteredTimerange.endIndex,
      timezoneStr,
      paddedStart || dataStart,
      paddedEnd || dataEnd
    );
    getSummaryTableData(
      timezoneStr,
      null,
      paddedStart || dataStart,
      paddedEnd || dataEnd
    );

    setAreaRef({ dataStart: null, dataEnd: null });
  };

  const chartPan = (state: CategoricalChartState, e: React.MouseEvent) => {
    if (
      state?.chartX &&
      state?.chartY &&
      mouseCoords.current !== null &&
      e.shiftKey
    ) {
      const scale =
        moment(domainEnd).diff(domainStart, "seconds") !== 0
          ? moment(domainEnd).diff(domainStart, "seconds") * 0.05
          : 0.1;

      const direction =
        mouseCoords.current.x - state.chartX < 0 ? "left" : "right";

      if (isZooming) {
        if (direction === "left") {
          const zoomStart = moment(domainStart).subtract(scale, "seconds");
          const zoomEnd = moment(domainEnd).subtract(scale, "seconds");

          if (moment(zoomStart).isBefore(startTime)) {
            setTimeRulerBorder(true, false);
            return;
          }

          setTimeRulerBorder(false, false);
          zoomTimespan(zoomStart, zoomEnd);
          mouseCoords.current = { x: state.chartX };
        } else if (direction === "right") {
          const zoomStart = moment(domainStart).add(scale, "seconds");
          const zoomEnd = moment(domainEnd).add(scale, "seconds");

          if (moment(endTime).isBefore(zoomEnd)) {
            setTimeRulerBorder(false, true);
            return;
          }

          setTimeRulerBorder(false, false);
          zoomTimespan(zoomStart, zoomEnd);
          mouseCoords.current = { x: state.chartX };
        }
      }
    }
  };

  const getPowerGraphColors = useCallback(
    (isFillColor?: boolean) => {
      if (isVuPowerGraph) {
        return isFillColor
          ? theme.palette.accent.primary[400]
          : theme.palette.accent.primary.main;
      } else if (isMuPowerGraph) {
        return isFillColor
          ? theme.palette.accent.secondary[400]
          : theme.palette.accent.secondary.main;
      } else {
        return null;
      }
    },
    [isMuPowerGraph, isVuPowerGraph, theme]
  );

  const maxYDomainValue = (
    maxPowerGraphValue +
    maxPowerGraphValue * 0.1
  ).toFixed(2);

  return (
    <div className="line-graph-wraper">
      <ResponsiveContainer height={165}>
        {isMuPowerGraph || isVuPowerGraph ? (
          <AreaChart
            data={timeSeries}
            syncId={id}
            syncMethod="value"
            margin={{ top: 5 }}
            onMouseMove={(
              state: CategoricalChartState,
              e: React.MouseEvent
            ) => {
              const { activeLabel, activeTooltipIndex } = state;
              if (!activeLabel || !activeTooltipIndex) return;
              if (state) {
                if (areaRef.dataStart && activeLabel) {
                  setAreaRef((val) => ({
                    ...val,
                    dataEnd: Number(activeLabel),
                  }));
                }
                if (mouseCoords.current !== null) {
                  chartPan(state, e);
                }
                mouseCoordsToGraph.current =
                  activeTooltipIndex / timeSeries.length;
              }
            }}
            onMouseDown={(
              state: CategoricalChartState,
              e: React.MouseEvent
            ) => {
              const { altKey, shiftKey } = e;
              const { activeLabel, chartX } = state;
              if (state) {
                if (altKey) {
                  setAreaRef((val) => ({
                    ...val,
                    dataStart: Number(activeLabel),
                  }));
                }
                if (shiftKey) {
                  mouseCoords.current = { x: chartX };
                }
              }
            }}
            onMouseUp={getZoomData}
          >
            <Area
              type="monotone"
              dataKey="value"
              stroke={getPowerGraphColors() || muColorsForGraph[index]}
              fill={getPowerGraphColors(true) || muColorsForGraph[index]}
              dot={false}
              legendType="none"
              activeDot={{
                r: 3,
                fill: "#fff",
                stroke: getPowerGraphColors() || muColorsForGraph[index],
              }}
              isAnimationActive={false}
              connectNulls={false}
            />
            <Tooltip
              position={{ y: -25 }}
              isAnimationActive={false}
              content={<AnalyticGraphTooltip graphUnits={graphUnits} />}
            />
            {areaRef?.dataStart && areaRef?.dataEnd ? (
              <ReferenceArea
                x1={+areaRef.dataStart}
                x2={+areaRef.dataEnd}
                strokeOpacity={0.3}
              />
            ) : null}
            <XAxis
              tick={false}
              dataKey={(d) =>
                moment(d.timestamp)
                  .subtract(offset, "milliseconds")
                  .add(projectOffset, "minutes")
                  .valueOf()
              }
              stroke="#0000001f"
              type="number"
              domain={[
                moment(domainStart).valueOf(),
                moment(domainEnd).valueOf(),
              ]}
              allowDataOverflow
            />
            <YAxis
              orientation="right"
              axisLine={false}
              tickLine={false}
              ticks={[0, Number(maxYDomainValue) / 2, maxYDomainValue]}
              fontSize="10px"
              width={0.1}
              tick={(props) => (
                <CustomizedTick graphUnits={graphUnits} {...props} />
              )}
              domain={[0, () => maxYDomainValue]}
            />
            <CartesianGrid vertical={false} stroke="#0000001f" />
          </AreaChart>
        ) : (
          <LineChart
            data={timeSeries}
            syncId={id}
            syncMethod="value"
            margin={{ top: 5 }}
            onMouseMove={(state: any, e: React.MouseEvent) => {
              const { activeLabel, activeTooltipIndex } = state;
              if (state) {
                if (areaRef.dataStart) {
                  setAreaRef((val) => ({ ...val, dataEnd: activeLabel }));
                }
                if (mouseCoords.current !== null) {
                  chartPan(state, e);
                }
                mouseCoordsToGraph.current =
                  activeTooltipIndex / timeSeries.length;
              }
            }}
            onMouseDown={(state: any, e: React.MouseEvent) => {
              const { altKey, shiftKey } = e;
              const { activeLabel, chartX } = state;
              if (state) {
                if (altKey) {
                  setAreaRef((val) => ({ ...val, dataStart: activeLabel }));
                }
                if (shiftKey) {
                  mouseCoords.current = { x: chartX };
                }
              }
            }}
            onMouseUp={getZoomData}
          >
            <Line
              type="monotone"
              dataKey="value"
              stroke={getPowerGraphColors() || muColorsForGraph[index]}
              dot={false}
              legendType="none"
              activeDot={{
                r: 3,
                fill: "#fff",
                stroke: getPowerGraphColors(false) || muColorsForGraph[index],
              }}
              isAnimationActive={false}
              connectNulls={
                isHeatSyncTempGraph || isAccumulatedGraph ? true : false
              }
            />
            <Tooltip
              position={{ y: -25 }}
              isAnimationActive={false}
              content={<AnalyticGraphTooltip graphUnits={graphUnits} />}
            />
            {areaRef?.dataStart && areaRef?.dataEnd ? (
              <ReferenceArea
                x1={+areaRef.dataStart}
                x2={+areaRef.dataEnd}
                strokeOpacity={0.3}
              />
            ) : null}
            <CartesianGrid vertical={false} stroke="#0000001f" />
            <XAxis
              tick={false}
              dataKey={(d) =>
                moment(d.timestamp)
                  .subtract(offset, "milliseconds")
                  .add(projectOffset, "minutes")
                  .valueOf()
              }
              stroke="#0000001f"
              type="number"
              domain={[
                moment(domainStart).valueOf(),
                moment(domainEnd).valueOf(),
              ]}
              allowDataOverflow
            />
            <YAxis
              orientation="right"
              axisLine={false}
              tickLine={false}
              tickCount={4}
              fontSize="10px"
              width={0.1}
              tick={(props) => (
                <CustomizedTick
                  graphUnits={graphUnits}
                  {...props}
                  isAccumulatedGraph={isAccumulatedGraph}
                />
              )}
              domain={([dataMin, dataMax]: [number, number]) => {
                if (dataMin !== 0) {
                  return [dataMin * 0.9, +(dataMax * 1.1).toFixed(2)];
                }
                return [
                  (yDomainFromDataMin && dataMin) || 0,
                  +(dataMax * 1.1).toFixed(2),
                ];
              }}
            />
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default observer(LineGraph);
