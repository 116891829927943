import { Typography, Stack } from "@mui/material";
import { ReactComponent as RangeIcon } from "Utils/Images/ProjectAnalytics/analytic-range-icon.svg";

export const SummaryTableHeader = () => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack alignItems="flex-start">
        <Typography
          variant="h5"
          component="p"
          sx={{ fontSize: 12, fontWeight: 400, lineHeight: "12px" }}
        >
          Selected Range
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontSize: 24, fontWeight: 400, lineHeight: "32px" }}
        >
          Summary
        </Typography>
      </Stack>
      <RangeIcon />
    </Stack>
  );
};
