export const actions = {
  SET_REST_DATA: "SET_REST_DATA",
  SET_REST_POLLING_DATA: "SET_REST_POLLING_DATA",
  ERROR_LOADING_DATA: "ERROR_LOADING_DATA",
  DC_SAMPLER_MESSAGE: "DC_SAMPLER_MESSAGE",
  DEVICES_STATES_MESSAGE: "DEVICES_STATES_MESSAGE",
  BATTERY_DATA_MESSAGE: "BATTERY_DATA_MESSAGE",
  CHARGING_MESSAGE: "CHARGING_MESSAGE",
  GPS_MESSAGE: "GPS_MESSAGE",
  FPGA_MESSAGE: "FPGA_MESSAGE",
  CONNECTION_STATE_CHANGE: "CONNECTION_STATE_CHANGE",
};
