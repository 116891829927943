import React from "react";
import "Screens/DepotDashboard/DepotSummary/DepotSummary.scss";

import { DataGrid } from "@mui/x-data-grid";
import { columns } from "Screens/DepotDashboard/DepotSummary/VehiclesAtDepotData";

import ReadyToChargeIcon from "Utils/Images/DepotIcons/vu-ready-to-charge-icon.svg";
import ChargingVehicleIcon from "Utils/Images/DepotIcons/vu-charging-icon.svg";
import FullyChargedIcon from "Utils/Images/DepotIcons/vu-fully-charged-icon.svg";
import WPTErrorIcon from "Utils/Images/DepotIcons/vu-wpt-icon.svg";

import PropTypes from "prop-types";

export const ICON_TYPES = {
  DISCHARGING: "Discharging",
  CHARGING: "Charging",
  FULLY_CHARGED: "Fully Charged",
  WPT_ERROR: "WPT Error",
};

const VehiclesDepotSummary = ({ store }) => {
  const rows = [
    {
      id: 1,
      statusIcon: ReadyToChargeIcon,
      type: ICON_TYPES.DISCHARGING,
      count: store.nonChargingVehicles.length,
    },
    {
      id: 2,
      statusIcon: ChargingVehicleIcon,
      type: ICON_TYPES.CHARGING,
      count: store.chargingVehicles.length,
    },
    {
      id: 3,
      statusIcon: FullyChargedIcon,
      type: ICON_TYPES.FULLY_CHARGED,
      count: store.fullyChargedVehicles.length,
    },
    {
      id: 4,
      statusIcon: WPTErrorIcon,
      type: ICON_TYPES.WPT_ERROR,
      count: store.vehiclesWithWPTError.length,
    },
  ];
  return (
    <div className="charging-vehicles-wrapper">
      <div className="charging-vehicles">
        <span className="charging-vehicles-title">Vehicles</span>
        <span className="charging-vehicles-amount">
          {Object.values(store.vuDevices).length}
        </span>
      </div>
      <div className="charging-depot-table vehicle-summary">
        <DataGrid
          getRowId={(rows) => rows.id}
          getRowHeight={() => "auto"}
          sx={{ border: 0 }}
          showColumnRightBorder={false}
          rows={rows}
          columns={columns}
          autoHeight
          headerHeight={1}
          hideFooter
        />
      </div>
    </div>
  );
};

export default VehiclesDepotSummary;

VehiclesDepotSummary.propTypes = {
  store: PropTypes.shape({
    vuDevices: PropTypes.object,
    nonChargingVehicles: PropTypes.array,
    chargingVehicles: PropTypes.array,
    fullyChargedVehicles: PropTypes.array,
    vehiclesWithWPTError: PropTypes.array,
  }).isRequired,
};
