import { useContext, useMemo } from "react";
import _ from "lodash";
import MainContext from "Contexts/MainContext";
import SegmentsContext from "Contexts/SegmentsContext";
import { segmentsMock } from "Utils/MockData/ProjectMockData";

const useSegments = (selectedDrawer, selectedSegmentIndex) => {
  const { selectedDevice } = useContext(MainContext);
  const { segmentsDispatch, ...segmentsState } = useContext(SegmentsContext);

  const { segments } = segmentsState;

  const socketMessageDataSegments =
    selectedDevice && segments?.[selectedDevice.id]?.["socketMessageData"];

  const removeShelfConfigurationFromList = (list) => {
    const result = list && _.cloneDeep(list);
    result &&
      result["shelf_configuration"] &&
      delete result["shelf_configuration"];
    return result;
  };
  const selectedDrawerSegments = useMemo(() => {
    return (
      (socketMessageDataSegments &&
        removeShelfConfigurationFromList(
          socketMessageDataSegments[`fpga_${selectedDrawer - 1}`]
        )) ||
      removeShelfConfigurationFromList(segmentsMock["fpga_0"])
    );
  }, [socketMessageDataSegments, selectedDrawer]);

  const selectedDrawerSegment = useMemo(() => {
    return (
      (selectedDrawerSegments &&
        selectedDrawerSegments[`segment_${selectedSegmentIndex}`]) ||
      0
    );
  }, [selectedDrawerSegments, selectedSegmentIndex]);

  return {
    segmentsState,
    segmentsDispatch,
    segments,
    socketMessageDataSegments,
    selectedDrawerSegments,
    selectedDrawerSegment,
  };
};
export default useSegments;
