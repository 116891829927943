import React from "react";
import PropTypes from "prop-types";
import ElectreonSmallLogo from "Utils/Images/GlobalIcons/electreon-logo.svg";
import "Components/Logo/logo.scss";

const Logo = ({
  logo = ElectreonSmallLogo,
  width,
  height,
  alt,
  onClickLogo,
  className,
}) => {
  return (
    <div className={`${className} logo-image-wrapper`} onClick={onClickLogo}>
      <img src={logo} height={height} width={width} alt={alt} />
    </div>
  );
};

export default Logo;

Logo.propTypes = {
  logo: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
  onClickLogo: PropTypes.func,
  className: PropTypes.string,
};
