import { lockDevice, unlockDevice } from "Utils/APIUtils";
import _ from "lodash";

export const setDeviceLockInterval = (
  deviceId,
  sessionId,
  locIntervalInstances,
  setLocIntervalInstances
) => {
  setLocIntervalInstances({
    ...locIntervalInstances,
    [deviceId]: {
      intervalId: setInterval(() => {
        lockDevice({
          deviceId: deviceId,
          sessionId: sessionId,
        }).then((res) => {
          if (res && res.status !== 200) {
            clearLockInterval(
              deviceId,
              locIntervalInstances,
              setLocIntervalInstances
            );
          }
        });
      }, 50000),
      lockedBySession: sessionId,
    },
  });
};

export const clearLockInterval = (
  deviceId,
  locIntervalInstances,
  setLocIntervalInstances
) => {
  if (locIntervalInstances && locIntervalInstances[deviceId]) {
    clearInterval(locIntervalInstances[deviceId]["intervalId"]);
    setLocIntervalInstances({
      ...locIntervalInstances,
      [deviceId]: {
        intervalId: "",
        lockedBySession: "",
      },
    });
  }
};

export const setDeviceLockTimeout = (
  deviceId,
  deviceLockExpiresAt,
  setLocTimeoutInstances,
  locTimeoutInstancesRef,
  lockInfoRef,
  socketDispatch
) => {
  if (deviceLockExpiresAt) {
    setLocTimeoutInstances({
      ...locTimeoutInstancesRef.current,
      [deviceId]: {
        timeoutId: setTimeout(() => {
          setLocTimeoutInstances({
            ...locTimeoutInstancesRef.current,
            [deviceId]: { timeoutId: undefined, isExpired: true },
          });
          let updatedLockInfo = _.cloneDeep(lockInfoRef.current);
          delete updatedLockInfo[deviceId];
          socketDispatch({
            type: "SET_LOCK_MESSAGE",
            payload: { lockInfo: updatedLockInfo },
          });
        }, deviceLockExpiresAt * 1000 - Date.now() + 3000),
        isExpired: false,
      },
    });
  } else {
    setLocTimeoutInstances({
      ...locTimeoutInstancesRef.current,
      [deviceId]: { timeoutId: undefined, isExpired: true },
    });
    let updatedLockInfo = _.cloneDeep(lockInfoRef.current);
    delete updatedLockInfo[deviceId];
    socketDispatch({
      type: "SET_LOCK_MESSAGE",
      payload: { lockInfo: updatedLockInfo },
    });
  }
};

export const handleLockOnSocketDisconnect = (
  oldSocketId,
  locIntervalInstancesRef,
  setLocIntervalInstances,
  lockInfo,
  intervalIds,
  mainDispatch,
  timeoutIds
) => {
  if (
    Object.keys(locIntervalInstancesRef.current) === 0 ||
    (intervalIds.length > 0 && timeoutIds.length > 0)
  ) {
    intervalIds.map((id) => clearInterval(id));
    timeoutIds.map((id) => clearTimeout(id));

    mainDispatch({
      type: "DELETE_INTERVAL_ID",
    });
    mainDispatch({
      type: "DELETE_TIMEOUT_ID",
    });

    if (Object.keys(lockInfo)?.length && Object.values(lockInfo)?.length) {
      for (const [key, value] of Object.entries(lockInfo)) {
        const isLockByMe = Object.values(value).find(
          (item) => item === oldSocketId
        );
        if (isLockByMe) {
          unlockDevice({ deviceId: key, sessionId: oldSocketId })
            .then((res) => {
              if (res && res.status === 200) {
                return lockDevice({ deviceId: key, sessionId: oldSocketId });
              }
            })
            .then((res) => {
              res &&
                res.status === 200 &&
                setDeviceLockInterval(
                  key,
                  oldSocketId,
                  locIntervalInstancesRef.current,
                  setLocIntervalInstances
                );
            });
        }
      }
    }
  }
};

export const handleLockOnAcquireToken = (
  deviceId,
  sessionId,
  intervalInstance,
  mainDispatch
) => {
  unlockDevice({ deviceId: deviceId, sessionId: sessionId }).then((res) => {
    if (res && res.status === 200) {
      return lockDevice({
        deviceId: deviceId,
        sessionId: sessionId,
      }).then((res) => {
        if (res && res.status === 200) {
          const locIntervalInstances = {
            ...intervalInstance,
            [deviceId]: {
              intervalId: setInterval(() => {
                return lockDevice({
                  deviceId: deviceId,
                  sessionId: sessionId,
                }).then((res) => {
                  if (res && res.status !== 200) {
                    mainDispatch({
                      type: "SET_INTERVAL_INSTANCE",
                      payload: {
                        intervalInstance: {
                          ...intervalInstance,
                          [deviceId]: {
                            intervalId: "",
                            lockedBySession: "",
                          },
                        },
                      },
                    });
                  }
                });
              }, 50000),
              lockedBySession: sessionId,
            },
          };
          mainDispatch({
            type: "SET_INTERVAL_INSTANCE",
            payload: { intervalInstance: locIntervalInstances },
          });
          mainDispatch({
            type: "IS_NEW_TOKEN_RECEIVED",
            payload: { isNewTokenReceived: true },
          });
        }
      });
    }
  });
};
