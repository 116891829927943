import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

//Components
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import SelectedSegmentSummary from "Screens/ProjectView/Devices/MU/SelectedSegment/SelectedSegmentSummary";
import SegmentControlPanel from "Screens/ProjectView/SetupTab/ControlPanel/SegmentControlPanel";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import StatusIndication from "Components/StatusIndication/StatusIndication";
import MultipleAreaGraph from "Components/Graphs/MultipleAreaGraph";
import OffIdleButtons from "Components/Buttons/OffIdleButtons/OffIdleButtons";

//Context
import MainContext from "Contexts/MainContext";
import useRole from "CustomHooks/useRole";
import SocketContext from "Contexts/SocketContext";

//Hooks
import useSegments from "CustomHooks/projectViewLogic/projectViewContextHooks/useSegments";
import useLockState from "CustomHooks/useLockState";

//Utils
import { GRAPH_SCALE } from "Utils/GraphUtils";
import { getSessionIdProject, setFpgaCommand } from "Utils/APIUtils";
import disableIcon from "Utils/Images/ProjectMaintanance/SD_disabled.svg";

const SetupSegmentDriverConfig = ({
  selectedSegmentIndex,
  selectedDrawer,
  locTimeoutInstances,
  setModalState,
  sendCommMessage,
  sendForceOnMessage,
  sendPWMMessage,
}) => {
  const [segmentForceState, setSegmentForceState] = useState("Force ON");

  const { userRole, selectedDevice } = useContext(MainContext);
  const { canDisableSegment } = useRole(userRole);
  const { socketMUMessageData } = useContext(SocketContext);

  const selectedDeviceId = selectedDevice?.id || "";

  const { selectedDrawerSegment } = useSegments(
    selectedDrawer,
    selectedSegmentIndex
  );
  const lockState = useLockState(selectedDevice, locTimeoutInstances);

  const selectedSegmentNotDetected = useMemo(() => {
    //todo add flag feature
    return false; //selectedDrawerSegment && selectedDrawerSegment.segmentStatus == 0;
  }, []);

  const selectedSegmentDisabled = useMemo(() => {
    return selectedDrawerSegment && selectedDrawerSegment.segmentStatus === 255;
  }, [selectedDrawerSegment]);

  const sessionId = getSessionIdProject();

  return (
    <div className="project-device-setup-selected-segment setup-section">
      <ProjectDeviceHeader
        title={`Segment Driver - ${
          selectedSegmentIndex + 1 + 12 * (selectedDrawer - 1)
        }`}
        infoIconDataFor="segment-driver-tip"
        data={
          <div className="project-device-setup-selected-segment-buttons-SD-top">
            {!selectedSegmentNotDetected && (
              <IndicationButton
                title="Disable Segment"
                isDisabled={
                  lockState.setupDisabled ||
                  selectedSegmentNotDetected ||
                  !canDisableSegment
                }
                onClick={() => {
                  if (sessionId) {
                    setFpgaCommand({
                      MUId: selectedDeviceId,
                      shelfNumber: selectedDrawer - 1,
                      segmentNumber: selectedSegmentIndex,
                      cmdType: "set_disable",
                      val: selectedSegmentDisabled ? 0 : 1,
                      sessionId: sessionId,
                    }).then((res) => {
                      // TODO: what to do on then?
                      if (res && res.status !== 200) {
                      }
                    });
                  }
                }}
                text="Disable"
                width={60}
                height={36}
                className="segment-disable-button"
                isPressed={selectedSegmentDisabled}
                buttonIconUrl={disableIcon}
              />
            )}
            <ReactTooltip
              id="segment-driver-tip"
              className="segment-driver-tip"
              getContent={() => (
                <div className="segment-driver-version">
                  Version:{" "}
                  {selectedDrawerSegment
                    ? selectedDrawerSegment.segmentDriverVersion || 0
                    : ""}
                </div>
              )}
              effect="solid"
              delayHide={0}
              delayShow={0}
              delayUpdate={0}
              place="right"
              border
              type="light"
            />
            <StatusIndication selectedDrawerSegment={selectedDrawerSegment} />
            {!selectedSegmentNotDetected && (
              <div className="drawer-force-indication-buttons">
                <OffIdleButtons
                  offButtonTitle="Force Off"
                  isDisabled={
                    lockState.setupDisabled ||
                    selectedSegmentDisabled ||
                    selectedSegmentNotDetected
                  }
                  offButtonOnClick={() => {
                    if (sessionId) {
                      setFpgaCommand({
                        MUId: selectedDeviceId,
                        shelfNumber: selectedDrawer - 1,
                        segmentNumber: selectedSegmentIndex,
                        cmdType: "set_force_off",
                        val: 1,
                        sessionId: sessionId,
                      }).then((res) => {
                        // TODO: what to do on then?
                        if (res && res.status !== 200) {
                        }
                      });
                    }
                  }}
                  idleButtonTitle="Idle"
                  idleButtonOnClick={() => {
                    if (sessionId) {
                      setFpgaCommand({
                        MUId: selectedDeviceId,
                        shelfNumber: selectedDrawer - 1,
                        segmentNumber: selectedSegmentIndex,
                        cmdType: "set_clear_segment_status",
                        val: 1,
                        sessionId: sessionId,
                      }).then((res) => {
                        // TODO: what to do on then?
                        if (res && res.status !== 200) {
                        }
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>
        }
      />
      {selectedDrawerSegment && !selectedSegmentNotDetected && (
        <SelectedSegmentSummary selectedSegment={selectedDrawerSegment} />
      )}
      {!selectedSegmentNotDetected && (
        <div className="setup-device-graph">
          <MultipleAreaGraph
            graphType="power"
            graphData={socketMUMessageData}
            selectedDevice={selectedDevice}
            graphScale={GRAPH_SCALE[5]}
            isHistoryOn={false}
          />
        </div>
      )}
      {!selectedSegmentNotDetected && (
        <SegmentControlPanel
          selectedDrawer={selectedDrawer}
          segmentForceState={segmentForceState}
          selectedDrawerSegment={selectedDrawerSegment}
          selectedSegmentIndex={selectedSegmentIndex}
          sendCommMessage={sendCommMessage}
          sendForceOnMessage={sendForceOnMessage}
          sendPWMMessage={sendPWMMessage}
          setModalState={setModalState}
          setSegmentForceState={setSegmentForceState}
          isDisabled={lockState.setupDisabled || selectedSegmentDisabled}
          sessionId={sessionId}
        />
      )}
    </div>
  );
};

export default SetupSegmentDriverConfig;

SetupSegmentDriverConfig.propTypes = {
  selectedSegmentIndex: PropTypes.number,
  selectedDrawer: PropTypes.number,
  locTimeoutInstances: PropTypes.object,
  setModalState: PropTypes.func,
  sendCommMessage: PropTypes.func,
  sendForceOnMessage: PropTypes.func,
  sendPWMMessage: PropTypes.func,
};
