import React from "react";
import PropTypes from "prop-types";
import "Components/ActivityIndicator/ActivityIndicator.scss";

const ActivityIndicator = ({
  label,
  color,
  height = 20,
  width = 20,
  padding = "10px",
}) => (
  <div className="activity-indicator-wrapper" style={{ padding }}>
    <div
      className="activity-indicator"
      style={{ backgroundColor: color || "#808080", height, width }}
    />
    {label && <label>{label}</label>}
  </div>
);

export default ActivityIndicator;

ActivityIndicator.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
