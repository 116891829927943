import React, { useContext, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { isNewerOrEqualVersion } from "Utils/UtilsFunctions";
import {
  fpgaOverCurrentVersion,
  overCurrentMaskFormulaConst,
  overCurrentStaticMaskFormulaConst,
  overCurrentThresholdFormulaConst,
} from "Utils/DefaultValues/ProjectViewDefaultValues";
import NumericInput from "Components/NumericInput/NumerInput";
import "Screens/ProjectView/SetupTab/ControlPanel/ProjectControlPanel.scss";
import AppButton from "Components/Buttons/AppButton/AppButton";
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import { setShelfConfigurationCommand } from "Utils/APIUtils";
import MainContext from "Contexts/MainContext";
import useRole from "CustomHooks/useRole";
import useProjectControlPanel from "Screens/ProjectView/SetupTab/ControlPanel/useProjectControlPanel";
import collapseIcon from "Utils/Images/GlobalIcons/collapse_icon.svg";
import { isCollapseFeatureEnabled } from "Utils/featureFlags";
import CollapsibleButton from "Components/Buttons/CollasibleButton/CollapsibleButton";
import "Screens/ProjectView/SetupTab/ControlPanel/ProjectControlPanel.scss";

const ProjectControlPanel = ({
  isMUsPanel,
  MUId,
  selectedDrawer,
  selectedDrawerConfiguration,
  lockState = { setupDisabled: true },
}) => {
  const { userRole } = useContext(MainContext);
  const { canChangeConfig } = useRole(userRole);
  const { setupDisabled } = lockState;

  const isFpgaOverCurrentVersion =
    selectedDrawerConfiguration &&
    selectedDrawerConfiguration["firmwareVersion"]
      ? isNewerOrEqualVersion(
          fpgaOverCurrentVersion,
          String(selectedDrawerConfiguration["firmwareVersion"])
        )
      : false;

  const isDefaultShelfConfiguration =
    selectedDrawerConfiguration &&
    String(selectedDrawerConfiguration["firmwareVersion"]) === "0";

  const {
    isUpdating,
    setIsUpdating,
    controlPanelDataMUsPresent,
    setControlPanelDataMUsPresent,
    controlPanelDataMUs,
  } = useProjectControlPanel(
    selectedDrawerConfiguration,
    isFpgaOverCurrentVersion
  );

  useEffect(() => {
    if (controlPanelDataMUs) {
      setControlPanelDataMUsPresent(controlPanelDataMUs);
      setIsUpdating([]);
    }
  }, [controlPanelDataMUs, setupDisabled]);

  const applyShelfConfig = () => {
    [1, 2, 3, 4, 5].forEach((shelf) => {
      setShelfConfigurationCommand({
        MUId: MUId,
        band0: Math.round(
          Number(controlPanelDataMUsPresent["band_0"]["value"]) /
            (isFpgaOverCurrentVersion ? overCurrentThresholdFormulaConst : 1)
        ),
        band1: Math.round(
          Number(controlPanelDataMUsPresent["band_1"]["value"]) /
            (isFpgaOverCurrentVersion ? overCurrentMaskFormulaConst : 1)
        ),
        band2: Math.round(
          Number(controlPanelDataMUsPresent["band_2"]["value"])
        ),
        band3: Math.round(
          Number(controlPanelDataMUsPresent["band_3"]["value"]) /
            (isFpgaOverCurrentVersion ? overCurrentStaticMaskFormulaConst : 1)
        ),
        band4: Math.round(
          Number(controlPanelDataMUsPresent["band_4"]["value"])
        ),
        band5: Math.round(
          Number(controlPanelDataMUsPresent["band_5"]["value"])
        ),
        currentOff: Math.round(
          Number(controlPanelDataMUsPresent["current_off"]["value"])
        ),
        pwmOn: Math.round(
          Number(controlPanelDataMUsPresent["pwn_on_time"]["value"])
        ),
        pwmOffD: Math.round(
          Number(controlPanelDataMUsPresent["pwn_off_time_dynamic"]["value"])
        ),
        pwmOffS: Math.round(
          Number(controlPanelDataMUsPresent["pwn_off_time_static"]["value"])
        ),
        pwmMax: Math.round(
          Number(controlPanelDataMUsPresent["pwm_max"]["value"])
        ),
        shelf_number: shelf - 1,
      }).then((res) => {
        setIsUpdating([]);
        if (res && res.status !== 200) {
          console.log(
            "panelData in res controlPanelProject: ",
            controlPanelDataMUs
          );
          setControlPanelDataMUsPresent(controlPanelDataMUs);
        }
      });
    });
  };

  return useMemo(() => {
    return isDefaultShelfConfiguration ? (
      ""
    ) : (
      <>
        {isCollapseFeatureEnabled && <CollapsibleButton icon={collapseIcon} />}
        <div
          className={`project-control-panel-wrapper ${
            isCollapseFeatureEnabled ? "collapsible-content" : ""
          }`}
        >
          <ProjectDeviceHeader
            title="Advanced Parameters"
            className="project-device-header-i"
            infoIconDataFor=""
          />
          <div className="mu-control-panel-wrapper">
            {!!isMUsPanel &&
              controlPanelDataMUsPresent &&
              Object.values(controlPanelDataMUsPresent).map(
                (itemData, itemIndex) => {
                  const {
                    label,
                    minValue,
                    maxValue,
                    step,
                    defaultValue,
                    value,
                    name,
                    units,
                  } = itemData;
                  return (
                    <div className="numeric-input-row" key={itemIndex}>
                      <NumericInput
                        inputClassName={
                          isUpdating && isUpdating.indexOf(itemIndex) > -1
                            ? "is-updating"
                            : ""
                        }
                        key={itemIndex}
                        label={label}
                        min={minValue}
                        max={maxValue}
                        name={name}
                        step={step}
                        value={Number(Number(value).toFixed(0))}
                        unit={itemData.units}
                        isDisabled={setupDisabled || !canChangeConfig}
                        onChange={(e) => {
                          if (e && e.target) {
                            setControlPanelDataMUsPresent({
                              ...controlPanelDataMUsPresent,
                              [name]: {
                                ...controlPanelDataMUsPresent[name],
                                value: e.target.value,
                              },
                            });
                          }
                        }}
                        onFocus={() =>
                          isUpdating &&
                          setIsUpdating([...isUpdating, itemIndex])
                        }
                        onBlur={() => {
                          const oldValue =
                            selectedDrawerConfiguration &&
                            Number(selectedDrawerConfiguration[name]);
                          const newValue = Number(value);
                          const unUpdatedValueIsZero =
                            oldValue === Number(value) && Number(value) === 0;
                          if (
                            unUpdatedValueIsZero ||
                            (newValue &&
                              oldValue &&
                              newValue.toFixed(0) === oldValue.toFixed(0))
                          ) {
                            isUpdating &&
                              setIsUpdating(
                                isUpdating.filter((e) => e !== itemIndex)
                              );
                          }
                        }}
                      />
                    </div>
                  );
                }
              )}
            <div className="numeric-input-row">
              <AppButton
                isDisabled={setupDisabled || !canChangeConfig}
                className="advanced-resonance-apply"
                text="Apply"
                onClick={applyShelfConfig}
              />
            </div>
          </div>
        </div>
      </>
    );
  }, [
    isMUsPanel,
    controlPanelDataMUsPresent,
    MUId,
    setupDisabled,
    canChangeConfig,
    selectedDrawer,
    isUpdating,
    selectedDrawerConfiguration,
    controlPanelDataMUs,
  ]);
};

export default ProjectControlPanel;

ProjectControlPanel.propTypes = {
  isMUsPanel: PropTypes.bool,
  MUId: PropTypes.string,
  selectedDrawer: PropTypes.number,
  selectedDrawerConfiguration: PropTypes.object,
  lockState: PropTypes.object,
};
