import { useMemo } from "react";
import useAnimationStyles from "./useAnimationStyles";
import { Box } from "@mui/material";

const DIRECTIONS = {
  LEFT: "left",
  RIGHT: "right",
};

const onTheRight = {
  transform: "translateX(400%)",
  opacity: 0,
};

const neutral = {
  transform: "translateX(0)",
  opacity: 1,
};

/**
 * HOC to wrap a component with sliding animation
 * @param {{ direction: "left" | "right", active: boolean, children: React.ReactNode }} props
 *
 */
export const WithSlidingAnimation = ({ active, direction, children }) => {
  const slideIn = useAnimationStyles(onTheRight, neutral, true, [0.7, 1.2]);
  const slideOut = useAnimationStyles(neutral, onTheRight, true, [1.2, 0.7]);

  const animationStyles = useMemo(() => {
    if (!active) return null;
    if (direction === DIRECTIONS.RIGHT) {
      return slideOut;
    } else if (direction === DIRECTIONS.LEFT) {
      return slideIn;
    } else {
      return null;
    }
  }, [active, direction, slideIn, slideOut]);

  return (
    <Box style={animationStyles} width="100%">
      {children}
    </Box>
  );
};
