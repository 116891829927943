import React, { useContext } from "react";
import PropTypes from "prop-types";

//Components
import AppButton from "Components/Buttons/AppButton/AppButton";
import ProgressLine from "Components/ProgressComponents/ProgressLine";

//Context
import MainContext from "Contexts/MainContext";
import useRole from "CustomHooks/useRole";
import MUsContext from "Contexts/MUsContext";
import SocketContext from "Contexts/SocketContext";

//Utils
import { getSessionIdProject, setFpgaCommand } from "Utils/APIUtils";
import "Screens/ProjectView/SetupTab/RackSection/RackConfigControl.scss";

const RackConfigControl = ({
  selectedDrawer,
  lockState = { setupDisabled: true },
}) => {
  const { userRole, selectedDevice } = useContext(MainContext);
  const { discretes } = useContext(SocketContext);
  const { fpgaIsInProgress, fpgaProgress, MUsDispatch } =
    useContext(MUsContext);

  const { canChangeConfig } = useRole(userRole);
  const sessionId = getSessionIdProject();

  const deviceId = selectedDevice?.id;
  const { contactor_control } = discretes[deviceId]?.socketMessageData || {};

  return (
    <div className="rack-config-buttons-wrapper">
      <div className="config-buttons">
        <div
          className="appButton-progress"
          title={fpgaProgress[deviceId] ? fpgaProgress[deviceId] + "%" : ""} // with this change we don't see progress when it 0%
        >
          <AppButton
            text="Calibrate 12 SD"
            isDisabled={
              lockState.setupDisabled ||
              contactor_control ||
              Object.keys(fpgaProgress).length ||
              !canChangeConfig
            }
            onClick={() => {
              if (sessionId && !contactor_control && canChangeConfig) {
                setFpgaCommand({
                  MUId: deviceId,
                  shelfNumber: selectedDrawer - 1,
                  segmentNumber: 0,
                  cmdType: "set_shelf_calibrate",
                  val: 1,
                  sessionId: sessionId,
                }).then((res) => {
                  if (res && res.status === 200) {
                    MUsDispatch({
                      type: "FPGA_PROGRESS",
                      payload: {
                        fpgaProgress: 0,
                        fpgaProgressDone: false,
                        fpgaIsInProgress: true,
                        fpgaDeviceId: deviceId,
                      },
                    });
                  }
                });
              }
            }}
          />
          <ProgressLine
            progress={fpgaProgress[deviceId]}
            isInProgress={fpgaIsInProgress}
          />
        </div>

        <AppButton
          text="Save Config"
          isDisabled={lockState.setupDisabled || !canChangeConfig}
          onClick={() => {
            if (sessionId) {
              setFpgaCommand({
                MUId: deviceId,
                shelfNumber: selectedDrawer - 1,
                segmentNumber: 0,
                cmdType: "set_save_freq_state",
                val: 1,
                sessionId: sessionId,
              }).then((res) => {
                // TODO: what to do on then?
                if (res && res.status !== 200) {
                }
              });
            }
          }}
        />
        <AppButton
          text="Load Config"
          isDisabled={
            lockState.setupDisabled || contactor_control || !canChangeConfig
          }
          onClick={() => {
            if (sessionId) {
              setFpgaCommand({
                MUId: deviceId,
                shelfNumber: selectedDrawer - 1,
                segmentNumber: 0,
                cmdType: "set_load_freq_state",
                val: 1,
                sessionId: sessionId,
              }).then((res) => {
                // TODO: what to do on then?
                if (res && res.status !== 200) {
                }
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default RackConfigControl;

RackConfigControl.propTypes = {
  selectedDrawer: PropTypes.number,
  lockState: PropTypes.object,
};
