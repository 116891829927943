import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

//Components
import CardDetails from "Components/ProjectCard/CardDetails/CardDetails";
import ThreeDotsMenu from "Components/ThreeDotsMenu/ThreeDotsMenu";
import ProjectDashboardButton from "Components/Buttons/ProjectDashboardButton/ProjectDashboardButton";
import AnalyticsToolButton from "Components/Buttons/AnalyticsToolButton/AnalyticsToolButton";
import Logo from "Components/Logo/Logo";

//Context
import MainContext from "Contexts/MainContext";

//Utils
import { getProjectLogo } from "Utils/ProjectUtils";
import {
  defaultManagementUnits,
  defaultChargingTypes,
  defaultDeploymentTypes,
  defaultVehicleUnits,
  defaultVehicleTypes,
} from "Utils/DefaultValues/ProjectCardDefaultValues";
import ElectreonSmallLogo from "Utils/Images/GlobalIcons/electreon-logo-small.svg";

import "Components/ProjectCard/ProjectCard.scss";
import { GinafDashboardButton } from "Components/Buttons/GinafDashboardButton/GinafDashboardButton";

const ProjectCard = ({ project }) => {
  const [projectLogo, setProjectLogo] = useState();

  const { mainDispatch } = useContext(MainContext);

  const { id, name, managementUnits, vehicleUnits } = project;

  const { deploymentTypes, chargingTypes, total, offline } =
    managementUnits || defaultManagementUnits;

  const { STATIC, DYNAMIC } = chargingTypes || defaultChargingTypes;

  const { UNDERGROUND, SURFACE, LAB } =
    deploymentTypes || defaultDeploymentTypes;

  const { vehicleTypes, totalVU, OfflineVU } = vehicleUnits
    ? {
        vehicleTypes: vehicleUnits.vehicleTypes,
        totalVU: vehicleUnits.total,
        OfflineVU: vehicleUnits.offline,
      }
    : defaultVehicleUnits;

  const { BUS, TRUCK, PRIVATE } = vehicleTypes || defaultVehicleTypes;

  const totalMU = total;

  const handleProjectClick = useCallback(() => {
    return mainDispatch({
      type: "SET_SELECTED_DEVICE",
      payload: {
        selectedDevice: undefined,
      },
    });
  }, []);

  useEffect(() => {
    const getLogo = async () => {
      const logo = await getProjectLogo(id);
      setProjectLogo(logo?.small || ElectreonSmallLogo);
    };
    getLogo();
  }, [id]);

  return (
    <div className="project-card-wrapper">
      <div className="project-card-buttons-wrapper">
        <div className="menuIcon">
          <ThreeDotsMenu id={id} />
        </div>
        <AnalyticsToolButton project={project} />
        <ProjectDashboardButton
          project={project}
          isDisabled={
            id !== 118 &&
            id !== 87 &&
            id !== 2 &&
            id !== 119 &&
            id !== 111 &&
            id !== 128
          }
        />
        {id === 2 && <GinafDashboardButton project={project} />}
      </div>
      <Link
        to={{
          pathname: `/dashboard/live/project/${id}/inline`,
        }}
        onClick={handleProjectClick}
        style={{ textDecoration: "none" }}
      >
        <div className="project-card shadow">
          <div className="upper-row">
            <Logo logo={projectLogo} alt="project logo" />
            <div className="project-name">
              <span>{name}</span>
            </div>
          </div>
          <div className="main-row">
            {total !== 0 && (
              <CardDetails
                cardType="MU"
                offlineNumber={offline}
                totalNumber={total}
                withBorder={OfflineVU !== 0}
                iconsMap={{
                  underground: UNDERGROUND,
                  surface: SURFACE,
                  lab: LAB,
                  static: STATIC,
                  dynamic: DYNAMIC,
                }}
              />
            )}
            {!!totalVU && !!totalMU && (
              <div className="mus-vus-middle-border" />
            )}
            {totalVU !== 0 && (
              <CardDetails
                cardType="VU"
                offlineNumber={OfflineVU}
                totalNumber={totalVU}
                iconsMap={{ truck: TRUCK, bus: BUS, car: PRIVATE }}
              />
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectCard;

ProjectCard.propTypes = {
  project: PropTypes.object,
};
