import { useEffect, useRef } from "react";
import { getProject } from "Utils/APIUtils";
import {
  projectDashboardSocket,
  closeSocket,
} from "Utils/socketApi";
import { actions } from "Screens/ProjectDashboard/lib/context/actionTypes";
import { useDashboardContext } from "Screens/ProjectDashboard/lib/context/dashboardContext";

window.socketMessages = [];
window.saveMessages = false;

window.setSocketMessages = (shouldSaveMEssages = true) => {
  window.saveMessages = shouldSaveMEssages;
  return "starting to record messages";
};

window.getSocketMessages = () => {
  return JSON.stringify(window.socketMessages);
};

const messageTypes = [
  {
    message: "dcSamplerMessage",
    actionType: actions.DC_SAMPLER_MESSAGE,
  },
  {
    message: "devicesStatesMessage",
    actionType: actions.DEVICES_STATES_MESSAGE,
  },
  {
    message: "batteryDataMessage",
    actionType: actions.BATTERY_DATA_MESSAGE,
  },
  {
    message: "chargingMessage",
    actionType: actions.CHARGING_MESSAGE,
  },
  {
    message: "gpsMessage",
    actionType: actions.GPS_MESSAGE,
  },
  {
    message: "fpgaMessage",
    actionType: actions.FPGA_MESSAGE,
  },
  {
    message: "connectionStateChanges",
    actionType: actions.CONNECTION_STATE_CHANGE,
  },
];

export const useSocketDataLoader = (projectId) => {
  const accessToken = useRef(localStorage.getItem("accessToken"));
  const socket = useRef(null);
  const { dispatch } = useDashboardContext();

  useEffect(() => {
    const initSocketConnection = async () => {
      const project = (await getProject(projectId)).data;
      socket.current = projectDashboardSocket(project, accessToken, projectId);

      messageTypes.forEach(({ message, actionType }) => {
        socket.current.on(message, (messageData) => {
          if (window.saveMessages) {
            window.socketMessages.push({
              message,
              timestamp: Date.now(),
              type: actionType,
              payload: messageData,
            });
          }
          dispatch({ type: actionType, payload: messageData });
        });
      });
    };
    initSocketConnection();

    return () => {
      if (socket.current) {
        console.log("closing socket");
        closeSocket(socket.current);
      }
    };
  }, []);
};
