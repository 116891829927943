export const segmentsMock = {
  fpga_0: {
    segment_0: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_1: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_2: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_3: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_4: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_5: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_6: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_7: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_8: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_9: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_10: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
    segment_11: {
      commDetected: 0,
      commDuration: null,
      forceComm: 0,
      forceOff: 0,
      forceOn: 0,
      forcePwm: 0,
      segmentCommDetect: 0,
      segmentCurrentInRms: 0,
      segmentDriverVersion: 0,
      segmentDutyCycle: 0,
      segmentHbridgeATemp: 0,
      segmentHbridgeBTemp: 0,
      segmentIndFrequency: 0,
      segmentIzeroCurrent: 0,
      segmentPhase: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
      segmentResonantFrequency: 0,
      segmentStatus: 0,
      segmentWorkingFrequency: 0,
      timestamp: 0,
    },
  },
  fpga_1: {
    segment_0: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_1: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 16.35347561639,
      segmentHbridgeBTemp: 16.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_2: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_3: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_4: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_5: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_6: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_7: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_8: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_9: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_10: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_11: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
  },
  fpga_2: {
    segment_0: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_1: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 16.35347561639,
      segmentHbridgeBTemp: 16.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_2: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_3: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_4: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_5: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_6: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_7: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_8: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_9: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_10: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_11: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
  },
  fpga_3: {
    segment_0: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_1: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 16.35347561639,
      segmentHbridgeBTemp: 16.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_2: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_3: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_4: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_5: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_6: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_7: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_8: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_9: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_10: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_11: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
  },
  fpga_4: {
    segment_0: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_1: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 16.35347561639,
      segmentHbridgeBTemp: 16.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_2: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_3: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_4: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_5: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_6: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_7: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_8: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_9: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_10: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
    segment_11: {
      segmentStatus: 0,
      commHighDuration: 0,
      commLowDuration: 0,
      segmentCommDetect: 1950,
      segmentDutyCycle: 0,
      segmentFrequency: 0.00009017132551848512,
      segmentHbridgeATemp: 15.35347561639,
      segmentHbridgeBTemp: 15.35347561639,
      segmentIzeroCurrent: 0,
      segmentPhaseACurrent: 0,
      segmentPhaseBCurrent: 0,
    },
  },
};

export const fansMock = {
  socketMessageData: {
    fans_drawer_1: {
      fan_0_1: 0,
      fan_1_0: 0,
      fan_1_1: 0,
      fan_2_0: 0,
      fan_2_1: 0,
      fan_3_0: 0,
      fan_3_1: 0,
      fan_4_0: 0,
      fan_4_1: 0,
      fan_5_0: 0,
      fan_5_1: 0,
      fan_6_0: 0,
      fan_6_1: 0,
      fanFoceOn_1: 0,
      fanFoceOn_2: 0,
      fanFoceOn_3: 0,
      fanFoceOn_4: 0,
      fanFoceOn_5: 0,
      fanFoceOn_6: 0,
    },
    fans_drawer_2: {
      fan_0_1: 0,
      fan_1_0: 0,
      fan_1_1: 0,
      fan_2_0: 0,
      fan_2_1: 0,
      fan_3_0: 0,
      fan_3_1: 0,
      fan_4_0: 0,
      fan_4_1: 0,
      fan_5_0: 0,
      fan_5_1: 0,
      fan_6_0: 0,
      fan_6_1: 0,
      fanFoceOn_1: 0,
      fanFoceOn_2: 0,
      fanFoceOn_3: 0,
      fanFoceOn_4: 0,
      fanFoceOn_5: 0,
      fanFoceOn_6: 0,
    },
    fans_drawer_3: {
      fan_0_1: 0,
      fan_1_0: 0,
      fan_1_1: 0,
      fan_2_0: 0,
      fan_2_1: 0,
      fan_3_0: 0,
      fan_3_1: 0,
      fan_4_0: 0,
      fan_4_1: 0,
      fan_5_0: 0,
      fan_5_1: 0,
      fan_6_0: 0,
      fan_6_1: 0,
      fanFoceOn_1: 0,
      fanFoceOn_2: 0,
      fanFoceOn_3: 0,
      fanFoceOn_4: 0,
      fanFoceOn_5: 0,
      fanFoceOn_6: 0,
    },
    fans_drawer_4: {
      fan_0_1: 0,
      fan_1_0: 0,
      fan_1_1: 0,
      fan_2_0: 0,
      fan_2_1: 0,
      fan_3_0: 0,
      fan_3_1: 0,
      fan_4_0: 0,
      fan_4_1: 0,
      fan_5_0: 0,
      fan_5_1: 0,
      fan_6_0: 0,
      fan_6_1: 0,
      fanFoceOn_1: 0,
      fanFoceOn_2: 0,
      fanFoceOn_3: 0,
      fanFoceOn_4: 0,
      fanFoceOn_5: 0,
      fanFoceOn_6: 0,
    },
    fans_drawer_5: {
      fan_0_1: 0,
      fan_1_0: 0,
      fan_1_1: 0,
      fan_2_0: 0,
      fan_2_1: 0,
      fan_3_0: 0,
      fan_3_1: 0,
      fan_4_0: 0,
      fan_4_1: 0,
      fan_5_0: 0,
      fan_5_1: 0,
      fan_6_0: 0,
      fan_6_1: 0,
      fanFoceOn_1: 0,
      fanFoceOn_2: 0,
      fanFoceOn_3: 0,
      fanFoceOn_4: 0,
      fanFoceOn_5: 0,
      fanFoceOn_6: 0,
    },
  },
  MUId: "UG0000100",
};

export const mockDataProjects = {
  projects: [
    {
      id: 1,
      name: "Tel Aviv University",
      country_code: "",
      country_name: "tel_aviv",
      creation_time: 1610818655801,
      last_change: 1610818615801,
      timezone_str: "asia/jerusalem",
      managementUnits: {
        totalMU: 11,
        OfflineMU: 2,
        chargingTypes: {
          Static: 5,
          Dynamic: 6,
        },
        deploymentTypes: {
          Underground: 8,
          Surface: 2,
          Lab: 1,
        },
      },
      vehicleUnits: {
        totalVU: 3,
        OfflineVU: 1,
        vehicleTypes: {
          Bus: 6,
          Truk: 2,
          Private: 1,
        },
      },
      projectImage: "",
    },
    {
      id: 2,
      name: "Gotland",
      country_code: "",
      country_name: "gotland",
      creation_time: 1610818655801,
      last_change: 1610818615801,
      timezone_str: "asia/jerusalem",
      managementUnits: {
        totalMU: 13,
        OfflineMU: 2,
        chargingTypes: {
          Static: 5,
          Dynamic: 6,
        },
        deploymentTypes: {
          Underground: 8,
          Surface: 2,
          Lab: 3,
        },
      },
      vehicleUnits: {
        totalVU: 4,
        OfflineVU: 1,
        vehicleTypes: {
          Bus: 1,
          Truk: 2,
          Private: 1,
        },
      },
      projectImage: "",
    },
    {
      id: 3,
      name: "Tel Aviv University",
      country_code: "",
      country_name: "tel_aviv",
      creation_time: 1610818655801,
      last_change: 1610818615801,
      timezone_str: "asia/jerusalem",
      managementUnits: {
        totalMU: 5,
        OfflineMU: 2,
        chargingTypes: {
          Static: 5,
          Dynamic: 6,
        },
        deploymentTypes: {
          Underground: 2,
          Surface: 2,
          Lab: 1,
        },
      },
      vehicleUnits: {
        totalVU: 2,
        OfflineVU: 1,
        vehicleTypes: {
          Bus: 1,
          Truk: 1,
          Private: 0,
        },
      },
      projectImage: "",
    },
    {
      id: 4,
      name: "Tel Aviv University",
      country_code: "",
      country_name: "tel_aviv",
      creation_time: 1610818655801,
      last_change: 1610818615801,
      timezone_str: "asia/jerusalem",
      managementUnits: {
        totalMU: 11,
        OfflineMU: 2,
        chargingTypes: {
          Static: 5,
          Dynamic: 6,
        },
        deploymentTypes: {
          Underground: 8,
          Surface: 2,
          Lab: 1,
        },
      },
      vehicleUnits: {
        totalVU: 9,
        OfflineVU: 1,
        vehicleTypes: {
          Bus: 6,
          Truk: 2,
          Private: 1,
        },
      },
      projectImage: "",
    },
    {
      id: 5,
      name: "Tel Aviv University",
      country_code: "",
      country_name: "tel_aviv",
      creation_time: 1610818655801,
      last_change: 1610818615801,
      timezone_str: "asia/jerusalem",
      managementUnits: {
        totalMU: 11,
        OfflineMU: 2,
        chargingTypes: {
          Static: 5,
          Dynamic: 6,
        },
        deploymentTypes: {
          Underground: 8,
          Surface: 2,
          Lab: 1,
        },
      },
      vehicleUnits: {
        totalVU: 4,
        OfflineVU: 1,
        vehicleTypes: {
          Bus: 1,
          Truk: 2,
          Private: 1,
        },
      },
      projectImage: "",
    },
  ],
};

export const mockDataVUs = {
  Vehicles: [
    {
      id: 1,
      status: "active_charging", //"active_charging" etc
      line: 10,
      number: 566,
      model: "",
      charging_percent: "10",
      project_id: 1,
      project_name: "tel_aviv",
      unit_type: "bus",
    },
    {
      id: 2,
      status: "active_charging", //"active_charging" etc
      line: 10,
      number: 566,
      model: "",
      charging_percent: "50",
      project_id: 1,
      project_name: "tel_aviv",
      unit_type: "bus",
    },
    {
      id: 3,
      status: "active_charging", //"active_charging" etc
      line: 10,
      number: 566,
      model: "",
      charging_percent: "20",
      project_id: 5,
      project_name: "tel_aviv",
      unit_type: "bus",
    },
    {
      id: 4,
      status: "active_charging", //"active_charging" etc
      line: 10,
      number: 566,
      model: "",
      charging_percent: "80",
      project_id: 5,
      project_name: "tel_aviv",
      unit_type: "bus",
    },
    {
      id: 5,
      status: "active_charging", //"active_charging" etc
      line: 10,
      number: 566,
      model: "",
      charging_percent: "90",
      project_id: 5,
      project_name: "tel_aviv",
      unit_type: "bus",
    },
  ],
};

export const mockDataMUs = {
  is_next: true, //For pagination - is next page exist
  mus_num: 200,
  mu_list: [
    {
      id: 1,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818605801,
      deployment_type: "above",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 0,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 100,
      currency: 20,
    },
    {
      id: 2,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "disconnected",
      on_off: false,
      last_change: 1610818645801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 600,
      currency: 100,
    },
    {
      id: 3,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818355801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818635801,
      deployment_type: "underground",
      charging_type: "dynamic",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 300,
      currency: 150,
    },
    {
      id: 4,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: false,
      last_change: 1610818625801,
      deployment_type: "lab",
      charging_type: "dynamic",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 0,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 350,
      currency: 120,
    },
    {
      id: 5,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "gotland",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818615801,
      deployment_type: "above",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 110,
      currency: 20,
    },
    {
      id: 6,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "gotland",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818695801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 40,
      currency: 20,
    },
    {
      id: 7,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "gotland",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818555801,
      deployment_type: "above",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 80,
      currency: 40,
    },
    {
      id: 8,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "gotland",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818255801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 110,
      currency: 10,
    },
    {
      id: 9,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "gotland",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610814655801,
      deployment_type: "underground",
      charging_type: "dynamic",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contractor_status: 1,
      segments_num: 6,
      voltage: 111,
      currency: 90,
    },
    {
      id: 10,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "gotland",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610813655801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 70,
      currency: 20,
    },
  ],
};

export const mockDataSegments = {
  segments: [
    {
      segment_id: 1,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 30,
      temperature2: 200,
      activity_status: 0,
      connection_status: 2,
      energy: 100,
      efficiency: 20,
      currency: 10,
      voltage: 200,
      power: 20,
      location: [30, 50],
      power_duty_cycle: 60,
      comm_RSSI: 50,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 2,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 50,
      temperature2: 100,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 40,
      comm_RSSI: 66,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 3,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 100,
      temperature2: 200,
      activity_status: 0,
      connection_status: 4,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 55,
      comm_RSSI: 40,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 4,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 150,
      temperature2: 180,
      activity_status: 0,
      connection_status: 3,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 79,
      comm_RSSI: 3,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 5,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 30,
      temperature2: 180,
      activity_status: 0,
      connection_status: 3,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 71,
      comm_RSSI: 80,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 6,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 30,
      temperature2: 190,
      activity_status: 0,
      connection_status: 2,
      energy: 100,
      efficiency: 20,
      currency: 10,
      voltage: 200,
      power: 20,
      location: [30, 50],
      power_duty_cycle: 81,
      comm_RSSI: 66,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 7,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 20,
      temperature2: 200,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 20,
      comm_RSSI: 65,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 8,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 10,
      temperature2: 190,
      activity_status: 0,
      connection_status: 4,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 15,
      comm_RSSI: 70,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 9,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 10,
      temperature2: 70,
      activity_status: 0,
      connection_status: 3,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 90,
      comm_RSSI: 26,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 10,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 15,
      temperature2: 140,
      activity_status: 0,
      connection_status: 3,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 95,
      comm_RSSI: 59,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 11,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature: 120,
      temperature2: 190,
      activity_status: 0,
      connection_status: 3,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 26,
      comm_RSSI: 79,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 12,
      mu_id: "UG0000100",
      FPGA_number: 1,
      drawer_number: 1,
      temperature1: 10,
      temperature2: 170,
      activity_status: 0,
      connection_status: 3,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 88,
      comm_RSSI: 24,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 1,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 100,
      temperature2: 180,
      activity_status: 0,
      connection_status: 4,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 25,
      comm_RSSI: 79,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 5,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature: 10,
      temperature2: 70,
      activity_status: 2,
      connection_status: 1,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 55,
      comm_RSSI: 25,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 3,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 60,
      temperature2: 400,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 45,
      comm_RSSI: 57,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 2,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 180,
      temperature2: 210,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 70,
      comm_RSSI: 80,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 4,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 140,
      temperature2: 210,
      activity_status: 0,
      connection_status: 1,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 22,
      comm_RSSI: 66,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 1,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 200,
      temperature2: 300,
      activity_status: 0,
      connection_status: 4,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 40,
      comm_RSSI: 35,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 6,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 30,
      temperature2: 60,
      activity_status: 2,
      connection_status: 1,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 99,
      comm_RSSI: 80,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 7,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 20,
      temperature2: 180,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 85,
      comm_RSSI: 55,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 8,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 40,
      temperature2: 190,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 30,
      comm_RSSI: 50,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 9,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 120,
      temperature2: 150,
      activity_status: 0,
      connection_status: 1,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 65,
      comm_RSSI: 87,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 11,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 10,
      temperature2: 170,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
      power_duty_cycle: 36,
      comm_RSSI: 42,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 12,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 2,
      temperature1: 200,
      temperature2: 250,
      activity_status: 0,
      connection_status: 1,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 90,
      comm_RSSI: 87,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 5,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 150,
      temperature2: 210,
      activity_status: 1,
      connection_status: 1,
      energy: 180,
      efficiency: 90,
      currency: 50,
      voltage: 2000,
      power: 100,
      location: [30, 60],
      power_duty_cycle: 78,
      comm_RSSI: 65,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 6,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 120,
      temperature2: 200,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 39,
      comm_RSSI: 12,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 7,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 180,
      temperature2: 210,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 100,
      comm_RSSI: 10,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 8,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 90,
      temperature2: 100,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 5,
      comm_RSSI: 42,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 9,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 180,
      temperature2: 280,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 10,
      comm_RSSI: 70,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 10,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 120,
      temperature2: 150,
      activity_status: 1,
      connection_status: 1,
      energy: 180,
      efficiency: 90,
      currency: 50,
      voltage: 2000,
      power: 100,
      location: [30, 60],
      power_duty_cycle: 86,
      comm_RSSI: 97,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 11,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 200,
      temperature2: 170,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 20,
      comm_RSSI: 100,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 12,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 180,
      temperature2: 300,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 86,
      comm_RSSI: 45,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 1,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 180,
      temperature2: 400,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 40,
      comm_RSSI: 30,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 3,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 180,
      temperature: 300,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 96,
      comm_RSSI: 54,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 4,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 20,
      temperature2: 180,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 6,
      comm_RSSI: 80,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 5,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 3,
      temperature1: 180,
      temperature: 220,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 66,
      comm_RSSI: 66,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },

    {
      segment_id: 1,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 150,
      temperature2: 210,
      activity_status: 1,
      connection_status: 1,
      energy: 180,
      efficiency: 90,
      currency: 50,
      voltage: 2000,
      power: 100,
      location: [30, 60],
      power_duty_cycle: 78,
      comm_RSSI: 65,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 2,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 120,
      temperature2: 200,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 39,
      comm_RSSI: 12,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 3,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 180,
      temperature2: 210,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 100,
      comm_RSSI: 10,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 4,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 90,
      temperature2: 100,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 5,
      comm_RSSI: 42,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 5,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 180,
      temperature2: 280,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 10,
      comm_RSSI: 70,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 6,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 120,
      temperature2: 150,
      activity_status: 1,
      connection_status: 1,
      energy: 180,
      efficiency: 90,
      currency: 50,
      voltage: 2000,
      power: 100,
      location: [30, 60],
      power_duty_cycle: 86,
      comm_RSSI: 97,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 7,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 200,
      temperature2: 170,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 20,
      comm_RSSI: 100,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 8,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 180,
      temperature2: 300,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 86,
      comm_RSSI: 45,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 9,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 180,
      temperature2: 400,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 40,
      comm_RSSI: 30,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 10,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 180,
      temperature: 300,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 96,
      comm_RSSI: 54,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 11,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 20,
      temperature2: 180,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 6,
      comm_RSSI: 80,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 12,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 4,
      temperature1: 180,
      temperature: 220,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 66,
      comm_RSSI: 66,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },

    {
      segment_id: 1,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 150,
      temperature2: 210,
      activity_status: 1,
      connection_status: 1,
      energy: 180,
      efficiency: 90,
      currency: 50,
      voltage: 2000,
      power: 100,
      location: [30, 60],
      power_duty_cycle: 78,
      comm_RSSI: 65,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 2,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 120,
      temperature2: 200,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 39,
      comm_RSSI: 12,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 3,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 180,
      temperature2: 210,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 100,
      comm_RSSI: 10,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 4,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 90,
      temperature2: 100,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 5,
      comm_RSSI: 42,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 5,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 180,
      temperature2: 280,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 10,
      comm_RSSI: 70,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 6,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 120,
      temperature2: 150,
      activity_status: 1,
      connection_status: 1,
      energy: 180,
      efficiency: 90,
      currency: 50,
      voltage: 2000,
      power: 100,
      location: [30, 60],
      power_duty_cycle: 86,
      comm_RSSI: 97,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 7,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 200,
      temperature2: 170,
      activity_status: 0,
      connection_status: 2,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 20,
      comm_RSSI: 100,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 8,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 180,
      temperature2: 300,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 86,
      comm_RSSI: 45,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 9,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 180,
      temperature2: 400,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 40,
      comm_RSSI: 30,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 10,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 180,
      temperature: 300,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 96,
      comm_RSSI: 54,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 11,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 20,
      temperature2: 180,
      activity_status: 1,
      connection_status: 3,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 6,
      comm_RSSI: 80,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
    {
      segment_id: 12,
      mu_id: "UG0000100",
      FPGA_number: 2,
      drawer_number: 5,
      temperature1: 180,
      temperature: 220,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
      power_duty_cycle: 66,
      comm_RSSI: 66,
      currentA: 10,
      currentB: 20,
      current0: 10,
      WFrequency: 255,
      RFrequency: 200,
    },
  ],
};

export const mockDataWebSocket = {
  mu_list: [
    {
      id: 1,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818605801,
      deployment_type: "above",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 100,
      currency: 150,
    },
    {
      id: 2,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "disconnected",
      on_off: false,
      last_change: 1610818645801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 700,
      currency: 80,
    },
    {
      id: 3,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818355801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818635801,
      deployment_type: "underground",
      charging_type: "dynamic",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 600,
      currency: 20,
    },
    {
      id: 4,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "tel_aviv",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: false,
      last_change: 1610818625801,
      deployment_type: "underground",
      charging_type: "dynamic",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 0,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 200,
      currency: 20,
    },
    {
      id: 5,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818615801,
      deployment_type: "above",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 0,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 100,
      currency: 90,
    },
    {
      id: 6,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818695801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 120,
      currency: 10,
    },
    {
      id: 7,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818555801,
      deployment_type: "above",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 200,
      currency: 100,
    },
    {
      id: 8,
      project_id: 5,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610818255801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 200,
      currency: 100,
    },
    {
      id: 9,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610814655801,
      deployment_type: "underground",
      charging_type: "dynamic",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 400,
      currency: 50,
    },
    {
      id: 10,
      project_id: 2,
      road_id: 4,
      lane_id: 2, //(lane id null for static mu)
      project_name: "",
      hw_version: "",
      fw_version: "",
      drawers_num: 5,
      creation_time: 1610818655801,
      connectivity: "connected",
      on_off: true,
      last_change: 1610813655801,
      deployment_type: "underground",
      charging_type: "static",
      alerts: [],
      fans: [
        {
          id: 1,
          status: 0,
        },
      ],
      ABB_status: 1,
      rectifier_status: 1,
      acknowledge_status: 1,
      contactor_status: 1,
      segments_num: 6,
      voltage: 100,
      currency: 20,
    },
  ],
  segments: [
    {
      segment_id: 1,
      mu_id: 1,
      FPGA_number: 1,
      drawer_number: 1,
      temperature: 30,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 20,
      currency: 10,
      voltage: 200,
      power: 20,
      location: [30, 50],
    },
    {
      segment_id: 2,
      mu_id: 1,
      FPGA_number: 2,
      drawer_number: 2,
      temperature: 200,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
    },
    {
      segment_id: 3,
      mu_id: 1,
      FPGA_number: 2,
      drawer_number: 2,
      temperature: 200,
      activity_status: 0,
      connection_status: 1,
      energy: 100,
      efficiency: 50,
      currency: 20,
      voltage: 1000,
      power: 1000,
      location: [30, 50],
    },
    {
      segment_id: 4,
      mu_id: 1,
      FPGA_number: 2,
      drawer_number: 2,
      temperature: 200,
      activity_status: 0,
      connection_status: 1,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
    },
    {
      segment_id: 5,
      mu_id: 1,
      FPGA_number: 2,
      drawer_number: 3,
      temperature: 150,
      activity_status: 1,
      connection_status: 1,
      energy: 180,
      efficiency: 90,
      currency: 50,
      voltage: 2000,
      power: 100,
      location: [30, 60],
    },
    {
      segment_id: 6,
      mu_id: 1,
      FPGA_number: 2,
      drawer_number: 2,
      temperature: 200,
      activity_status: 0,
      connection_status: 1,
      energy: 200,
      efficiency: 80,
      currency: 50,
      voltage: 1000,
      power: 200,
      location: [30, 60],
    },
    {
      segment_id: 7,
      mu_id: 1,
      FPGA_number: 2,
      drawer_number: 3,
      temperature: 180,
      activity_status: 1,
      connection_status: 0,
      energy: 180,
      efficiency: 99,
      currency: 80,
      voltage: 1500,
      power: 200,
      location: [30, 60],
    },
  ],
};
