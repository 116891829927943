import React from "react";
import { useParams, useLocation } from "react-router-dom";

import DashboardWrapper from "Screens/ProjectDashboard/DashboardWrapper";
import { useDashboardRestDataLoader } from "Screens/ProjectDashboard/lib/hooks/useDashboardRestDataLoader";
import { useDashboardContext } from "Screens/ProjectDashboard/lib/context/dashboardContext";
import { useSocketDataLoader } from "Screens/ProjectDashboard/lib/hooks/useSocketDataLoader";
import Header from "Components/Header/Header";

import "Screens/ProjectDashboard/styles.scss";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Dashboard = () => {
  let { id: projectId } = useParams();
  let requestedVuIds = useQuery().get("vuIds");

  requestedVuIds = requestedVuIds ? requestedVuIds.split(",") : [];

  useDashboardRestDataLoader(projectId, requestedVuIds);
  useSocketDataLoader(projectId);

  const { state } = useDashboardContext();

  return (
    <div className="provider-wrapper">
      <Header transparentBackground />
      {state.initialDataLoaded ? (
        <DashboardWrapper
          projectId={projectId}
          roadPath={state.roadPath}
          projectSettings={state.projectSettings}
          projectSegments={state.projectSegments}
          managementUnits={state.managementUnits}
          backgroundGeoJson={state.backgroundGeojson}
          segmentsPath={state.segmentsPath}
          gpsPositions={state.gpsPositions}
          vehicleData={state.vehicleData}
        />
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
};

export default Dashboard;
