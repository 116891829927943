import { useEffect, useRef } from "react";
import {
  Box,
  Button,
  GlobalStyles,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { debounce } from "lodash";

//Components
import Header from "Components/Header/Header";
import ExportButton from "Components/Buttons/ExportButton/ExportButton";
import ExportGraphAsPngButton from "Components/Buttons/ExportGraphAsPngButton/ExportGraphAsPngButton";
import CustomLoader from "Components/Loader/Loader";
import AnalyticsAdvancedParamsSelection from "Screens/AnalyticsTool/AnalyticsParametersSelection/AnalyticsAdvancedParamsSelection";
import AnalyticsDataDisplay from "Screens/AnalyticsTool/AnalyticsDataDisplay/AnalyticsDataDisplay";
import AdvancedParamsDatePicker from "Screens/AnalyticsTool/AdvancedParamsDatePicker/AdvancedParamsDatePicker";
import TimeRuler from "Screens/AnalyticsTool/TimeRuler/TimeRuler";
import PersistentDrawerRight from "Components/RightSideDrawer/RightSideDrawer";
import SelectDeviceActivity from "./SelectDeviceActivity/SelectDeviceActivity";
import AnalyticsSummaryTable from "./AnalyticsSummaryTable/AnalyticsSummaryTable";
import ActivityGraph from "./ActivityGraph/ActivityGraph";
import { SummaryTableHeader } from "./AnalyticsSummaryTable/SummaryTableHeader";

//Hooks
import { useAppStore } from "MobxStores/context";
import useSelectedProjectData from "CustomHooks/useSelectedProject";
import ProjectAnalyticsStore from "MobxStores/ProjectAnalyticsStore";
import { useRequestHistory } from "Screens/AnalyticsTool/Hooks/useRequestHistory";

//Utils
import { getAnalyticsDataGraphParams } from "Utils/APIUtils";
import {
  copyTableDataToClipboard,
  geFilteredIndexes,
  padTimeTo,
} from "Utils/AnalyticUtils";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import RawOnIcon from "@mui/icons-material/RawOn";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import GetData from "@mui/icons-material/GetApp";
import "Screens/AnalyticsTool/ProjectAnalyticsTool.scss";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const ProjectAnalyticsTool = () => {
  const { timezoneStr } = useSelectedProjectData();
  const { projectAnalyticsStore } = useAppStore();
  const {
    projectAnalyticsSummaryTableData,
    dataToExport,
    isLoadingDataFromServer,
    deviceActivityData,
    getDataForSelectedTimespan,
    selectedDate,
    setSelectedDate,
    clearAdvancedParamsData,
    getDeviceActivityData,
    clearDeviceActivityData,
    setSelectedTimespan,
    selectedTimespanIndices,
    selectedActivityDevice,
    getSummaryTableData,
    getPreviousActivityDay,
    getNextActivityDay,
    deviceActivityLoaded,
    setTimezone,
    isZooming,
    selectedTimespanValues,
    setTimeRulerBorder,
    setIsZooming,
    timeValuesBeforeZoom,
    zoomTimespan,
    selectedTimeRange,
    updateActivityData,
    isLoadingGraphData,
    stepBackInHistory,
    stepForwardInHistory,
    historyTraversal,
    dataRequestHistory,
  } = projectAnalyticsStore as ProjectAnalyticsStore;
  let { current: isActivityGraphPressed } = useRef(false);
  const { startTime, endTime } = selectedTimespanValues;
  useRequestHistory();
  const isRawData = moment(endTime).diff(moment(startTime), "seconds") <= 2;
  const preventStepBack =
    historyTraversal.stepsBack >= dataRequestHistory.length - 1 ||
    dataRequestHistory.length < 2;

  const fileName = "tempFileName";
  //  { startIndex?: number | null; endIndex?: number | null }
  const debouncedSetSelectedTimespan = debounce((e: any) => {
    if (isActivityGraphPressed) {
      isActivityGraphPressed = false;
      return;
    }
    if (!e.startIndex || !e.endIndex) return;

    const { startIndex, endIndex } = e;
    updateActivityData(timezoneStr);
    setSelectedTimespan(startIndex, endIndex);
    getDataForSelectedTimespan(startIndex, endIndex, timezoneStr);
    getSummaryTableData(timezoneStr);
  }, 1000);

  const handleDateChange = (e: Date | null) => {
    const momentDate = moment(e);
    if (!momentDate.isValid()) return;
    setSelectedDate(momentDate);
  };

  const handleDateSubmit = async (e: Date | null) => {
    const momentDate = moment(e);
    if (!momentDate?.isValid()) return;
    if (projectAnalyticsStore.selectedParamCount === 0) return;
    setSelectedDate(momentDate);
    clearAdvancedParamsData();
    clearDeviceActivityData();

    await getDeviceActivityData(timezoneStr, selectedActivityDevice);
    await getSummaryTableData(timezoneStr);
  };

  const getZoomData = () => {
    let paddedStart;
    let paddedEnd;

    if (moment(endTime).diff(startTime, "seconds") < 1) {
      const { newStart, newEnd } = padTimeTo(
        "second",
        startTime.valueOf(),
        endTime.valueOf()
      );
      paddedStart = newStart;
      paddedEnd = newEnd;
    }

    const filteredTimerange = geFilteredIndexes(
      deviceActivityData,
      paddedStart || startTime,
      paddedEnd || endTime,
      timezoneStr
    );

    setSelectedTimespan(
      filteredTimerange.startIndex,
      filteredTimerange.endIndex,
      paddedStart || startTime,
      paddedEnd || endTime
    );

    getDataForSelectedTimespan(
      filteredTimerange.startIndex,
      filteredTimerange.endIndex,
      timezoneStr,
      paddedStart || startTime,
      paddedEnd || endTime
    );

    getSummaryTableData(
      timezoneStr,
      null,
      paddedStart || startTime,
      paddedEnd || endTime
    );

    updateActivityData(timezoneStr);
  };

  const dropZoom = () => {
    zoomTimespan(timeValuesBeforeZoom.start, timeValuesBeforeZoom.end);
    setIsZooming(false);
    setTimeRulerBorder(true, true);
  };

  useEffect(() => {
    getAnalyticsDataGraphParams().then((res) => {
      if (res && res.data) {
        projectAnalyticsStore.setProjectAdvancedParams(res.data);
      } else {
        console.error("Error fetching advanced params");
      }
    });
    setTimezone(timezoneStr);
    return () => projectAnalyticsStore.cleanup();
  }, [timezoneStr]);

  const tableData = projectAnalyticsSummaryTableData;

  return (
    <Box className="project-analytics-page" sx={{ overflow: "hidden" }}>
      <Header />
      <PersistentDrawerRight
        title={<SummaryTableHeader />}
        body={
          <AnalyticsSummaryTable
            key="AnalyticsSummaryTable-ProjectAnalyticsTool"
            loading={isLoadingDataFromServer}
            tableData={tableData}
          />
        }
        // footer={
        //   <Button
        //     variant={"outlined"}
        //     disabled={!Object.keys(tableData).length || isLoadingDataFromServer}
        //     onClick={() =>
        //       copyTableDataToClipboard(projectAnalyticsSummaryTableData)
        //     }
        //     sx={{ borderRadius: "4px" }}
        //   >
        //     <Typography variant="button" component="span">
        //       Copy data
        //     </Typography>
        //   </Button>
        // }
      >
        <Box className="project-analytics-body">
          <Stack className="analytics-tool-container" direction="row">
            <GlobalStyles styles={{ html: { fontSize: "16px" } }} />
            <AnalyticsAdvancedParamsSelection />
            <SelectDeviceActivity />
            <AdvancedParamsDatePicker
              date={moment(selectedDate).utc(true)}
              onChange={handleDateChange}
              onSubmit={handleDateSubmit}
              loading={isLoadingDataFromServer || isLoadingGraphData}
            />
            <Stack
              direction="row"
              sx={{
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              <ExportGraphAsPngButton />
              {/* <ExportButton
                isDisabled={
                  // !projectAnalyticsSummaryTableData ||
                  // !advancedParamsData?.length
                  // temporarily disabling export button
                  // todo: implement raw data export similiar to history page
                  true
                }
                fileNameArr={[fileName]}
                csvDataArr={dataToExport}
                darkMode
                isAnalytic
              /> */}
              <CustomLoader
                className={`${isLoadingDataFromServer ? "visible" : "hidden"}`}
                width={40}
                height={40}
              />
            </Stack>
            <Stack direction="row">
              <IconButton
                disabled={preventStepBack}
                size="small"
                onClick={stepBackInHistory}
                title="Previous request"
              >
                <SkipPreviousIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={stepForwardInHistory}
                disabled={historyTraversal.stepsBack === 0}
                title="Next request"
              >
                <SkipNextIcon />
              </IconButton>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            className="activity-graph-container"
            alignItems="center"
          >
            <IconButton
              sx={{ padding: 0, height: "100%" }}
              onClick={() => {
                getPreviousActivityDay(timezoneStr);
              }}
              disabled={isLoadingDataFromServer || isLoadingGraphData}
            >
              <ChevronLeft />
            </IconButton>
            <ActivityGraph
              data={deviceActivityData}
              onTimespanChange={debouncedSetSelectedTimespan}
              xAxisTickInterval={59}
              brushStart={selectedTimespanIndices.startIndex}
              brushEnd={selectedTimespanIndices.endIndex}
              showBrush={deviceActivityLoaded}
              // onMouseDown={() => (isActivityGraphPressed = true)}
              // onMouseUp={() => (isActivityGraphPressed = false)}
              // onMouseLeave={() => (isActivityGraphPressed = false)}
            />
            <IconButton
              sx={{ padding: 0, height: "100%" }}
              onClick={() => {
                getNextActivityDay(timezoneStr);
              }}
              disabled={isLoadingDataFromServer || isLoadingGraphData}
            >
              <ChevronRight />
            </IconButton>
          </Stack>
          <Stack direction="column">
            <TimeRuler />
            <Stack
              direction="row"
              mt={1}
              height="24px"
              justifyContent="flex-end"
            >
              {isZooming && !!selectedTimeRange.length && (
                <>
                  <IconButton
                    size="small"
                    sx={{ padding: "0 1px" }}
                    onClick={dropZoom}
                    title="Reset zoom"
                  >
                    <ZoomOutMapIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    sx={{ padding: "0 1px" }}
                    onClick={getZoomData}
                    disabled={isRawData}
                    title="Enhance"
                  >
                    <GetData />
                  </IconButton>
                </>
              )}
              {isRawData && !!selectedTimeRange.length && <RawOnIcon />}
            </Stack>
          </Stack>
          <AnalyticsDataDisplay />
        </Box>
      </PersistentDrawerRight>
    </Box>
  );
};

export default observer(ProjectAnalyticsTool);
