import ProgressBarWithLabel from "../ProgressBarWithLabel/ProgressBarWithLabel";
import PropTypes from "prop-types";

const BatteryLevelDisplay = ({ batteryLevel }) => {
  if (!batteryLevel && batteryLevel !== 0) return null;
  return (
    <ProgressBarWithLabel
      value={batteryLevel}
      labelSpacing={1}
      barMaxWidth={"85%"}
    />
  );
};

export default BatteryLevelDisplay;

BatteryLevelDisplay.propTypes = {
  batteryLevel: PropTypes.number,
};
