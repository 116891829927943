import { Box } from "@mui/material";
import { columns as mainTableColumns } from "Screens/DepotDashboard/DepotOverviewTable/DepotMainTableData";
import { columns as vehicleTableColumns } from "Screens/DepotDashboard/DepotOverviewTable/VehicleTableData";
import DepotMainTable from "../DepotOverviewTable/DepotMainTable";
import DepotVehicleTable from "../DepotOverviewTable/DepotVehicleTable";
import PropTypes from "prop-types";

const DepotMainTables = ({ store }) => {
  return (
    <Box
      width={"100%"}
      sx={{
        overflowY: "scroll",
        maxHeight: "100vh",
        "&::-webkit-scrollbar": { display: "none" },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <DepotMainTable rows={store.allParkingSpots} columns={mainTableColumns} />
      <DepotVehicleTable
        rows={store.nonParkedVehicles}
        columns={vehicleTableColumns}
      />
    </Box>
  );
};

export default DepotMainTables;

DepotMainTables.propTypes = {
  store: PropTypes.shape({
    allParkingSpots: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.shape({
          batteryLevel: PropTypes.number,
          charging: PropTypes.bool,
        }),
        connectionStatus: PropTypes.string,
      })
    ),
    nonParkedVehicles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        state: PropTypes.shape({
          batteryLevel: PropTypes.number,
          charging: PropTypes.bool,
        }),
        connectionStatus: PropTypes.string,
      })
    ),
  }),
};
