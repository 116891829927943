import {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useBeforeunload } from "react-beforeunload";

//Context and Reducer imports
import MUsContext from "Contexts/MUsContext";
import VUsContext from "Contexts/VUsContext";
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";
import SegmentsContext from "Contexts/SegmentsContext";

//Utils imports
import { getAccessToken, urlNodeEnv } from "Utils/APIUtils";
import {
  saveHubMessages,
  saveFpgaMessages,
} from "Utils/EventsMessages/MessagesFunctions";
import {
  closeSocket,
  socketLocalExec,
  subscribeSocketMessages,
} from "Utils/socketApi";
import {
  getRandomColorFromRange,
  getClaimsFromToken,
  getDevicesAPICall,
} from "Utils/UtilsFunctions";
import {
  defaultMessageData,
  defaultModalState,
} from "Utils/DefaultValues/ProjectViewDefaultValues";
import {
  handleLockOnSocketDisconnect,
  setDeviceLockTimeout,
} from "Utils/LockUtils";
import { GRAPH_SCALE } from "Utils/GraphUtils";
import useSendMsg from "CustomHooks/projectViewLogic/useSendMsg";

const useProjectViewData = () => {
  const [IOTHubMessages, setNewIOTHubMessage] = useState("");
  const [fpgaMessages, setNewFpgaMessage] = useState("");
  const [locIntervalInstances, setLocIntervalInstances] = useState({});
  const [locTimeoutInstances, setLocTimeoutInstances] = useState({});
  const [selectedDrawer, setSelectedDrawer] = useState(1);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
  const [graphScale, setGraphScale] = useState(GRAPH_SCALE[5]);
  const [lastSessionSocketInstance, setLastSessionSocketInstance] =
    useState(undefined);
  const [messageData, setMessageData] = useState(defaultMessageData);
  const [modalState, setModalState] = useState(defaultModalState);

  const { segmentsDispatch, ...SegmentsState } = useContext(SegmentsContext);
  const { VUsDispatch, activeVU } = useContext(VUsContext);
  const {
    fpgaProgressDone,
    musEventsMessages,
    continuationToken,
    MUsDispatch,
  } = useContext(MUsContext);
  const {
    selectedDevice,
    selectedProject,
    mainDispatch,
    shouldAcquireToken,
    intervalIds,
    intervalInstance,
    isNewTokenReceived,
    timeoutIds,
  } = useContext(MainContext);
  const { socketDispatch, ...SocketState } = useContext(SocketContext);
  const { sendCommMessage, sendForceOnMessage, sendPWMMessage } =
    useSendMsg(setModalState);

  const history = useHistory();

  const locTimeoutInstancesRef = useRef();
  const lockInfoRef = useRef();
  const locIntervalInstancesRef = useRef();

  locTimeoutInstancesRef.current = locTimeoutInstances;
  locIntervalInstancesRef.current = locIntervalInstances;

  const { segments } = SegmentsState;
  const {
    socketMessageData,
    deviceId,
    isActiveVU,
    eventName,
    deviceSocketCallback,
    isProjectSocket,
    entityId,
    entity,
    isProjectMessage,
  } = messageData;

  const {
    socketMUMessageData,
    socketInstanceActiveVU,
    socketDcSamplerData,
    socketInstanceProject,
    socketInstancesProjectDevices,
    discretes,
    fans,
    receiversFans,
    cuUnitTemp,
    lockInfo,
    connectionState,
    receivers,
    HPStateSocket,
    chargingStateSocket,
  } = SocketState;
  lockInfoRef.current = lockInfo;

  const { data } = socketMessageData;

  const handleSocketJwtError = () => {
    // closeSockets
    if (!shouldAcquireToken) {
      mainDispatch({
        type: "SET_SHOULD_ACQUIRE_TOKEN",
        payload: { shouldAcquireToken: true },
      });
    }
    // reopenNewOnes
  };

  const memoGraphScale = useMemo(() => {
    switch (graphScale) {
      case "5 Sec":
        return 138;
      case "15 Sec":
        return 425;
      case "30 Sec":
        return 833;
      default:
        return 138;
    }
  }, [graphScale]);

  const sessionIdProject = useMemo(() => {
    if (socketInstanceProject) {
      const localStorageProjectSocketId =
        localStorage.getItem("sessionIdProject");
      if (
        !localStorageProjectSocketId ||
        localStorageProjectSocketId === "undefined"
      ) {
        localStorage.setItem("sessionIdProject", socketInstanceProject.id);
        return socketInstanceProject.id;
      }

      return localStorageProjectSocketId;
    }

    return null;
  }, [socketInstanceProject]);

  const socketInstanceSelectedDevice = useCallback(
    (selectedDevice) => {
      return socketInstancesProjectDevices &&
        selectedDevice &&
        socketInstancesProjectDevices[selectedDevice.id]
        ? socketInstancesProjectDevices[selectedDevice.id]
        : "";
    },
    [socketInstancesProjectDevices, selectedDevice]
  );

  const addMessageData = useCallback(() => {
    const messageKeys = socketMessageData ? Object.keys(socketMessageData) : [];

    if (eventName === "eventsMessage") {
      setNewIOTHubMessage(socketMessageData);
    }

    if (
      messageKeys.some((messageKey) => messageKey.indexOf("fpga") > -1) &&
      !isProjectMessage
    ) {
      let newSocketSegmentsMessage = segments || {};
      newSocketSegmentsMessage[deviceId] = messageData;
      //fpgaMessage
      if (
        messageData &&
        messageData.socketMessageData &&
        messageData.socketMessageData[`fpga_${selectedDrawer - 1}`]
      ) {
        let selectedDrawerSocketMessageData =
          messageData.socketMessageData[`fpga_${selectedDrawer - 1}`];
        let fpgaEvents =
          selectedDrawerSocketMessageData["shelf_configuration"] &&
          selectedDrawerSocketMessageData["shelf_configuration"].events;
        setNewFpgaMessage(fpgaEvents);
      }

      return segmentsDispatch({
        type: "SET_SEGMENTS_MESSAGE",
        payload: { segments: newSocketSegmentsMessage },
      });
    } else if (
      socketMessageData &&
      !socketMessageData["fans_drawer_1"] &&
      !!socketMessageData["data"]
    ) {
      //dcSamplerMessage
      let newSocketGraphMessage = socketMUMessageData;

      if (
        (newSocketGraphMessage && !newSocketGraphMessage[deviceId]) ||
        (newSocketGraphMessage &&
          !!newSocketGraphMessage[deviceId] &&
          !!newSocketGraphMessage[deviceId]["isActiveVU"] &&
          selectedDevice?.id === deviceId)
      ) {
        //update if exist in data object. otherwise initiate
        if (newSocketGraphMessage[deviceId]) {
          newSocketGraphMessage[deviceId] = {
            data: [...newSocketGraphMessage[deviceId].data, ...data],
            color: getRandomColorFromRange(),
            isSelected: true,
            isActiveVU,
          };
        } else {
          newSocketGraphMessage[deviceId] = {
            data,
            color: getRandomColorFromRange(),
            isSelected: true,
            isActiveVU,
          };
        }

        //isSelected to false on id defferent from deviceId or selectedDevice.id
        Object.keys(newSocketGraphMessage).map((key) => {
          if (key !== deviceId && key !== selectedDevice?.id) {
            newSocketGraphMessage[key] = {
              ...newSocketGraphMessage[key],
              isSelected: false,
              isActiveVU,
            };
          }
        }); //Think how to rewrite this logic and move to graphs
      } else if (
        newSocketGraphMessage &&
        !!newSocketGraphMessage[deviceId] &&
        newSocketGraphMessage[deviceId]["data"] &&
        !!newSocketGraphMessage[deviceId]["data"].length &&
        newSocketGraphMessage[deviceId]["data"].length > memoGraphScale
      ) {
        const lengthToCut =
          newSocketGraphMessage[deviceId]["data"].length - memoGraphScale;
        newSocketGraphMessage[deviceId]["data"] = [
          ...newSocketGraphMessage[deviceId]["data"],
          ...data,
        ];
        if (newSocketGraphMessage[deviceId]["data"]) {
          newSocketGraphMessage[deviceId]["data"] =
            newSocketGraphMessage[deviceId]["data"].slice(lengthToCut);
        }
      } else if (
        newSocketGraphMessage &&
        !!newSocketGraphMessage[deviceId] &&
        newSocketGraphMessage[deviceId]["data"] &&
        !!newSocketGraphMessage[deviceId]["data"].length &&
        newSocketGraphMessage[deviceId]["data"].length <= memoGraphScale
      ) {
        newSocketGraphMessage[deviceId]["data"] = [
          ...newSocketGraphMessage[deviceId]["data"],
          ...data,
        ];
      }

      let newDcSamplerData = socketDcSamplerData;
      newDcSamplerData[deviceId] = messageData;

      return (
        !isProjectMessage &&
        socketDispatch({
          type: "SET_DEVICE_DC_SAMPLER_AND_GRAPH_SOCKET_MESSAGES",
          payload: {
            MUGraphData: newSocketGraphMessage,
            dcSamplerData: newDcSamplerData,
          },
        })
      );
    } else if (socketMessageData && !!socketMessageData["fans_drawer_1"]) {
      let newSocketFanMessage = fans;
      newSocketFanMessage[deviceId] = messageData;
      //fansMessage
      return socketDispatch({
        type: "SET_FANS_MESSAGE",
        payload: { fans: newSocketFanMessage },
      });
    } else if (
      socketMessageData &&
      socketMessageData.hasOwnProperty("contactor_indication") &&
      !isProjectMessage
    ) {
      let newSocketDiscretesMessage = discretes;
      newSocketDiscretesMessage[deviceId] = messageData;
      return socketDispatch({
        type: "SET_DISCRETES_MESSAGE",
        payload: { discretes: newSocketDiscretesMessage },
      });
    } else if (socketMessageData && !!socketMessageData["RF_Boost_Level"]) {
      // Receivers message
      let newSocketReceiversMessage = receivers;
      newSocketReceiversMessage[deviceId] = messageData;
      return socketDispatch({
        type: "SET_RECEIVERS_MESSAGE",
        payload: { receivers: newSocketReceiversMessage },
      });
    } else if (
      socketMessageData &&
      socketMessageData.hasOwnProperty("receiver_fan_1")
    ) {
      let newSocketFanMessage = receiversFans;
      newSocketFanMessage[deviceId] = messageData;
      //fansMessage - for vu receivers
      return socketDispatch({
        type: "SET_RECEIVERS_FANS_MESSAGE",
        payload: { receiversFans: newSocketFanMessage },
      });
    } else if (
      socketMessageData &&
      socketMessageData.hasOwnProperty("cuTemp")
    ) {
      let newSocketCuTempMessage = cuUnitTemp;
      newSocketCuTempMessage[deviceId] = messageData;
      //cuUnitTempMessage
      return socketDispatch({
        type: "SET_CU_UNIT_TEMP_MESSAGE",
        payload: { cuUnitTemp: newSocketCuTempMessage },
      });
    } else if (
      eventName === "deviceTwinChanges" &&
      messageData &&
      messageData["socketMessageData"] &&
      messageData["socketMessageData"]["tags"] &&
      !(
        Object.keys(messageData["socketMessageData"]["tags"]).includes(
          "numOfOpenRooms"
        ) && Object.keys(messageData["socketMessageData"]["tags"]).length === 1
      )
    ) {
      // lock message
      const messageLockInfo =
        messageData &&
        messageData["socketMessageData"] &&
        messageData["socketMessageData"]["tags"] &&
        messageData["socketMessageData"]["tags"]["lockInfo"];
      let newSocketLockMessage = _.cloneDeep(lockInfo);
      newSocketLockMessage[deviceId] = messageLockInfo
        ? typeof messageLockInfo === "string"
          ? JSON.parse(messageLockInfo)
          : messageLockInfo
        : null;

      const expiresAt = messageLockInfo
        ? Number(messageLockInfo["expiresAt"])
        : undefined;
      const { isLockedByMe } = messageLockInfo
        ? sessionIdProject === messageLockInfo["sessionId"]
        : false;
      if (!isLockedByMe) {
        locTimeoutInstances[deviceId] &&
          clearTimeout(locTimeoutInstances[deviceId]["timeoutId"]);
        if (newSocketLockMessage[deviceId]) {
          setDeviceLockTimeout(
            deviceId,
            expiresAt,
            setLocTimeoutInstances,
            locTimeoutInstancesRef,
            lockInfoRef,
            socketDispatch
          );
        } else {
          // lockInfo from the socket is null
          setDeviceLockTimeout(
            deviceId,
            undefined,
            setLocTimeoutInstances,
            locTimeoutInstancesRef,
            lockInfoRef,
            socketDispatch
          );
        }
      }
      if (newSocketLockMessage[deviceId]) {
        socketDispatch({
          type: "SET_LOCK_MESSAGE",
          payload: { lockInfo: newSocketLockMessage },
        });
      }
    } else if (
      eventName === "deviceTwinChanges" &&
      messageData &&
      messageData["socketMessageData"] &&
      messageData["socketMessageData"]["properties"] &&
      messageData["socketMessageData"]["properties"]["reported"]
    ) {
      const newDeviceConnectionState = _.cloneDeep(connectionState);
      if (
        newDeviceConnectionState &&
        newDeviceConnectionState[deviceId]?.socketMessageData
      ) {
        newDeviceConnectionState[deviceId].socketMessageData.opertionState =
          "deviceConnected";
      }
      return socketDispatch({
        type: "SET_CONNECTION_STATE_MESSAGE",
        payload: { connectionState: newDeviceConnectionState },
      });
    } else if (eventName === "connectionStateChanges") {
      let newSocketConnectionStateMessage = _.cloneDeep(connectionState);
      if (newSocketConnectionStateMessage) {
        newSocketConnectionStateMessage[deviceId] = messageData;
      }
      // check if device got disconnected
      if (
        messageData?.["socketMessageData"]?.["opertionState"] ===
        "deviceDisconnected"
      ) {
        // remove disconnected device data from socketMUMessageData
        if (socketMUMessageData[deviceId]) {
          const clonedMsgData = _.cloneDeep(socketMUMessageData);
          const newMsgData = _.omit(clonedMsgData, `${deviceId}`);
          const clonedSamplerData = _.cloneDeep(socketDcSamplerData);
          const newSmaplerData = _.omit(clonedSamplerData, `${deviceId}`);
          socketDispatch({
            type: "SET_MU_MESSAGE_DATA",
            payload: {
              muMessageData: newMsgData,
              socketDcSamplerData: newSmaplerData,
            },
          });
        }

        // remove disconnected device data from cuUnitTemp
        if (cuUnitTemp[deviceId]) {
          const clonedCuUnitTemp = _.cloneDeep(cuUnitTemp);
          const newCuUnitTemp = _.omit(clonedCuUnitTemp, `${deviceId}`);
          socketDispatch({
            type: "SET_CU_UNIT_TEMP_MESSAGE",
            payload: { cuUnitTemp: newCuUnitTemp },
          });
        }

        // remove disconnected device data from recievers
        if (receivers[deviceId]) {
          const clonedRecievers = _.cloneDeep(receivers);
          const newRecieversData = _.omit(clonedRecievers, `${deviceId}`);
          socketDispatch({
            type: "SET_RECEIVERS_MESSAGE",
            payload: { receivers: newRecieversData },
          });
        }

        // remove disconnected device data from receiversFans
        if (receiversFans[deviceId]) {
          const clonedRecieversFans = _.cloneDeep(receiversFans);
          const newRecieversFansData = _.omit(
            clonedRecieversFans,
            `${deviceId}`
          );
          socketDispatch({
            type: "SET_RECEIVERS_FANS_MESSAGE",
            payload: { receiversFans: newRecieversFansData },
          });
        }

        // remove disconnected device data from segments
        if (segments[deviceId]) {
          const clonedSegments = _.cloneDeep(segments);
          const newSegmentsData = _.omit(clonedSegments, `${deviceId}`);
          segmentsDispatch({
            type: "SET_SEGMENTS_MESSAGE",
            payload: { segments: newSegmentsData },
          });
        }
      }

      return socketDispatch({
        type: "SET_CONNECTION_STATE_MESSAGE",
        payload: { connectionState: newSocketConnectionStateMessage },
      });
    } else if (eventName === "hpMessage") {
      let newSocketHPStateMessage = HPStateSocket;
      if (newSocketHPStateMessage) {
        newSocketHPStateMessage[deviceId] = messageData;
      }

      return socketDispatch({
        type: "SET_HP_STATE_MESSAGE",
        payload: { HPStateSocket: newSocketHPStateMessage },
      });
    } else if (eventName === "chargingMessage") {
      let newSocketChargingStateMessage = chargingStateSocket;
      if (newSocketChargingStateMessage) {
        newSocketChargingStateMessage[deviceId] = messageData;
      }

      return socketDispatch({
        type: "SET_CHARGING_STATE",
        payload: { chargingStateSocket: newSocketChargingStateMessage },
      });
    } else if (eventName === "devicesStatesMessage") {
      return socketDispatch({
        type: "SET_DEVICES_INITIAL_STATE",
        payload: {
          devicesInitialStateSocket: messageData,
        },
      });
    } else if (eventName === "JwtError") {
      console.log("eventName: ", eventName);
      handleSocketJwtError();
    }
  }, [
    messageData,
    socketMUMessageData,
    fans,
    receiversFans,
    cuUnitTemp,
    discretes,
    socketMessageData,
    deviceId,
    memoGraphScale,
    selectedDevice,
    activeVU,
    isActiveVU,
    eventName,
    lockInfo,
    connectionState,
    socketInstancesProjectDevices,
    sessionIdProject,
    deviceSocketCallback,
    isProjectSocket,
    selectedProject,
    entityId,
    entity,
    sessionStorage,
    isProjectMessage,
    chargingStateSocket,
    locTimeoutInstances,
  ]);

  const socketLocal = useCallback(
    (selectedDevice) => {
      const accessToken = getAccessToken();
      const decodetToken = getClaimsFromToken(accessToken);
      const currentTime = new Date().getTime();

      if (decodetToken.exp * 1000 < currentTime) {
        mainDispatch({
          type: "SET_SHOULD_ACQUIRE_TOKEN",
          payload: { shouldAcquireToken: true },
        });
      } else {
        return socketLocalExec(selectedDevice, accessToken, selectedProject.id);
      }
    },
    [selectedProject]
  );

  const handleAddingVU = useCallback(
    (selectedVU) => {
      if (selectedVU?.deviceType === "VU") {
        const accessToken = getAccessToken();
        const newSocketInstanceActiveVU = socketLocal(selectedVU);
        subscribeSocketMessages(
          newSocketInstanceActiveVU,
          selectedVU,
          true,
          false,
          false,
          accessToken,
          setMessageData
        );
        socketDispatch({
          type: "SET_SOCKET_ACTIVE_VU_SOCKET_INSTANCE",
          payload: {
            socketInstanceActiveVU: newSocketInstanceActiveVU,
          },
        });
      }
    },
    [subscribeSocketMessages, socketLocal]
  );

  const handleClosingSockets = useCallback(() => {
    if (Object.keys(lockInfo).length) {
      const accessToken = getAccessToken();
      const bearer = "Bearer " + accessToken;
      const lockedByMeDevices = Object.entries(lockInfo).filter((device) => {
        if (device[1].sessionId === sessionIdProject) {
          return device;
        }
      });
      if (lockedByMeDevices.length) {
        const promisses = lockedByMeDevices.map((device) => {
          fetch(
            `https://${urlNodeEnv}cloud.electreon.com/mu/control/unlock/${device[0]}`,
            {
              keepalive: true,
              method: "POST",
              headers: {
                Authorization: bearer,
                "x-lock-sessionId": String(sessionIdProject),
                "Content-Type": "application/json",
              },
              body: null,
            }
          );
        });
        Promise.all(promisses);
      }
    }

    if (
      socketInstancesProjectDevices &&
      !!Object.keys(socketInstancesProjectDevices).length
    ) {
      const devicesKeys = Object.keys(socketInstancesProjectDevices);
      devicesKeys.forEach((deviceId, index) => {
        console.log("closed");
        closeSocket(socketInstancesProjectDevices[deviceId]);
        closeSocket(socketInstanceActiveVU);

        if (index === devicesKeys.length - 1 && socketInstanceProject) {
          console.log("closed project");
          closeSocket(socketInstanceProject);
        }
      });
    }
    socketDispatch({
      type: "SET_LOCK_MESSAGE",
      payload: { lockInfo: {} },
    });
    socketDispatch({
      type: "SET_SOCKET_INSTANCES_PROJECT_DEVICES",
      payload: {
        socketInstancesProjectDevices: {},
      },
    });

    closeSocket(socketInstanceProject, `PROJ_${selectedProject.id}_TREE`);
  }, [
    socketInstancesProjectDevices,
    socketInstanceActiveVU,
    socketInstanceProject,
    sessionIdProject,
    selectedProject,
    Object.keys(lockInfo),
  ]);

  //this function seams like doesn't work
  const handleClearLockIntervals = useCallback(() => {
    if (locIntervalInstances) {
      return Object.keys(locIntervalInstances).forEach((deviceId) => {
        if (locIntervalInstances[deviceId]) {
          clearInterval(locIntervalInstances[deviceId]["intervalId"]);
        }
      });
    }
    if (locTimeoutInstances) {
      return Object.keys(locTimeoutInstances).forEach((deviceId) => {
        if (locTimeoutInstances[deviceId]) {
          clearTimeout(locTimeoutInstances[deviceId]["timeoutId"]);
        }
      });
    }
  }, [Object.keys(locIntervalInstances), Object.keys(locTimeoutInstances)]);

  useEffect(() => {
    Object.values(locIntervalInstances).map((device) => {
      device.intervalId &&
        mainDispatch({
          type: "SET_INTERVAL_ID",
          payload: { intervalIds: device.intervalId },
        });
    });
    Object.values(locTimeoutInstances).map((device) => {
      device.timeoutId &&
        mainDispatch({
          type: "SET_TIMEOUT_ID",
          payload: { timeoutIds: device.timeoutId },
        });
    });
  }, [locIntervalInstances, locTimeoutInstances]);

  useEffect(() => {
    if (
      isNewTokenReceived &&
      locIntervalInstances &&
      intervalInstance &&
      !_.isEqual(locIntervalInstances, intervalInstance)
    ) {
      setLocIntervalInstances(intervalInstance);
      mainDispatch({
        type: "IS_NEW_TOKEN_RECEIVED",
        payload: { isNewTokenReceived: false },
      });
    }
  }, [locIntervalInstances, intervalInstance, isNewTokenReceived]);

  useEffect(() => {
    if (shouldAcquireToken) return;
    const accessToken = getAccessToken();

    if (selectedDevice && Object.keys(selectedDevice).length) {
      if (lastSessionSocketInstance) {
        closeSocket(lastSessionSocketInstance);
      }

      if (socketInstanceActiveVU) {
        closeSocket(socketInstanceActiveVU);
      }

      VUsDispatch({
        type: "SET_ACTIVE_VU",
        payload: { activeVU: undefined },
      });

      const newSocketInstance = socketLocal(selectedDevice);

      subscribeSocketMessages(
        newSocketInstance,
        selectedDevice,
        isActiveVU,
        false,
        false,
        accessToken,
        setMessageData
      );

      socketDispatch({
        type: "SET_SOCKET_INSTANCES_PROJECT_DEVICES",
        payload: {
          socketInstancesProjectDevices: {
            ...socketInstancesProjectDevices,
            [selectedDevice?.id]: newSocketInstance,
          },
        },
      });
    }
  }, [shouldAcquireToken]);

  useEffect(() => {
    if (selectedProject) {
      getDevicesAPICall({
        id: selectedProject.id,
        mainDispatch,
        socketDispatch,
        VUsDispatch,
        lockInfoRef,
        setLocTimeoutInstances,
        locTimeoutInstancesRef,
        connectionState,
        continuationToken,
      });
    }
  }, [selectedProject]);

  useEffect(() => {
    socketDispatch({
      type: "SET_LOCK_MESSAGE",
      payload: { lockInfo: {} },
    });

    // cleanup
    return () => {
      socketDispatch({
        type: "RESET_CONNECTION_STATE_MESSAGE",
      });

      socketDispatch({
        type: "SET_LOCK_MESSAGE",
        payload: { lockInfo: {} },
      });

      mainDispatch({
        type: "SET_MU_DATA",
        payload: {
          musData: null,
        },
      });
    };
  }, [selectedProject]);

  useEffect(() => {
    return () => {
      let newSocketMUMessageData = {};

      for (const variableKey in socketMUMessageData) {
        if (socketMUMessageData.hasOwnProperty(variableKey)) {
          newSocketMUMessageData[variableKey] = {
            ...socketMUMessageData[variableKey],
            isSelected: false,
            data: undefined,
          };
        }
      }

      socketDispatch({
        type: "SET_MU_GRAPH_SOCKET_MESSAGES",
        payload: { MUGraphData: newSocketMUMessageData },
      });
    };
  }, [selectedProject]);

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP" || history.action === "PUSH") {
        console.log("unload");
        handleClosingSockets();
        handleClearLockIntervals();
      }
    });
  }, [
    history,
    socketInstancesProjectDevices,
    Object.keys(locIntervalInstances),
    Object.keys(locTimeoutInstances),
    Object.keys(lockInfo),
  ]);

  useEffect(() => {
    if (socketInstanceProject) {
      const localStorageProjectSocketId =
        localStorage.getItem("sessionIdProject");
      const newSocketId = socketInstanceProject.id;
      if (newSocketId && newSocketId !== localStorageProjectSocketId) {
        handleLockOnSocketDisconnect(
          localStorageProjectSocketId,
          locIntervalInstancesRef,
          setLocIntervalInstances,
          lockInfoRef.current,
          intervalIds,
          mainDispatch,
          timeoutIds
        );
      }
    }
  }, [socketInstanceProject && socketInstanceProject.id]);

  //listen to IOTHubmessages
  useEffect(() => {
    saveHubMessages(IOTHubMessages, MUsDispatch);
  }, [IOTHubMessages]);

  //listen to FPGA messages
  useEffect(() => {
    saveFpgaMessages(
      fpgaMessages,
      selectedDrawer,
      MUsDispatch,
      fpgaProgressDone,
      selectedDevice?.id
    );
  }, [fpgaMessages]);

  //listen to events messages (for messages array length)
  useEffect(() => {
    if (musEventsMessages && musEventsMessages.length > 49) {
      MUsDispatch({
        type: "MUS_EVENTS_MESSAGES_POP",
      });
    }
  }, [musEventsMessages]);

  useEffect(() => {
    if (messageData) {
      addMessageData();
    }
  }, [messageData]);

  useEffect(() => {
    if (selectedDevice && socketInstanceSelectedDevice(selectedDevice)) {
      setLastSessionSocketInstance(
        socketInstanceSelectedDevice(selectedDevice)
      );
    }
  }, [
    socketInstanceSelectedDevice && Object.values(socketInstanceSelectedDevice),
  ]);

  useEffect(() => {
    if (selectedDevice) {
      if (lastSessionSocketInstance) {
        closeSocket(lastSessionSocketInstance);
      }

      VUsDispatch({
        type: "SET_ACTIVE_VU",
        payload: { activeVU: undefined },
      });

      if (socketInstanceActiveVU) {
        closeSocket(socketInstanceActiveVU);
      }

      const newSocketInstance = socketLocal(selectedDevice);
      const accessToken = getAccessToken();

      subscribeSocketMessages(
        newSocketInstance,
        selectedDevice,
        isActiveVU,
        false,
        false,
        accessToken,
        setMessageData
      );

      socketDispatch({
        type: "SET_SOCKET_INSTANCES_PROJECT_DEVICES",
        payload: {
          socketInstancesProjectDevices: {
            ...socketInstancesProjectDevices,
            [selectedDevice?.id]: newSocketInstance,
          },
        },
      });
    }

    return () => {
      // close socket on unmount
      closeSocket(lastSessionSocketInstance);
      closeSocket(socketInstanceActiveVU);
      socketDispatch({
        type: "SET_SOCKET_INSTANCES_PROJECT_DEVICES",
        payload: {
          socketInstancesProjectDevices: {},
        },
      });
    };
  }, [selectedDevice?.id]);

  useEffect(() => {
    if (selectedProject) {
      const accessToken = getAccessToken();
      const newSocketInstance = socketLocal(selectedProject);
      subscribeSocketMessages(
        newSocketInstance,
        selectedProject,
        false,
        false,
        true,
        accessToken,
        setMessageData
      );
      socketDispatch({
        type: "SET_SOCKET_INSTANCE_PROJECT",
        payload: {
          socketInstanceProject: newSocketInstance,
          socketInstanceProjectId: newSocketInstance?.id,
        },
      });
    }
  }, [selectedProject?.id]);

  useBeforeunload((event) => {
    handleClosingSockets();
    handleClearLockIntervals();
  });

  return {
    setLocIntervalInstances,
    locIntervalInstances,
    selectedDrawer,
    setSelectedDrawer,
    setSelectedSegmentIndex,
    setModalState,
    socketInstanceProject,
    selectedSegmentIndex,
    sendCommMessage,
    sendForceOnMessage,
    sendPWMMessage,
    graphScale,
    setGraphScale,
    handleAddingVU,
    modalState,
    locTimeoutInstances,
  };
};

export default useProjectViewData;
