import React from "react";
import PropTypes from "prop-types";

const Image = ({
  src,
  height,
  width,
  alt,
  className,
  title,
  style,
  dataFor,
}) => (
  <div
    className={`image-wrapper ${className}`}
    data-for={dataFor}
    data-tip
    style={{ display: "flex" }}
  >
    <img
      src={src}
      title={title}
      alt={alt}
      height={height}
      width={width}
      style={style}
    />
  </div>
);

export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  alt: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  dataFor: PropTypes.string,
};
