import "Screens/DepotDashboard/DepotOverviewTable/DepotOverviewTable.scss";
import BatteryLevelDisplay from "../BatteryLevelDisplay/BatteryLevelDisplay";
import { Chip } from "@mui/material";
import getPowerDisplayString from "../Utils/GetPowerDisplayString";
import DepotDeviceIcon from "../DepotDeviceIcon/DepotDeviceIcon";
import { WithSlidingAnimation } from "../Hooks/WithSlidingAnimation";

const getVehicleData = (params) => params.row.pairedVuData;

export const columns = [
  {
    field: "icon",
    headerName: "Spot",
    flex: 2,
    columnSeparator: "hidden",
    sortable: false, // hide the sort arrrow
    disableColumnMenu: "hidden", // hide the menu button
    renderCell: (params) => {
      return <DepotDeviceIcon device={params.row} />;
    },
    headerClassName: "lastcolumnSeparator",
  },
  {
    field: "name",
    headerName: "",
    flex: 3,
    sortable: false,
    disableColumnMenu: "hidden",
    columnSeparator: "hidden",
    renderCell: (params) => {
      return <Chip variant="outlined" label={params.row?.name} />;
    },
  },
  {
    field: "vehicle",
    headerName: "Vehicle",
    flex: 3,
    sortable: false,
    disableColumnMenu: "hidden",
    renderCell: (params) => {
      const vehicle = getVehicleData(params);
      if (!params.row?.state?.vuPaired) return null;
      return (
        <WithSlidingAnimation
          active={vehicle.state.isTransitioning}
          direction={params.row.state.charging ? "left" : "right"}
        >
          <Chip variant="outlined" label={vehicle.id} />
        </WithSlidingAnimation>
      );
    },
  },
  {
    field: "batteryStatus",
    headerName: "Battery status",
    flex: 10,
    sortable: false,
    disableColumnMenu: "hidden",
    headerClassName: "lastcolumnSeparator",
    renderCell: (params) => {
      const vehicle = getVehicleData(params);
      if (!params.row?.state?.vuPaired) return null;
      return (
        <WithSlidingAnimation
          active={vehicle.state.isTransitioning}
          direction={params.row.state.charging ? "left" : "right"}
        >
          <BatteryLevelDisplay batteryLevel={vehicle.state.batteryLevel} />
        </WithSlidingAnimation>
      );
    },
  },
  {
    field: "power",
    headerName: "Power",
    flex: 2,
    sortable: false,
    disableColumnMenu: "hidden",
    renderCell: (params) => {
      const vehicle = getVehicleData(params);
      if (!params.row?.state?.vuPaired) return null;
      return (
        <WithSlidingAnimation
          active={vehicle.state.isTransitioning}
          direction={params.row.state.charging ? "left" : "right"}
        >
          <span>
            {getPowerDisplayString(params.row?.pairedVuData?.state?.power)}
          </span>
        </WithSlidingAnimation>
      );
    },
  },
  // TODO: add time to fully charged and range to empty columns when data is available
  // {
  //   field: "range",
  //   headerName: "Range",
  //   flex: 2,
  //   sortable: false,
  //   disableColumnMenu: "hidden",
  //   renderCell: (params) => {
  //     return params.row?.state?.vuPaired ? (
  //       <span>{params.row?.pairedVuData.state.range || 150 + "*"} km</span>
  //     ) : null;
  //   }
  // },
  // {
  //   field: "time",
  //   headerName: "Time",
  //   flex: 2,
  //   sortable: false,
  //   disableColumnMenu: "hidden",
  //   headerClassName: "lastcolumnSeparator",
  //   renderCell: (params) => {
  //     return params.row?.state?.vuPaired ? (
  //       <span>{params.row?.pairedVuData.state.chargingTime || 10 + "*"} min</span>
  //     ) : null;
  //   }
  // },
];
