import React from "react";
import PropTypes from "prop-types";
import MultipleAreaGraph from "Components/Graphs/MultipleAreaGraph";
import MultipleLineGraph from "Components/Graphs/MultipleLineGraph";

const HistoryPerformanceGraph = ({
  graphType,
  historyGraphData,
  graphScale,
  selectedDevice,
  handleGraphClick,
  isHistoryOn,
  isHistoryLoaded,
  xAxisMinSpanTimestamp,
  graphPngExportRef,
}) => {
  return graphType === "current" || graphType === "voltage" ? (
    <MultipleLineGraph
      graphData={historyGraphData}
      graphName={graphType}
      isAnimationActive={false}
      handleGraphClick={handleGraphClick}
      YAxisUnit={
        selectedDevice
          ? graphType === "current"
            ? `${graphType} (A)`
            : graphType === "voltage"
            ? `${graphType} (V)`
            : ""
          : ""
      }
      graphScale={graphScale}
      strokeColor="#488ec8"
      selectedDevice={selectedDevice}
      isHistoryOn={isHistoryOn}
      isHistoryLoaded={isHistoryLoaded}
      xAxisMinSpanTimestamp={xAxisMinSpanTimestamp}
      graphRef={graphPngExportRef}
    />
  ) : (
    <MultipleAreaGraph
      handleGraphClick={handleGraphClick}
      graphType={graphType}
      graphData={historyGraphData}
      selectedDevice={selectedDevice}
      graphScale={graphScale}
      isHistoryOn={isHistoryOn}
      isHistoryLoaded={isHistoryLoaded}
      xAxisMinSpanTimestamp={xAxisMinSpanTimestamp}
      graphRef={graphPngExportRef}
    />
  );
};
export default HistoryPerformanceGraph;

HistoryPerformanceGraph.propTypes = {
  graphScale: PropTypes.string,
  graphType: PropTypes.string,
  historyGraphData: PropTypes.object,
  selectedDevice: PropTypes.object,
  handleGraphClick: PropTypes.func,
  isHistoryOn: PropTypes.bool,
  isHistoryLoaded: PropTypes.bool,
  xAxisMinSpanTimestamp: PropTypes.number,
  graphPngExportRef: PropTypes.object,
};
