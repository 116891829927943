import { computed, makeAutoObservable, runInAction } from "mobx";
import { getDepotParkingSpots, getDepotVehicles } from "Utils/APIUtils";

export const CONNECTION_STATUS = {
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
};

class DepotStore {
  depotId = null;
  isLoading = false;
  parkingSpots = {}; // {parkingId: {{...parkingSpot}, state: {}}} - parkingSpot is the device telemetry data
  vuDevices = {}; // {vuDeviceId: {{...vehicle}, state: {}}} - vehicle is the device telemetry data
  projectId = null;

  constructor() {
    makeAutoObservable(this, {
      nonParkedVehicles: computed,
      vacantParkingSpots: computed,
      chargingParkingSpots: computed,
      nonOperationalParkingSpots: computed,
      occupiedParkingSpots: computed,
      nonChargingVehicles: computed,
      chargingVehicles: computed,
      fullyChargedVehicles: computed,
      vehiclesWithWPTError: computed,
      pairedVehicles: computed,
      connectedVehicles: computed,
      allParkingSpots: computed,
    });
  }

  async init(projectId, depotId) {
    this.depotId = depotId;
    this.projectId = projectId;
    try {
      this.setIsLoading(true);
      await this.setParkingSpots();
      await this.setVehicles();
    } catch (error) {
      console.error("Error occured while initializing depot store", error);
    }
    runInAction(() => this.setIsLoading(false));
  }

  setIsLoading(loading) {
    this.isLoading = loading ? true : false;
  }

  async setVehicles(vehicleArray) {
    try {
      const vuDevices =
        vehicleArray || (await getDepotVehicles(this.projectId));
      const vuDevicesMap = vuDevices.data.devices.reduce((acc, vuDevice) => {
        acc[vuDevice.id] = vuDevice;
        acc[vuDevice.id].state = {
          charging: false,
          inParkingSpot: false,
          parkingSpotId: null,
          batteryLevel: null,
          wptError: false,
          power: null,
          isTransitioning: false,
        };
        return acc;
      }, {});
      runInAction(() => (this.vuDevices = vuDevicesMap));
    } catch (error) {
      console.error("Error occured while fetching vehicles", error);
    }
  }

  async setParkingSpots(parkingSpotsArray) {
    try {
      const parkingSpots =
        parkingSpotsArray || (await getDepotParkingSpots(this.depotId));
      const parkingSpotsMap = parkingSpots.data.reduce((acc, parkingSpot) => {
        acc[parkingSpot.id] = parkingSpot;
        acc[parkingSpot.id].state = {
          vuPaired: false,
          pairedVuData: null,
          parkingSpotId: null,
          charging: false,
          occupied: false,
          nonOperational: false,
        };
        return acc;
      }, {});
      runInAction(() => (this.parkingSpots = parkingSpotsMap));
    } catch (error) {
      console.error("Error occured while fetching parking spots", error);
    }
  }

  setVehicleCharging(vuDeviceId, charging) {
    this.vuDevices[vuDeviceId].state.charging = charging;
  }

  setParkingSpotCharging(parkingSpotId, charging) {
    this.parkingSpots[parkingSpotId].state.charging = charging;
  }

  async setPairingActive(parkingSpotId, vuDeviceId) {
    this.parkingSpots[parkingSpotId].state.charging = true;
    this.parkingSpots[parkingSpotId].state.vuPaired = true;
    this.parkingSpots[parkingSpotId].state.vuDeviceId = vuDeviceId;
    this.parkingSpots[parkingSpotId].pairedVuData = this.vuDevices[vuDeviceId];

    this.vuDevices[vuDeviceId].state.charging = true;
    this.vuDevices[vuDeviceId].state.isTransitioning = true;
    this.vuDevices[vuDeviceId].state.parkingSpotId = parkingSpotId;

    // wait 1 seconds before removing the device from the vehicle list
    await new Promise((resolve) => setTimeout(resolve, 1000));
    runInAction(() => {
      this.vuDevices[vuDeviceId].state.inParkingSpot = true;
      this.vuDevices[vuDeviceId].state.isTransitioning = false;
    });
  }

  async setPairingInactive(parkingSpotId, vuDeviceId) {
    this.vuDevices[vuDeviceId].state.isTransitioning = true;
    this.parkingSpots[parkingSpotId].state.charging = false;
    this.vuDevices[vuDeviceId].state.inParkingSpot = false;
    this.vuDevices[vuDeviceId].state.charging = false;

    // wait for 1 second to reset device state for transition effect
    await new Promise((resolve) => setTimeout(resolve, 1000));
    runInAction(() => {
      this.vuDevices[vuDeviceId].state.isTransitioning = false;
      this.parkingSpots[parkingSpotId].state.vuDeviceId = null;
      this.vuDevices[vuDeviceId].state.parkingSpotId = null;
      this.parkingSpots[parkingSpotId].state.vuPaired = false;
      this.parkingSpots[parkingSpotId].pairedVuData = null;
    });
  }

  setVehicleBatteryLevel(vuDeviceId, batteryLevel) {
    this.vuDevices[vuDeviceId].state.batteryLevel = batteryLevel;
  }

  setVehiclePowerState(vuDeviceId, powerState) {
    this.vuDevices[vuDeviceId].state.power = powerState;
  }

  setVehicleConnectionState(vuDeviceId, connected) {
    this.vuDevices[vuDeviceId].connectionStatus = connected
      ? CONNECTION_STATUS.CONNECTED
      : CONNECTION_STATUS.DISCONNECTED;

    if (!connected) {
      this.vuDevices[vuDeviceId].state.charging = false;
      this.vuDevices[vuDeviceId].state.batteryLevel = null;
      this.vuDevices[vuDeviceId].state.power = null;
      this.vuDevices[vuDeviceId].state.wptError = false;
    }
  }

  setParkingSpotConnectionState(parkingSpotId, connected) {
    if (!this.parkingSpots[parkingSpotId]) {
      console.error(
        `Received socket data for unknown parking spot ${parkingSpotId}`
      );
      return;
    }
    this.parkingSpots[parkingSpotId].connectionStatus = connected
      ? CONNECTION_STATUS.CONNECTED
      : CONNECTION_STATUS.DISCONNECTED;
  }

  get connectedVehicles() {
    const connectedVehicles = Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.connectionStatus === CONNECTION_STATUS.CONNECTED
    );
    return connectedVehicles;
  }

  get nonParkedVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => !vuDevice.state?.inParkingSpot
    );
  }

  get vacantParkingSpots() {
    return Object.values(this.parkingSpots).filter(
      (parkingSpot) =>
        !parkingSpot.state?.charging &&
        !parkingSpot.state?.occupied &&
        !parkingSpot.state?.nonOperational
    );
  }

  get chargingParkingSpots() {
    return Object.values(this.parkingSpots).filter(
      (parkingSpot) => parkingSpot.state?.charging
    );
  }

  get allParkingSpots() {
    return Object.values(this.parkingSpots);
  }

  get nonOperationalParkingSpots() {
    return Object.values(this.parkingSpots).filter(
      (parkingSpot) => parkingSpot.state?.nonOperational
    );
  }

  get occupiedParkingSpots() {
    return Object.values(this.parkingSpots).filter(
      (parkingSpot) => parkingSpot.state?.occupied
    );
  }

  get nonChargingVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => !vuDevice.state?.charging
    );
  }

  get chargingVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.state?.charging
    );
  }

  get fullyChargedVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.state?.batteryLevel > 95
    );
  }

  get vehiclesWithWPTError() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.state?.wptError
    );
  }

  get pairedVehicles() {
    return Object.values(this.vuDevices).filter(
      (vuDevice) => vuDevice.state?.inParkingSpot
    );
  }

  setRandomVehiclePaired() {
    const nonParkedVehicles = this.nonParkedVehicles;
    const vacantParkingSpots = this.vacantParkingSpots;
    if (nonParkedVehicles.length === 0 || vacantParkingSpots.length === 0) {
      return;
    }
    const randomVehicle =
      nonParkedVehicles[Math.floor(Math.random() * nonParkedVehicles.length)];
    const randomParkingSpot =
      vacantParkingSpots[Math.floor(Math.random() * vacantParkingSpots.length)];
    this.setPairingActive(randomParkingSpot.id, randomVehicle.id);
  }

  setRandomVehicleUnpaired() {
    const pairedVehicles = this.pairedVehicles;
    if (pairedVehicles.length === 0) {
      return;
    }
    const randomVehicle =
      pairedVehicles[Math.floor(Math.random() * pairedVehicles.length)];
    this.setPairingInactive(
      randomVehicle.state.parkingSpotId,
      randomVehicle.id
    );
  }

  resetStore() {
    this.depotId = null;
    this.isLoading = false;
    this.parkingSpots = [];
    this.vuDevices = [];
    this.projectId = null;
  }
}

export default DepotStore;
