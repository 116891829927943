import React, { useState } from "react";

//Components
import AnalyticsAdvancedParamsPopup from "Screens/AnalyticsTool/AnalyticsParametersSelection/AnalyticsAdvancedParamsPopup";

//Utils
import { ReactComponent as AnalyticsAdvancedParametersIcon } from "Utils/Images/ProjectAnalytics/advanced-parameters-icon.svg";
import { Box, Button, Typography } from "@mui/material";

const AnalyticsAdvancedParamsSelection = () => {
  const [isParamsSelectionOpen, setIsParamsSelectionOpen] = useState(false);

  return (
    <Box height={"100%"}>
      <Button
        variant="toolmenu"
        onClick={() => setIsParamsSelectionOpen((prevValue) => !prevValue)}
      >
        <AnalyticsAdvancedParametersIcon />
        <Typography variant="toolmenu">Add Parameters</Typography>
      </Button>

      <AnalyticsAdvancedParamsPopup
        isParamsSelectionOpen={isParamsSelectionOpen}
        setIsParamsSelectionOpen={setIsParamsSelectionOpen}
      />
    </Box>
  );
};

export default AnalyticsAdvancedParamsSelection;

AnalyticsAdvancedParamsSelection.propTypes = {};
