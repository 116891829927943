import { useCallback, useContext } from "react";
import MainContext from "Contexts/MainContext";
import { setFpgaCommand } from "Utils/APIUtils";
import { defaultModalState } from "Utils/DefaultValues/ProjectViewDefaultValues";
import { getSessionIdProject } from "Utils/APIUtils";

const useSendMsg = (setModalState) => {
  const { selectedDevice } = useContext(MainContext);
  const sessionIdProject = getSessionIdProject();
  const sendPWMMessage = useCallback(
    (selectedDrawerSegment, selectedDrawer, selectedSegmentIndex) => {
      selectedDrawerSegment &&
        !selectedDrawerSegment.forcePwm &&
        setFpgaCommand({
          MUId: selectedDevice?.id,
          shelfNumber: selectedDrawer - 1,
          segmentNumber: selectedSegmentIndex,
          cmdType: "set_pwm_enable_segment",
          val: 1,
          sessionId: sessionIdProject,
        }).then((res) => {
          // TODO: what to do on then?
          if (res && res.status !== 200) {
          }
        });
      return setModalState(defaultModalState);
    },
    [sessionIdProject, setFpgaCommand, selectedDevice, defaultModalState]
  );

  const sendCommMessage = useCallback(
    (selectedDrawerSegment, selectedDrawer, selectedSegmentIndex) => {
      selectedDrawerSegment &&
        !selectedDrawerSegment.forceComm &&
        setFpgaCommand({
          MUId: selectedDevice?.id,
          shelfNumber: selectedDrawer - 1,
          segmentNumber: selectedSegmentIndex,
          cmdType: "set_com_on",
          val: 1,
          sessionId: sessionIdProject,
        }).then((res) => {
          // TODO: what to do on then?
          if (res && res.status !== 200) {
          }
        });
      return setModalState(defaultModalState);
    },
    [sessionIdProject, setFpgaCommand, selectedDevice?.id, defaultModalState]
  );

  const sendForceOnMessage = useCallback(
    (selectedDrawerSegment, selectedDrawer, selectedSegmentIndex) => {
      selectedDrawerSegment &&
        !selectedDrawerSegment.forceOn &&
        setFpgaCommand({
          MUId: selectedDevice?.id,
          shelfNumber: selectedDrawer - 1,
          segmentNumber: selectedSegmentIndex,
          cmdType: "set_force_on",
          val: 1,
          sessionId: sessionIdProject,
        }).then((res) => {
          // TODO: what to do on then?
          if (res && res.status !== 200) {
          }
        });
      return setModalState(defaultModalState);
    },
    [sessionIdProject, setFpgaCommand, selectedDevice?.id, defaultModalState]
  );

  return {
    sendCommMessage,
    sendPWMMessage,
    sendForceOnMessage,
  };
};

export default useSendMsg;
