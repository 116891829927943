import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";

//Components
import {
  LineChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceArea,
  Line,
} from "recharts";
import ZoomInfoDisplay from "Components/Graphs/ZoomInfoDisplay";
import GraphTooltip from "Components/Tooltip/GraphTooltip";

//Context
import VUsContext from "Contexts/VUsContext";

//Hooks
import useGraphFormat from "CustomHooks/useGraphFormat";
import useSelectedProjectData from "CustomHooks/useSelectedProject";
import useGraphZoom from "CustomHooks/useGraphZoom";

//Utils
import {
  formatDataForGraph,
  roundToNearest100,
  getMissingDataXsArray,
  defaultColorsForGraphs,
} from "Utils/GraphUtils";
import { muColorsForGraph } from "Utils/AnalyticUtils";

const MultipleLineGraph = ({
  graphData,
  graphName,
  handleGraphClick,
  YAxisUnit,
  selectedDevice,
  graphScale,
  isHistoryOn,
  isHistoryLoaded,
  xAxisMinSpanTimestamp,
  graphRef,
}) => {
  const { MUDevices, VUDevices } = useSelectedProjectData();
  const { activeVU } = useContext(VUsContext);

  const messageKeys = !!graphData
    ? Object.keys(graphData).filter(
        (MUId) =>
          graphData[MUId] &&
          !!graphData[MUId].data &&
          (MUId === (selectedDevice && selectedDevice.id) ||
            (activeVU && MUId === activeVU.id))
      )
    : [];

  const { formatXAxis, graphMaxTimestamp, ticksArray, XAxisTickCount } =
    useGraphFormat(graphData, messageKeys, graphScale, isHistoryOn, graphName);

  const formattedData = formatDataForGraph(
    graphData,
    messageKeys,
    graphName,
    graphMaxTimestamp,
    graphScale,
    undefined,
    isHistoryOn
  );

  const {
    isZoomed,
    isZooming,
    showZoomBox,
    zoomArea,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleZoomOut,
    zoomData,
    zoomTicksArray,
    historyZoomSkip,
    isZoomLoading,
    setIsZoomed,
    isZoomOutRef,
  } = useGraphZoom(
    formattedData,
    messageKeys,
    selectedDevice,
    false,
    handleGraphClick,
    graphName,
    undefined,
    graphRef
  );

  const missingDataXs = useMemo(
    () =>
      isZoomed
        ? getMissingDataXsArray(
            zoomData,
            xAxisMinSpanTimestamp,
            zoomData?.at(-1)?.timestamp,
            isHistoryOn,
            true
          )
        : getMissingDataXsArray(
            formattedData,
            xAxisMinSpanTimestamp,
            graphMaxTimestamp,
            isHistoryOn,
            isHistoryLoaded
          ),
    [
      isZoomed,
      formattedData,
      zoomData,
      isHistoryOn,
      graphMaxTimestamp,
      isHistoryLoaded,
      xAxisMinSpanTimestamp,
    ]
  );

  useEffect(() => {
    if (isHistoryOn) {
      setIsZoomed(false);
    }
  }, [graphData, setIsZoomed, isHistoryOn]);

  return (
    <>
      {isHistoryOn && isZoomed && (
        <div className="zoom-info-container">
          <ZoomInfoDisplay
            isLoading={isZoomLoading}
            startTimestamp={zoomData[0]?.timestamp}
            endTimestamp={zoomData?.at(-1)?.timestamp}
            handleZoomOut={handleZoomOut}
            skipSpanForZoom={historyZoomSkip}
          />
        </div>
      )}

      <ResponsiveContainer height="96%" width="100%">
        <LineChart
          width={1200}
          height={300}
          data={isHistoryOn && isZoomed ? zoomData : formattedData}
          margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={isHistoryOn ? handleMouseUp : handleGraphClick}
          ref={graphRef}
        >
          <XAxis
            dataKey="timestamp"
            type="number"
            tickFormatter={formatXAxis}
            domain={isZoomed ? ["dataMin", "dataMax"] : ["dataMin", 0]}
            ticks={
              isZoomed && zoomTicksArray.length ? zoomTicksArray : ticksArray
            }
            tickCount={Number(XAxisTickCount)}
          />
          <YAxis
            tickFormatter={(format) => {
              return format;
            }}
            domain={[
              0,
              (dataMax) => (dataMax <= 200 ? 200 : roundToNearest100(dataMax)),
            ]}
            orientation="left"
            label={{ value: YAxisUnit, angle: -90, position: "insideLeft" }}
          />
          {messageKeys &&
            messageKeys.map((key, index) => {
              const device =
                MUDevices.find((mu) => mu.id === key) ||
                VUDevices.find((vu) => vu.id === key);
              const lineColor =
                device?.deviceType === "VU"
                  ? defaultColorsForGraphs["defaultVU"]
                  : muColorsForGraph[index];
              return (
                <Line
                  type="monotone"
                  name={`${device?.name}`}
                  dataKey={`${key}`}
                  fill={lineColor}
                  stroke={lineColor}
                  stackId={index}
                  key={`${key}_${index}`}
                  dot={false}
                  isAnimationActive={false}
                />
              );
            })}
          {!isZooming ? (
            <Tooltip
              content={
                <GraphTooltip
                  graphType={graphName}
                  graphUnits={graphName === "current" ? "A" : "V"}
                />
              }
            />
          ) : null}
          {missingDataXs.length
            ? missingDataXs.map((data, index) => {
                return (
                  <ReferenceArea
                    fill={defaultColorsForGraphs["line"]}
                    isFront
                    fillOpacity={1}
                    x1={data.min}
                    x2={data.max}
                    key={index}
                  />
                );
              })
            : null}
          {showZoomBox && (
            <ReferenceArea
              x1={!isZoomOutRef.current ? zoomArea?.x1 : null}
              x2={!isZoomOutRef.current ? zoomArea?.x2 : null}
            />
          )}{" "}
          <CartesianGrid strokeDasharray="3 3" />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default MultipleLineGraph;

MultipleLineGraph.propTypes = {
  graphData: PropTypes.object,
  selectedDevice: PropTypes.object,
  graphScale: PropTypes.string,
  isHistoryOn: PropTypes.bool,
  isHistoryLoaded: PropTypes.bool,
  xAxisMinSpanTimestamp: PropTypes.number,
  graphRef: PropTypes.object,
  graphName: PropTypes.string,
  handleGraphClick: PropTypes.func,
  YAxisUnit: PropTypes.string,
};
