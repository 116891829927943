import React from "react";
import PropTypes from "prop-types";
import "Components/Inputs/DateInput.scss";

const DateInput = ({
  value,
  onChange,
  onFocus,
  onBlur,
  label,
  inputClassName,
}) => {
  return (
    <div className="app-date-input-wrapper">
      <label>{label} </label>
      <input
        className={`app-date-input ${inputClassName}`}
        type="date"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        max="2999-12-31"
      />
    </div>
  );
};

export default DateInput;

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  inputClassName: PropTypes.string,
};
