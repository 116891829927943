import React from "react";
import PropTypes from "prop-types";
import SelectButton from "Components/Buttons/SelectButton/SelectButton";
import AppButton from "Components/Buttons/AppButton/AppButton";
import { GRAPH_SCALE } from "Utils/GraphUtils";

const selectTimeSpanOptions = [
  { value: GRAPH_SCALE[5], label: GRAPH_SCALE[5] },
  { value: GRAPH_SCALE[15], label: GRAPH_SCALE[15] },
  { value: GRAPH_SCALE[30], label: GRAPH_SCALE[30] },
];

const LiveGraphOptions = ({
  isFrozen,
  graphScale,
  setGraphScale,
  handleFreezeGraph,
}) => {
  return (
    <div className="live-graph-options-wrapper">
      <SelectButton
        className="time-span-select"
        isDisabled={isFrozen}
        options={selectTimeSpanOptions}
        defaultValue={
          selectTimeSpanOptions.find(
            (spanOption) => spanOption.value === graphScale
          ) || selectTimeSpanOptions[0]
        }
        onChange={setGraphScale}
      />
      <AppButton
        text={!isFrozen ? "Freeze" : "Unfreeze"}
        className={`freeze-button ${isFrozen ? "frozen" : "not-frozen"}`}
        onClick={handleFreezeGraph}
      />
    </div>
  );
};
export default LiveGraphOptions;

LiveGraphOptions.propTypes = {
  graphScale: PropTypes.string,
  isFrozen: PropTypes.bool,
  setGraphScale: PropTypes.func,
  handleFreezeGraph: PropTypes.func,
};
