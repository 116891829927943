import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { getSessionIdProject } from "Utils/APIUtils";
import useSelectedProject from "CustomHooks/useSelectedProject";

import arrowRight from "Utils/Images/GlobalIcons/arrow-right-blue.svg";
import { isHistoryTimeInvalid } from "Utils/UtilsFunctions";
import "Components/Buttons/SpanRangeButtons/SpanRangeButton.scss";

const SpanRangeButton = ({ loadedTimeRange, onClick }) => {
  const sessionId = getSessionIdProject();
  const { timezoneStr } = useSelectedProject();
  const time = useMemo(() => moment().tz(timezoneStr).format("HH:mm:ss"), []);
  const dataToLoad = useMemo(() => {
    return {
      sessionId: sessionId,
      rangeSec: loadedTimeRange.span,
      projectTz: timezoneStr,
    };
  }, [sessionId, timezoneStr, loadedTimeRange.span]);

  const hasLoadedTimeRange = !(
    loadedTimeRange.date === "" &&
    loadedTimeRange.startTime === "" &&
    loadedTimeRange.span === ""
  );

  const isDisabled = useMemo(() => {
    return isHistoryTimeInvalid(
      loadedTimeRange.date,
      loadedTimeRange.startTime,
      2 * loadedTimeRange.span,
      time
    );
  }, [loadedTimeRange, time]);

  const onLoadFunction = useCallback(
    (spanToAdd) => {
      const newStartDate = moment(
        new Date(
          `${loadedTimeRange.date}T${loadedTimeRange.startTime}`
        ).getTime() +
          spanToAdd * 1000
      ).format("YYYY-MM-DD HH:mm:ss");
      onClick(
        {
          ...dataToLoad,
          startSec: `${newStartDate.split(" ")[0]}T${
            newStartDate.split(" ")[1]
          }Z`,
        },
        loadedTimeRange.span,
        newStartDate.split(" ")[0],
        newStartDate.split(" ")[1]
      );
    },
    [dataToLoad, loadedTimeRange, onClick]
  );

  return (
    <div className="skip-span-btn-wrapper">
      <div
        title="Previous Span"
        onClick={() => {
          if (!hasLoadedTimeRange) return;
          onLoadFunction(-loadedTimeRange.span);
        }}
        className={`span-skip-btn ${!hasLoadedTimeRange ? "disabled" : ""}`}
      >
        <img
          className="horizontal-flipped-img"
          src={arrowRight}
          width={12}
          height={12}
          alt="Previous Span Skip Button"
        />
      </div>
      <div
        title="Next Span"
        onClick={() => {
          if (
            isDisabled ||
            isHistoryTimeInvalid(
              loadedTimeRange.date,
              loadedTimeRange.startTime,
              2 * loadedTimeRange.span,
              time
            )
          )
            return;
          onLoadFunction(loadedTimeRange.span);
        }}
        className={`span-skip-btn ${
          !hasLoadedTimeRange || isDisabled ? "disabled" : ""
        }`}
      >
        <img
          src={arrowRight}
          width={12}
          height={12}
          alt="Next Span Skip Button"
        />
      </div>
    </div>
  );
};
export default SpanRangeButton;

SpanRangeButton.propTypes = {
  loadedTimeRange: PropTypes.object,
  onClick: PropTypes.func,
};
