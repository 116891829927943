import { useContext, useMemo } from "react";
import _ from "lodash";

//Context
import MainContext from "Contexts/MainContext";
import MUsContext from "Contexts/MUsContext";

//Components
import useRole from "CustomHooks/useRole";

//Utils
import { defaultShelfConfig } from "Utils/DefaultValues/ProjectViewDefaultValues";
import useLockState from "CustomHooks/useLockState";
import { getSessionIdProject } from "Utils/APIUtils";

const useSetupTab = (
  locTimeoutInstances,
  socketMessageDataSegments,
  selectedDrawer
) => {
  const { selectedDevice, userRole } = useContext(MainContext);
  const selectedDeviceId = selectedDevice?.id;
  const { fotaProgress } = useContext(MUsContext);
  const { canControlIdleOff, canControlFans } = useRole(userRole);
  const sessionId = getSessionIdProject();
  const lockState = useLockState(selectedDevice, locTimeoutInstances);

  const selectedDrawerConfiguration = useMemo(() => {
    const shelfData =
      socketMessageDataSegments &&
      socketMessageDataSegments[`fpga_${selectedDrawer - 1}`];
    const shelfConfig =
      shelfData && _.cloneDeep(shelfData["shelf_configuration"]);
    return shelfConfig || defaultShelfConfig;
  }, [socketMessageDataSegments, selectedDrawer]);

  return {
    selectedDevice,
    selectedDeviceId,
    selectedDrawerConfiguration,
    fotaProgress,
    sessionId,
    lockState,
    canControlFans,
    canControlIdleOff,
  };
};
export default useSetupTab;
