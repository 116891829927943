import { useContext, useEffect, useState } from "react";
import MainContext from "Contexts/MainContext";
import { getProjectUnitList } from "Utils/APIUtils";
import { limitMUList } from "Utils/DefaultValues/ProjectViewDefaultValues";
import MUsContext from "Contexts/MUsContext";

function useSelectedProjectData() {
  const { selectedProject, shouldAcquireToken } = useContext(MainContext);
  const { continuationToken } = useContext(MUsContext);
  const [MUDevices, setMUDevices] = useState([]);
  const [VUDevices, setVUDevices] = useState([]);
  const { id, name, timezoneStr } = selectedProject || {
    id: "",
    name: "",
    timezoneStr: "Asia/Jerusalem",
  };

  const getDevicesAPICall = () => {
    if (id) {
      getProjectUnitList({
        projectId: id,
        limit: limitMUList,
        continuationToken: continuationToken,
        unitType: "mu",
      })
        .then((res) => {
          if (res && res.data && res.data.devices) {
            setMUDevices(res.data.devices);
          }
        })
        .catch((error) => {
          console.error(error);
        });

      getProjectUnitList({
        projectId: id,
        limit: limitMUList,
        continuationToken: continuationToken,
        unitType: "vu",
      })
        .then((res) => {
          if (res && res.data && res.data.devices) {
            setVUDevices(res.data.devices);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (shouldAcquireToken) return;
    getDevicesAPICall();
  }, [selectedProject?.id, shouldAcquireToken]);

  return {
    MUDevices,
    VUDevices,
    selectedProject,
    projectId: id,
    projectName: name,
    timezoneStr,
  };
}

export default useSelectedProjectData;
