import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

//Components
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import NumericInput from "Components/NumericInput/NumerInput";
import SliderWrapper from "Components/SliderWrapper/SliderWrapper";
import AppButton from "Components/Buttons/AppButton/AppButton";
import ForceButtons from "Components/Buttons/ForceButtons/ForceButtons";
import CollapsibleButton from "Components/Buttons/CollasibleButton/CollapsibleButton";
import OffIdleButtons from "Components/Buttons/OffIdleButtons/OffIdleButtons";

//Hooks
import useDebounce from "CustomHooks/useDebounce";
import useRole from "CustomHooks/useRole";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";

//Utils
import collapseIcon from "Utils/Images/GlobalIcons/collapse_icon.svg";
import StatusIndication from "Components/StatusIndication/StatusIndication";
import { setFpgaCommand } from "Utils/APIUtils";
import {
  calculateSegmentResonantFrequency,
  calculateSegmentWorkingFrequency,
} from "Utils/UtilsFunctions";
import { isCollapseFeatureEnabled } from "Utils/featureFlags";
import { defaultModalState } from "Utils/DefaultValues/ProjectViewDefaultValues";

const SegmentControlPanel = ({
  selectedDrawerSegment,
  selectedDrawer,
  selectedSegmentIndex,
  sendForceOnMessage,
  sendCommMessage,
  setModalState,
  sendPWMMessage,
  isDisabled,
  sessionId,
}) => {
  const [RFStill, setRFStill] = useState(false);
  const [WFStill, setWFStill] = useState(false);
  const [DCStill, setDCStill] = useState(false);
  const [phaseStill, setPhaseStill] = useState(false);
  const [pendingApiCall, setPendingApiCall] = useState(false);
  const [
    selectedDrawerSegmentDataPresent,
    setSelectedDrawerSegmentDataPresent,
  ] = useState(selectedDrawerSegment);

  const { discretes } = useContext(SocketContext);
  const { userRole, selectedDevice } = useContext(MainContext);
  const { canChangeConfig, canChangePhase } = useRole(userRole);

  const { contactor_control } =
    discretes[selectedDevice.id]?.socketMessageData || {};

  useEffect(() => {
    if (
      selectedDrawerSegment &&
      !pendingApiCall &&
      !RFStill &&
      !WFStill &&
      !DCStill &&
      !phaseStill
    ) {
      setSelectedDrawerSegmentDataPresent(selectedDrawerSegment);
    }
  }, [selectedDrawerSegment]);

  useEffect(() => {
    setSelectedDrawerSegmentDataPresent(selectedDrawerSegment);
  }, [selectedDrawer, selectedSegmentIndex]);

  const debouncedSetFpgaCommand = useDebounce(
    setFpgaCommand,
    () => setTimeout(() => setPendingApiCall(false), 2000),
    500
  );

  return (
    <div className="segment-setting-panel">
      <div className="segment-settings-freq-calibrate-phase">
        <div className="segment-settings-freq-calibrate">
          <div className="segment-slider-data">
            <div className="selected-segment resonance-freq">
              <div className="selected-segment-name">RFreq</div>
              <SliderWrapper
                isDisabled={isDisabled || !canChangeConfig}
                onFocus={() => {
                  setRFStill(true);
                }}
                onBlur={() => {
                  setRFStill(false);
                }}
                value={Number(
                  selectedDrawerSegmentDataPresent.segmentResonantFrequency
                )}
                className="resonance-freq-slider"
                step={1}
                min={55}
                max={125}
                reverse
                onChange={(value) =>
                  setSelectedDrawerSegmentDataPresent({
                    ...selectedDrawerSegmentDataPresent,
                    segmentResonantFrequency: Number(value),
                  })
                }
                onAfterChange={(value) => {
                  if (value) {
                    setPendingApiCall(true);
                    setFpgaCommand({
                      MUId: selectedDevice.id,
                      shelfNumber: selectedDrawer - 1,
                      segmentNumber: selectedSegmentIndex,
                      cmdType: "set_resonance_freq",
                      val: Number(value),
                      sessionId: sessionId,
                    }).then((res) => {
                      setPendingApiCall(false);
                      if (res && res.status !== 200) {
                      }
                    });
                  }
                }}
                trackStyle={{ backgroundColor: "#CCDDFF" }}
                handleStyle={{
                  border: "1px solid rgba(0, 34, 102, 0.94)",
                  borderRadius: "12px",
                  height: "24px",
                  width: "24px",
                  top: "22%",
                }}
                railStyle={{ backgroundColor: "#002266", width: "90%" }}
              />
              <div className="selected-segment-value">
                <NumericInput
                  readOnly
                  isDisabled={isDisabled || !canChangeConfig}
                  isArrows
                  onClickMinusButton={() => {
                    if (
                      selectedDrawerSegmentDataPresent[
                        "segmentResonantFrequency"
                      ] >= 55 &&
                      selectedDrawerSegmentDataPresent[
                        "segmentResonantFrequency"
                      ] < 125
                    ) {
                      setSelectedDrawerSegmentDataPresent({
                        ...selectedDrawerSegmentDataPresent,
                        segmentResonantFrequency:
                          selectedDrawerSegmentDataPresent[
                            "segmentResonantFrequency"
                          ] + 1,
                      });
                      setPendingApiCall(true);
                      debouncedSetFpgaCommand({
                        MUId: selectedDevice.id,
                        shelfNumber: selectedDrawer - 1,
                        segmentNumber: selectedSegmentIndex,
                        cmdType: "set_resonance_freq",
                        val: Number(
                          selectedDrawerSegmentDataPresent.segmentResonantFrequency +
                            1
                        ),
                        sessionId: sessionId,
                      });
                    }
                  }}
                  onClickPlusButton={() => {
                    if (
                      selectedDrawerSegmentDataPresent[
                        "segmentResonantFrequency"
                      ] > 55 &&
                      selectedDrawerSegmentDataPresent[
                        "segmentResonantFrequency"
                      ] <= 125
                    ) {
                      setSelectedDrawerSegmentDataPresent({
                        ...selectedDrawerSegmentDataPresent,
                        segmentResonantFrequency:
                          selectedDrawerSegmentDataPresent[
                            "segmentResonantFrequency"
                          ] - 1,
                      });
                      setPendingApiCall(true);
                      debouncedSetFpgaCommand({
                        MUId: selectedDevice.id,
                        shelfNumber: selectedDrawer - 1,
                        segmentNumber: selectedSegmentIndex,
                        cmdType: "set_resonance_freq",
                        val: Number(
                          selectedDrawerSegmentDataPresent.segmentResonantFrequency -
                            1
                        ),
                        sessionId: sessionId,
                      });
                    }
                  }}
                  value={calculateSegmentResonantFrequency(
                    selectedDrawerSegmentDataPresent
                  )}
                  name="resonance-freq"
                  label="" //kHz
                  onMouseEnter={() => {
                    // setRFStill(true);
                  }}
                  onMouseLeave={() => {
                    // setRFStill(false);
                  }}
                  unit="kHz"
                />
                <AppButton
                  isDisabled={isDisabled || !canChangeConfig}
                  text="Set All"
                  width="103"
                  className={`set-all-freq ${isDisabled ? "disabled" : ""}`}
                  onClick={() => {
                    setModalState({
                      isModalOpened: true,
                      modalMessage: `This action will change the values for all the segments in the Rack. Do you will to proceed?`,
                      executeOnCancel: () => {
                        return setModalState(defaultModalState);
                      },
                      executeOnSubmit: () => {
                        setPendingApiCall(true);
                        setFpgaCommand({
                          MUId: selectedDevice.id,
                          shelfNumber: selectedDrawer - 1,
                          segmentNumber: selectedSegmentIndex,
                          cmdType: "set_all_resonance_freq",
                          val: selectedDrawerSegmentDataPresent[
                            "segmentResonantFrequency"
                          ],
                          sessionId: sessionId,
                        }).then((res) => {
                          setPendingApiCall(false);
                          // TODO: what to do on then?
                          if (res && res.status !== 200) {
                            // add massage ?
                          }
                        });
                        return setModalState(defaultModalState);
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <AppButton
            isDisabled={isDisabled || !canChangeConfig}
            className={`calibrate-SD ${isDisabled ? "disabled" : ""}`}
            text="Calibrate SD"
            onClick={() => {
              if (sessionId && !contactor_control && canChangeConfig) {
                setPendingApiCall(true);
                setFpgaCommand({
                  MUId: selectedDevice.id,
                  shelfNumber: selectedDrawer - 1,
                  segmentNumber: selectedSegmentIndex,
                  cmdType: "set_calibrate",
                  val: 1,
                  sessionId: sessionId,
                }).then((res) => {
                  setPendingApiCall(false);
                  // TODO: what to do on then?
                  if (res && res.status !== 200) {
                  }
                });
              }
            }}
          />
        </div>
        <div className="segment-settings-phase">
          <NumericInput
            value={selectedDrawerSegmentDataPresent.segmentPhase}
            isDisabled={isDisabled || !canChangePhase}
            label="Phase"
            max={255}
            min={0}
            step={1}
            name="phase"
            onChange={(e) => {
              setSelectedDrawerSegmentDataPresent({
                ...selectedDrawerSegmentDataPresent,
                segmentPhase: Number(e.target.value),
              });
              setPendingApiCall(true);
              debouncedSetFpgaCommand({
                MUId: selectedDevice.id,
                shelfNumber: selectedDrawer - 1,
                segmentNumber: selectedSegmentIndex,
                cmdType: "set_segment_phase",
                val: Number(e.target.value),
                sessionId: sessionId,
              });
            }}
            onFocus={() => {
              setPhaseStill(true);
            }}
            onBlur={() => {
              setPhaseStill(false);
            }}
          />
        </div>
      </div>
      {isCollapseFeatureEnabled && <CollapsibleButton icon={collapseIcon} />}
      <div
        className={`segment-settings-forces ${
          isCollapseFeatureEnabled ? "collapsible-content" : ""
        }`}
      >
        <div className="segment-settings-sliders-checkboxes">
          <div className="segment-settings-force-checkboxes">
            <ProjectDeviceHeader
              title="Debug"
              className="project-device-header-i"
              infoIconDataFor=""
            />
            <StatusIndication selectedDrawerSegment={selectedDrawerSegment} />
            <div className={"project-device-setup-selected-segment-buttons-i"}>
              <OffIdleButtons
                offButtonTitle="Force Off"
                isDisabled={isDisabled}
                offButtonOnClick={() => {
                  if (!selectedDrawerSegmentDataPresent.forceOff) {
                    setSelectedDrawerSegmentDataPresent({
                      ...selectedDrawerSegmentDataPresent,
                      forceOff: 1,
                    });
                    setPendingApiCall(true);
                    setFpgaCommand({
                      MUId: selectedDevice.id,
                      shelfNumber: selectedDrawer - 1,
                      segmentNumber: selectedSegmentIndex,
                      cmdType: "set_force_off",
                      val: 1,
                      sessionId: sessionId,
                    }).then((res) => {
                      setPendingApiCall(false);
                      if (res && res.status !== 200) {
                      }
                    });
                  }
                }}
                idleButtonTitle="Idle"
                idleButtonOnClick={() => {
                  setPendingApiCall(true);
                  setFpgaCommand({
                    MUId: selectedDevice.id,
                    shelfNumber: selectedDrawer - 1,
                    segmentNumber: selectedSegmentIndex,
                    cmdType: "set_clear_segment_status",
                    val: 1,
                    sessionId: sessionId,
                  }).then((res) => {
                    setPendingApiCall(false);
                    if (res && res.status !== 200) {
                      //TODO: what to do on then?
                    }
                  });
                }}
              />
            </div>
          </div>
          <div className="segment-settings-sliders-forces-buttons">
            <div className="segment-settings-sliders">
              <div className="segment-slider-data">
                <div className="selected-segment working-freq">
                  <div className="selected-segment-name">WFreq</div>
                  <SliderWrapper
                    isDisabled={isDisabled || !canChangeConfig}
                    onFocus={() => {
                      setWFStill(true);
                    }}
                    onBlur={() => {
                      setWFStill(false);
                    }}
                    min={1}
                    max={140}
                    step={1}
                    value={
                      selectedDrawerSegmentDataPresent.segmentWorkingFrequency
                    }
                    className="working-freq-slider"
                    onChange={(value) => {
                      setSelectedDrawerSegmentDataPresent({
                        ...selectedDrawerSegmentDataPresent,
                        segmentWorkingFrequency: Number(value),
                      });
                    }}
                    onAfterChange={(value) => {
                      if (value) {
                        setPendingApiCall(true);
                        setFpgaCommand({
                          MUId: selectedDevice.id,
                          shelfNumber: selectedDrawer - 1,
                          segmentNumber: selectedSegmentIndex,
                          cmdType: "set_freq",
                          val: Number(value),
                          sessionId: sessionId,
                        }).then((res) => {
                          setPendingApiCall(false);
                          if (res && res.status !== 200) {
                          }
                        });
                      }
                    }}
                    reverse
                    railStyle={{ backgroundColor: "#002266", width: "90%" }}
                    trackStyle={{ backgroundColor: "#CCDDFF" }}
                    handleStyle={{
                      border: "1px solid rgba(0, 34, 102, 0.94)",
                      borderRadius: "12px",
                      height: "24px",
                      width: "24px",
                      top: "22%",
                    }}
                  />
                  <div className="selected-segment-value">
                    <NumericInput
                      readOnly
                      unit="kHz"
                      onClickMinusButton={() => {
                        if (
                          selectedDrawerSegmentDataPresent[
                            "segmentWorkingFrequency"
                          ] >= 1 &&
                          selectedDrawerSegmentDataPresent[
                            "segmentWorkingFrequency"
                          ] < 140
                        ) {
                          setSelectedDrawerSegmentDataPresent({
                            ...selectedDrawerSegmentDataPresent,
                            segmentWorkingFrequency:
                              selectedDrawerSegmentDataPresent[
                                "segmentWorkingFrequency"
                              ] + 1,
                          });
                          setPendingApiCall(true);
                          debouncedSetFpgaCommand({
                            MUId: selectedDevice.id,
                            shelfNumber: selectedDrawer - 1,
                            segmentNumber: selectedSegmentIndex,
                            cmdType: "set_freq",
                            val: Number(
                              selectedDrawerSegmentDataPresent.segmentWorkingFrequency +
                                1
                            ),
                            sessionId: sessionId,
                          });
                        }
                      }}
                      onClickPlusButton={() => {
                        if (
                          selectedDrawerSegmentDataPresent[
                            "segmentWorkingFrequency"
                          ] > 1 &&
                          selectedDrawerSegmentDataPresent[
                            "segmentWorkingFrequency"
                          ] <= 140
                        ) {
                          setSelectedDrawerSegmentDataPresent({
                            ...selectedDrawerSegmentDataPresent,
                            segmentWorkingFrequency:
                              selectedDrawerSegmentDataPresent[
                                "segmentWorkingFrequency"
                              ] - 1,
                          });
                          setPendingApiCall(true);
                          debouncedSetFpgaCommand({
                            MUId: selectedDevice.id,
                            shelfNumber: selectedDrawer - 1,
                            segmentNumber: selectedSegmentIndex,
                            cmdType: "set_freq",
                            val: Number(
                              selectedDrawerSegmentDataPresent.segmentWorkingFrequency -
                                1
                            ),
                            sessionId: sessionId,
                          });
                        }
                      }}
                      isArrows
                      value={calculateSegmentWorkingFrequency(
                        selectedDrawerSegmentDataPresent
                      )}
                      isDisabled={isDisabled || !canChangeConfig}
                      name="working-freq"
                      onMouseEnter={() => {
                        // setWFStill(true);
                      }}
                      onMouseLeave={() => {
                        // setWFStill(false);
                      }}
                      label="" //kHz
                    />
                  </div>
                </div>
              </div>

              <div className="segment-slider-data">
                <div className="selected-segment duty-cycle">
                  <div className="selected-segment-name">Duty Cycle</div>
                  <SliderWrapper
                    isDisabled={
                      isDisabled ||
                      !selectedDrawerSegment.forceOn ||
                      !canChangeConfig
                    }
                    className="duty-cycle-slider"
                    onFocus={() => {
                      setDCStill(true);
                    }}
                    onBlur={() => {
                      setDCStill(false);
                    }}
                    step={1}
                    max={100}
                    min={0}
                    value={
                      selectedDrawerSegmentDataPresent &&
                      selectedDrawerSegmentDataPresent.segmentDutyCycle
                        ? selectedDrawerSegmentDataPresent.segmentDutyCycle
                        : 0
                    }
                    onChange={(value) => {
                      setSelectedDrawerSegmentDataPresent({
                        ...selectedDrawerSegmentDataPresent,
                        segmentDutyCycle: Number(value),
                      });
                    }}
                    onAfterChange={(value) => {
                      if (value) {
                        setPendingApiCall(true);
                        setFpgaCommand({
                          MUId: selectedDevice.id,
                          shelfNumber: selectedDrawer - 1,
                          segmentNumber: selectedSegmentIndex,
                          cmdType: "set_duty_cycle",
                          val: Number(value),
                          sessionId: sessionId,
                        }).then((res) => {
                          setPendingApiCall(false);
                          if (res && res.status !== 200) {
                          }
                        });
                      }
                    }}
                    reverse={false}
                    trackStyle={{ backgroundColor: "#002266" }}
                    handleStyle={{
                      border: "1px solid rgba(0, 34, 102, 0.94)",
                      borderRadius: "12px",
                      height: "24px",
                      width: "24px",
                      top: "22%",
                    }}
                    railStyle={{ backgroundColor: "#CCDDFF" }}
                  />
                  <div className="selected-segment-value">
                    <NumericInput
                      isDisabled={
                        isDisabled ||
                        !selectedDrawerSegment.forceOn ||
                        !canChangeConfig
                      }
                      isArrows
                      onClickMinusButton={() => {
                        if (
                          selectedDrawerSegmentDataPresent["segmentDutyCycle"] >
                            0 &&
                          selectedDrawerSegmentDataPresent[
                            "segmentDutyCycle"
                          ] <= 100
                        ) {
                          setSelectedDrawerSegmentDataPresent({
                            ...selectedDrawerSegmentDataPresent,
                            segmentDutyCycle:
                              selectedDrawerSegmentDataPresent[
                                "segmentDutyCycle"
                              ] - 1,
                          });
                          setPendingApiCall(true);
                          debouncedSetFpgaCommand({
                            MUId: selectedDevice.id,
                            shelfNumber: selectedDrawer - 1,
                            segmentNumber: selectedSegmentIndex,
                            cmdType: "set_duty_cycle",
                            val: Number(
                              selectedDrawerSegmentDataPresent.segmentDutyCycle -
                                1
                            ),
                            sessionId: sessionId,
                          });
                        }
                      }}
                      onClickPlusButton={() => {
                        if (
                          selectedDrawerSegmentDataPresent[
                            "segmentDutyCycle"
                          ] >= 0 &&
                          selectedDrawerSegmentDataPresent["segmentDutyCycle"] <
                            100
                        ) {
                          setSelectedDrawerSegmentDataPresent({
                            ...selectedDrawerSegmentDataPresent,
                            segmentDutyCycle:
                              selectedDrawerSegmentDataPresent[
                                "segmentDutyCycle"
                              ] + 1,
                          });
                          setPendingApiCall(true);
                          debouncedSetFpgaCommand({
                            MUId: selectedDevice.id,
                            shelfNumber: selectedDrawer - 1,
                            segmentNumber: selectedSegmentIndex,
                            cmdType: "set_duty_cycle",
                            val: Number(
                              selectedDrawerSegmentDataPresent.segmentDutyCycle +
                                1
                            ),
                            sessionId: sessionId,
                          });
                        }
                      }}
                      value={
                        selectedDrawerSegmentDataPresent &&
                        selectedDrawerSegmentDataPresent.segmentDutyCycle
                          ? Number(
                              selectedDrawerSegmentDataPresent.segmentDutyCycle
                            )
                          : 0
                      }
                      max={100}
                      min={0}
                      step={1}
                      name="duty-cycle"
                      readOnly
                      label=""
                      onMouseEnter={() => {
                        // setDCStill(true);
                      }}
                      onMouseLeave={() => {
                        // setDCStill(false);
                      }}
                      unit="%"
                    />
                  </div>
                </div>
              </div>
            </div>
            <AppButton
              className="set-all-freq"
              text="Set All"
              isDisabled={isDisabled || !canChangeConfig}
              width="103"
              onClick={() => {
                setModalState({
                  isModalOpened: true,
                  modalMessage: `This action will change the values for all the segments in the Rack. Do you will to proceed?`,
                  executeOnCancel: () => {
                    return setModalState(defaultModalState);
                  },
                  executeOnSubmit: () => {
                    setPendingApiCall(true);
                    setFpgaCommand({
                      MUId: selectedDevice.id,
                      shelfNumber: selectedDrawer - 1,
                      segmentNumber: selectedSegmentIndex,
                      cmdType: "set_all_freq",
                      val: selectedDrawerSegmentDataPresent[
                        "segmentWorkingFrequency"
                      ],
                      sessionId: sessionId,
                    }).then((res) => {
                      setPendingApiCall(false);
                      // TODO: what to do on then?
                      if (res && res.status !== 200) {
                        //send massage?
                      }
                    });
                    return setModalState(defaultModalState);
                  },
                });
              }}
            />
            <ForceButtons
              setModalState={setModalState}
              sendCommMessage={sendCommMessage}
              selectedDrawer={selectedDrawer}
              selectedSegmentIndex={selectedSegmentIndex}
              sendPWMMessage={sendPWMMessage}
              sendForceOnMessage={sendForceOnMessage}
              selectedDrawerSegmentDataPresent={selectedDrawerSegment}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentControlPanel;

SegmentControlPanel.propTypes = {
  selectedDrawerSegment: PropTypes.object,
  selectedDrawer: PropTypes.number,
  selectedSegmentIndex: PropTypes.number,
  sendForceOnMessage: PropTypes.func,
  sendCommMessage: PropTypes.func,
  setModalState: PropTypes.func,
  sendPWMMessage: PropTypes.func,
  isDisabled: PropTypes.bool,
  sessionId: PropTypes.string,
};
