import { useContext } from "react";
import SocketContext from "Contexts/SocketContext";
import { getSessionIdProject } from "Utils/APIUtils";
import MainContext from "Contexts/MainContext";

const useLockState = (device, locTimeoutInstances) => {
  const { lockInfo } = useContext(SocketContext);
  const { currentUser } = useContext(MainContext);
  const {
    idTokenClaims: { sub },
  } = currentUser;
  const sessionId = getSessionIdProject();
  if (
    device &&
    device.connectionStatus &&
    device.connectionStatus.toUpperCase().trim() === "DISCONNECTED"
  ) {
    return { setupDisabled: true };
  }
  // TODO: if device not connected dont call the function at all.
  const deviceId = device ? device.id : "";
  const lockInfoData = device && device.lockInfo;
  let { userId, displayName, expiresAt } =
    lockInfo && deviceId && lockInfo[deviceId]
      ? lockInfo[deviceId]
      : lockInfoData
      ? lockInfoData
      : "";

  const sessionLocked =
    lockInfo && deviceId && lockInfo[deviceId]
      ? lockInfo[deviceId]["sessionId"]
      : "";

  const isLockedByMe =
    !!Object.keys(lockInfo).length &&
    device &&
    sessionId &&
    userId === sub &&
    sessionLocked === sessionId;

  const isLockExpired = isLockedByMe
    ? false
    : locTimeoutInstances && locTimeoutInstances[deviceId]
    ? locTimeoutInstances[deviceId]["isExpired"]
    : false;
  const setupDisabled = !userId || (userId && !isLockedByMe);
  const isLocked = !!sessionLocked && !isLockExpired;
  return {
    isLockedByMe,
    isLocked,
    lockedBy: displayName,
    setupDisabled,
    expiresAt,
  };
}
export default useLockState;
