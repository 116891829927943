import { LogLevel } from "@azure/msal-browser";
import { b2cPolicies, nodeEnv, appClientId } from "azure/policies";

const AzureActiveDirectoryAppClientId = appClientId;
const windowLocation = window.location;
const hostName = windowLocation.hostname;
const protocolName = windowLocation.protocol;
const portNum = windowLocation.port;
const protocolHost = `${protocolName}//${hostName}`;

export const MSAL_CONFIG = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri:
      window.location.hostname === "localhost"
        ? `${protocolHost}:${portNum}`
        : `${protocolHost}`,
    knownAuthorities: [`electreoncloudb2c${nodeEnv}.b2clogin.com`],
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    useRefreshTokens: true,
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
