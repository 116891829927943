import React, { useContext } from "react";
import PropTypes from "prop-types";

//Components
import Segment from "Screens/ProjectView/Devices/MU/Segment/Segment";
import SliderWrapper from "Components/SliderWrapper/SliderWrapper";
import Image from "Components/Image/Image";
import ForceButtons from "Components/Buttons/ForceButtons/ForceButtons";
import OffIdleButtons from "Components/Buttons/OffIdleButtons/OffIdleButtons";

//Context
import SocketContext from "Contexts/SocketContext";
import MainContext from "Contexts/MainContext";

//Hooks
import useLockState from "CustomHooks/useLockState";

//Utils
import { getIsDeviceDisconnected } from "Utils/UtilsFunctions";
import forceRFIcon from "Utils/Images/ProjectMaintanance/segment-force-rf-icon.svg";
import { getSessionIdProject, setFpgaCommand } from "Utils/APIUtils";
import { segmentsMock } from "Utils/MockData/ProjectMockData";
import chargingIcon from "Utils/Images/ProjectMaintanance/duty-cycle-charging-icon.svg";
import { getSegmentValue } from "Utils/UtilsFunctions";
import "Screens/ProjectView/PerformanceTab/MuPerformance/AllSegments.scss";

const drawers = [1, 2, 3, 4, 5].slice(0).reverse();

const AllSegments = ({
  socketMessageDataSegments,
  selectedSegmentIndex,
  setSelectedSegmentIndex,
  setModalState,
  sendCommMessage,
  sendPWMMessage,
  sendForceOnMessage,
}) => {
  const { selectedDevice } = useContext(MainContext);
  const { connectionState } = useContext(SocketContext);

  const sessionId = getSessionIdProject();
  const isDeviceOffline = getIsDeviceDisconnected(
    connectionState,
    selectedDevice
  );
  const lockState = useLockState(selectedDevice, null);
  const { setupDisabled } = lockState;

  return (
    <div
      className={`project-graph-selected-drawer-segments ${
        isDeviceOffline ? "gray-layout" : ""
      }`}
    >
      {drawers.map((drawerNumber, drawerIndex) => {
        // TODO: get this data ready and don't remove the shelf_config here!!
        if (
          socketMessageDataSegments &&
          socketMessageDataSegments[`fpga_${drawerNumber - 1}`]
        ) {
          const drawerData =
            socketMessageDataSegments &&
            Object.values(
              socketMessageDataSegments[`fpga_${drawerNumber - 1}`]
            );

          let drawerSegments =
            drawerData &&
            drawerData.filter(
              (element, index) => index < drawerData.length - 1
            );
          if (!drawerSegments) {
            drawerSegments = segmentsMock["fpga_0"];
          }

          return drawerSegments ? (
            Object.keys(drawerSegments)
              .slice(0)
              .reverse()
              .map((selectedDrawerSegment, selectedDrawerSegmentIndex) => {
                const currentSegment = drawerSegments[selectedDrawerSegment];
                const currentSegmentIndicationFreq =
                  currentSegment && currentSegment.segmentIndFrequency
                    ? currentSegment.segmentIndFrequency
                    : 0;
                const currentSegmentDutyCycle = currentSegment.segmentDutyCycle;
                const commDuration = currentSegment.commDuration;
                const segmentNewIndex = 12 - selectedDrawerSegmentIndex - 1;
                const segmentId =
                  drawerNumber === 1
                    ? segmentNewIndex + 1
                    : segmentNewIndex + 1 + 12 * (drawerNumber - 1);
                const currentSegmentDisabled =
                  currentSegment.segmentStatus === 255;
                const currentSegmentNotDetected = false;
                return (
                  <div
                    className={`graph-selected-drawer-segment ${
                      selectedSegmentIndex === segmentNewIndex ? "selected" : ""
                    }`}
                    id={`SD_${segmentId}`}
                    key={segmentId}
                    onClick={() => setSelectedSegmentIndex(segmentNewIndex)}
                  >
                    <div className="graph-selected-drawer-segment-status-and-control">
                      <Segment
                        segmentStatus={currentSegment?.segmentStatus}
                        className="mu-performance-all-segments-status"
                        value={getSegmentValue(drawerNumber, segmentNewIndex)}
                      />
                      {!currentSegmentNotDetected && (
                        <div className="graph-selected-drawer-segment-indication-freq">
                          {currentSegmentDisabled
                            ? 0
                            : currentSegmentIndicationFreq}{" "}
                          kHz
                        </div>
                      )}
                      {!currentSegmentNotDetected && (
                        <div className="off-and-idle-buttons">
                          <OffIdleButtons
                            offButtonTitle="Force Off"
                            offButtonOnClick={() => {
                              if (!selectedDrawerSegment.forceOff) {
                                setFpgaCommand({
                                  MUId: selectedDevice.id,
                                  shelfNumber: drawerNumber - 1,
                                  segmentNumber: segmentNewIndex,
                                  cmdType: "set_force_off",
                                  val: 1,
                                  sessionId: sessionId,
                                }).then((res) => {
                                  // TODO: what to do on then?
                                  if (res && res.status !== 200) {
                                  }
                                });
                              }
                            }}
                            isDisabled={setupDisabled || currentSegmentDisabled}
                            idleButtonTitle="Idle"
                            idleButtonOnClick={() => {
                              setFpgaCommand({
                                MUId: selectedDevice.id,
                                shelfNumber: drawerNumber - 1,
                                segmentNumber: segmentNewIndex,
                                cmdType: "set_clear_segment_status",
                                val: 1,
                                sessionId: sessionId,
                              }).then((res) => {
                                // TODO: what to do on then?
                                if (res && res.status !== 200) {
                                }
                              });
                            }}
                          />
                        </div>
                      )}
                      {!currentSegmentNotDetected && (
                        <ForceButtons
                          isDisabled={setupDisabled || currentSegmentDisabled}
                          setModalState={setModalState}
                          sendCommMessage={sendCommMessage}
                          selectedDrawer={drawerNumber}
                          selectedSegmentIndex={segmentNewIndex}
                          sendPWMMessage={sendPWMMessage}
                          sendForceOnMessage={sendForceOnMessage}
                          selectedDrawerSegmentDataPresent={
                            selectedDrawerSegment
                          }
                        />
                      )}
                      {!currentSegmentNotDetected && (
                        <div className="segment-operational-mode-info">
                          <div className="duty-cycle-percentage-bar">
                            <SliderWrapper
                              handleStyle={{ display: "none" }}
                              trackStyle={{
                                backgroundColor: "rgba(0, 34, 102, 0.94)",
                              }}
                              railStyle={{
                                backgroundColor: "#CCDDFF",
                              }}
                              value={
                                currentSegmentDutyCycle
                                  ? Number(currentSegmentDutyCycle.toFixed(1))
                                  : 0
                              }
                              className="graph-segment-duty-cycle-slider"
                              step={1}
                              min={0}
                              max={100}
                            />
                            <Image
                              src={chargingIcon}
                              className="duty-cycle-icon"
                            />
                            <div className="graph-segment-duty-cycle-value">
                              {currentSegmentDutyCycle &&
                                currentSegmentDutyCycle.toFixed(1)}
                              <span>%</span>
                            </div>
                          </div>
                          <div className="RF-percentage-bar">
                            <SliderWrapper
                              handleStyle={{ display: "none" }}
                              trackStyle={{
                                backgroundColor: "rgba(0, 34, 102, 0.94)",
                              }}
                              railStyle={{
                                backgroundColor: "#CCDDFF",
                              }}
                              value={
                                commDuration
                                  ? Number(commDuration.toFixed(0))
                                  : 0
                              }
                              className="graph-segment-duty-cycle-slider"
                              step={1}
                              min={0}
                              max={100}
                            />
                            <Image
                              src={forceRFIcon}
                              className="force-rf-icon"
                            />
                            <div className="graph-segment-RF-value">
                              {commDuration ? commDuration.toFixed(0) : 0}
                              <span>%</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
          ) : (
            <div> No Segments Found </div>
          );
        }
      })}
    </div>
  );
};

export default AllSegments;

AllSegments.propTypes = {
  socketMessageDataSegments: PropTypes.object,
  selectedSegmentIndex: PropTypes.number,
  setSelectedSegmentIndex: PropTypes.func,
  setModalState: PropTypes.func,
  sendCommMessage: PropTypes.func,
  sendPWMMessage: PropTypes.func,
  sendForceOnMessage: PropTypes.func,
};
