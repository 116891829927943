import React, { useContext, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import axios from "axios";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";
import { Puff } from "react-loader-spinner";
import LogRocket from "logrocket";

//Components
import Logo from "Components/Logo/Logo";
import ElectreonLoginButton from "Components/Login/ElectreonLoginButton";
import LoginButton from "Components/Login/LoginButton";
import { ErrorMessage } from "Screens/Login/LoginConstants";

//Context
import MainContext from "Contexts/MainContext";
import { useAppStore } from "MobxStores/context";

//Utils
import { execLogIn, getAccessToken, handleSignOut } from "Utils/APIUtils";
import { getClaimsFromToken } from "Utils/UtilsFunctions";
import "Screens/Login/login.scss";

const Login = () => {
  const [loginStatusMessage, setLoginStatusMessage] = useState(
    ErrorMessage.noError
  );
  const { authenticationModule, mainDispatch, isLoading } =
    useContext(MainContext);
  const { userStore, projectStore } = useAppStore();
  const history = useHistory();
  const { location } = history;

  const handleError = () => {
    mainDispatch({
      type: "SET_IS_LOADING",
      payload: { isLoading: false },
    });
    handleSignOut(mainDispatch, history, authenticationModule, projectStore);
  };

  const setUserInfo = (user) => {
    console.log(
      `setUserInfo from Login to ${user?.idTokenClaims?.extension_UserRole?.trim()}`
    );

    userStore.setUserData(user);
    userStore.updateRole(user?.idTokenClaims?.extension_UserRole?.trim());

    mainDispatch({
      type: "SET_USER",
      payload: {
        currentUser: user,
        authenticated: !!user?.username,
        userRole: user?.idTokenClaims?.extension_UserRole?.trim(),
        userScope: user?.idTokenClaims?.extension_ProjectScope?.trim(),
      },
      isLoading: false,
    });
  };

  const setAuthenticatedUser = (accessToken, refreshToken, account = "") => {
    localStorage.setItem("accessToken", accessToken);
    typeof authenticationModule.setAccount === "function" &&
      authenticationModule.setAccount(account, setUserInfo);
    const bearer = "Bearer " + accessToken;
    axios.defaults.headers.common["Authorization"] = bearer;
    const userAuthenticated = !!(account && account.name);
    if (userAuthenticated) {
      // console.log(account,"account");
      LogRocket.identify(account?.localAccountId, {
        name: account?.idTokenClaims?.name || account?.name,
        email: account?.username,

        // Add your own custom user variables here, ie:
        userRole: account?.idTokenClaims?.extension_UserRole?.trim(),
        userScope: account?.idTokenClaims?.extension_ProjectScope?.trim(),
        accountType: account?.idTokenClaims?.idp,
      });
      if (location.pathname === "/" || location.pathname === "") {
        if (
          account &&
          (account.username === "fiat@electreon.com" ||
            account.username === "iveco@electreon.com") //check and remove if not needed
        ) {
          // history.push("/dashboard/live/project/87");
        } else {
          history.push("/dashboard");
        }
      }
    }
  };

  useEffect(() => {
    history.block(() => {
      const accessToken = getAccessToken();
      if (
        !accessToken &&
        (location.pathname === "/" || location.pathname === "")
      ) {
        return false;
      }
      return true;
    });
    // handleLoginRedirect
    authenticationModule.myMSALObj
      .handleRedirectPromise()
      .then((res) => {
        const prevAccessToken = localStorage.getItem("prevAccessToken");
        const isPrevTokenLocal = getClaimsFromToken(prevAccessToken)
          ?.iss?.toLowerCase()
          .includes("local_account");
        if (
          res &&
          !isPrevTokenLocal &&
          res.accessToken &&
          res.accessToken !== localStorage.getItem("prevAccessToken")
        ) {
          setAuthenticatedUser(res.accessToken, "", res.account);
        }
      })
      .then((res) => {
        mainDispatch({
          type: "SET_IS_LOADING",
          payload: { isLoading: false },
        });
      })
      .catch((err) => {
        handleError(err);
      });
  }, []);

  const handleLogin = useCallback(
    (values) => {
      setLoginStatusMessage(ErrorMessage.noError);
      return execLogIn("loginROPC", authenticationModule, setUserInfo, {
        username: values.username,
        password: values.password,
      }).then((res) => {
        if (res && res.status === 200) {
          if (
            res &&
            res.account &&
            (res.account.username === "`fiat`@electreon.com" ||
              res.account.username === "iveco@electreon.com")
          ) {
            // history.push("/dashboard/live/project/87");
          } else {
            history.push("/dashboard");
          }
          mainDispatch({
            type: "SET_IS_LOADING",
            payload: { isLoading: false },
          });
        } else {
          setLoginStatusMessage(ErrorMessage.noError);
          if (res.response && res.response.status === 400) {
            setLoginStatusMessage(ErrorMessage.wrongCredentials);
          } else {
            setLoginStatusMessage(ErrorMessage.loginAttemptFailed);
          }
          handleError();
        }
      });
    },
    [authenticationModule]
  );

  const handleElectreonLogin = useCallback(() => {
    return execLogIn("loginRedirect", authenticationModule, setUserInfo);
  }, [authenticationModule]);

  return isLoading ? (
    <Puff color="#00BFFF" height={100} width={100} timeout={3000} />
  ) : (
    <div className="login-wrapper">
      <div className="login-inner-wrapper">
        <Logo />
        {authenticationModule &&
          authenticationModule.isAuthenticationConfigured && (
            <>
              <Form onSubmit={(values) => handleLogin(values)}>
                {({ handleSubmit, submitting }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="local-account-login-form"
                  >
                    <Field
                      name="username"
                      component="input"
                      placeholder="User Name"
                      className={
                        loginStatusMessage === ErrorMessage.wrongCredentials
                          ? "error-border"
                          : ""
                      }
                      validate={(value) => (value ? undefined : "Required")}
                    />
                    <Field
                      name="password"
                      component="input"
                      type="password"
                      className={
                        loginStatusMessage === ErrorMessage.wrongCredentials
                          ? "error-border"
                          : ""
                      }
                      placeholder="Password"
                      validate={(value) => (value ? undefined : "Required")}
                      autoComplete="true"
                    />

                    <label className="show-error-message">
                      {loginStatusMessage}
                    </label>

                    <LoginButton disabled={submitting} />
                  </form>
                )}
              </Form>

              <ElectreonLoginButton logIn={handleElectreonLogin} />
            </>
          )}
      </div>
    </div>
  );
};

export default observer(Login);
