import dashboardButtonIcon from "Utils/Images/GlobalIcons/dashboard_icon.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import { useContext } from "react";
import MainContext from "Contexts/MainContext";

const ProjectDashboardButton = ({ project, isDisabled }) => {
  const history = useHistory();
  const { mainDispatch } = useContext(MainContext);
  return (
    <IndicationButton
      onClick={() => {
        if (isDisabled) {
          // project dashboard is not ready for other projects
          return;
        }
        history.push(`/dashboard/live/project/${project?.id}`);
        mainDispatch({
          type: "SET_SELECTED_PROJECT",
          payload: { selectedProject: project, isLoading: false },
        });
      }}
      isDisabled={isDisabled}
      className="project-dashboard-button"
      id="project-dashboard-button"
      buttonIconUrl={dashboardButtonIcon}
      title="project dashboard"
    />
  );
};

export default ProjectDashboardButton;

ProjectDashboardButton.propTypes = {
  project: PropTypes.object,
  isDisabled: PropTypes.bool,
};
