import React from "react";
import "Components/StatusIndication/StatusIndication.scss";
import PropTypes from "prop-types";

//Components
import Image from "Components/Image/Image";

//Utils
import { getSegmentDataByConnection } from "Utils/UtilsFunctions";

const StatusIndication = ({
  selectedDrawerSegment,
  backgroundColor,
  borderStyle,
  borderRadius,
  icon,
  statusText,
  statusTextColor = "#001133",
}) => {
  if (selectedDrawerSegment) {
    const segmentDataByConnection = getSegmentDataByConnection(
      selectedDrawerSegment?.segmentStatus
    );
    icon = segmentDataByConnection.icon;
    statusText = segmentDataByConnection.statusText;
    statusTextColor = segmentDataByConnection.textColor || "#001133";
    backgroundColor = segmentDataByConnection.backgroundColor;
    borderStyle = segmentDataByConnection.border || "1px solid transparent";
  }

  return (
    <div
      className="segment-driver-status-indication"
      style={{
        backgroundColor,
        border: borderStyle,
        borderRadius,
        color: statusTextColor,
      }}
    >
      {icon && <Image src={icon} height={16} width={16} />}
      <div className="segment-driver-status-indication-text">{statusText}</div>
    </div>
  );
};

export default StatusIndication;

StatusIndication.propTypes = {
  selectedDrawerSegment: PropTypes.object,
  backgroundColor: PropTypes.string,
  borderStyle: PropTypes.string,
  borderRadius: PropTypes.string,
  icon: PropTypes.string,
  statusText: PropTypes.string,
  statusTextColor: PropTypes.string,
};
