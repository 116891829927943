import PropTypes from "prop-types";
import CustomTooltip from "./CustomTooltip";

const VtTooltip = ({ tooltipData }) => {
  const isNotConfigured =
    !tooltipData || tooltipData.every((entry) => !entry.value);
  const decimalPlaces = 0;

  return (
    <div className="segment-driver-status-tooltip">
      {isNotConfigured ? (
        <CustomTooltip
          header={<p className="unit-type">{`VT Range not configured`}</p>}
        />
      ) : (
        <CustomTooltip
          body={
            <div className="tooltip-device-info">
              {tooltipData.map((data) => {
                return (
                  <p className="label" key={data.name}>{`${
                    data?.name
                  }: ${data?.value?.toFixed(decimalPlaces)}${data?.unit}`}</p>
                );
              })}
            </div>
          }
        />
      )}
    </div>
  );
};

export default VtTooltip;

VtTooltip.propTypes = {
  tooltipData: PropTypes.array,
};
