import { alpha, Theme, ThemeOptions } from "@mui/material";

export const baseStyles: ThemeOptions = {
  typography: {
    fontFamily: "Urbanist, Arial, sans-serif",
    fontSize: 16,
    h5: {
      fontSize: 24,
      lineHeight: "32px",
      fontWeight: 400,
      fontFamily: "Urbanist, Arial, sans-serif",
    },
    button: {
      fontSize: 13,
      lineHeight: "22px",
      letterSpacing: "0.46px",
      fontWeight: 500,
      fontFamily: "Urbanist, Arial, sans-serif",
    },
  },

  components: {
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          "& .Mui-checked .MuiSwitch-thumb": {
            backgroundColor: theme.palette.accent.primary.main,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          height: "100%",
          gap: "9px",
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: "4px",
          padding: "8px 16px",
          "& .MuiTypography-root": {
            whiteSpace: "nowrap",
          },
          transition: "background-color 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: alpha(theme.palette.accent.primary.main, 0.5),
            color: theme.palette.accent.primary.main,
          },
        }),
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "label" },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: 16,
            fontWeight: 400,
            lineHeight: "22px",
            color: theme.palette.accent.gray.main,
            fontFamily: "Readex Pro, sans-serif, Arial",
          }),
        },
        {
          props: { variant: "labelSmall" },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "22px",
            color: theme.palette.accent.gray.main,
            fontFamily: "Readex Pro, sans-serif, Arial",
          }),
        },
        {
          props: { variant: "toolmenu" },
          style: ({ theme }: { theme: Theme }) => ({
            fontSize: 15,
            fontWeight: 500,
            lineHeight: "26px",
            color: theme.palette.primary.main,
            fontFamily: "Urbanist, Arial, sans-serif",
          }),
        },
      ],
      styleOverrides: {
        root: {
          fontSize: "16px",
          letterSpacing: "0.15px",
          fontFamily: "Urbanist, Arial, sans-serif",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: "100%",
        },
        input: {
          fontSize: "16px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            color: theme.palette.accent.primary.main,
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "none",
          boxShadow: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: "row-reverse",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          "&.Mui-expanded": {},
        },
        expandIconWrapper: {
          paddingLeft: "10px",
          paddingRight: "10px",
          filter: "grayscale(100%)",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          marginLeft: "7px",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          marginLeft: "7px",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: "457px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }: { theme: Theme }) => ({
          fontSize: "24px",
          fontWeight: 400,
          padding: "24px",
          margin: 0,
          background: theme.palette.primary.main,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          marginBottom: "16px",
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          lineHeight: "24px",
        },
      },
    },
  },
};
