import { Button, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const PopupButton = ({ text, onClick, primary = false }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#00D1FD",
      },
      secondary: {
        main: "#fff",
      },
      customFonts: {
        primaryFontColor: "#fff",
        secondaryFontColor: "#00D1FD",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        sx={{
          color: primary
            ? "customFonts.primaryFontColor"
            : "customFonts.secondaryFontColor",
        }}
        color={primary ? "primary" : "secondary"}
        onClick={onClick}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
};

export default PopupButton;
