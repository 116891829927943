import "Components/StatusIndication/ParkingStatusIndication.scss";
import forceOffIcon from "Utils/Images/ProjectMaintanance/segment-force-off-icon.svg";
import Image from "Components/Image/Image";
import React from "react";

const ParkingStatusIndication = () => {
  return (
    <div className="parking-status-indication">
      <Image src={forceOffIcon} height={16} width={16} />
    </div>
  );
};
export default ParkingStatusIndication;
