import React, { Fragment } from "react";
import { TableValue } from "./types";

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Typography,
} from "@mui/material";

type TableProps = {
  tableDataArray: {
    header: string;
    accessor: string;
    border?: boolean;
    pt?: boolean;
    values: TableValue[];
  }[];
  loading: boolean;
};

const addCommasToLargerThanThousand = (value: string) => {
  if (value.length > 3 && !isNaN(Number(value))) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return value;
};

const VerticalTable: React.FC<TableProps> = ({ tableDataArray, loading }) => {
  return (
    <Table
      sx={{
        marginTop: "16px",
        "& .time": {
          ml: "-20%",
        },
        "& span": {
          fontFamily: "Urbanist, sans-serif",
        },
      }}
    >
      <TableBody>
        {tableDataArray.map((col) => {
          return (
            <Fragment key={col.accessor}>
              <TableRow>
                <TableCell
                  sx={{
                    padding: 0,
                    paddingBottom: 2,
                    paddingLeft: 2,
                    border: !col.border ? "none" : null,
                    paddingTop: col.pt ? 2 : 0,
                    whiteSpace: "nowrap",
                    fontFamily: "Urbanist, sans-serif",
                  }}
                >
                  {col.header}
                </TableCell>
                {!loading &&
                  col.values.map((rowData: TableValue, index: number) => (
                    <TableCell
                      key={index}
                      sx={{
                        padding: 0,
                        border: !col.border ? "none" : null,
                        paddingBottom: 2,
                        paddingTop: col.pt ? 2 : 0,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Stack direction="row">
                        <Typography
                          component="span"
                          ml={col.accessor === "deviceId" ? "-35px" : "-75px"}
                          textAlign="end"
                          width="100%"
                          fontSize="14px"
                          fontWeight="bold"
                          className={
                            col.accessor === "time" ||
                            col.accessor === "start" ||
                            col.accessor === "end"
                              ? "time"
                              : ""
                          }
                        >
                          {addCommasToLargerThanThousand(String(rowData.value || ""))}
                        </Typography>
                        {rowData.unit &&
                        rowData.value !== null &&
                        rowData.value !== undefined ? (
                          <Typography
                            component="span"
                            fontWeight="normal"
                            fontSize="14px"
                            color="#b7b7b7"
                            ml="5px"
                          >
                            {rowData.unit}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </TableCell>
                  ))}
              </TableRow>
            </Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default VerticalTable;
