export const getUserPermissions = (userRole) => {
  const upperCaseUserRole = userRole?.toUpperCase();

  const canControlIdleOff =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR" ||
    upperCaseUserRole === "OPERATOR";

  const canControlForceBtns =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR";

  const canControlFans =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR";

  const canDisableSegment =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR";

  const canChangeConfig =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR";

  const canDebug =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR";

  const canControlReceivers =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR" ||
    upperCaseUserRole === "OPERATOR";

  const canAddProject =
    upperCaseUserRole === "SUPER_ADMIN" || upperCaseUserRole === "ADMIN";
  const canDeleteProject =
    upperCaseUserRole === "SUPER_ADMIN" || upperCaseUserRole === "ADMIN";
  const canEditProject =
    upperCaseUserRole === "SUPER_ADMIN" || upperCaseUserRole === "ADMIN";

  const canLock =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR" ||
    upperCaseUserRole === "OPERATOR";

  const canChangePhase =
    upperCaseUserRole === "SUPER_ADMIN" ||
    upperCaseUserRole === "ADMIN" ||
    upperCaseUserRole === "INTEGRATOR" ||
    upperCaseUserRole === "OPERATOR";

  const canViewAdvancedParameters =
    upperCaseUserRole !== "PROJECT_VIEWER" &&
    upperCaseUserRole !== "PROJECT_USER" &&
    upperCaseUserRole !== "OPERATOR";

  return {
    canControlIdleOff,
    canControlForceBtns,
    canControlFans,
    canDisableSegment,
    canChangeConfig,
    canDebug,
    canControlReceivers,
    canAddProject,
    canDeleteProject,
    canEditProject,
    canLock,
    canChangePhase,
    canViewAdvancedParameters,
  };
};
