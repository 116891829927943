import React from "react";
import PropTypes from "prop-types";
import "Components/NumericInput/NumericInput.scss";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import minusIcon from "Utils/Images/GlobalIcons/minus_icon.svg";
import plusIcon from "Utils/Images/GlobalIcons/plus_icon-blue.svg";

const NumericInput = ({
  min,
  max,
  maxlength,
  step,
  name,
  label,
  isDisabled,
  defaultValue,
  onChange,
  value,
  unit,
  isArrows,
  onClickMinusButton,
  onClickPlusButton,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  readOnly = false,
  buttonsOnTheRight = false,
  inputClassName,
}) => {
  return (
    <div className={`app-number-input-wrapper ${isDisabled ? "disabled" : ""}`}>
      <label>{label} </label>
      <div
        className={` ${
          buttonsOnTheRight ? "buttonsOnTheRight" : ""
        } app-number-input-content`}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={"app-number-input-buttons"}>
          {isArrows && (
            <IndicationButton
              buttonIconUrl={minusIcon}
              onClick={onClickMinusButton}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              isDisabled={isDisabled}
            />
          )}
          {isArrows && (
            <IndicationButton
              buttonIconUrl={plusIcon}
              onClick={onClickPlusButton}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              isDisabled={isDisabled}
            />
          )}
        </div>
        <div className="app-number-input-value-unit">
          <input
            className={`app-number-input ${inputClassName}`}
            readOnly={readOnly}
            value={value}
            type="text"
            min={min}
            max={max}
            step={step}
            name={name}
            disabled={isDisabled}
            defaultValue={defaultValue}
            onChange={onChange}
            onFocus={onFocus}
            maxLength={maxlength}
            pattern="\d"
          />
          <span className="unit-suffix">{unit}</span>
        </div>
      </div>
    </div>
  );
};

export default NumericInput;

NumericInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
  unit: PropTypes.string,
  isArrows: PropTypes.bool,
  onClickPlusButton: PropTypes.func,
  onClickMinusButton: PropTypes.func,
  buttonsOnTheRight: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
