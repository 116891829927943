const SegmentsReducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_SEGMENTS":
      return {
        ...state,
        selectedSegments: action.payload.selectedSegments,
      };
    case "ADD_SELECTED_SEGMENT":
      return {
        ...state,
        selectedSegments: [
          ...state.selectedSegments,
          action.payload.selectedSegment,
        ],
      };
    case "REMOVE_SELECTED_SEGMENT":
      return {
        ...state,
        selectedSegments: action.payload.updatedSelectedSegments,
      };
    case "SET_IS_SEGMENT_CONTROL_PANEL":
      return {
        ...state,
        selectedSegmentPanel: action.payload.selectedSegmentPanel,
      };
    case "SET_SEGMENTS_MESSAGE":
      return {
        ...state,
        segments: action.payload.segments,
      };
    case "UPDATE_SELECTED_SEGMENT":
      return {
        ...state,
        selectedSegments: [
          ...state.selectedSegments.slice(0, action.payload.index),
          action.payload.selectedSegment,
          ...state.selectedSegments.slice(action.payload.index + 1),
        ],
      };
    case "SET_SELECTED_SEGMENT":
      return {
        selectedMUSegment: action.payload.selectedMUSegment
      }
    default:
      return state;
  }
};

export default SegmentsReducer;
