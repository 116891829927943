import React from "react";
import "Screens/DepotDashboard/DepotSummary/DepotSummary.scss";
import Image from "Components/Image/Image";
import ChargingSpotsSummaryTable from "Screens/DepotDashboard/DepotSummary/DepotChargingSummarySpots";
import VehiclesDepotSummary from "./VehiclesAtDepot";
import DepotSummaryEnergyGraph from "Screens/DepotDashboard/DepotSummary/DepotSummaryEnergyGraph";
import SeparatorLine from "Utils/Images/GlobalIcons/separator_line_icon.svg";
import PropTypes from "prop-types";

const DepotSummary = ({ store, depotName = "Reading Terminal" }) => {
  const terminalEnergyCalculate = () => {
    return 0;
  };

  return (
    <div className="depot-summary-card-section">
      <div className="depot-name"> {depotName}</div>
      <div className="terminal-energy-wrapper">
        <div className="terminal-energy-title">
          <span className="total-energy-title">Power</span>
          <span className="depot-total-energy">
            {terminalEnergyCalculate()}
          </span>
        </div>
        <DepotSummaryEnergyGraph />
      </div>
      <Image className="separator-line" src={SeparatorLine} />
      <ChargingSpotsSummaryTable store={store} />
      <VehiclesDepotSummary store={store} />
    </div>
  );
};

export default DepotSummary;

DepotSummary.propTypes = {
  store: PropTypes.shape({
    allParkingSpots: PropTypes.array,
    vacantParkingSpots: PropTypes.array,
    occupiedParkingSpots: PropTypes.array,
    chargingParkingSpots: PropTypes.array,
    nonOperationalParkingSpots: PropTypes.array,
  }),
  depotName: PropTypes.string,
};
