import "Components/Buttons/AppButton/AppButton.scss";

type ButtonProps = {
  width?: React.CSSProperties["width"],
  text: string | JSX.Element,
  textStyles?: React.CSSProperties,
  className?: string,
  onClick?: () => void,
  isDisabled?: boolean,
  buttonIcon?: string,
  height?: string,
};

const AppButton: React.FC<ButtonProps> = ({
  text,
  className,
  onClick,
  width,
  height,
  isDisabled,
  buttonIcon = "",
  textStyles,
}) => (
  <button
    disabled={isDisabled}
    className={`app-button ${className} ${isDisabled ? "disabled" : ""}`}
    onClick={onClick}
    style={{ width, height }}
  >
    {buttonIcon ? <img src={buttonIcon} /> : ""}
    <span style={textStyles}>{text}</span>
  </button>
);

export default AppButton;
