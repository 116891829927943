import React from "react";

const useRole = (userRole) => {

  const canControlIdleOff =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR" ||
    userRole === "OPERATOR";

  const canControlForceBtns =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR";

  const canControlFans =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR";

  const canDisableSegment =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR";

  const canChangeConfig =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR";

  const canDebug =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR";

  const canControlReceivers =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR" ||
    userRole === "OPERATOR";

  const canAddProject = userRole === "SUPER_ADMIN" || userRole === "ADMIN";
  const canDeleteProject = userRole === "SUPER_ADMIN" || userRole === "ADMIN";
  const canEditProject = userRole === "SUPER_ADMIN" || userRole === "ADMIN";

  const canLock =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR" ||
    userRole === "OPERATOR";

  const canChangePhase =
    userRole === "SUPER_ADMIN" ||
    userRole === "ADMIN" ||
    userRole === "INTEGRATOR" ||
    userRole === "OPERATOR";

  return {
    canControlIdleOff,
    canControlForceBtns,
    canControlFans,
    canDisableSegment,
    canChangeConfig,
    canDebug,
    canControlReceivers,
    canAddProject,
    canDeleteProject,
    canEditProject,
    canLock,
    canChangePhase,
  };
};
export default useRole;
