import React from "react";
import PropTypes from "prop-types";
import MultipleAreaGraph from "Components/Graphs/MultipleAreaGraph";
import MultipleLineGraph from "Components/Graphs/MultipleLineGraph";

const LivePerformanceGraph = ({
  graphType,
  isFrozen,
  frozenGraphData,
  graphScale,
  selectedDevice,
  socketMUMessageData,
  handleGraphClick,
}) => {
  return graphType === "current" || graphType === "voltage" ? (
    <MultipleLineGraph
      graphData={isFrozen ? frozenGraphData : socketMUMessageData}
      graphName={graphType}
      isAnimationActive={false}
      handleGraphClick={handleGraphClick}
      isFrozen={isFrozen}
      YAxisUnit={
        selectedDevice
          ? graphType === "current"
            ? `${graphType} (A)`
            : graphType === "voltage"
            ? `${graphType} (V)`
            : ""
          : ""
      }
      graphScale={graphScale}
      strokeColor="#488ec8"
      selectedDevice={selectedDevice}
      isHistoryOn={false}
    />
  ) : (
    <MultipleAreaGraph
      handleGraphClick={handleGraphClick}
      graphType={graphType}
      graphData={isFrozen ? frozenGraphData : socketMUMessageData}
      selectedDevice={selectedDevice}
      graphScale={graphScale}
      isHistoryOn={false}
    />
  );
};

export default LivePerformanceGraph;

LivePerformanceGraph.propTypes = {
  graphScale: PropTypes.string,
  graphType: PropTypes.string,
  selectedDevice: PropTypes.object,
  handleGraphClick: PropTypes.func,
  isFrozen: PropTypes.bool,
  frozenGraphData: PropTypes.object,
  socketMUMessageData: PropTypes.object,
};
