import React, { useContext } from "react";
import PropTypes from "prop-types";

import MUMainView from "Components/MUMainView/MUMainView";
import AllSegments from "Screens/ProjectView/PerformanceTab/MuPerformance/AllSegments";
import MiniVU from "Screens/ProjectView/PerformanceTab/MuPerformance/MiniVU";

import VUsContext from "Contexts/VUsContext";

import useSegments from "CustomHooks/projectViewLogic/projectViewContextHooks/useSegments";

const MuPerformance = ({
  selectedDrawer,
  setSelectedDrawer,
  setModalState,
  selectedSegmentIndex,
  setSelectedSegmentIndex,
  sendCommMessage,
  sendPWMMessage,
  sendForceOnMessage,
  handleAddingVU,
  locIntervalInstances,
  setLocIntervalInstances,
  locTimeoutInstances,
  isHistoryOn,
}) => {
  const { activeVU } = useContext(VUsContext);
  const { socketMessageDataSegments } = useSegments(selectedDrawer);

  return (
    <div className="mu-performance">
      <MUMainView
        selectedDrawer={selectedDrawer}
        setSelectedDrawer={setSelectedDrawer}
        socketMessageDataSegments={socketMessageDataSegments}
        setModalState={setModalState}
      />
      <AllSegments
        socketMessageDataSegments={socketMessageDataSegments}
        selectedSegmentIndex={selectedSegmentIndex}
        setSelectedSegmentIndex={setSelectedSegmentIndex}
        setModalState={setModalState}
        sendCommMessage={sendCommMessage}
        sendPWMMessage={sendPWMMessage}
        sendForceOnMessage={sendForceOnMessage}
      />
      <div
        className={"graph-vehicle-info" + (!activeVU ? " no-vu-selected" : "")}
      >
        <MiniVU
          handleAddingVU={handleAddingVU}
          locIntervalInstances={locIntervalInstances}
          setLocIntervalInstances={setLocIntervalInstances}
          locTimeoutInstances={locTimeoutInstances}
          isHistoryOn={isHistoryOn}
        />
      </div>
    </div>
  );
};

export default MuPerformance;

MuPerformance.propTypes = {
  selectedDrawer: PropTypes.number,
  setSelectedDrawer: PropTypes.func,
  setModalState: PropTypes.func,
  selectedSegmentIndex: PropTypes.number,
  setSelectedSegmentIndex: PropTypes.func,
  sendCommMessage: PropTypes.func,
  sendPWMMessage: PropTypes.func,
  sendForceOnMessage: PropTypes.func,
  handleAddingVU: PropTypes.func,
  locIntervalInstances: PropTypes.object,
  setLocIntervalInstances: PropTypes.func,
  locTimeoutInstances: PropTypes.object,
  isHistoryOn: PropTypes.bool,
};
