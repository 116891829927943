import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { useAppStore } from "MobxStores/context";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";

//Utils
import { getSessionIdProject, lockDevice, unlockDevice } from "Utils/APIUtils";
import lockedByMeIcon from "Utils/Images/ProjectMaintanance/locked-by-me-icon.svg";
import lockedIcon from "Utils/Images/ProjectMaintanance/locked-icon.svg";
import unlockedIcon from "Utils/Images/ProjectMaintanance/unlocked-icon.svg";
import { clearLockInterval, setDeviceLockInterval } from "Utils/LockUtils";
import { getIsDeviceDisconnected } from "Utils/UtilsFunctions";

const LockButton = ({
  device,
  locIntervalInstances,
  setLocIntervalInstances,
  lockState = { setupDisabled: true },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { connectionState } = useContext(SocketContext);
  const { shouldAcquireToken } = useContext(MainContext);
  const sessionId = getSessionIdProject();
  const { userStore } = useAppStore();
  const { canLock } = userStore.getUserPermissions();

  useEffect(() => {
    console.log(userStore.role, "<==== User role at lockButton");
  }, [userStore.role]);

  const shouldDisplay = canLock && sessionId;
  const deviceOffline = getIsDeviceDisconnected(connectionState, device);

  const shouldDisableLock = () => {
    if (device && canLock) {
      if (lockState.isLocked && !lockState.isLockedByMe) {
        return true;
      }
      if (isLoading || deviceOffline) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    shouldAcquireToken && setIsLoading(false);
  }, [shouldAcquireToken]);

  return shouldDisplay ? (
    <div
      title={
        !lockState.isLocked
          ? "Click to lock device"
          : lockState.isLockedByMe
          ? "Click to unlock device"
          : `Device is locked by ${lockState.lockedBy}`
      }
      onClick={() => {
        if (isLoading || shouldDisableLock()) {
          return;
        }
        setIsLoading(true);
        const { isLockedByMe, isLocked } = lockState;
        if (!isLocked) {
          return lockDevice({
            deviceId: device ? device.id : "",
            sessionId: sessionId,
          })
            .then((res) => {
              if (res && res.status === 200 && device && device.id) {
                setDeviceLockInterval(
                  device.id,
                  sessionId,
                  locIntervalInstances,
                  setLocIntervalInstances
                );

                setIsLoading(false);
              }
            })
            .catch((err) => setIsLoading(false));
        } else if (isLockedByMe && device) {
          return unlockDevice({
            deviceId: device ? device.id : "",
            sessionId: sessionId,
          })
            .then((res) => {
              device &&
                clearLockInterval(
                  device.id,
                  locIntervalInstances,
                  setLocIntervalInstances
                );

              setIsLoading(false);
            })
            .catch((err) => setIsLoading(false));
        }
      }}
      className={`locked-unlock-icon ${shouldDisableLock() ? "disabled" : ""}`}
    >
      <img
        src={
          lockState.isLockedByMe
            ? lockedByMeIcon
            : lockState.isLocked
            ? lockedIcon
            : unlockedIcon
        }
        height={16}
        width={16}
        alt={`Lock Button on device type ${device ? device.name : ""}`}
      />
    </div>
  ) : lockState.isLocked ? (
    <div className={`locked-unlock-icon disabled`}>
      <img
        src={lockedIcon}
        height={16}
        width={16}
        alt={`Lock Button on device type ${device ? device.name : ""}`}
      />
    </div>
  ) : (
    ""
  );
};

export default observer(LockButton);

LockButton.propTypes = {
  device: PropTypes.object,
  locIntervalInstances: PropTypes.object,
  setLocIntervalInstances: PropTypes.func,
  lockState: PropTypes.object,
};
