import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

//Components
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import FansView from "Screens/ProjectView/Devices/MU/FansView/FansView";
import Image from "Components/Image/Image";
import Segment from "Screens/ProjectView/Devices/MU/Segment/Segment";
import SliderWrapper from "Components/SliderWrapper/SliderWrapper";
import RackConfigControl from "Screens/ProjectView/SetupTab/RackSection/RackConfigControl";

//Context
import MainContext from "Contexts/MainContext";
import useRole from "CustomHooks/useRole";

//Utils
import { setFpgaCommand } from "Utils/APIUtils";
import { getSegmentValue, isForceOffAll } from "Utils/UtilsFunctions";
import separatorLineIcon from "Utils/Images/GlobalIcons/separator_line_icon.svg";
import chargingIcon from "Utils/Images/ProjectMaintanance/duty-cycle-charging-icon.svg";
import forceRFIcon from "Utils/Images/ProjectMaintanance/segment-force-rf-icon.svg";
import autoFanIcon from "Utils/Images/ProjectMaintanance/auto-fan-icon.svg";
import ONFanIcon from "Utils/Images/ProjectMaintanance/on_fan_icon_2.svg";
import { setAllShelfFans } from "Utils/APIUtils";
import "Screens/ProjectView/SetupTab/RackSection/SetupRackColumn.scss";
import OffIdleButtons from "Components/Buttons/OffIdleButtons/OffIdleButtons";

const SetupRackColumn = ({
  sessionId,
  deviceId,
  selectedDrawer,
  selectedDrawerConfiguration,
  selectedDrawerSegments,
  selectedSegmentIndex,
  setSelectedSegmentIndex,
  lockState = { setupDisabled: true },
  socketMessageDataSegments,
}) => {
  const { userRole } = useContext(MainContext);
  const { canControlFans } = useRole(userRole);

  return (
    <div className="project-device-setup-selected-drawer setup-section">
      <ProjectDeviceHeader
        title={`Rack - 0${selectedDrawer}`}
        infoIconDataFor="fpga-version-tip"
        data={
          <div className="drawer-force-indication-buttons">
            <ReactTooltip
              id="fpga-version-tip"
              className="fpga-version-tip"
              getContent={(dataTip) => (
                <>
                  <div className="fpga-version">
                    FPGA FW Version:{" "}
                    {selectedDrawerConfiguration
                      ? selectedDrawerConfiguration["firmwareVersion"]
                      : 0}
                  </div>
                  <div className="linux-version">
                    FPGA Linux Version:{" "}
                    {selectedDrawerConfiguration
                      ? selectedDrawerConfiguration["linuxAppVersion"]
                      : 0}
                  </div>
                </>
              )}
              effect="solid"
              delayHide={0}
              delayShow={0}
              delayUpdate={0}
              place="right"
              border
              type="light"
            />
            <div className="indication-buttons">
              <OffIdleButtons
                offButtonTitle="Force Off Rack"
                isDisabled={lockState.setupDisabled}
                offButtonOnClick={() => {
                  if (
                    isForceOffAll(selectedDrawer, socketMessageDataSegments)
                  ) {
                    setFpgaCommand({
                      MUId: deviceId,
                      shelfNumber: selectedDrawer - 1,
                      segmentNumber: 0,
                      cmdType: "set_force_off_shelf",
                      val: 1,
                      sessionId: sessionId,
                    }).then((res) => {
                      // TODO: what to do on then?
                      if (res && res.status !== 200) {
                      }
                    });
                  }
                }}
                idleButtonTitle="Idle Rack"
                idleButtonOnClick={() => {
                  setFpgaCommand({
                    MUId: deviceId,
                    shelfNumber: selectedDrawer - 1,
                    segmentNumber: 0,
                    cmdType: "set_clear_shelf_status",
                    val: 1,
                    sessionId: sessionId,
                  }).then((res) => {
                    // TODO: what to do on then?
                    if (res && res.status !== 200) {
                    }
                  });
                }}
              />
            </div>
            <div className="fan-force-all">
              <IndicationButton
                title={lockState.isLocked ? "Rack fans AUTO" : ""}
                isDisabled={lockState.setupDisabled || !canControlFans}
                text=""
                onClick={() => {
                  setAllShelfFans({
                    MUId: deviceId,
                    fan_drawer: selectedDrawer - 1,
                    fan_cmd_type: 1,
                    sessionId: sessionId,
                  }).then((res) => {
                    if (res && res.status !== 200) {
                      console.error(res);
                    }
                  });
                }}
                buttonIconUrl={autoFanIcon}
              />
              <IndicationButton
                title={lockState.isLocked ? "Rack Fans force ON" : ""}
                isDisabled={lockState.setupDisabled || !canControlFans}
                text=""
                onClick={() => {
                  setAllShelfFans({
                    MUId: deviceId,
                    fan_drawer: selectedDrawer - 1,
                    fan_cmd_type: 2,
                    sessionId: sessionId,
                  }).then((res) => {
                    if (res && res.status !== 200) {
                      console.error(res);
                    }
                  });
                }}
                buttonIconUrl={ONFanIcon}
              />
            </div>
          </div>
        }
      />
      <div className="project-device-setup-selected-drawer-segments-fans">
        <div className="project-device-setup-selected-drawer-segments">
          <Image className="separator-line" src={separatorLineIcon} />
          {selectedDrawerSegments &&
            Object.keys(selectedDrawerSegments)
              .slice(0)
              .reverse()
              .map((selectedDrawerSegment, selectedDrawerSegmentIndex) => {
                const currentSegment =
                  selectedDrawerSegments[selectedDrawerSegment];
                const currentSegmentIndicationFreq =
                  currentSegment && currentSegment.segmentIndFrequency
                    ? currentSegment.segmentIndFrequency
                    : 0;
                const currentSegmentDutyCycle = currentSegment.segmentDutyCycle;
                const commDuration = currentSegment.commDuration;
                const currentSegmentDisabled =
                  currentSegment.segmentStatus === 255;
                const currentSegmentNotDetected = false;
                const newSegmentIndex = 12 - selectedDrawerSegmentIndex - 1;

                return (
                  <div
                    key={selectedDrawerSegmentIndex}
                    className="rack-segment-wrapper"
                  >
                    <div
                      className={`selected-drawer-segment ${
                        selectedSegmentIndex === newSegmentIndex
                          ? "selected"
                          : ""
                      }`}
                      key={newSegmentIndex}
                      onClick={() => setSelectedSegmentIndex(newSegmentIndex)}
                    >
                      <Segment
                        segmentStatus={currentSegment?.segmentStatus}
                        value={getSegmentValue(selectedDrawer, newSegmentIndex)}
                      />
                      {!currentSegmentNotDetected && (
                        <div className="selected-drawer-segment-indication-freq">
                          {currentSegmentDisabled
                            ? 0
                            : currentSegmentIndicationFreq}{" "}
                          kHz
                        </div>
                      )}
                      {!currentSegmentNotDetected && (
                        <div className="segment-operational-mode-info">
                          <div className="duty-cycle-percentage-bar">
                            <div className="value-icon-wrapper">
                              <Image
                                src={chargingIcon}
                                className="duty-cycle-icon"
                              />
                              <div className="graph-segment-duty-cycle-value">
                                {currentSegmentDutyCycle &&
                                  currentSegmentDutyCycle.toFixed(1)}
                                <span>%</span>
                              </div>
                            </div>
                            <SliderWrapper
                              handleStyle={{
                                display: "none",
                              }}
                              trackStyle={{
                                backgroundColor: "rgba(0, 34, 102, 0.94)",
                              }}
                              railStyle={{
                                backgroundColor: "#CCDDFF",
                              }}
                              value={
                                currentSegmentDutyCycle
                                  ? currentSegmentDutyCycle.toFixed(1)
                                  : 0
                              }
                              className="graph-segment-duty-cycle-slider"
                              step={1}
                              min={0}
                              max={100}
                            />
                          </div>
                          <div className="RF-percentage-bar">
                            <div className="value-icon-wrapper">
                              <Image
                                src={forceRFIcon}
                                className="force-rf-icon"
                              />
                              <div className="graph-segment-RF-value">
                                {commDuration ? commDuration.toFixed(0) : 0}
                                <span>%</span>
                              </div>
                            </div>
                            <SliderWrapper
                              handleStyle={{
                                display: "none",
                              }}
                              trackStyle={{
                                backgroundColor: "rgba(0, 34, 102, 0.94)",
                              }}
                              railStyle={{
                                backgroundColor: "#CCDDFF",
                              }}
                              value={commDuration ? commDuration.toFixed(0) : 0}
                              className="graph-segment-duty-cycle-slider"
                              step={1}
                              min={0}
                              max={100}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {selectedDrawerSegmentIndex % 2 ? (
                      <Image
                        className="separator-line"
                        src={separatorLineIcon}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
        </div>
        <div className="project-device-setup-selected-drawer-fans">
          <FansView
            selectedDrawer={selectedDrawer}
            MUId={deviceId}
            isLocked={lockState.setupDisabled}
            sessionId={sessionId}
          />
        </div>
      </div>
      <RackConfigControl
        selectedDrawer={selectedDrawer}
        lockState={lockState}
      />
    </div>
  );
};

export default SetupRackColumn;

SetupRackColumn.propTypes = {
  sessionId: PropTypes.string,
  deviceId: PropTypes.string,
  selectedDrawer: PropTypes.number,
  selectedDrawerConfiguration: PropTypes.object,
  selectedDrawerSegments: PropTypes.object,
  selectedSegmentIndex: PropTypes.number,
  setSelectedSegmentIndex: PropTypes.func,
  lockState: PropTypes.object,
  socketMessageDataSegments: PropTypes.object,
};
