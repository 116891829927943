import { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";

import MainContext from "Contexts/MainContext";

import AnalyticsToolIcon from "Utils/Images/GlobalIcons/analytics_tool_icon.svg";

const AnalyticsToolButton = ({ project }) => {
  const history = useHistory();
  const { mainDispatch } = useContext(MainContext);
  return (
    <IndicationButton
      onClick={() => {
        history.push(`/dashboard/history/project/${project?.id}`);
        mainDispatch({
          type: "SET_SELECTED_PROJECT",
          payload: { selectedProject: project, isLoading: false },
        });
      }}
      className="analytics-tool-button"
      buttonIconUrl={AnalyticsToolIcon}
      title="analytics tool"
    />
  );
};

export default AnalyticsToolButton;

AnalyticsToolButton.propTypes = {
  project: PropTypes.object,
};
