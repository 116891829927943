import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { getUserPermissions } from "MobxStores/UserHelperFunctions";
import { RoleTypes, ScopeTypes } from "Utils/constants";
import { getUserScopeType } from "./utils/userScopeType";
class UserStore {
  role = "Template User Role";
  userName = "Template Username";
  userData = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "UserStore",
      properties: ["role", "userName"],
      storage: window.localStorage,
    });
  }

  setUserData = (user) => {
    this.userData = new UserData(user);
  };

  updateRole(role) {
    console.log("user store updating role to: ", role);
    this.role = role;
  }

  updateUserName(userName) {
    this.userName = userName;
  }

  getUserPermissions() {
    // console.log(this.role.toUpperCase(), "getUserPermissions role")
    return getUserPermissions(this.role);
  }

  resetStore() {
    this.role = "Template User Role";
    this.userName = "Template Username";
    this.userData = null;
  }
}

export class UserData {
  constructor(user) {
    this.name = user.name;
    this.role = user.idTokenClaims.extension_UserRole;
    this.projectScope = user.idTokenClaims.extension_ProjectScope;
    this.projectIds = this.projectScope.split(",");
    this.scopeType = getUserScopeType(user);
    console.log("UserData", this);
  }

  isSingleProjectViewer = () => {
    return (
      this.role === RoleTypes.PROJECT_VIEWER &&
      this.scopeType === ScopeTypes.SINGLE
    );
  };

  isSingleScoped = () => {
    return this.scopeType === ScopeTypes.SINGLE;
  };
}

export default UserStore;
