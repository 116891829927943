import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

//Components
import VU from "Screens/ProjectView/Devices/VU/VU";

//Context
import MainContext from "Contexts/MainContext";
import VUsContext from "Contexts/VUsContext";
import SocketContext from "Contexts/SocketContext";
import MUsContext from "Contexts/MUsContext";
import { useAppStore } from "MobxStores/context";

//Utils
import {
  getIsDeviceDisconnected,
  getDeviceToolTip,
  handleDeviceClick,
} from "Utils/UtilsFunctions";
import arrowRight from "Utils/Images/GlobalIcons/arrow-down-blue.svg";
import arrowDown from "Utils/Images/GlobalIcons/arrow-right-blue.svg";

const vehicleTypes = ["Trucks", "Buses", "Vans", "Private"];

const VehiclesSection = ({ locTimeoutInstances }) => {
  const [vehicleTypeCollapsed, setVehicleTypeCollapsed] = useState({
    Trucks: false,
    Buses: false,
    Vans: false,
  });

  const { selectedDevice, mainDispatch, selectedProject } =
    useContext(MainContext);
  const { vusList } = useContext(VUsContext);
  const { connectionState } = useContext(SocketContext);
  const { MUsDispatch } = useContext(MUsContext);
  const { projectStore } = useAppStore();

  return vehicleTypes.map((vehicleType, vehicleTypeIndex) => {
    const availableVusPerType =
      vusList &&
      vusList.filter(
        (vuDevice) =>
          vuDevice?.vehicleType.substring(0, 3).toUpperCase() ===
            vehicleType.substring(0, 3).toUpperCase() &&
          vuDevice?.projectId === selectedProject?.id
      );

    return availableVusPerType.length ? (
      <div className="vehicle-type-wrapper" key={vehicleTypeIndex}>
        <div
          className="vehicle-type-title"
          onClick={() =>
            setVehicleTypeCollapsed({
              ...vehicleTypeCollapsed,
              [vehicleType]: !vehicleTypeCollapsed[vehicleType],
            })
          }
        >
          <div className="vehicle-type-collapse">
            <img
              src={!vehicleTypeCollapsed[vehicleType] ? arrowRight : arrowDown}
              height={10}
              width={10}
              alt={
                !vehicleTypeCollapsed[vehicleType]
                  ? "collapse-arrow-right"
                  : "collapse-arrow-down"
              }
            />
          </div>
          <div className="vehicle-type-title-name">{vehicleType}</div>
        </div>
        <div className="vu-list-wrapper">
          {!vehicleTypeCollapsed[vehicleType] &&
            availableVusPerType.map((vuDevice, vuDeviceIndex) => {
              const { id, name } = vuDevice;
              const isVUSelected = selectedDevice && id === selectedDevice.id;
              const isConnected = !getIsDeviceDisconnected(
                connectionState,
                vuDevice
              );
              const tooltip = getDeviceToolTip({
                ...vuDevice,
                isConnected,
              });

              return (
                <VU
                  key={vuDeviceIndex}
                  isVUSelected={isVUSelected}
                  id={id}
                  name={name}
                  onVUClick={() =>
                    handleDeviceClick({
                      device: vuDevice,
                      connectionState,
                      projectStore,
                      MUsDispatch,
                      mainDispatch,
                    })
                  }
                  tooltip={tooltip}
                  vuDevice={vuDevice}
                  locTimeoutInstances={locTimeoutInstances}
                  isDeviceConnected={isConnected}
                />
              );
            })}
        </div>
      </div>
    ) : (
      ""
    );
  });
};

export default VehiclesSection;

VehiclesSection.propTypes = {
  locTimeoutInstances: PropTypes.object,
};
