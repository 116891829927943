import "Components/Buttons/LsmuParkingControlButton/LsmuParkingControlButton.scss";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import autoLsmuIcon from "Utils/Images/ProjectMaintanance/auto-icon.svg";
import manualLsmuIcon from "Utils/Images/ProjectMaintanance/manual-icon.svg";
import { useState } from "react";
import {
  AutoLsmuParkingControl,
  ManualLsmuParkingControl,
} from "Utils/DefaultValues/LsmuDefaultValues";

const LsmuParkingControlButton = ({ isDisabled }) => {
  const [controlState, setControlState] = useState(AutoLsmuParkingControl);
  const toggleControl = () => {
    let newState = AutoLsmuParkingControl;
    if (controlState === AutoLsmuParkingControl) {
      newState = ManualLsmuParkingControl;
    }
    setControlState(newState);
  };
  return (
    <IndicationButton
      title="Parking Control"
      isDisabled={isDisabled}
      text=""
      onClick={toggleControl}
      buttonIconUrl={
        controlState === AutoLsmuParkingControl ? autoLsmuIcon : manualLsmuIcon
      }
    />
  );
};
export default LsmuParkingControlButton;
