import jwt_decode from "jwt-decode";
import moment from "moment";
import _ from "lodash";

import {
  execLogIn,
  handleSignOut,
  getMuList,
  getProjectUnitList,
} from "Utils/APIUtils";
import { clearMuMessages } from "Utils/EventsMessages/MessagesFunctions";
import { setDeviceLockTimeout } from "Utils/LockUtils";
import { limitMUList } from "Utils/DefaultValues/ProjectViewDefaultValues";
import noSDDetectedIcon from "Utils/Images/ProjectMaintanance/SD_disabled.svg";
import IdleIcon from "Utils/Images/ProjectMaintanance/segment-idle-icon.svg";
import defaultIcon from "Utils/Images/GlobalIcons/default_icon.svg";
import forceOnIcon from "Utils/Images/ProjectMaintanance/segment-force-on-icon.svg";
import onIcon from "Utils/Images/ProjectMaintanance/segment-on-icon.svg";
import forceOffIcon from "Utils/Images/ProjectMaintanance/segment-force-off-icon.svg";
import forceRFIcon from "Utils/Images/ProjectMaintanance/segment-force-rf-icon.svg";
import forcePWMIcon from "Utils/Images/ProjectMaintanance/segment-force-pwm-icon.svg";
import errorIndicationIcon from "Utils/Images/ProjectMaintanance/segment-error-indication-icon.svg";

export const getRandomColor = () => {
  let color = "#";
  for (let i = 0; i < 3; i++)
    color += (
      "0" +
      Math.floor(((1.2 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)
    ).slice(-2);
  return color;
};

export const getRandomColorFromRange = () => {
  const random = Math.floor(Math.random() * 10);
  switch (random) {
    case 1:
      return "#5ac2e1";
    case 2:
      return "#e3a5b5";
    case 3:
      return "#7a718d";
    case 4:
      return "#071737";
    case 5:
      return "#ffbfff";
    case 6:
      return "#03ee0e";
    case 7:
      return "#fdb4aa";
    case 8:
      return "#0ae8b6";
    case 9:
      return "#d39adb";
    case 0:
      return "#16a9f6";
    case 11:
      return "#f45408";
    default:
      return "#f4d508";
  }
};

export const getVUChargingStatus = (receivers, device, current) => {
  const { Receiver_1, Receiver_2, Receiver_3, Receiver_4, Receiver_5 } =
    receivers &&
    device &&
    receivers[device.id] &&
    receivers[device.id]["socketMessageData"]
      ? receivers[device.id]["socketMessageData"]
      : {
          Charging_Allowed_Ind: 0,
          RF_Boost_Level: 0,
          Receiver_1: null,
          Receiver_2: null,
          Receiver_3: null,
          Receiver_4: null,
          Receiver_5: null,
        };
  let chargingStatus =
    [Receiver_1, Receiver_2, Receiver_3, Receiver_4, Receiver_5].filter(
      (receiver) => receiver && receiver["State"] === 2
    ).length === 0
      ? "OFF"
      : current > 1
      ? "Charging"
      : "Idle";

  switch (chargingStatus) {
    case "OFF":
      return {
        text: "OFF",
        color: "#ccddff",
        icon: forceOffIcon,
      };
    case "Idle":
      return {
        text: "Idle",
        color: "#CCFFFF",
        icon: IdleIcon,
      };
    case "Charging":
      return {
        text: "Charging",
        color: "#00FFFF",
        icon: onIcon,
      };
    default:
      return {
        text: "",
        color: "",
        icon: "",
      };
  }
};

export const getDcSamplerSummaryNumbers = (liveDCSamplerData) => {
  return {
    avgPowerLiveValue:
      (liveDCSamplerData && liveDCSamplerData["avgPower"]) || 0,
    avgVoltageLiveValue:
      (liveDCSamplerData && liveDCSamplerData["avgVoltage"]) || 0,
    avgCurrentLiveValue:
      (liveDCSamplerData && liveDCSamplerData["avgCurrent"]) || 0,
  };
};

export const getVuRfStatusInd = (value) => {
  switch (value) {
    case 0: // 0 - COMM OFF
      return {
        color: "#CCDDFF",
        message: "COMM OFF",
      };
    case 1: // 1 - COMM SET ON
      return {
        color: "#CCDDFF",
        message: "COMM SET ON",
      };
    case 2: // 2 - COMM IND ON
      return {
        color: "#00FFFF",
        message: "COMM IND ON",
      };
    case 3: // 3 - POWER CABLE DISCONNECTED
      return {
        color: "#bfbfbf",
        message: "POWER CABLE DISCONNECTED",
      };
    case 4: // 4 - COMM ERROR
      return {
        color: "#F81728",
        message: "COMM ERROR",
      };
    default:
      return {
        color: "#eeeeee",
        message: "No Status Found",
      };
  }
};
export const getSegmentValue = (segmentRow, segmentIndex) => {
  return segmentRow === 1
    ? segmentIndex + 1
    : segmentIndex + 1 + 12 * (segmentRow - 1);
};
export const getSegmentDataByConnection = (segmentStatus) => {
  switch (segmentStatus) {
    case 0:
      return {
        statusText: "NO SD detected",
        icon: "",
        backgroundColor: "#FFFFFF",
        textColor: "#CCDDFF",
        disabled: true,
      };
    case 1:
      return {
        statusText: "IDLE",
        icon: IdleIcon,
        backgroundColor: "#CCFFFF",
      };

    case 2:
      return {
        statusText: "Detected COMM", //IDle //Acticated //Force on // Force comm// Force PWM// oVER TEMP// OVER CURRENT// fORCE OFF
        icon: onIcon,
        backgroundColor: "#00FFFF",
      };
    case 3:
      return {
        statusText: "AP Opened SiC",
        icon: onIcon,
        backgroundColor: "#00FFFF",
      };
    case 4:
      return {
        statusText: "Active Transfer Power",
        icon: onIcon,
        backgroundColor: "#00FFFF",
      };
    case 5:
      return {
        statusText: "AP",
        icon: onIcon,
        backgroundColor: "#00FFFF",
      };
    case 6:
      return {
        statusText: "AP Shut Down",
        icon: defaultIcon,
        backgroundColor: "#5f75f9",
      };
    case 7:
      return {
        statusText: "AP Shut Down (No Io)", //IDle //Acticated //Force on // Force comm// Force PWM// oVER TEMP// OVER CURRENT// fORCE OFF
        icon: defaultIcon,
        backgroundColor: "#f9cd5f",
      };
    case 8:
      return {
        statusText: "FORCE COMM",
        icon: forceRFIcon,
        backgroundColor: "#FFD600",
      };
    case 9:
      return {
        statusText: "FORCE PWM",
        icon: forcePWMIcon,
        backgroundColor: "#FFD600",
      };
    case 10:
      return {
        statusText: "FORCE ON",
        icon: forceOnIcon,
        backgroundColor: "#FFD600",
      };
    case 11:
      return {
        statusText: "FORCE OFF", //IDle //Acticated //Force on // Force comm// Force PWM// oVER TEMP// OVER CURRENT// fORCE OFF
        icon: forceOffIcon,
        backgroundColor: "#CCDDFF",
      };
    case 12:
      return {
        statusText: "OFF - Over Temp",
        icon: errorIndicationIcon,
        backgroundColor: "#F81728",
        textColor: "#FFFFFF",
      };
    case 13:
      return {
        statusText: "OFF - Over Current",
        icon: errorIndicationIcon,
        backgroundColor: "#F81728",
        textColor: "#FFFFFF",
      };
    case 255:
      return {
        statusText: "Disabled",
        icon: noSDDetectedIcon,
        backgroundColor: "#FFFFFF",
        disabled: true,
        border: "1px solid #CCDDFF",
      };
    default:
      return {
        statusText: "",
        icon: "",
        backgroundColor: "#eeeeee",
      };
  }
};

export const calculateSegmentWorkingFrequency = (
  selectedDrawerSegmentDataPresent
) => {
  return selectedDrawerSegmentDataPresent &&
    selectedDrawerSegmentDataPresent.segmentWorkingFrequency
    ? Number(
        Number(
          Math.pow(10, 6) /
            (11100 +
              10 *
                Number(
                  selectedDrawerSegmentDataPresent.segmentWorkingFrequency
                ))
        ).toFixed(3)
      )
    : 80.0;
};

export const calculateSegmentResonantFrequency = (
  selectedDrawerSegmentDataPresent
) => {
  return selectedDrawerSegmentDataPresent &&
    selectedDrawerSegmentDataPresent.segmentResonantFrequency
    ? Number(
        Number(
          Math.pow(10, 6) /
            (10000 +
              20 *
                Number(
                  selectedDrawerSegmentDataPresent.segmentResonantFrequency
                ))
        ).toFixed(3)
      )
    : 80.0;
};

export const getDeviceToolTip = ({
  id,
  softwareVersion,
  sdCardFree,
  sdCardTotal,
  lastReconnect,
  isConnected,
}) => {
  const freeSpace =
    sdCardFree && sdCardFree
      ? `(${Math.round((sdCardFree / sdCardTotal) * 100)}% free)`
      : "";
  softwareVersion = softwareVersion ? `\n${softwareVersion} ${freeSpace}` : "";
  id = id || "";
  let lastConnectedSec =
    lastReconnect && Math.round(Date.now() / 1000 - lastReconnect);
  let lastConnectedM = Math.round(lastConnectedSec / 60);
  let lastConnectedH = lastConnectedM > 60 && Math.round(lastConnectedM / 60);
  let lastConnectedD = lastConnectedH > 24 && Math.round(lastConnectedH / 24);
  let lastConnectedMonths =
    lastConnectedD > 29 && Math.round(lastConnectedD / 30);
  let lastConnectedYears =
    lastConnectedMonths > 12 && Math.round(lastConnectedMonths / 12);

  let lastConnectedString = lastConnectedYears
    ? `\n${lastConnectedYears} years ago`
    : lastConnectedMonths
    ? `\n${lastConnectedMonths} months ago`
    : lastConnectedD
    ? `\n${lastConnectedD} days ago`
    : lastConnectedH
    ? `\n${lastConnectedH} hours ago`
    : lastConnectedM
    ? `\n${lastConnectedM} minutes ago`
    : lastConnectedSec
    ? `\n${lastConnectedSec} seconds ago`
    : "";

  let lastConnected = !!isConnected ? "" : lastConnectedString;

  return `${id}${softwareVersion}${lastConnected}`;
};

export const getClaimsFromToken = (token) => {
  if (!token) {
    return "";
  }
  let decoded = jwt_decode(token);
  return decoded;
};

export const acquireTokenSilent = (
  type,
  mainDispatch,
  authenticationModule,
  history,
  setUserInfo,
  projectStore
) => {
  execLogIn(type, authenticationModule, setUserInfo).then((res) => {
    if (
      (type === "acquireTokenSilent" ||
        type === "acquireTokenSilentNewAccessToken") &&
      res &&
      (res.isAxiosError || !!res.errorMessage)
    ) {
      handleSignOut(mainDispatch, history, authenticationModule, projectStore);
    }
    mainDispatch({
      type: "SET_SHOULD_ACQUIRE_TOKEN",
      payload: { shouldAcquireToken: false },
    });
  });
};

export const isForceOffAll = (drawerNumber, socketMessageDataSegments) => {
  if (
    socketMessageDataSegments &&
    drawerNumber > 0 &&
    socketMessageDataSegments[`fpga_${drawerNumber - 1}`]
  ) {
    const segmentsDataObj = Object.values(
      socketMessageDataSegments[`fpga_${drawerNumber - 1}`]
    ); //TODO: use selectedDrawerSegments
    return segmentsDataObj
      .filter((element, index) => index < segmentsDataObj.length - 1)
      .some((segmentItem) => !segmentItem.forceOff);
  }
  return false;
};

const validateHistoryDate = (date) => {
  const dateToValidate = new Date(date);

  // if today return 0
  // if ealier date return 1
  // if invalid date return -1

  if (dateToValidate.getFullYear() === new Date().getFullYear()) {
    if (dateToValidate.getMonth() === new Date().getMonth()) {
      if (dateToValidate.getDate() === new Date().getDate()) {
        return 0;
      } else if (dateToValidate.getDate() < new Date().getDate()) {
        return 1;
      }
    } else if (dateToValidate.getMonth() < new Date().getMonth()) {
      return 1;
    }
  } else if (dateToValidate.getFullYear() < new Date().getFullYear()) {
    return 1;
  }
  return -1;
};

export const isHistoryTimeInvalid = (date, currentTime, span, time) => {
  switch (validateHistoryDate(date)) {
    case 0:
      //same date, validate time
      if (
        convertFormattedTimeToMiliseconds(currentTime) + span * 1000 <
        convertFormattedTimeToMiliseconds(time)
      ) {
        return false;
      } else {
        return true;
      }
    case 1:
      //earlier date
      return false;
    default:
      //future date
      return true;
  }
};

export const convertFormattedTimeToMiliseconds = (formattedTime) => {
  const time = formattedTime.split(":");

  if (time.length > 2) {
    return (
      Number(time[0]) * 60 * 60 * 1000 +
      Number(time[1]) * 60 * 1000 +
      Number(time[2]) * 1000
    );
  } else {
    return Number(time[0]) * 60 * 1000 + Number(time[1]) * 1000;
  }
};

export const isNewerOrEqualVersion = (oldVer, newVer) => {
  // todo: maybe try with Array.find()
  if (newVer === "1.0.0.0") return true;
  const oldParts = oldVer.split(".");
  const newParts = newVer.split(".");
  for (let i = 0; i < newParts.length; i++) {
    const a = Number(newParts[i]);
    const b = Number(oldParts[i]);
    if (a > b) return true;
    if (a < b) return false;
  }
  return true;
};

export const getConvertedEndDateAndTime = (spanVal, startTime, dateVal) => {
  return moment(
    new Date(`${dateVal} ${startTime}`).getTime() + spanVal * 1000
  ).format("YYYY-MM-DDTHH:mm:ss");
};

export const getIsDeviceDisconnected = (connectionState, device) => {
  if (connectionState[device?.id]?.["socketMessageData"]?.["opertionState"]) {
    return (
      connectionState[device.id]["socketMessageData"]["opertionState"] ===
      "deviceDisconnected"
    );
  }
  return device?.connectionStatus === "DISCONNECTED";
};

export const handleDeviceClick = ({
  device,
  connectionState,
  projectStore,
  MUsDispatch,
  mainDispatch,
}) => {
  const isMUDevice = device.deviceType === "MU";

  const isDeviceOffline = getIsDeviceDisconnected(connectionState, device);

  projectStore.setSelectedTab(
    isDeviceOffline || !isMUDevice ? "PERFORMANCE" : "SETUP"
  );

  clearMuMessages(MUsDispatch);

  return mainDispatch({
    type: "SET_SELECTED_DEVICE",
    payload: {
      selectedDevice: {
        ...device,
        connectionStatus: isDeviceOffline ? "DISCONNECTED" : "CONNECTED",
      },
    },
  });
};

export const getDevicesAPICall = ({
  id,
  mainDispatch,
  socketDispatch,
  VUsDispatch,
  lockInfoRef,
  setLocTimeoutInstances,
  locTimeoutInstancesRef,
  connectionState,
  continuationToken,
}) => {
  if (id) {
    getMuList(id).then((res) => {
      if (res && res.data) {
        mainDispatch({
          type: "SET_MU_DATA",
          payload: {
            musData: res.data,
          },
        });

        let devices = [];
        let initialMusLockState = _.cloneDeep(lockInfoRef.current);
        if (res.data["unassignedUnits"]) {
          res.data["unassignedUnits"].map((device) => {
            return devices.push(device);
          });
        }
        if (res.data["staticDeployment"].depots) {
          res.data["staticDeployment"].depots.map((depot) => {
            return depot.managementUnits.map((unit) => {
              return devices.push(unit.managementUnit);
            });
          });
        }
        devices.forEach((mu) => {
          if (mu.lockInfo && mu.lockInfo.expiresAt) {
            setDeviceLockTimeout(
              mu.id,
              mu.lockInfo.expiresAt,
              setLocTimeoutInstances,
              locTimeoutInstancesRef,
              lockInfoRef,
              socketDispatch
            );
            initialMusLockState[mu.id] =
              typeof mu.lockInfo === "string"
                ? JSON.parse(mu.lockInfo)
                : mu.lockInfo;
          }

          // updates connectionState with all MUS
          const newConnectionState = _.cloneDeep(connectionState);
          if (newConnectionState) {
            newConnectionState[mu.id] = {
              socketMessageData: {
                id: mu.id,
                opertionState:
                  mu.connectionStatus === "CONNECTED"
                    ? "deviceConnected"
                    : "deviceDisconnected",
              },
            };
          }
          socketDispatch({
            type: "SET_CONNECTION_STATE_MESSAGE",
            payload: {
              connectionState: newConnectionState,
            },
          });
        });

        socketDispatch({
          type: "SET_LOCK_MESSAGE",
          payload: { lockInfo: initialMusLockState },
        });
      }
    });

    getProjectUnitList({
      projectId: id,
      limit: limitMUList,
      continuationToken: continuationToken,
      unitType: "vu",
    })
      .then((res) => {
        if (res && res.data && res.data.devices) {
          let initialVusLockState = _.cloneDeep(lockInfoRef.current);
          VUsDispatch({
            type: "SET_VU_LIST",
            payload: {
              vusList: res.data.devices,
              continuationToken: res.data.continuationToken,
            },
          });
          res.data.devices.forEach((vu) => {
            if (vu.lockInfo && vu.lockInfo.expiresAt) {
              setDeviceLockTimeout(
                vu.id,
                vu.lockInfo.expiresAt,
                setLocTimeoutInstances,
                locTimeoutInstancesRef,
                lockInfoRef,
                socketDispatch
              );
              initialVusLockState[vu.id] =
                typeof vu.lockInfo === "string"
                  ? JSON.parse(vu.lockInfo)
                  : vu.lockInfo;
            }

            // updates connectionState for all VUS
            const newConnectionState = _.cloneDeep(connectionState);
            if (newConnectionState) {
              newConnectionState[vu.id] = {
                socketMessageData: {
                  id: vu.id,
                  opertionState:
                    vu.connectionStatus === "CONNECTED"
                      ? "deviceConnected"
                      : "deviceDisconnected",
                },
              };
            }
            socketDispatch({
              type: "SET_CONNECTION_STATE_MESSAGE",
              payload: {
                connectionState: newConnectionState,
              },
            });
          });
          socketDispatch({
            type: "SET_LOCK_MESSAGE",
            payload: { lockInfo: initialVusLockState },
          });
        }
      })
      .catch((err) => {
        //(err);
      });

    socketDispatch({
      type: "SET_MU_GRAPH_SOCKET_MESSAGES",
      payload: { MUGraphData: {} },
    });
  }
};

export const convertedSummaryTimeSpan = (startTime, endTime) => {
  const diff = moment
    .utc(
      moment
        .duration(moment(endTime).diff(moment(startTime)))
        .as("milliseconds")
    )
    .format("HH:mm:ss.SSS");

  const daysDiff = moment
    .duration(moment(endTime).diff(moment(startTime)))
    .as("days");

  const convertedDayPlusTime = diff
    .split(":")
    .map((el, i) => (i === 0 ? Number(el) + 24 : el))
    .join(":");

  return daysDiff > 1 ? convertedDayPlusTime : diff;
};
