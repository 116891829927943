import React from "react";

const SocketContext = React.createContext({
  socketMUMessageData: {},
  socketVUMessageData: {},
  socketDcSamplerData: {},
  fans: {},
  receiversFans: {},
  cuUnitTemp: {},
  discretes: {},
  lockInfo: {},
  connectionState: {},
  HPStateSocket: {},
  receivers: {},
  socketInstance: undefined,
  socketInstancesProjectDevices: {},
  socketInstanceProject: undefined,
  socketInstanceProjectId: "",
  socketInstanceActiveVU: undefined,
  chargingStateSocket: {},
  devicesInitialStateSocket: {},
  socketDispatch: () => {},
});

export default SocketContext;
