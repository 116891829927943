import React from "react";
import "Screens/DepotDashboard/DepotAquarium/DepotAquarium.scss";
import { Divider, Typography } from "@mui/material";
import AquariumIcon from "./AquariumIcon";
import PropTypes from "prop-types";

const dividerStyle = {
  transform: "translateY(50%)",
  color: "rgba(0, 0, 0, 0.6)",
};

const DepotAquarium = ({ vehiclesData }) => {
  return (
    <div className="depot-aquarium-section-wrapper">
      <div className="charging-icons-container">
        <Divider orientation="horizontal">
          <Typography variant="body2" sx={dividerStyle}>
            100%
          </Typography>
        </Divider>
        <Divider orientation="horizontal">
          <Typography variant="body2" sx={dividerStyle}>
            50%
          </Typography>
        </Divider>
        <Divider orientation="horizontal">
          <Typography variant="body2" sx={dividerStyle}>
            0%
          </Typography>
        </Divider>
        {vehiclesData.map((vehicle) => {
          return <AquariumIcon vehicle={vehicle} key={vehicle.id} />;
        })}
      </div>
    </div>
  );
};

export default DepotAquarium;

DepotAquarium.propTypes = {
  vehiclesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      state: PropTypes.shape({
        batteryLevel: PropTypes.number,
        charging: PropTypes.bool,
      }),
      connectionStatus: PropTypes.string,
    })
  ),
};
