import { useMemo, useCallback, useContext } from "react";
import moment from "moment";

import SocketContext from "Contexts/SocketContext";
import MainContext from "Contexts/MainContext";

import useSelectedProjectData from "CustomHooks/useSelectedProject";

import { getDcSamplerSummaryNumbers } from "Utils/UtilsFunctions";
import { GRAPH_SCALE } from "Utils/GraphUtils";

const useGraphFormat = (
  graphData,
  messageKeys,
  graphScale = GRAPH_SCALE[5],
  isHistoryOn,
  type
) => {
  const { selectedDevice } = useContext(MainContext);
  const { socketDcSamplerData } = useContext(SocketContext);

  const { timezoneStr } = useSelectedProjectData();

  const liveDCSamplerData =
    socketDcSamplerData[selectedDevice.id]?.socketMessageData;

  const { avgVoltageLiveValue } = useMemo(
    () => getDcSamplerSummaryNumbers(liveDCSamplerData),
    [liveDCSamplerData]
  );

  const graphMaxTimestamp = useMemo(() => {
    if (graphData) {
      if (messageKeys && !!messageKeys.length) {
        const sortedKeys = messageKeys.sort();
        const muGraphData = graphData[sortedKeys[0]];
        const { data } = muGraphData;
        if (data && data.length > 0 && typeof data.reduce === "function") {
          const max = data.reduce((prev, current) =>
            prev.timestamp > current.timestamp
              ? prev.timestamp
              : current.timestamp
          );
          return max;
        }
      }
    }
  }, [graphData && messageKeys]);

  const avgValue = useMemo(() => {
    let avg = 0;
    if (!isHistoryOn) {
      avg = avgVoltageLiveValue;
    } else {
      const size = graphData[messageKeys[0]]?.data?.length;
      if (size) {
        for (let i = 0; i < size; i++) {
          avg += graphData[messageKeys[0]].data[i][type];
        }

        avg /= size;
      }
    }

    return avg;
  }, [graphData, messageKeys, isHistoryOn]);

  const formatXAxis = useCallback(
    (tickItem) => {
      if (tickItem && graphMaxTimestamp) {
        if (isHistoryOn) {
          return moment(tickItem).tz(timezoneStr).format("HH:mm:ss.SS");
        }
        const duration = moment.duration(
          moment(tickItem).diff(graphMaxTimestamp)
        );
        const secValue = duration.asSeconds();
        if (secValue) {
          switch (graphScale) {
            case GRAPH_SCALE[5]: {
              if (secValue < -4.9) {
                return -5;
              }
            }
            case GRAPH_SCALE[15]: {
              if (secValue < -14.9) {
                return -15;
              }
            }
            case GRAPH_SCALE[30]: {
              if (secValue < -29.9) {
                return -30;
              }
            }
          }
          return secValue.toFixed(0);
        }
      }
      return "now";
    },
    [timezoneStr, graphMaxTimestamp, graphScale]
  );

  const XAxisTickCount = useCallback(() => {
    if (isHistoryOn) {
      return 9;
    }
    switch (graphScale) {
      case GRAPH_SCALE[5]: {
        return 6;
      }
      case GRAPH_SCALE[15]: {
        return 16;
      }
      case GRAPH_SCALE[30]: {
        return 31;
      }
    }
  }, [graphScale]);

  const tooltipLabelFormatter = useCallback(
    (labelData) => {
      if (moment(labelData).tz(timezoneStr)) {
        return moment(labelData).tz(timezoneStr).format("HH:mm:ss.SSS");
      }
    },
    [timezoneStr, graphData]
  );

  const yAxisDomainPower = useMemo(() => {
    return selectedDevice?.deviceType === "MU" &&
      messageKeys.length === 1 &&
      avgValue <= 50
      ? [0, 300]
      : [0, 120];
  }, [avgValue]);

  const graphUnits = useMemo(() => {
    return selectedDevice?.deviceType === "MU" &&
      messageKeys.length === 1 &&
      avgValue <= 50
      ? "(W)"
      : "(kW)";
  }, [avgValue]);

  const tooltipsFormatter = useCallback(
    (value, name, props) => {
      return value
        ? graphUnits === "(W)"
          ? value.toFixed(1) + " (W)"
          : value.toFixed(2) + " (kW)"
        : "";
    },
    [graphData, graphUnits]
  );

  const yAxisTicks = useMemo(() => {
    return selectedDevice?.deviceType === "MU" &&
      messageKeys.length === 1 &&
      avgValue <= 50
      ? [0, 50, 100, 150, 200, 250, 300]
      : [0, 30, 60, 90, 120];
  }, [avgValue]);

  const graphPowerFormatter = useMemo(() => {
    return (value) =>
      avgValue <= 50 && messageKeys?.length === 1
        ? Number(value)
        : Number(value) / 1000;
  }, [avgValue, messageKeys]);

  const formatYAxis = useCallback(
    (format) => {
      const yAxisValue = Number(format);
      return yAxisValue.toFixed(0);
    },
    [avgValue]
  );

  const yAxisTickCount = useMemo(() => yAxisTicks.length, [yAxisTicks]);

  const maxTicksArray = [
    graphMaxTimestamp - 1000,
    graphMaxTimestamp - 2000,
    graphMaxTimestamp - 3000,
    graphMaxTimestamp - 4000,
    graphMaxTimestamp - 5000,
    graphMaxTimestamp - 6000,
    graphMaxTimestamp - 7000,
    graphMaxTimestamp - 8000,
    graphMaxTimestamp - 9000,
    graphMaxTimestamp - 10000,
    graphMaxTimestamp - 11000,
    graphMaxTimestamp - 12000,
    graphMaxTimestamp - 13000,
    graphMaxTimestamp - 14000,
    graphMaxTimestamp - 15000,
    graphMaxTimestamp - 16000,
    graphMaxTimestamp - 17000,
    graphMaxTimestamp - 18000,
    graphMaxTimestamp - 19000,
    graphMaxTimestamp - 20000,
    graphMaxTimestamp - 21000,
    graphMaxTimestamp - 22000,
    graphMaxTimestamp - 23000,
    graphMaxTimestamp - 24000,
    graphMaxTimestamp - 25000,
    graphMaxTimestamp - 26000,
    graphMaxTimestamp - 27000,
    graphMaxTimestamp - 28000,
    graphMaxTimestamp - 29000,
    graphMaxTimestamp - 30000,
  ];

  const ticksArray = useMemo(() => {
    if (isHistoryOn) {
      const array = [];
      const graphScaleSec =
        typeof graphScale === "number" ? graphScale : graphScale.split(" ")[0];
      for (let i = 1; i <= 8; i++) {
        array.push(graphMaxTimestamp - i * ((graphScaleSec * 1000) / 8));
      }
      return array;
    } else {
      return graphScale === GRAPH_SCALE[5]
        ? maxTicksArray.slice(0, 5)
        : graphScale === GRAPH_SCALE[15]
        ? maxTicksArray.slice(0, 15)
        : maxTicksArray;
    }
  }, [isHistoryOn, graphScale, graphMaxTimestamp]);

  return {
    formatXAxis,
    XAxisTickCount,
    graphMaxTimestamp,
    tooltipsFormatter,
    tooltipLabelFormatter,
    yAxisDomainPower,
    graphUnits,
    yAxisTicks,
    graphPowerFormatter,
    formatYAxis,
    ticksArray,
    yAxisTickCount,
  };
};

export default useGraphFormat;
