import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "Screens/Dashboard/Dashboard";
import Login from "Screens/Login/Login";
import ProjectView from "Screens/ProjectView/ProjectView";
import DepotDashboard from "Screens/DepotDashboard/DepotDashboard";

import MainDashboardWrapper from "Screens/ProjectDashboard/ContextWrapper";
import { LoadScript } from "@react-google-maps/api";
import ProjectAnalyticsTool from "Screens/AnalyticsTool/ProjectAnalyticsTool";

const AppRouter = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyBdr5DLVsc0bCKtZkyU_7CKUKqTZkeaMpc">
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/dashboard/live/project/:id/inline">
          <ProjectView />
        </Route>
        <Route exact path="/dashboard/live/project/:id/:variant?">
          <MainDashboardWrapper />
        </Route>
        <Route path="/dashboard/history/project/:id">
          <ProjectAnalyticsTool />
        </Route>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/dashboard/live/depot/:projectId/:depotId">
          <DepotDashboard />
        </Route>
      </Switch>
    </LoadScript>
  );
};

export default AppRouter;
