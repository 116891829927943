import React, { useMemo, useContext } from "react";
import SocketContext from "Contexts/SocketContext";
import MainContext from "Contexts/MainContext";
import VUsContext from "Contexts/VUsContext";
import { getDcSamplerSummaryNumbers } from "Utils/UtilsFunctions";
import "Components/DcSampler/DcSamplerSummary.scss";

const DcSamplerSummary = ({ view = "MU" }) => {
  const { selectedDevice } = useContext(MainContext);
  const { socketDcSamplerData } = useContext(SocketContext);
  const { activeVU } = useContext(VUsContext);

  const deviceToShow = view === "MU" ? selectedDevice.id : activeVU?.id;
  const liveDCSamplerData =
    socketDcSamplerData[deviceToShow]?.socketMessageData;

  const { avgPowerLiveValue, avgVoltageLiveValue, avgCurrentLiveValue } =
    useMemo(
      () => getDcSamplerSummaryNumbers(liveDCSamplerData),
      [liveDCSamplerData]
    );

  const voltageDisplay = useMemo(() => {
    return avgVoltageLiveValue
      ? avgVoltageLiveValue < 100
        ? Number(avgVoltageLiveValue).toFixed(1)
        : Number(avgVoltageLiveValue).toFixed(0)
      : 0;
  }, [avgVoltageLiveValue]);

  const powerDisplay = useMemo(() => {
    return {
      value: avgPowerLiveValue
        ? avgPowerLiveValue < 1000
          ? Number(avgPowerLiveValue).toFixed(1)
          : Number(avgPowerLiveValue / 1000).toFixed(2)
        : 0,
      units: avgPowerLiveValue < 1000 ? "W" : "KW",
    };
  }, [avgPowerLiveValue]);

  const currentDisplay = useMemo(() => {
    return avgCurrentLiveValue
      ? avgCurrentLiveValue < 10
        ? avgCurrentLiveValue.toFixed(2)
        : avgCurrentLiveValue.toFixed(1)
      : 0;
  }, [avgCurrentLiveValue]);

  return (
    <div className="dcSampler-data-summary">
      <div className="dcSampler-data-summary-item">
        <div className="dcSampler-data-summary-item-value">
          {voltageDisplay || 0}
        </div>
        <div className="dcSampler-data-summary-item-unit">V</div>
      </div>
      <div className="dcSampler-data-summary-item">
        <div className="dcSampler-data-summary-item-value">
          {currentDisplay || 0}
        </div>
        <div className="dcSampler-data-summary-item-unit">A</div>
      </div>
      <div className="dcSampler-data-summary-item">
        <div className="dcSampler-data-summary-item-value">
          {powerDisplay ? powerDisplay.value : 0}
        </div>
        <div className="dcSampler-data-summary-item-unit">
          {powerDisplay ? powerDisplay.units : "W"}
        </div>
      </div>
    </div>
  );
};

export default DcSamplerSummary;
