import "Screens/DepotDashboard/DepotOverviewTable/DepotOverviewTable.scss";
import { Chip, Typography } from "@mui/material";
import BatteryLevelDisplay from "../BatteryLevelDisplay/BatteryLevelDisplay";
import { DepotVehicleStatusIcon } from "../VehicleStatusIcon/VehicleStatusIcon";
import { DepotTableIconContainer } from "../DepotTableIconContainer/DepotTableIconContainer";
import getPowerDisplayString from "../Utils/GetPowerDisplayString";
import { WithSlidingAnimation } from "../Hooks/WithSlidingAnimation";

const getVehicleData = (params) => params.row;

export const columns = [
  {
    field: "icon",
    headerName: "",
    flex: 2,
    visibility: "hidden",
    columnSeparator: "hidden",
    sortable: false, // hide the sort arrrow
    disableColumnMenu: "hidden", // hide the menu button
    renderCell: (params) => {
      const vehicle = getVehicleData(params);
      return (
        <WithSlidingAnimation
          active={vehicle.state.isTransitioning}
          direction={vehicle.state.charging ? "right" : "left"}
        >
          <DepotVehicleStatusIcon vehicle={vehicle} />
        </WithSlidingAnimation>
      );
    },
    headerClassName: "lastcolumnSeparator",
  },
  {
    // empty column to match MainTableData's column width
    field: "",
    headerName: "",
    flex: 3,
    visibility: "hidden",
    sortable: false,
    disableColumnMenu: "hidden",
    columnSeparator: "hidden",
  },
  {
    field: "id",
    headerName: "",
    flex: 3,
    sortable: false,
    disableColumnMenu: "hidden",
    renderCell: (params) => {
      const vehicle = getVehicleData(params);
      if (!vehicle.id) return null;
      return (
        <WithSlidingAnimation
          active={vehicle.state.isTransitioning}
          direction={vehicle.state.charging ? "right" : "left"}
        >
          <DepotTableIconContainer height={"22px"}>
            <Chip variant="outlined" label={vehicle.id} />
          </DepotTableIconContainer>
        </WithSlidingAnimation>
      );
    },
  },
  {
    field: "batteryStatus",
    headerName: "",
    flex: 10,
    sortable: false,
    disableColumnMenu: "hidden",
    headerClassName: "lastcolumnSeparator",
    renderCell: (params) => {
      const vehicle = getVehicleData(params);
      return (
        <WithSlidingAnimation
          active={vehicle.state.isTransitioning}
          direction={vehicle.state.charging ? "right" : "left"}
        >
          <BatteryLevelDisplay batteryLevel={params.row?.state?.batteryLevel} />
        </WithSlidingAnimation>
      );
    },
  },
  {
    field: "power",
    headerName: "",
    flex: 2,
    sortable: false,
    disableColumnMenu: "hidden",
    renderCell: (params) => {
      const vehicle = getVehicleData(params);
      const power = vehicle.state?.power;
      if (power === null || power === undefined) return;
      return (
        <WithSlidingAnimation
          active={vehicle.state.isTransitioning}
          direction={vehicle.state.charging ? "right" : "left"}
        >
          <Typography variant="labelSmall">
            {getPowerDisplayString(power)}
          </Typography>
        </WithSlidingAnimation>
      );
    },
  },
  // {
  //   field: "range",
  //   headerName: "",
  //   flex: 2,
  //   sortable: false,
  //   disableColumnMenu: "hidden",
  //   renderCell: (params) => {
  //     return params.row?.state?.vuPaired ? (
  //       <span>{params.row?.state?.range || 150} km</span>
  //     ) : null;
  //   },
  // },
  // {
  //   field: "time",
  //   headerName: "",
  //   flex: 2,
  //   sortable: false,
  //   disableColumnMenu: "hidden",
  //   headerClassName: "lastcolumnSeparator",
  //   renderCell: (params) => {
  //     return params.row?.state?.vuPaired ? (
  //       <span>{params.row?.state?.chargingTime || 10} min</span>
  //     ) : null;
  //   },
  // },
];
