import moment from "moment";
import { observer } from "mobx-react-lite";
import { Box, alpha } from "@mui/material";
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  Line,
  YAxis,
} from "recharts";

//Components
import AnalyticGraphTooltip from "Components/Tooltip/AnalyticGraphTooltip";
import { TimeRulerProps } from "Components/Graphs/AdvancedParamsGraphs";

//Hooks
import ProjectAnalyticsStore from "MobxStores/ProjectAnalyticsStore";
import { getPalette } from "Themes/colorPalette";
import { useAppStore } from "MobxStores/context";
import useSelectedProjectData from "CustomHooks/useSelectedProject";

const { palette } = getPalette();

const SelectedTimeGraph: TimeRulerProps = ({
  showLabelEveryXTicks = 6,
  tickCount = 30,
  maxSecondsForShowingMilliseconds = 2,
  xAxisColor = palette.primary.main,
  timeLabelColor = palette.accent.gray[900],
  backgroundColor = alpha(palette.primary.main, 0.08),
  gridLineColor = alpha(palette.primary.main, 0.08),
}) => {
  const { projectAnalyticsStore } = useAppStore();
  const {
    selectedTimeRange,
    selectedTimespanValues,
    selectedActivityDevice,
    showTimeRulerBorder,
    deviceActivityData,
  } = projectAnalyticsStore as ProjectAnalyticsStore;
  const { timezoneStr } = useSelectedProjectData();

  const { startTime, endTime } = selectedTimespanValues;
  const shouldShowMilliseconds =
    moment(endTime).diff(moment(startTime), "seconds") <=
    maxSecondsForShowingMilliseconds;

  const timeFormat = shouldShowMilliseconds ? "HH:mm:ss.SSS" : "HH:mm:ss";

  const tickFormatter = (value: string, index: number) => {
    if (index % showLabelEveryXTicks === 0)
      return moment(value).format(timeFormat);
    else return "";
  };

  if (selectedTimeRange.length === 0) return null;
  if (!deviceActivityData?.[0]?.timestamp) return null;

  const offset =
    moment(deviceActivityData[0].timestamp).utcOffset() * 60 * 1000;
  const projectOffset = moment(deviceActivityData[0].timestamp)
    .tz(timezoneStr)
    .utcOffset();

  return (
    <Box
      width="100%"
      sx={{
        backgroundColor,
        "& .recharts-text.recharts-cartesian-axis-tick-value": {
          stroke: `${timeLabelColor}`,
          strokeWidth: "0.4px",
        },
        borderRight: showTimeRulerBorder.right
          ? `2px solid ${palette.primary.main}`
          : "none",
        borderLeft: showTimeRulerBorder.left
          ? `2px solid ${palette.primary.main}`
          : "none",
      }}
    >
      <ResponsiveContainer width="100%" height={30}>
        <LineChart
          data={selectedTimeRange}
          margin={{ top: 5, right: 6, left: 6 }}
          syncId="graph-sync-id"
          syncMethod="value"
        >
          <CartesianGrid
            horizontal={false}
            vertical
            height={14}
            y={7}
            stroke={gridLineColor}
          />
          <XAxis
            dataKey={(d) =>
              moment(d.timestamp)
                .subtract(offset, "milliseconds")
                .add(projectOffset, "minutes")
                .valueOf()
            }
            axisLine={false}
            tickLine={false}
            tickMargin={3}
            interval="preserveStartEnd"
            tickCount={tickCount}
            tickFormatter={tickFormatter}
            type="number"
            fontFamily="Urbanist, sans-serif"
            stroke={xAxisColor}
            domain={[moment(startTime).valueOf(), moment(endTime).valueOf()]}
            allowDataOverflow
            mirror
          />
          <YAxis hide />
          <Line
            type="monotone"
            opacity={0}
            dot={false}
            dataKey={() => 1}
            isAnimationActive={false}
            strokeWidth={5}
            activeDot={{
              cy: 15,
            }}
          />
          <Tooltip
            isAnimationActive={false}
            content={
              <AnalyticGraphTooltip
                payload={selectedTimeRange}
                displayName={String(selectedActivityDevice)}
                timeGraph
              />
            }
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default observer(SelectedTimeGraph);
