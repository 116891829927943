import Image from "Components/Image/Image";
import ReadyToChargeIcon from "Utils/Images/DepotIcons/vu-ready-to-charge-icon.svg";

import "Screens/DepotDashboard/DepotSummary/DepotSummary.scss";

export const columns = [
  {
    field: "vehicle-summary-icon",
    flex: 2,
    headerName: "",
    align: "center",
    visibility: "hidden",
    columnSeparator: "hidden",
    sortable: false, // hide the sort arrrow
    disableColumnMenu: "hidden", // hide the menu button
    renderCell: (params) =>
      params.id ? (
        <Image src={params.row.statusIcon} className="spot-status-icon" />
      ) : (
        <Image src={ReadyToChargeIcon} className="spot-status-icon" />
      ),
    headerClassName: "lastcolumnSeparator",
  },
  {
    field: "vehicle-summary-id",
    flex: 5,
    headerName: "",
    align: "left",
    display: "none",
    visibility: "hidden",
    sortable: false,
    disableColumnMenu: "hidden",
    columnSeparator: "hidden",
    renderCell: (params) => {
      return params.row.type;
    },
  },
  {
    field: "vehicle-summary-name",
    flex: 2,
    headerName: "",
    align: "center",
    sortable: false,
    visibility: "hidden",
    disableColumnMenu: "hidden",
    renderCell: (params) => {
      return params.row.count;
    },
  },
];
