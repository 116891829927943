import { TextField, MenuItem, useTheme } from "@mui/material";
import { useAppStore } from "MobxStores/context";
import { observer } from "mobx-react-lite";
import useSelectedProjectData from "CustomHooks/useSelectedProject";
import ProjectAnalyticsStore from "MobxStores/ProjectAnalyticsStore";

const SelectDeviceActivity = () => {
  const { projectAnalyticsStore } = useAppStore();
  const {
    selectedDevices: devices,
    selectedActivityDevice: selectedDevice,
    setSelectedActivityDevice: setSelectedDevice,
    getDeviceActivityData,
    clearDeviceActivityData,
    setIsLoadingDataFromServer,
    getSummaryTableData,
  } = projectAnalyticsStore as ProjectAnalyticsStore;
  const { timezoneStr } = useSelectedProjectData();
  const theme = useTheme();

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (selectedDevice !== event.target.value) {
      clearDeviceActivityData();
      setIsLoadingDataFromServer(true);
      setSelectedDevice(event.target.value);
      await getDeviceActivityData(timezoneStr, event.target.value);
      await getSummaryTableData(timezoneStr, event.target.value);
    }
  };

  return (
    <TextField
      size="small"
      select
      label="Activity by"
      value={selectedDevice}
      onChange={handleChange}
      sx={{
        flexBasis: "200px",
        "& .MuiSelect-select": {
          minWidth: "150px",
          textAlign: "left",
        },
        "& fieldset": {
          borderWidth: "1px !important",
        },
      }}
      InputLabelProps={{
        style: {
          color: theme.palette.accent.gray.main,
        },
      }}
    >
      {Object.keys(devices)?.length > 0 ? (
        Object.entries(devices).map(
          (
            [
              deviceId,
              {
                device: { name },
              },
            ],
            index
          ) => (
            <MenuItem key={deviceId + index} value={deviceId}>
              {name}
            </MenuItem>
          )
        )
      ) : (
        <MenuItem value="No devices" disabled>
          No devices
        </MenuItem>
      )}
    </TextField>
  );
};

export default observer(SelectDeviceActivity);
