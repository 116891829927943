import PropTypes from "prop-types";

const CustomTooltip = ({ header, body }) => {
  return (
    <div className="custom-tooltip">
      {header && <div className="tooltip-header">{header}</div>}
      {body && <div className="tooltip-body">{body}</div>}
    </div>
  );
};

export default CustomTooltip;

CustomTooltip.propTypes = {
  header: PropTypes.node,
  body: PropTypes.node,
};
