import React from "react";
import DashboardMain from "Screens/ProjectDashboard/main";
import axios from "axios";

import { DashboardProvider } from "Screens/ProjectDashboard/lib/context/dashboardContext";

const ContextWrapper = () => {
  if (!axios.defaults.headers.common["Authorization"]) {
    return null;
  }
  return (
    <DashboardProvider>
      <DashboardMain />
    </DashboardProvider>
  );
};

export default ContextWrapper;
