import "./ActivityGraph.scss";
import { Box, alpha } from "@mui/material";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
} from "recharts";

// import { ReactComponent as BrushHandle } from "Utils/Images/GlobalIcons/graph-brush-start-handle.svg";
import { getPlaceHolderActivityData } from "./PlaceholderData";
import useSelectedProjectData from "CustomHooks/useSelectedProject";
import { getPalette } from "Themes/colorPalette";
import {
  ActivityProps,
  LabelProps,
  BrushStartEndIndex,
} from "Screens/AnalyticsTool/ActivityGraph";
import { setCustomBrushHandleClassnames } from "Utils/AnalyticUtils";

const { palette } = getPalette();

// const CustomLabel: React.FC<LabelProps> = ({ x, y, stroke, value }) => {
//   return (
//     // TODO: Add label/icons here for events on the graph when api provides this data
//     <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
//       {/* *** */}
//     </text>
//   );
// };

const ActivityGraph: React.FC<ActivityProps> = ({
  data,
  onTimespanChange,
  height = 50,
  width = "100%",
  padding = 6,
  xAxisTickInterval = "preserveEnd",
  showBrush = true,
  brushStart,
  brushEnd,
  brushHandleColor = alpha(palette.primary.main, 0.8),
  brushedAreaColor = palette.primary.main,
  activityLineColor = palette.accent.primary[600],
  timeLabelColor = palette.accent.gray[800],
  travellerWidth = 3,
  travellerZoomFactor = 3.5,
  // onMouseDown,
  // onMouseUp,
  // onMouseLeave,
}) => {
  const [displayData, setDisplayData] = useState(data);
  const [brushSelect, setBrushSelect] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);

  const isDataEmpty = data.length === 0;
  const { timezoneStr } = useSelectedProjectData();

  const handleBrushChange = ({ startIndex, endIndex }: BrushStartEndIndex) => {
    if (!startIndex || !endIndex) return;
    onTimespanChange({ startIndex, endIndex });
  };

  // const handleGraphClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   if (e.altKey) {
  //     setBrushSelect(true);
  //   } else {
  //     setBrushSelect(false);
  //   }
  // };

  useEffect(() => {
    if (isDataEmpty) {
      setDisplayData(getPlaceHolderActivityData());
    } else {
      setDisplayData(data);
    }
  }, [data, isDataEmpty]);

  if (!displayData.length) return null;

  return (
    <Box
      width={width}
      sx={{
        position: "relative",
        height: height,
        py: `${padding}px`,
        "& .recharts-cartesian-axis-ticks": {
          transform: "translateY(-42px)",
        },

        "& .chart-brush .recharts-cartesian-axis-ticks": {
          display: "none",
        },

        "& .chart-brush": {
          position: "absolute",
          top: 0,
          left: 0,
        },

        "& .chart-brush .recharts-surface": {
          overflow: "visible",
        },

        ".recharts-layer.recharts-line": {
          // strokeLinecap: "square",
        },

        "& .recharts-brush-texts": {
          stroke: timeLabelColor,
          strokeWidth: "0.6px",
        },

        "& .recharts-brush .recharts-brush-slide": {
          fill: brushedAreaColor,
          fillOpacity: 0.08,
        },

        "& .custom-brush-handle": {
          width: travellerWidth,
          height: 40,
          backgroundColor: brushHandleColor,
          transition: "all 0.15s ease-in-out",
          transformOrigin: "center",
        },

        "& .custom-brush-handle-right": {
          transform: `translateX(-${travellerWidth / travellerZoomFactor}px)`,
        },

        "& .custom-brush-handle.hovered": {
          fillOpacity: 0.5,
        },

        "& .custom-brush-handle-right.hovered": {
          width: travellerWidth * travellerZoomFactor,
          transform: `translateX(-${travellerWidth}px)`,
        },

        "& .custom-brush-handle-left.hovered": {
          width: travellerWidth * travellerZoomFactor,
          transform: `translateX(-${travellerWidth}px)`,
        },
      }}
      // onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => handleGraphClick(e)}
    >
      <ResponsiveContainer>
        <LineChart
          data={displayData}
          margin={{
            top: 11,
            right: 30,
            left: 30,
            bottom: 0,
          }}
        >
          <CartesianGrid
            horizontal={false}
            vertical={true}
            height={height / 2 - padding / 2}
            y={0}
          />
          <XAxis
            dataKey="timestamp"
            axisLine={false}
            tickLine={false}
            tickMargin={height + 6}
            tickFormatter={(tick) => moment(tick).format("HH")}
            interval={isDataEmpty ? 0 : xAxisTickInterval}
            tick={{ fontSize: 12, fontFamily: "Urbanist, sans-serif" }}
          />
          <Line
            type="monotone"
            strokeWidth={8}
            dataKey={(data) => (data.chargeActive ? 1 : null)}
            strokeLinecap="square"
            stroke={activityLineColor}
            opacity={0.6}
            fill={activityLineColor}
            dot={false}
            isAnimationActive={false}
            // strokeDasharray="1"
            // label={<CustomLabel />}
          />
        </LineChart>
      </ResponsiveContainer>
      <ResponsiveContainer className="chart-brush" key={brushStart}>
        <LineChart
          data={displayData}
          // onMouseDown={onMouseDown}
          // onMouseUp={onMouseUp}
          // onMouseLeave={onMouseLeave}
          margin={{
            top: 0,
            right: 25,
            left: 25,
            bottom: 5,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {!isDataEmpty && showBrush && (
            <Brush
              // dataKey={(d) => {
              //   const timestamp = d.timestamp;
              //   const date = moment(timestamp).tz(timezoneStr);
              //   return date;
              // }}
              dataKey="timestamp"
              fill={alpha(palette.primary.main, 0)}
              tickFormatter={(timestamp) =>
                moment(timestamp).format("HH:mm:ss")
              }
              startIndex={brushStart}
              endIndex={brushEnd}
              onChange={handleBrushChange}
              stroke={brushHandleColor}
              alwaysShowText
              traveller={(props) => {
                const { x, y } = props;
                setCustomBrushHandleClassnames();
                return (
                  <rect
                    x={x}
                    y={y}
                    fill={brushHandleColor}
                    className={`custom-brush-handle ${isHovered ? "hovered" : ""}`}
                  />
                );
              }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default observer(ActivityGraph);
