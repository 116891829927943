import React, { useContext, useState, useRef, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";

//Components
import ExportButton from "Components/Buttons/ExportButton/ExportButton";
import TimeRangeSelection from "Components/TimeRangeSelection/TimeRangeSelection";
import ExportRawData from "Components/RawData/ExportRawData";
import SpanRangeButton from "Components/Buttons/SpanRangeButtons/SpanRangeButton";
import AppButton from "Components/Buttons/AppButton/AppButton";
import CustomLoader from "Components/Loader/Loader";

//Context
import MainContext from "Contexts/MainContext";
import VUsContext from "Contexts/VUsContext";

//Hooks
import useSelectedProjectData from "CustomHooks/useSelectedProject";

//Utils
import { getHistory } from "Utils/APIUtils";
import { getHistoryFormattedData, getHistoryErrorMsg } from "Utils/GraphUtils";
import { defaultModalState } from "Utils/DefaultValues/ProjectViewDefaultValues";
import {
  enableDownloadGraphAsPNG,
  graphsMissingDataPopupMessages,
} from "Utils/featureFlags";

const HistoryGraphOptions = ({
  setHistorySpan,
  setHistoryGraphData,
  setHistoryDataModal,
  setIsHistoryLoaded,
  setXAxisMinSpanTimestamp,
  handleDownloadAsPng,
  isDownloading,
}) => {
  const [historyData, setHistoryData] = useState([]);
  const [activeVuHistoryData, setActiveVuHistoryData] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [activeVuFileName, setActiveVuFileName] = useState(null);
  const [pngFileName, setPngFileName] = useState(null);
  const [loadedTimeRange, setLoadedTimeRang] = useState({
    date: "",
    span: "",
    startTime: "",
  });
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [isExportDisabled, setIsExportDisabled] = useState(false);

  const { shouldAcquireToken } = useContext(MainContext);
  const { selectedDevice } = useContext(MainContext);
  const { activeVU } = useContext(VUsContext);
  const { timezoneStr } = useSelectedProjectData();

  const shouldReloadData = useRef(false);
  shouldReloadData.current = false;

  const closeErrorModal = () => {
    if (!activeVU) {
      setHistoryGraphData({});
    }
    return setHistoryDataModal(defaultModalState);
  };

  useEffect(() => {
    if (loadedTimeRange.date !== "" && loadedTimeRange.startTime !== "") {
      setXAxisMinSpanTimestamp(
        new Date(
          moment(`${loadedTimeRange.date}T${loadedTimeRange.startTime}`)
            .tz(timezoneStr)
            .format("YYYY-MM-DDTHH:mm:ss.SSSS")
        ).getTime()
      );
    }
  }, [loadedTimeRange, setXAxisMinSpanTimestamp, timezoneStr]);

  const loadHistory = (dataToLoad, spanVal, dateVal, startTime) => {
    const mappedHistoryData = {};
    setIsHistoryLoading(true);
    // Send both axios as once.
    return Promise.all([
      getHistory({
        ...dataToLoad,
        deviceType: selectedDevice?.deviceType,
        deviceId: selectedDevice?.id,
      }),

      // check if needs to send for activeVU
      // if not, resolve to null.
      selectedDevice?.deviceType === "MU" && activeVU
        ? getHistory({
            ...dataToLoad,
            deviceType: activeVU?.deviceType,
            deviceId: activeVU?.id,
          })
        : null,
    ])
      .then((values) => {
        // return array of resolve for each promise
        const [selectedDeviceHistoryRes, activeVUHistoryRes] = values;

        if (selectedDeviceHistoryRes?.status !== 200) {
          throw new Error("Couldn't Load device data. Please try again.");
        }
        // handle successful response for selectedDevice
        if (selectedDeviceHistoryRes) {
          const { data } = selectedDeviceHistoryRes;
          if (data?.timeSeries?.length) {
            mappedHistoryData[data.deviceId] = getHistoryFormattedData(data);
          }

          setHistorySpan(`${spanVal} Sec`);
          setHistoryData(
            data.timeSeries.map((point) => {
              return {
                ...point,
                time: moment(point.timestamp)
                  .tz(timezoneStr)
                  .format("D-M-Y H:m:s"),
              };
            })
          );
          setFileName(
            `${data.deviceId}_${data.deviceType}_${dateVal}T${startTime}Z_${spanVal}-seconds`
          );
        } else {
          setHistoryData(null);
          setFileName(null);
        }

        // handle successful response for activeVU
        if (activeVUHistoryRes) {
          const { data } = activeVUHistoryRes;
          if (data?.timeSeries?.length) {
            mappedHistoryData[data.deviceId] = getHistoryFormattedData(data);
          }
          setActiveVuHistoryData(
            data.timeSeries.map((point) => {
              return {
                ...point,
                time: moment(point.timestamp)
                  .tz(timezoneStr)
                  .format("D-M-Y H:m:s"),
              };
            })
          );
          setActiveVuFileName(
            `${data.deviceId}_${data.deviceType}_${dateVal}T${startTime}Z_${spanVal}-seconds`
          );
        } else {
          setActiveVuHistoryData(null);
          setActiveVuFileName(null);
        }
        setHistoryGraphData(mappedHistoryData);

        // handle cases for missing data
        const popupMsg = getHistoryErrorMsg(
          selectedDevice,
          activeVU,
          selectedDeviceHistoryRes?.data?.timeSeries?.length,
          activeVUHistoryRes?.data?.timeSeries?.length
        );

        if (graphsMissingDataPopupMessages && popupMsg) {
          setHistoryDataModal({
            isModalOpened: true,
            modalMessage: popupMsg,
            executeOnCancel: null,
            executeOnSubmit: () => {
              closeErrorModal();
            },
          });
        }
        setLoadedTimeRang({
          span: spanVal,
          date: dateVal,
          startTime: startTime,
        });
        setIsHistoryLoading(false);
        setIsHistoryLoaded(true);

        return values;
      })
      .catch((error) => {
        // handle error for requests (40* error);
        console.log("error from load history: ", error);
        setIsHistoryLoading(false);
        setHistoryDataModal({
          isModalOpened: true,
          modalMessage: `Please Press Load Again`,
          executeOnCancel: null,
          executeOnSubmit: () => {
            closeErrorModal();
          },
        });
        setLoadedTimeRang({
          span: spanVal,
          date: dateVal,
          startTime: startTime,
        });
        return error;
      });
  };
  //do we realy need this logic? Why shouldAcquireToken didn't used by itself?
  // needs to check and update on each render ( useEffect not providing the desired result)
  if (shouldAcquireToken !== shouldReloadData.current) {
    shouldReloadData.current = shouldAcquireToken;
  }

  useEffect(() => {
    if (!historyData?.length && !activeVuHistoryData?.length) {
      setIsExportDisabled(true);
    } else {
      setIsExportDisabled(false);
    }
    setIsHistoryLoading(false);
  }, [historyData, activeVuHistoryData]);

  useEffect(() => {
    setFileName(null);
    setActiveVuFileName(null);
    setPngFileName(null);
    setHistoryData([]);
    setActiveVuHistoryData([]);
    setIsExportDisabled(true);
  }, [selectedDevice?.id]);

  useEffect(() => {
    if (activeVU && activeVuFileName) {
      setPngFileName(
        `${selectedDevice.id}_${selectedDevice.deviceType}_${activeVU.id}_${activeVU.deviceType}_${loadedTimeRange.date}T${loadedTimeRange.startTime}Z_${loadedTimeRange.span}-seconds`
      );
    } else {
      setPngFileName(fileName);
    }
  }, [fileName, activeVuFileName, activeVU]);

  useEffect(() => {
    if (loadedTimeRange.date !== "")
      setLoadedTimeRang({
        date: "",
        span: "",
        startTime: "",
      });
  }, [selectedDevice?.id]);

  return (
    <div className="history-graph-options-wrapper">
      <TimeRangeSelection onLoadFunction={loadHistory} maxValue="259200" />
      {/* <ExportButton
        fileNameArr={[fileName, activeVuFileName]}
        csvDataArr={[historyData, activeVuHistoryData]}
        isDisabled={isExportDisabled}
        isAnalytic={false}
      /> */}
      <ExportRawData
        timeRange={loadedTimeRange}
        shouldDisable={isExportDisabled}
        isHistoryLoading={isHistoryLoading}
      />
      {enableDownloadGraphAsPNG && (
        <AppButton
          onClick={() => handleDownloadAsPng(pngFileName)}
          text={isDownloading ? "Downloading..." : "Download PNG"}
          isDisabled={
            (!activeVuHistoryData?.length && !historyData?.length) ||
            isDownloading
          }
        />
      )}
      <SpanRangeButton
        loadedTimeRange={loadedTimeRange}
        onClick={loadHistory}
      />
      <CustomLoader
        className={`${isHistoryLoading ? "history-graph-loader" : "hidden"}`}
        width={20}
        height={20}
      />
    </div>
  );
};

export default HistoryGraphOptions;

HistoryGraphOptions.propTypes = {
  setHistorySpan: PropTypes.func,
  setHistoryGraphData: PropTypes.func,
  setHistoryDataModal: PropTypes.func,
  setIsHistoryLoaded: PropTypes.func,
  setXAxisMinSpanTimestamp: PropTypes.func,
  handleDownloadAsPng: PropTypes.func,
  isDownloading: PropTypes.bool,
};
