import moment from "moment";
import {
  Payload,
  ValueType,
  NameType,
} from "recharts/types/component/DefaultTooltipContent";

import "./AnalyticTooltip.scss";
import { Stack } from "@mui/material";

interface IAnalyticTooltip extends Payload<ValueType, NameType> {
  body?: boolean;
  timeGraph?: boolean;
  displayName?: string;
  graphUnits?: string;
  label?: number;
}

const AnalyticGraphTooltip: React.FC<IAnalyticTooltip> = ({
  payload,
  body = true,
  timeGraph = false,
  label,
  displayName,
  graphUnits,
}) => {
  if (!payload || !payload.length || !body) return null;
  let formattedStringValue;
  if (!timeGraph) {
    const isKw = payload[0]?.value > 1000;
    const isWh = graphUnits === "wH";

    formattedStringValue = isWh
      ? `${Math.round(Number(payload[0]?.value / 1000))} ${graphUnits}`
      : isKw
      ? `${Number(payload[0]?.value / 1000).toFixed(2)} k${graphUnits || "W"}`
      : `${Number(payload[0]?.value).toFixed(2)} ${graphUnits || "W"}`;
  }

  return (
    <div className="tooltip-wraper">
      {timeGraph ? (
        <div>
          {/* <p>{displayName}</p> */}
          <p>{moment(label)?.format("HH:mm:ss.SSS")}</p>
        </div>
      ) : (
        <Stack direction={"row"} spacing={1}>
          <span>{formattedStringValue}</span>
        </Stack>
      )}
    </div>
  );
};

export default AnalyticGraphTooltip;
