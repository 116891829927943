import React from "react";
import PropTypes from "prop-types";
import "Components/Inputs/TimeInput.scss";

const TimeInput = ({
  value,
  step,
  onChange,
  onFocus,
  label,
  inputClassName,
  onBlur,
}) => {
  return (
    <div className="app-time-input-wrapper">
      <label>{label} </label>
      <input
        className={`app-time-input ${inputClassName}`}
        type="time"
        value={value}
        step={step}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export default TimeInput;

TimeInput.propTypes = {
  value: PropTypes.string,
  step: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  inputClassName: PropTypes.string,
};
