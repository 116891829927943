import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";
import { ThemeMode } from "GlobalTypes";
import { PropsWithChildren } from "react";
import { getPalette } from "./colorPalette";
import { baseStyles } from "Themes/baseComponentStyles";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
    labelSmall: true;
    toolmenu: true;
  }
}

const getThemeStyle = (mode: ThemeMode): ThemeOptions => {
  return baseStyles;
};

export const generateTheme = (mode: ThemeMode) => {
  const theme = createTheme(getPalette(mode), getThemeStyle(mode));
  return theme;
};

const theme = generateTheme("light");

const GlobalThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GlobalThemeProvider;
