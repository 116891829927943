import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

//Components
import MU from "Screens/ProjectView/Devices/MU/MU";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";
import MUsContext from "Contexts/MUsContext";
import { useAppStore } from "MobxStores/context";

//Utils
import {
  getIsDeviceDisconnected,
  getDeviceToolTip,
  handleDeviceClick,
} from "Utils/UtilsFunctions";
import arrowRight from "Utils/Images/GlobalIcons/arrow-down-blue.svg";
import arrowDown from "Utils/Images/GlobalIcons/arrow-right-blue.svg";

const chargingTypes = {
  dynamicDeployment: "Dynamic",
  staticDeployment: "Static",
  unassignedUnits: "Unassigned",
};

const InfrastructureSection = ({ locTimeoutInstances }) => {
  const [chargingTypeCollapsed, setChargingTypeCollapsed] = useState({
    // Dynamic: false, // needed for future dynamic deployment
    Static: false,
    Unassigned: false,
  });
  // const [roadsCollapsed, setRoadsCollapsed] = useState({}); // needed for future dynamic deployment
  const [subSectionCollapsed, setSubSectionCollapsed] = useState({});

  const { selectedDevice, musData, mainDispatch } = useContext(MainContext);
  const { connectionState } = useContext(SocketContext);
  const { MUsDispatch } = useContext(MUsContext);
  const { projectStore } = useAppStore();

  const deviceData = (muDevice, index) => {
    const { id, name } = muDevice;
    const isConnected = !getIsDeviceDisconnected(connectionState, muDevice);
    const tooltip = getDeviceToolTip({
      ...muDevice,
      isConnected,
    });

    return (
      <MU
        key={index}
        isMUSelected={id === selectedDevice?.id}
        id={id}
        name={name}
        onMUClick={() =>
          handleDeviceClick({
            device: muDevice,
            connectionState,
            projectStore,
            MUsDispatch,
            mainDispatch,
          })
        }
        tooltip={tooltip}
        muDevice={muDevice}
        locTimeoutInstances={locTimeoutInstances}
        isDeviceConnected={isConnected}
      />
    );
  };

  return (
    musData &&
    Object.keys(musData).map((deploymentType, deploymentIndex) => {
      if (deploymentType === "dynamicDeployment") {
        return null;
      }
      return (
        <div className="charging-type-wrapper" key={deploymentIndex}>
          <div
            className="charging-type-title"
            onClick={() =>
              setChargingTypeCollapsed({
                ...chargingTypeCollapsed,
                [chargingTypes[deploymentType]]:
                  !chargingTypeCollapsed[chargingTypes[deploymentType]],
              })
            }
          >
            <div className="charging-type-collapse">
              <img
                src={
                  !chargingTypeCollapsed[chargingTypes[deploymentType]]
                    ? arrowRight
                    : arrowDown
                }
                height={10}
                width={10}
                alt={
                  !chargingTypeCollapsed[chargingTypes[deploymentType]]
                    ? "collapse-arrow-right"
                    : "collapse-arrow-down"
                }
              />
            </div>
            <div className="charging-type-title-name">
              {chargingTypes[deploymentType]}
            </div>
          </div>
          {!chargingTypeCollapsed[chargingTypes[deploymentType]] &&
            (deploymentType === "unassignedUnits"
              ? musData[deploymentType]?.map((muDevice, index) => (
                  <div className="mu-list-wrapper" key={index}>
                    {deviceData(muDevice, index)}
                  </div>
                ))
              : musData[deploymentType]?.depots?.map((depot, index) => (
                  <div className="road-wrapper" key={index}>
                    <div
                      className="road-title"
                      onClick={() =>
                        setSubSectionCollapsed({
                          ...subSectionCollapsed,
                          [depot.id]: !subSectionCollapsed[depot.id],
                        })
                      }
                    >
                      <div className="road-collapse">
                        <img
                          src={
                            !subSectionCollapsed[depot.id]
                              ? arrowRight
                              : arrowDown
                          }
                          height={10}
                          width={10}
                          alt={
                            !subSectionCollapsed[depot.id]
                              ? "collapse-arrow-right"
                              : "collapse-arrow-down"
                          }
                        />
                      </div>
                      <div className="road-title-name">{depot.name}</div>
                    </div>
                    <div className="mu-list-wrapper">
                      {!subSectionCollapsed[depot.id] &&
                        depot.managementUnits.map((unit, index) => {
                          const { managementUnit } = unit;
                          return deviceData(managementUnit, index);
                        })}
                    </div>
                  </div>
                )))}
        </div>
      );
    })
  );
};

export default InfrastructureSection;

InfrastructureSection.propTypes = {
  locTimeoutInstances: PropTypes.object,
};
