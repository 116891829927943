import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class ProjectStore {
  projectsList = [];
  selectedMaintenanceTab = "SETUP";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "ProjectStore",
      properties: ["projectsList", "selectedMaintenanceTab"],
      storage: window.localStorage,
    });
  }

  setProjectsList(projects) {
    this.projectsList = projects;
  }

  setSelectedTab(name) {
    this.selectedMaintenanceTab = name;
  }

  resetStore() {
    this.projectsList = [];
    this.selectedMaintenanceTab = "SETUP";
  }
}

export default ProjectStore;
