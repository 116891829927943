/**
 * It defines the various message types and their corresponding actions for depot dashboard socket connection.
 */

const MESSAGE_TYPES = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  DEPOT: {
    SG_CHARGING: "sg_charging",
    SG_STOPPED_CHARGING: "sg_stopped_charging",
    VU_CHARGING: "vu_charging",
    VU_STOPPED_CHARGING: "vu_stopped_charging",
    PAIRING_TRANSACTION: "pairing_transaction",
    PAIRING_TRANSACTION_STOPPED: "pairing_transaction_stopped",
  },
  PROJECT: {
    DC_SAMPLER_MESSAGE: "dcSamplerMessage",
    // DEVICES_STATES_MESSAGE: "devicesStatesMessage",
    BATTERY_DATA_MESSAGE: "batteryDataMessage",
    // CHARGING_MESSAGE: "chargingMessage",
    // GPS_MESSAGE: "gpsMessage",
    // FPGA_MESSAGE: "fpgaMessage",
    CONNECTION_STATE_CHANGE: "connectionStateChanges",
  },
  DEPOT_PROJECT: {
    PAIRING_TRANSACTION: "pairing_transaction",
    PAIRING_TRANSACTION_STOPPED: "pairing_transaction_stopped",
    SG_CHARGING: "sg_charging",
    SG_STOPPED_CHARGING: "sg_stopped_charging",
    VU_CHARGING: "vu_charging",
    VU_STOPPED_CHARGING: "vu_stopped_charging",
  },
};

const messageHandlers = {
  // [MESSAGE_TYPES.CONNECT]: () => console.log("--> connected to socket"),
  // [MESSAGE_TYPES.DISCONNECT]: () => console.log("--> connected to socket"),

  [MESSAGE_TYPES.DEPOT.VU_CHARGING]: (data, depotStore) => {
    depotStore.setVehicleCharging(data.vehicleId, true);
    data.description = `Vehicle ${data.vehicleId} is charging.`;
  },

  [MESSAGE_TYPES.DEPOT.VU_STOPPED_CHARGING]: (data, depotStore) => {
    depotStore.setVehicleCharging(data.vehicleId, false);
    data.description = `Vehicle ${data.vehicleId} stopped charging.`;
  },

  [MESSAGE_TYPES.PROJECT.DC_SAMPLER_MESSAGE]: (data, depotStore) => {
    depotStore.setVehiclePowerState(data.id, data.msg.avgPower);
  },

  [MESSAGE_TYPES.PROJECT.BATTERY_DATA_MESSAGE]: (data, depotStore) => {
    depotStore.setVehicleBatteryLevel(data.id, data.msg.Percetage);
  },

  [MESSAGE_TYPES.PROJECT.CONNECTION_STATE_CHANGE]: (data, depotStore) => {
    const isConnected = data.msg.operationState === "deviceConnected";
    const deviceType = data.msg.deviceType;
    if (deviceType === "VU") depotStore.setVehicleConnectionState(data.id, isConnected);
    else if (deviceType === "MU") depotStore.setParkingSpotConnectionState(data.id, isConnected);
  },
  [MESSAGE_TYPES.DEPOT_PROJECT.PAIRING_TRANSACTION]: (data, depotStore) => {
    depotStore.setPairingActive(data.parkingPath.parkingSpotId, data.vehicleId);
  },
  [MESSAGE_TYPES.DEPOT_PROJECT.PAIRING_TRANSACTION_STOPPED]: (data, depotStore) => {
    depotStore.setPairingInactive(data.parkingPath.parkingSpotId, data.vehicleId);
  },
  [MESSAGE_TYPES.DEPOT_PROJECT.SG_CHARGING]: (data, depotStore) => {
    depotStore.setParkingSpotCharging(data.parkingPath.parkingSpotId, true);
  },
  [MESSAGE_TYPES.DEPOT_PROJECT.SG_STOPPED_CHARGING]: (data, depotStore) => {
    depotStore.setParkingSpotCharging(data.parkingPath.parkingSpotId, false);
  },
};

export { messageHandlers, MESSAGE_TYPES };
