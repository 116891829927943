import "Components/Buttons/OffIdleButtons/OffIdleButtons.scss";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import IdleIcon from "Utils/Images/ProjectMaintanance/segment-idle-icon.svg";
import ForceOffIcon from "Utils/Images/ProjectMaintanance/segment-force-off-icon.svg";

import React, { useContext } from "react";
import MainContext from "Contexts/MainContext";
import useRole from "CustomHooks/useRole";

const OffIdleButtons = ({
  offButtonTitle,
  offButtonOnClick,
  isDisabled,
  idleButtonTitle,
  idleButtonOnClick,
}) => {
  const { userRole } = useContext(MainContext);
  const { canControlIdleOff } = useRole(userRole);

  return (
    <div className="off-idle-buttons-wrapper">
      <IndicationButton
        title={offButtonTitle}
        isDisabled={isDisabled || !canControlIdleOff}
        onClick={offButtonOnClick}
        text=""
        className="force-off-button"
        buttonIconUrl={ForceOffIcon}
      />
      <IndicationButton
        title={idleButtonTitle}
        isDisabled={isDisabled || !canControlIdleOff}
        onClick={idleButtonOnClick}
        text=""
        className="idle-button"
        buttonIconUrl={IdleIcon}
      />
    </div>
  );
};
export default OffIdleButtons;
