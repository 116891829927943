import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "Components/HeaderMenu/HeaderMenu.scss";

//Components
import Image from "Components/Image/Image";

//Context
import MainContext from "Contexts/MainContext";
import { useAppStore } from "MobxStores/context";

//Utils
import dashboardButtonIcon from "Utils/Images/GlobalIcons/dashboard_icon_header.svg";
import dashboardButtonIconSelected from "Utils/Images/GlobalIcons/dashboard_icon_header_selected.svg";
import analyticsToolIcon from "Utils/Images/GlobalIcons/analytics_tool_icon_header.svg";
import analyticsToolIconSelected from "Utils/Images/GlobalIcons/analytics_tool_icon_header_selected.svg";
import maintenanceIcon from "Utils/Images/GlobalIcons/maintenance_icon_header.svg";
import maintenanceIconSelected from "Utils/Images/GlobalIcons/maintenance_icon_header_selected.svg";
import { ScopeTypes } from "Utils/constants";

const toolPages = ["Dashboard", "Maintenance", "Analytics"];
const toolImages = [dashboardButtonIcon, maintenanceIcon, analyticsToolIcon];
const toolImagesSelected = [
  dashboardButtonIconSelected,
  maintenanceIconSelected,
  analyticsToolIconSelected,
];

const HeaderMenu = () => {
  const { selectedProject, mainDispatch } = useContext(MainContext);
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const { userStore } = useAppStore();
  const { userData } = userStore;
  const shouldShowHeaderMenu = userData && !userData.isSingleProjectViewer();

  const pageName = pathname.includes("inline")
    ? "Maintenance"
    : pathname.includes("history")
    ? "Analytics"
    : "Dashboard";

  if (!shouldShowHeaderMenu) return null;

  return (
    <div className="menu-wrapper">
      {toolPages.map((toolPage, toolIndex) => {
        const dashboardExists =
          selectedProject?.id === 118 ||
          selectedProject?.id === 87 ||
          selectedProject?.id === 2 ||
          selectedProject?.id === 119 ||
          selectedProject?.id === 111 ||
          selectedProject?.id === 128;
        if (
          (!dashboardExists && toolPage === "Dashboard") ||
          !selectedProject
        ) {
          return null;
        }

        return (
          <div
            className="menu-button menu-item"
            key={toolPage}
            onClick={() => {
              const pageUrl =
                toolPage === "Maintenance"
                  ? `/dashboard/live/project/${selectedProject?.id}/inline`
                  : toolPage === "Analytics"
                  ? `/dashboard/history/project/${selectedProject?.id}`
                  : toolPage === "Dashboard"
                  ? `/dashboard/live/project/${selectedProject?.id}`
                  : "";
              history.push(pageUrl);
              mainDispatch({
                type: "SET_SELECTED_DEVICE",
                payload: {
                  selectedDevice: null,
                },
              });
            }}
          >
            <div className={`item ${pageName === toolPage ? "selected" : ""}`}>
              <Image
                className="item-img"
                src={
                  pageName === toolPage
                    ? toolImagesSelected[toolIndex]
                    : toolImages[toolIndex]
                }
              />
              {toolPage}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HeaderMenu;
