import React from "react";
import PropTypes from "prop-types";

//Components
import Popup from "Components/Popup/Popup";
import { DeviceAndParamSelection } from "../DeviceAndParamSelection/DeviceAndParamSelection";
import useSelectedProjectData from "CustomHooks/useSelectedProject";

//Hooks
import { useAppStore } from "MobxStores/context";
import { observer } from "mobx-react-lite";

const AnalyticsAdvancedParamsPopup = ({
  isParamsSelectionOpen,
  setIsParamsSelectionOpen,
}) => {
  const { projectAnalyticsStore } = useAppStore();
  const { MUDevices, VUDevices, timezoneStr } = useSelectedProjectData();
  const currentProjectDevices = VUDevices.concat(MUDevices);
  const {
    getDeviceActivityData,
    clearAdvancedParamsData,
    getSummaryTableData,
    selectedDevices,
    setSelectedDeviceIds,
    selectedActivityDevice,
  } = projectAnalyticsStore;

  const handleSuccess = async () => {
    clearAdvancedParamsData();
    setIsParamsSelectionOpen(false);
    setSelectedDeviceIds({ ...selectedDevices });
    await getDeviceActivityData(timezoneStr, selectedActivityDevice);
    await getSummaryTableData(timezoneStr);
  };

  const handleClose = () => {
    setIsParamsSelectionOpen(false);
    projectAnalyticsStore.setSelectedProjectParams([]);
  };

  return (
    <Popup
      isOpen={isParamsSelectionOpen}
      handleClose={handleClose}
      handleSuccess={handleSuccess}
      title="Advanced Parameters"
      content={
        <DeviceAndParamSelection
          store={projectAnalyticsStore}
          devices={currentProjectDevices}
          filters={[
            "All",
            VUDevices.length > 0 && "Vehicles",
            MUDevices.length > 0 && "Charging Units",
          ].filter(Boolean)}
          parameterTypes={projectAnalyticsStore.projectAdvancedParams}
          orientation="vertical"
        />
      }
    />
  );
};

export default observer(AnalyticsAdvancedParamsPopup);

AnalyticsAdvancedParamsPopup.propTypes = {
  isParamsSelectionOpen: PropTypes.bool,
  setIsParamsSelectionOpen: PropTypes.func,
};
