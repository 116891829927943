import { useMemo, useEffect, useState } from "react";

const useProjectControlPanel = (
  {
    band_0,
    band_1,
    band_2,
    band_3,
    band_4,
    band_5,
    current_off,
    pwn_off_time_dynamic,
    pwn_off_time_static,
    pwn_on_time,
    pwm_max,
  },
  isFpgaOverCurrentVersion
) => {
  const [isUpdating, setIsUpdating] = useState([]);
  const [controlPanelDataMUsPresent, setControlPanelDataMUsPresent] =
    useState();

  const controlPanelDataMUs = useMemo(
    () => ({
      band_0: isFpgaOverCurrentVersion
        ? {
            label: "OCP Enabled Threashold",
            minValue: 0,
            maxValue: 5100,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_0",
            value: band_0,
            name: "band_0",
            units: "nSec",
          }
        : {
            label: "Band 0",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_0",
            value: band_0,
            name: "band_0",
            units: "%",
          },
      band_1: isFpgaOverCurrentVersion
        ? {
            label: "OCP Mask Time",
            minValue: 0,
            maxValue: 1275,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_1",
            value: band_1,
            name: "band_1",
            units: "mSec",
          }
        : {
            label: "Band 1",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_1",
            value: band_1,
            name: "band_1",
            units: "%",
          },
      band_2: isFpgaOverCurrentVersion
        ? {
            label: "OCP Allowed Times",
            minValue: 0,
            maxValue: 255,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_2",
            value: band_2,
            name: "band_2",
            units: "",
          }
        : {
            label: "Band 2",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_2",
            value: band_2,
            name: "band_2",
            units: "%",
          },
      band_3: isFpgaOverCurrentVersion
        ? {
            label: "OCP Static Masking Time",
            minValue: 0,
            maxValue: 1275,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_3",
            value: band_3,
            name: "band_3",
            units: "mSec",
          }
        : {
            label: "Band 3",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            discreteName: "set_sic_open_3",
            value: band_3,
            name: "band_3",
            units: "%",
          },
      band_4: isFpgaOverCurrentVersion
        ? {
            label: "Tracker",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            value: band_4,
            name: "band_4",
            units: "%",
          }
        : {
            label: "Band 4",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            value: band_4,
            name: "band_4",
            units: "%",
          },
      band_5: isFpgaOverCurrentVersion
        ? {
            label: "Full",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            value: band_5,
            name: "band_5",
            units: "%",
          }
        : {
            label: "Band 5",
            minValue: 0,
            maxValue: 100,
            step: 1,
            defaultValue: 0,
            value: band_5,
            name: "band_5",
            units: "%",
          },
      current_off: {
        label: "Current Off",
        minValue: 0,
        maxValue: 5100,
        step: 1,
        defaultValue: 0,
        discreteName: "set_current_shut_down",
        value: current_off,
        name: "current_off",
        units: "nSec",
      },
      pwn_on_time: {
        label: "PWM On Time",
        minValue: 0,
        maxValue: 255,
        step: 1,
        defaultValue: 0,
        discreteName: "set_pwm_on_Time",
        value: pwn_on_time,
        name: "pwn_on_time",
        units: "sec",
      },
      pwn_off_time_dynamic: {
        label: "PWM Off D",
        minValue: 0,
        maxValue: 255,
        step: 1,
        defaultValue: 0,
        discreteName: "set_pam_off_time",
        value: pwn_off_time_dynamic,
        name: "pwn_off_time_dynamic",
        units: "sec",
      },
      pwn_off_time_static: {
        label: "PWM Off S",
        minValue: 0,
        maxValue: 255,
        step: 1,
        defaultValue: 0,
        discreteName: "set_pwm_max_duty_cycle",
        value: pwn_off_time_static,
        name: "pwn_off_time_static",
        units: "sec",
      },
      pwm_max: {
        label: "PWM Max",
        minValue: 0,
        maxValue: 25.5,
        step: 0.1,
        defaultValue: 0,
        discreteName: "set_pwm_max",
        value: pwm_max || 0,
        name: "pwm_max",
        units: "%",
      },
    }),
    [
      isFpgaOverCurrentVersion,
      band_0,
      band_1,
      band_2,
      band_3,
      band_4,
      band_5,
      current_off,
      pwn_off_time_dynamic,
      pwn_off_time_static,
      pwn_on_time,
      pwm_max,
    ]
  );

  useEffect(() => {
    if (controlPanelDataMUs) {
      setControlPanelDataMUsPresent(controlPanelDataMUs);
      setIsUpdating([]);
    }
  }, [controlPanelDataMUs]);

  return {
    isUpdating,
    setIsUpdating,
    controlPanelDataMUsPresent,
    setControlPanelDataMUsPresent,
    controlPanelDataMUs,
  };
};

export default useProjectControlPanel;
