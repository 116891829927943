import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

//Components
import Image from "Components/Image/Image";
import DcSamplerSummary from "Components/DcSampler/DcSamplerSummary";
import Table from "Components/Tables/Table";
import ActivityIndicator from "Components/ActivityIndicator/ActivityIndicator";
import SwitchButton from "Components/Buttons/SwitchButton/SwitchButton";
import LockButton from "Components/Buttons/LockButton/LockButton";
import SelectButton from "Components/Buttons/SelectButton/SelectButton";
import RoundedProgress from "Components/ProgressComponents/RoundedProgress";

//Context
import VUsContext from "Contexts/VUsContext";
import MUsContext from "Contexts/MUsContext";
import SocketContext from "Contexts/SocketContext";
import MainContext from "Contexts/MainContext";

//Hooks
import useRole from "CustomHooks/useRole";
import useLockState from "CustomHooks/useLockState";

//Utils
import WiFiSignalIcon from "Utils/Images/ProjectMaintanance/signal_wifi_icon.svg";
import busIcon from "Utils/Images/ProjectMaintanance/bus-icon.svg";
import {
  getDcSamplerSummaryNumbers,
  getVUChargingStatus,
  getVuRfStatusInd,
} from "Utils/UtilsFunctions";
import forceRFICon from "Utils/Images/ProjectMaintanance/segment-force-rf-icon.svg";
import {
  getSessionIdProject,
  postReceiverControlStatus,
  postReceiversDebugMode,
} from "Utils/APIUtils";
import { defaultRecievers } from "Utils/DefaultValues/ReceiversViewDefaultValues";
import "Screens/ProjectView/PerformanceTab/MuPerformance/MiniVU.scss";

const MiniVU = ({
  handleAddingVU,
  locIntervalInstances,
  setLocIntervalInstances,
  locTimeoutInstances,
  isHistoryOn,
}) => {
  const [selectedVU, setSelectedVU] = useState();

  const { userRole, selectedDevice } = useContext(MainContext);
  const { activeVU, vusList, VUsDispatch } = useContext(VUsContext);
  const { fotaProgress } = useContext(MUsContext);
  const { socketDcSamplerData, connectionState, receivers } =
    useContext(SocketContext);
  const { canControlReceivers, canDebug } = useRole(userRole);
  const lockState = useLockState(activeVU, locTimeoutInstances);
  const sessionId = getSessionIdProject();

  const {
    Charging_Work_Mode,
    Receiver_1,
    Receiver_2,
    Receiver_3,
    Receiver_4,
    Receiver_5,
  } = receivers[activeVU?.id]?.socketMessageData || defaultRecievers;

  const liveDCSamplerData =
    activeVU &&
    socketDcSamplerData &&
    socketDcSamplerData[activeVU.id] &&
    socketDcSamplerData[activeVU.id]["socketMessageData"];

  const isMiniVUDisabled = activeVU
    ? !(
        locIntervalInstances[activeVU.id] &&
        locIntervalInstances[activeVU.id]["lockedBySession"]
      )
    : true;

  const debugMode = useMemo(() => !!Charging_Work_Mode, [Charging_Work_Mode]);

  const activeVuDcSamplerLiveValues = useMemo(
    () => getDcSamplerSummaryNumbers(liveDCSamplerData),
    [liveDCSamplerData]
  );

  const { avgCurrentLiveValue } = activeVuDcSamplerLiveValues;

  const connectedForSelect = useMemo(() => {
    const selectList = [{ value: "undefined", label: "Select Vehicle" }];
    if (vusList && vusList.length > 0) {
      vusList.forEach((vu) => {
        if (isHistoryOn) {
          if (vu) {
            selectList.push({ value: vu.id, label: vu.name });
          }
        } else {
          if (
            vu &&
            connectionState[vu.id]?.["socketMessageData"]?.["opertionState"] ===
              "deviceConnected"
          ) {
            selectList.push({ value: vu.id, label: vu.name });
          }
        }
      });
    }
    return selectList;
  }, [vusList, isHistoryOn]);

  const chargingStatus = useMemo(
    () => getVUChargingStatus(receivers, activeVU, avgCurrentLiveValue),
    [receivers, activeVU, avgCurrentLiveValue]
  );

  const toggleControlBtn = useCallback(
    (receiverNumber, controlStatus) => {
      postReceiverControlStatus({
        sessionId,
        vehicleUnitId: activeVU && activeVU.id,
        receiver_id: receiverNumber,
        state: controlStatus,
      }).then((res) => {
        //const error = res instanceof Error;
        //!error && setControlBtnIsChecked(newControlBtnIsChecked);
      });
    },
    [activeVU && activeVU.id, debugMode, sessionId]
  );

  const tableData = useMemo(() => {
    return [
      {
        receiverName: "R1",
        master: Receiver_1 && Receiver_1["Allowed_Ind"],
        control: Receiver_1 && Receiver_1["Set_State"],
        rf: Receiver_1 && Receiver_1["State"],
      },
      {
        receiverName: "R2",
        master: Receiver_2 && Receiver_2["Allowed_Ind"],
        control: Receiver_2 && Receiver_2["Set_State"],
        rf: Receiver_2 && Receiver_2["State"],
      },
      {
        receiverName: "R3",
        master: Receiver_3 && Receiver_3["Allowed_Ind"],
        control: Receiver_3 && Receiver_3["Set_State"],
        rf: Receiver_3 && Receiver_3["State"],
      },
      {
        receiverName: "R4",
        master: Receiver_4 && Receiver_4["Allowed_Ind"],
        control: Receiver_4 && Receiver_4["Set_State"],
        rf: Receiver_4 && Receiver_4["State"],
      },
      {
        receiverName: "R5",
        master: Receiver_5 && Receiver_5["Allowed_Ind"],
        control: Receiver_5 && Receiver_5["Set_State"],
        rf: Receiver_5 && Receiver_5["State"],
      },
    ];
  }, [Receiver_1, Receiver_2, Receiver_3, Receiver_4, Receiver_5]);

  const tableColumns = useMemo(() => {
    return [
      { Header: "", accessor: "receiverName" },
      {
        Header: "Master",
        accessor: "master",
        Cell: ({ value }) => {
          return <ActivityIndicator color={!value ? "#bfbfbf" : "#00FFFF"} />;
        },
      },
      {
        Header: "Control",
        accessor: "control",
        Cell: ({ row, value }) => {
          let buttonText = "EV";
          if (debugMode) {
            buttonText = "REQ ON";
          }
          const receiverNumber = Number(
            row &&
              row.original &&
              row.original.receiverName &&
              row.original.receiverName[1]
          );
          const receiverNewStatus =
            value === 0
              ? 1
              : value === 2
              ? 3
              : value === 1
              ? 0
              : value === 3
              ? 2
              : 0;
          return (
            <div className="table-receiver-control-button">
              <SwitchButton
                isDisabled={isMiniVUDisabled || !canControlReceivers}
                label={buttonText}
                isChecked={!debugMode ? value === 0 : value === 2}
                onChange={() => {
                  return toggleControlBtn(receiverNumber, receiverNewStatus);
                }}
              />
              <div></div>
            </div>
          );
        },
      },
      {
        Header: "RF",
        accessor: "rf",
        Cell: ({ value }) => {
          return (
            <Image
              src={forceRFICon}
              style={{
                padding: "3px",
                backgroundColor: getVuRfStatusInd(value).color,
                borderRadius: "15px",
              }}
            />
          );
        },
      },
    ];
  }, [debugMode, isMiniVUDisabled, toggleControlBtn]);

  const handleSubmitVU = useCallback(
    (value) => {
      if (value.toString() === "undefined") {
        VUsDispatch({
          type: "SET_ACTIVE_VU",
          payload: { activeVU: null },
        });
        handleAddingVU(null);
        return;
      }
      if (value && vusList) {
        const activeVU = vusList.find((vu) => vu.id === value);
        VUsDispatch({
          type: "SET_ACTIVE_VU",
          payload: { activeVU },
        });
        handleAddingVU(activeVU);
        connectedForSelect && activeVU
          ? setSelectedVU(
              connectedForSelect.find((option) => option.value === value)
            )
          : setSelectedVU(connectedForSelect[0]);
      }
    },
    [vusList, handleAddingVU]
  );

  const toggleDebug = useCallback(() => {
    postReceiversDebugMode({
      sessionId: sessionId,
      vehicleUnitId: activeVU && activeVU.id,
      mode: Number(!debugMode),
    }).then((res) => {
      if (res && res.status !== 200) {
        //(res, "possible error");
        return;
      }
    });
  }, [sessionId, activeVU && activeVU.id, debugMode]);

  useEffect(() => {
    setSelectedVU(connectedForSelect[0]);
  }, [selectedDevice]);

  return (
    <div className="mini-vu-wrapper">
      <div className="vu-basic-info">
        <div className="mini-vu-header-icons">
          <Image
            src={WiFiSignalIcon}
            className="vu-cellular-icon"
            dataFor="vu-cellular"
          />
          <ReactTooltip
            id="vu-cellular"
            className="vu-cellular"
            getContent={(dataTip) => (
              <div>
                <div className="vu-cellular">Cellular: not available yet</div>
              </div>
            )}
            effect="solid"
            delayHide={0}
            delayShow={0}
            delayUpdate={0}
            place="top"
            border
            type="light"
          />
          {activeVU && fotaProgress[activeVU.id] && (
            <RoundedProgress
              valueMin="0"
              valueMax="100"
              valueNow={fotaProgress[activeVU.id]}
            />
          )}
        </div>
        <SelectButton
          className="vu-dropdown-select"
          value={selectedVU}
          options={connectedForSelect}
          onChange={handleSubmitVU}
        />
        <div className="mini-vu-header-icons">
          <Image src={busIcon} className="bus-icon" />
          <Image
            src={chargingStatus.icon}
            className="charging-status"
            style={{
              backgroundColor: chargingStatus.color,
            }}
          />
        </div>
      </div>

      <div className="vu-data-summary">
        <DcSamplerSummary view="VU" />
      </div>
      <div className="vu-receivers-debug-wrapper">
        <div className="miniVuLock">
          <LockButton
            device={activeVU}
            lockState={lockState}
            locIntervalInstances={locIntervalInstances}
            setLocIntervalInstances={setLocIntervalInstances}
          />
        </div>

        <SwitchButton
          label="NORM"
          isChecked={debugMode}
          isDisabled={isMiniVUDisabled || !canDebug}
          onChange={toggleDebug}
        />
      </div>
      <div className="vu-buttons">
        <Table data={tableData} columns={tableColumns} />
      </div>
    </div>
  );
};

export default MiniVU;

MiniVU.propTypes = {
  handleAddingVU: PropTypes.func,
  locIntervalInstances: PropTypes.object,
  setLocIntervalInstances: PropTypes.func,
  locTimeoutInstances: PropTypes.object,
  isHistoryOn: PropTypes.bool,
};
