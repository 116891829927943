import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import useSelectedProject from "CustomHooks/useSelectedProject";
import MUsContext from "Contexts/MUsContext";
import "Screens/ProjectView/LogsSidePanel/LogsSidePanel.scss";

const LogsSidePanel = ({ buttonTitle, panelTitle }) => {
  const [triggeredButtonTitle, updateButtonTitle] = useState(buttonTitle);
  const [panelClass, updatePanelClass] = useState("closed");
  const [searchTerm, setSearchTerm] = useState("");

  const { musEventsMessages } = useContext(MUsContext);
  const { timezoneStr } = useSelectedProject();

  useEffect(() => {
    if (triggeredButtonTitle === "CLOSE") {
      updatePanelClass("slided");
    } else {
      updatePanelClass("closed");
    }
  }, [triggeredButtonTitle]);

  return (
    <div className={`project-side-panel ${panelClass}`}>
      <button
        className="performance-log-toggle"
        onClick={() =>
          updateButtonTitle(
            triggeredButtonTitle === buttonTitle ? "CLOSE" : buttonTitle
          )
        }
      >
        {" "}
        {triggeredButtonTitle}
        {musEventsMessages && musEventsMessages.length ? (
          <span className="messages-count">({musEventsMessages.length})</span>
        ) : (
          ""
        )}
      </button>
      <div>{panelTitle}</div>
      <div className="panel-content">
        {musEventsMessages && musEventsMessages.length ? (
          <div className="messages-search-wrapper">
            <input
              type="text"
              placeholder="Start typing..."
              onChange={(event) => {
                event.target && setSearchTerm(event.target.value);
              }}
            />
          </div>
        ) : (
          ""
        )}

        {musEventsMessages && musEventsMessages.length ? (
          <div className="messages-list">
            {musEventsMessages
              .filter((val) => {
                if (searchTerm === "") {
                  return val;
                } else if (
                  (val.messageObject &&
                    val.messageObject.description &&
                    val.messageObject.description
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())) ||
                  (val.messageTime &&
                    val.messageTime
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()))
                ) {
                  return val;
                }
              })
              .map((message, key) => {
                let messageObject = message.messageObject;
                if (messageObject) {
                  return (
                    <div
                      key={key}
                      className={`message-item ${messageObject.messageType}`}
                    >
                      {message.selectedDevice && (
                        <strong className="selected-device">
                          ({message.selectedDevice})
                        </strong>
                      )}
                      {message.messageTime && (
                        <span className="message-time">
                          {moment(message.messageTime.getTime())
                            .tz(timezoneStr)
                            .format("DD/MM/YYYY, HH:mm:ss") + " - "}
                        </span>
                      )}
                      {message.messageShelf && (
                        <span className="drawer">
                          (Shelf: {message.messageShelf})
                        </span>
                      )}
                      {message.reciever && (
                        <span className="reciever">({message.reciever})</span>
                      )}
                      {messageObject.description}
                    </div>
                  );
                }
              })}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default LogsSidePanel;

LogsSidePanel.propTypes = {
  buttonTitle: PropTypes.string,
  panelTitle: PropTypes.string,
};
