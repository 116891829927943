import Image from "Components/Image/Image";
import ReadyToChargeIcon from "Utils/Images/DepotIcons/vu-ready-to-charge-icon.svg";
import VuChargingIcon from "Utils/Images/DepotIcons/vu-charging-icon.svg";
import VuFullyChargeIcon from "Utils/Images/DepotIcons/vu-fully-charged-icon.svg";
import VuWPTErrorIcon from "Utils/Images/DepotIcons/vu-wpt-icon.svg";

export const DepotVehicleStatusIcon = ({ vehicle }) => {
  const vehicleState = vehicle.state;
  const isCharging = vehicleState?.charging;
  const isFullyCharged = vehicleState?.batteryLevel > 90;
  const hasWPTError = vehicleState.wptError;

  if (isCharging) {
    return (
      <Image
        src={VuChargingIcon}
        alt="Vehicle charging icon"
        className="vehicle-status-icon"
      />
    );
  } else if (isFullyCharged) {
    return (
      <Image
        src={VuFullyChargeIcon}
        alt="Vehicle fully charged icon"
        className="vehicle-status-icon"
      />
    );
  } else if (hasWPTError) {
    return (
      <Image
        src={VuWPTErrorIcon}
        alt="Vehicle WPT error icon"
        className="vehicle-status-icon"
      />
    );
  } else {
    return (
      <Image
        src={ReadyToChargeIcon}
        alt="Vehicle ready to charge icon"
        className="vehicle-status-icon"
      />
    );
  }
};
