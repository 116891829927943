import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Popup from "reactjs-popup";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { IconContext } from "react-icons";
import { FaRegTrashAlt, FaWindowClose } from "react-icons/fa";

import MainContext from "Contexts/MainContext";

import { getBookmarkList, deleteBookmark } from "Utils/APIUtils";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "Components/Calendar/Calendar.scss";

const localizer = momentLocalizer(moment);

const CalendarWrapper = () => {
  const [events, setEvents] = useState([]);
  const [time, setTime] = useState({
    startTime: moment()
      .clone()
      .subtract(1, "months")
      .startOf("month")
      .valueOf(),
    endTime: moment().clone().endOf("month").valueOf(),
  });

  const { selectedProject } = useContext(MainContext);
  const { id } = selectedProject;

  const fetchEvents = async () => {
    const response = await getBookmarkList({
      projectId: id,
      startTime: time.startTime,
      endTime: time.endTime,
    });

    const { data } = response || [];
    if (data) {
      setEvents(
        data.map((event) => {
          return {
            title: event.name,
            start: moment(event.startTime).format("YYYY-MM-DD HH:mm"),
            end: moment(event.endTime).format("YYYY-MM-DD HH:mm"),
            allDay: false,
            resource: {
              devices: event.deviceIds,
              bookmark: event.description,
              projectId: event.projectId,
              id: event.id,
              userName: event.userName,
            },
          };
        })
      );
    } else {
      setEvents([]);
    }
  };

  //(events);
  useEffect(() => {
    fetchEvents();
  }, [time]);

  const Event = (events) => {
    const { event } = events || {
      event: {
        resource: { userName: "", devices: [], bookmark: "", id: null },
        start: "",
        title: "",
      },
    };
    const { resource, start, title } = event || {
      resource: { userName: "", devices: [], bookmark: "", id: null },
      start: "",
      title: "",
    };
    const { devices, bookmark, id, userName } = resource || {
      devices: [],
      bookmark: "",
      id: null,
      userName: "",
    };

    return (
      <div className="event-wrapper">
        <Popup trigger={<div className="Popup">{title}</div>} modal nested>
          {(close) => (
            <div className="modal">
              <div className="close" onClick={close}>
                <IconContext.Provider value={{ className: "close" }}>
                  <FaWindowClose />
                </IconContext.Provider>
              </div>
              <h4>{userName}</h4>
              <div>
                <span>{`Time: ${start}`}</span>
              </div>
              <div className="devices">
                {devices.map((device, index) => {
                  return <span key={index}>{device}</span>;
                })}
              </div>
              <div className="the_bookmark">
                <h3 className="header">{title}</h3>
                <div className="content">{bookmark}</div>
              </div>
            </div>
          )}
        </Popup>
        <span
          className="delete"
          onClick={async () => {
            await deleteBookmark({ bookmarkId: id })
              .then(() => {
                fetchEvents();
              })
              .catch((err) => console.log(err));
          }}
        >
          <IconContext.Provider value={{ className: "deleteIcon" }}>
            <FaRegTrashAlt />
          </IconContext.Provider>
        </span>
      </div>
    );
  };

  const eventStyleGetter = (event) => {
    let current_time = moment().format("YYYY MM DD HH:mm");
    let event_time = moment(event.start).format("YYYY MM DD HH:mm:ss");
    let background =
      current_time > event_time ? "rgb(4,202,191)" : "rgb(253,185,177)";
    return {
      style: {
        backgroundColor: background,
      },
    };
  };

  return (
    <div className="calendar-container">
      <Calendar
        popup
        selectable
        localizer={localizer}
        events={events}
        views={["month"]}
        step={60}
        components={{ event: Event }}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
        onNavigate={(date) => {
          setTime({
            startTime: moment(date)
              .subtract(1, "months")
              .startOf("month")
              .valueOf(),
            endTime: moment(date)
              .subtract(-1, "months")
              .endOf("month")
              .valueOf(),
          });
        }}
      />
    </div>
  );
};

export default CalendarWrapper;

CalendarWrapper.propTypes = {};
