import React, { useState, useCallback, useEffect, useContext } from "react";
import PropTypes from "prop-types";

//Components
import AppButton from "Components/Buttons/AppButton/AppButton";

//Context
import MainContext from "Contexts/MainContext";

//Hooks
import useSelectedProjectData from "CustomHooks/useSelectedProject";

//Utils
import { getRawData, putRawData } from "Utils/APIUtils";
import { getConvertedEndDateAndTime } from "Utils/UtilsFunctions";
import "Components/RawData/RawData.scss";
import exportIconDark from "Utils/Images/GlobalIcons/export-icon-dark.svg";

const sasKey =
  "sp=rw&st=2022-04-28T14:45:13Z&se=2025-04-28T22:45:13Z&spr=https&sv=2020-08-04&sr=c&sig=7y0OvsGGuWImP28yVIfvEPcg2abMckgeUpY2L4vdmK0%3D";

const ExportRawData = ({ shouldDisable, timeRange, isHistoryLoading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [operationId, setOperationId] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [message, setMessage] = useState(null);

  const { selectedDevice } = useContext(MainContext);
  const { timezoneStr } = useSelectedProjectData();

  const { startTime, date, span } = timeRange;
  const dcSampler =
    selectedDevice?.deviceType === "MU" ? "DcSamplerMu" : "DcSamplerVu";

  const getData = useCallback(() => {
    getRawData(operationId, timezoneStr).then((res) => {
      if (res && res.status === 200) {
        setRawData(res.data);
      } else {
        setMessage(res);
        setIsLoading(false);
      }
    });
  }, [operationId, timezoneStr]);

  const handleGetData = useCallback(() => {
    setIsLoading(true);
    setRawData(null);
    setOperationId(null);
    setMessage(null);

    const startValue = `${date}T${startTime}`;
    const endValue = getConvertedEndDateAndTime(span, startTime, date);

    const body = {
      tableName: dcSampler,
      deviceIds: [selectedDevice?.id],
      startTime: startValue,
      endTime: endValue,
      timeZone: timezoneStr,
    };

    putRawData(body).then((res) => {
      if (res && res.status === 200) {
        setOperationId(res?.data?.operationId);
      } else {
        setMessage(res);
        setIsLoading(false);
      }
    });
  }, [selectedDevice, date, timezoneStr, startTime, dcSampler, span]);

  useEffect(() => {
    if (shouldDisable) {
      return;
    }
    if (isLoading && operationId && !message && !rawData) {
      getData();
    }
    if (rawData && rawData.operationState !== "Completed" && !message) {
      setTimeout(() => {
        getData();
      }, 5000);
    }
    if (
      rawData &&
      rawData.operationState === "Completed" &&
      !rawData.outputUrls
    ) {
      setMessage("No data for export");
      setIsLoading(false);
    }
    if (rawData?.operationState === "Completed" && !message) {
      setIsLoading(false);
    }
  }, [operationId, isLoading, rawData, getData, message, shouldDisable]);

  useEffect(() => {
    setIsLoading(false);
    setMessage(null);
    setRawData(null);
    setOperationId(null);
  }, [selectedDevice?.id, isHistoryLoading]);

  return (
    <>
      <div title={isLoading ? "Exporting Raw Data" : ""}>
        <AppButton
          text={`${isLoading ? "Exporting..." : "Export Raw Data"}`}
          onClick={handleGetData}
          isDisabled={isLoading || shouldDisable}
          indication={isLoading && !shouldDisable}
          className="export-raw-data-button"
          buttonIcon={exportIconDark}
        />
        {!shouldDisable && message && (
          <div className="info">{String(message)}</div>
        )}
      </div>
      {!shouldDisable && rawData?.outputUrls && (
        <div className="info links">
          {rawData.outputUrls.map((el, i) => (
            <a key={i} href={`${el}?${sasKey}`} download>{`File ${i + 1}`}</a>
          ))}
        </div>
      )}
    </>
  );
};

export default ExportRawData;

ExportRawData.propTypes = {
  shouldDisable: PropTypes.bool,
  timeRange: PropTypes.object,
  isHistoryLoading: PropTypes.bool,
};
