const MainReducer = (state, action) => {
  switch (action.type) {
    case "SET_MU_DATA":
      return {
        ...state,
        musData: action.payload.musData,
      };
    case "SET_USER":
      return {
        ...state,
        currentUser: action.payload.currentUser,
        authenticated: action.payload.authenticated,
        userRole: action.payload.userRole,
        userScope: action.payload.userScope,
        isLoading: action.payload.isLoading,
      };
    case "SET_SHOULD_ACQUIRE_TOKEN":
      return {
        ...state,
        shouldAcquireToken: action.payload.shouldAcquireToken,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case "SET_QUERY":
      return {
        ...state,
        appQuery: action.payload.query,
      };
    case "TOGGLE_SEARCH_BOX":
      return {
        ...state,
        isSearchOpened: action.payload.isSearchOpened,
      };
    case "SET_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: action.payload.searchResults,
      };
    case "TOGGLE_LIVE_VIEW":
      return {
        ...state,
        isLiveView: action.payload.isLiveView,
      };

    case "SET_SELECTED_DEVICE":
      return {
        ...state,
        selectedDevice: action.payload.selectedDevice,
      };
    case "SET_SELECTED_PROJECT":
      return {
        ...state,
        selectedProject: action.payload.selectedProject,
        isLoading: action.payload.isLoading || state.isLoading,
      };
    case "SET_INTERVAL_ID":
      return {
        ...state,
        intervalIds: [...state.intervalIds, action.payload.intervalIds],
      };
    case "DELETE_INTERVAL_ID":
      return {
        ...state,
        intervalIds: [],
      };
    case "SET_INTERVAL_INSTANCE":
      return {
        ...state,
        intervalInstance: action.payload.intervalInstance,
      };
    case "IS_NEW_TOKEN_RECEIVED":
      return {
        ...state,
        isNewTokenReceived: action.payload.isNewTokenReceived,
      };
    case "SET_TIMEOUT_ID":
      return {
        ...state,
        timeoutIds: [...state.timeoutIds, action.payload.timeoutIds],
      };
    case "DELETE_TIMEOUT_ID":
      return {
        ...state,
        timeoutIds: [],
      };
    default:
      return state;
  }
};

export default MainReducer;
