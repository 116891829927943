const VUsReducer = (state, action) => {
  switch (action.type) {
    case "SET_VU_LIST":
      return {
        ...state,
        vusList: action.payload.vusList,
        continuationToken: action.payload.continuationToken,
      };
    case "SET_SELECTED_VU_CONTROL_PANEL":
      return {
        ...state,
        selectedVUControlPanel: action.payload.selectedVUControlPanel,
      };
    case "SET_SELECTED_VU":
      return {
        ...state,
        selectedVU: action.payload.selectedVU,
      };
    case "SET_ACTIVE_VU":
      return {
        ...state,
        activeVU: action.payload.activeVU,
      };
    case "SET_BATTERY_DATA":
      return {
        ...state,
        batteryData: action.payload.batteryData,
      };
    default:
      return state;
  }
};

export default VUsReducer;
