import React from "react";
import PropTypes from "prop-types";
import "Components/Buttons/SwitchButton/SwitchButton.scss";

const SwitchButton = ({
  isChecked,
  onChange,
  label,
  isDisabled,
  className,
}) => {
  label = label || "LP";
  return (
    <div
      className={`switch-button ${className} ${
        isChecked ? "checked" : "unchecked"
      } ${isDisabled ? "disabled gray-layout" : ""}`}
    >
      <input
        className="switch-button-checkbox"
        type="checkbox"
        onChange={onChange}
      />
      <label className="switch-button-label" htmlFor="">
        <span className="switch-button-label-span">{label}</span>
      </label>
    </div>
  );
};

export default SwitchButton;

SwitchButton.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};
