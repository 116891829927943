import React, { useMemo, useContext } from "react";
import PropTypes from "prop-types";
import DataSummary from "Components/DataSummary/DataSummary";
import SliderWrapper from "Components/SliderWrapper/SliderWrapper";
import Image from "Components/Image/Image";

import SocketContext from "Contexts/SocketContext";
import MainContext from "Contexts/MainContext";

import { getDcSamplerSummaryNumbers } from "Utils/UtilsFunctions";
import forceRFIcon from "Utils/Images/ProjectMaintanance/segment-force-rf-icon.svg";
import chargingIcon from "Utils/Images/ProjectMaintanance/duty-cycle-charging-icon.svg";
import "Screens/ProjectView/Devices/MU/SelectedSegment/SelectedSegmentSummary.scss";

const SelectedSegmentSummary = ({ selectedSegment }) => {
  const { selectedDevice } = useContext(MainContext);
  const { socketDcSamplerData } = useContext(SocketContext);

  const liveDCSamplerData =
    socketDcSamplerData[selectedDevice.id]?.socketMessageData;

  const { avgPowerLiveValue, avgVoltageLiveValue } = useMemo(
    () => getDcSamplerSummaryNumbers(liveDCSamplerData),
    [liveDCSamplerData]
  );

  const {
    segmentIzeroCurrent,
    segmentPhaseACurrent,
    segmentPhaseBCurrent,
    segmentIndFrequency,
    segmentDutyCycle,
    segmentHbridgeATemp,
    segmentHbridgeBTemp,
    commDuration,
    segmentCurrentInRms,
  } = selectedSegment;

  const voltageDisplay = useMemo(() => {
    return avgVoltageLiveValue
      ? avgVoltageLiveValue < 100
        ? Number(avgVoltageLiveValue).toFixed(1)
        : Number(avgVoltageLiveValue).toFixed(0)
      : 0;
  }, [avgVoltageLiveValue]);

  const powerDisplay = useMemo(() => {
    return {
      value: avgPowerLiveValue
        ? avgPowerLiveValue < 1000
          ? Number(avgPowerLiveValue).toFixed(1)
          : Number(avgPowerLiveValue / 1000).toFixed(2)
        : 0,
      units: avgPowerLiveValue < 1000 ? "W" : "KW",
    };
  }, [avgPowerLiveValue]);

  const irmsDisplay = useMemo(() => {
    return segmentCurrentInRms
      ? segmentCurrentInRms < 10
        ? segmentCurrentInRms.toFixed(2)
        : segmentCurrentInRms.toFixed(1)
      : 0;
  }, [segmentCurrentInRms]);

  return (
    <div className="selected-segment-summary">
      <div className="selected-segment-summary-row first-row">
        <DataSummary subtitle="Voltage" value={voltageDisplay} units="V" />
        <DataSummary subtitle="I-RMS" value={irmsDisplay} units="A" />
        <DataSummary
          subtitle="Power"
          value={powerDisplay ? powerDisplay.value : 0}
          units={powerDisplay ? powerDisplay.units : "W"}
        />
        <DataSummary subtitle="Io" value={segmentIzeroCurrent} units="nSec" />
        <DataSummary subtitle="IA" value={segmentPhaseACurrent} units="nSec" />
        <DataSummary subtitle="IB" value={segmentPhaseBCurrent} units="nSec" />
      </div>
      <div className="selected-segment-summary-row second-row">
        <DataSummary
          subtitle="Fi"
          value={segmentIndFrequency ? segmentIndFrequency : 0}
          units="kHz"
        />
        <div className="segment-bars-wrapper">
          <div className="duty-cycle-percentage-bar-wrapper">
            <div className="duty-cycle-percentage-bar">
              <SliderWrapper
                handleStyle={{ display: "none" }}
                trackStyle={{
                  backgroundColor: "rgba(0, 34, 102, 0.94)",
                }}
                railStyle={{
                  backgroundColor: "#CCDDFF",
                }}
                value={segmentDutyCycle && segmentDutyCycle.toFixed(1)}
                className="graph-segment-duty-cycle-slider"
                step={1}
                min={0}
                max={100}
              />
              <Image src={chargingIcon} className="duty-cycle-icon" />
              <div className="graph-segment-duty-cycle-value">
                {segmentDutyCycle && segmentDutyCycle.toFixed(1)}
                <span>%</span>
              </div>
            </div>
            <div className="subtitle"> Duty Cycle </div>
          </div>
          <div className="RF-percentage-bar-wrapper">
            <div className="RF-percentage-bar">
              <SliderWrapper
                handleStyle={{ display: "none" }}
                trackStyle={{
                  backgroundColor: "rgba(0, 34, 102, 0.94)",
                }}
                railStyle={{
                  backgroundColor: "#CCDDFF",
                }}
                value={commDuration ? commDuration.toFixed(0) : 0}
                className="graph-segment-duty-cycle-slider"
                step={1}
                min={0}
                max={100}
              />
              <Image src={forceRFIcon} className="force-rf-icon" />
              <div className="graph-segment-RF-value">
                {commDuration ? commDuration.toFixed(0) : 0}
                <span>%</span>
              </div>
            </div>
            <div className="subtitle"> RF </div>
          </div>
        </div>
        <DataSummary
          subtitle="T1"
          value={segmentHbridgeATemp ? segmentHbridgeATemp.toFixed(1) : 0}
          units="°C"
        />
        <DataSummary
          subtitle="T2"
          value={segmentHbridgeBTemp ? segmentHbridgeBTemp.toFixed(1) : 0}
          units="°C"
        />
      </div>
    </div>
  );
};

export default SelectedSegmentSummary;

SelectedSegmentSummary.propTypes = {
  selectedSegment: PropTypes.object,
};
