import blackSetupIcon from "Utils/Images/ProjectMaintanance/setup_icon_black.svg";
import blueSetupIcon from "Utils/Images/ProjectMaintanance/setup_icon_blue.svg";
import blueGraphIcon from "Utils/Images/ProjectMaintanance/performance-icon-blue.svg";
import blackGraphIcon from "Utils/Images/ProjectMaintanance/performance-icon-black.svg";
import blueActivityLogIcon from "Utils/Images/ProjectMaintanance/activity_log_icon_blue.svg";
import blackActivityLogIcon from "Utils/Images/ProjectMaintanance/activity_log_icon_black.svg";
import blueReportsIcon from "Utils/Images/ProjectMaintanance/reports_icon_blue.svg";
import blackReportsIcon from "Utils/Images/ProjectMaintanance/reports-icon-black.svg";

export const defaultMessageData = {
  socketMessageData: {},
  deviceId: 0,
  isActiveVU: false,
  eventName: "",
  deviceSocketCallback: undefined,
  isProjectSocket: false,
  entityId: "",
  entity: null,
};

export const defaultModalState = {
  isModalOpened: false,
  modalMessage: "",
  executeOnCancel: () => {},
  executeOnSubmit: () => {},
};

export const defaultShelfConfig = {
  band_0: 0,
  band_1: 0,
  band_2: 0,
  band_3: 0,
  band_4: 0,
  band_5: 0,
  current_off: 0,
  pwn_off_time_dynamic: 0,
  pwn_off_time_static: 0,
  pwn_on_time: 0,
  firmwareVersion: 0,
  linuxAppVersion: 0,
};

export const projectDeviceTabs = [
  {
    title: "PERFORMANCE",
    activeIcon: blackGraphIcon,
    nonActiveIcon: blueGraphIcon,
  },
  {
    title: "SETUP",
    activeIcon: blackSetupIcon,
    nonActiveIcon: blueSetupIcon,
  },
  {
    title: "ACTIVITY LOG",
    activeIcon: blackActivityLogIcon,
    nonActiveIcon: blueActivityLogIcon,
  },
  {
    title: "REPORTS",
    activeIcon: blackReportsIcon,
    nonActiveIcon: blueReportsIcon,
  },
];

export const limitMUList = 100;
export const isSideListOpen = true;
export const isGraphOpen = true;
export const fpgaOverCurrentVersion = "1.0.0.137";
export const overCurrentThresholdFormulaConst = 20;
export const overCurrentMaskFormulaConst = 5;
export const overCurrentStaticMaskFormulaConst = 5;
