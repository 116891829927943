import { createTheme, Theme } from "@mui/material";
import { ThemeMode } from "GlobalTypes";

type ColorTone = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
type ColorToneMap = Record<ColorTone, string> | { disabled: string };

declare module "@mui/material/styles" {
  interface Palette {
    accent: {
      primary: Palette["primary"];
      secondary: Palette["primary"];
      gray: Palette["primary"];
      black: Palette["primary"];
      green: Palette["primary"];
    };
  }
  interface PaletteOptions {
    accent: {
      primary: PaletteOptions["primary"] | ColorToneMap;
      secondary: PaletteOptions["primary"] | ColorToneMap;
      gray: PaletteOptions["primary"] | ColorToneMap;
      black: PaletteOptions["primary"] | ColorToneMap;
      green: PaletteOptions["primary"] | ColorToneMap;
    };
  }

  interface PaletteColor extends Record<ColorTone, string> {}
}

const lightPalette = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "rgba(249, 250, 251, 1)",
      paper: "#ffffff",
    },
    accent: {
      primary: {
        main: "#00D1FD",
        50: "#C2F5FF",
        100: "#AEF1FF",
        200: "#93EDFF",
        300: "#6EE7FF",
        400: "#3EDFFF",
        500: "#00D1FD",
        600: "#00B3D7",
        700: "#0098B7",
        800: "#00819B",
        900: "#006E84",
      },
      secondary: {
        main: "#3EE452",
        50: "#D1F9D6",
        100: "#C2F6C8",
        200: "#AEF4B6",
        300: "#93F09D",
        400: "#6EEB7D",
        500: "#3EE452",
        600: "#1ED834",
        700: "#1AB82C",
        800: "#169C26",
        900: "#138520",
      },
      gray: {
        main: "#62696F",
        disabled: "#BBBCC1",
        50: "#D9DBDE",
        100: "#CCD0D2",
        200: "#BCC0C3",
        300: "#A5ABB0",
        400: "#878F95",
        500: "#62696F",
        600: "#53595E",
        700: "#474C50",
        800: "#3C4044",
        900: "#33373A",
      },
      black: {
        main: "#001133",
      },
      green: {
        main: "#3EE452",
      },
      // TODO: add additional, and system colors from figma
    },
  },
});

const darkPalette = createTheme({
  palette: {
    ...lightPalette.palette,
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#000000",
    },
  },
});

export const getPalette = (type?: ThemeMode): Theme => {
  switch (type) {
    case "light":
      return lightPalette;
    case "dark":
      return darkPalette;
    default:
      return lightPalette;
  }
};
