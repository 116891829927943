import React from "react";

const MUsContext = React.createContext({
  fotaProgress: [],
  fpgaProgress: [],
  fpgaProgressDone: false,
  fpgaIsInProgress: false,
  musEventsMessages: [],
  MUsDispatch: () => {},
});

export default MUsContext;
