import React, { useContext } from "react";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
import Image from "Components/Image/Image";
import MainContext from "Contexts/MainContext";

const FilteredSearchResults = ({
  filteredData,
  filteredProperty,
  secondaryProperty,
  filteredDataName,
  total,
  itemImageFn,
}) => {
  const { isLiveView, appQuery ,Dispatch } = useContext(MainContext);

  return (
    <div className="unit-search-result">
      <div className="search-result-title">
        <div className="search-result-name">{startCase(filteredDataName)}</div>
        <div className="search-result-length">{total}</div>
      </div>
      {filteredData &&
        filteredData.map((dataItem, index) => {
          return (
            <div
              key={index}
              className="filtered-search-item"
              onClick={() =>
                filteredDataName !== "projects"
                  ? Dispatch({
                      type: "SET_SELECTED_DEVICE",
                      payload: {
                        selectedDevice: dataItem,
                      },
                    })
                  : Dispatch({
                      type: "SET_SELECTED_PROJECT",
                      payload: {
                        selectedProject: dataItem,
                      },
                    })
              }
            >
              <Image
                className="image-dropdown"
                src={itemImageFn(dataItem)}
                height={30}
                width={30}
              />
              <div className="filtered-search-dropdown">
                <Link
                  className="link-dropdown"
                  to={{
                    //todo: get red of the isLiveView
                    pathname:
                      filteredDataName === "projects"
                        ? `/dashboard/${
                            isLiveView ? "live" : "history"
                          }/project/${dataItem.id}/inline`
                        : `/dashboard/${
                            isLiveView ? "live" : "history"
                          }/project/${dataItem.projectId}/inline`,
                  }}
                >
                  <Highlighter
                    searchWords={[appQuery]}
                    autoEscape
                    textToHighlight={
                      filteredDataName === "projects"
                        ? startCase(dataItem[filteredProperty])
                        : dataItem[filteredProperty]
                    }
                  />
                </Link>
                <div className="identity-dropdown">
                  <Highlighter
                    searchWords={[appQuery]}
                    autoEscape
                    textToHighlight={
                      filteredDataName === "projects"
                        ? startCase(dataItem[secondaryProperty])
                        : dataItem[secondaryProperty]
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FilteredSearchResults;

FilteredSearchResults.propTypes = {
  filteredData: PropTypes.array,
  filteredProperty: PropTypes.string,
  secondaryProperty: PropTypes.string,
  filteredDataName: PropTypes.string,
  Dispatch: PropTypes.func,
  total: PropTypes.number,
  itemImageFn: PropTypes.func,
};
