export const defaultManagementUnits = {
  deploymentTypes: [],
  chargingTypes: [],
  total: 0,
  offline: 0,
  projectId: null,
};

export const defaultChargingTypes = { STATIC: 0, DYNAMIC: 0 };

export const defaultDeploymentTypes = {
  UNDERGROUND: 0,
  SURFACE: 0,
  LAB: 0,
};

export const defaultVehicleUnits = {
  vehicleTypes: [],
  totalVU: 0,
  OfflineVU: 0,
};

export const defaultVehicleTypes = {
  BUS: "",
  TRUCK: "",
  PRIVATE: "",
};
