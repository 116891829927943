import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import DateInput from "Components/Inputs/DateInput";
import TimeInput from "Components/Inputs/TimeInput";
import NumericInput from "Components/NumericInput/NumerInput";
import moment from "moment";
import AppButton from "Components/Buttons/AppButton/AppButton";
import { isHistoryTimeInvalid } from "Utils/UtilsFunctions";
import { getSessionIdProject } from "Utils/APIUtils";
import useSelectedProjectData from "CustomHooks/useSelectedProject";
import MainContext from "Contexts/MainContext";
import "Components/TimeRangeSelection/TimeRangeSelection.scss";

const date = () => moment().format("yyyy-MM-DD");

const TimeRangeSelection = ({ onLoadFunction, maxValue }) => {
  const sessionId = getSessionIdProject();
  const { timezoneStr } = useSelectedProjectData();
  const { selectedDevice } = useContext(MainContext);
  const time = useMemo(
    () => moment().tz(timezoneStr).format("HH:mm:ss"),
    [selectedDevice?.id]
  );
  const [isUpdating, setIsUpdating] = useState([]);
  const [dateVal, setDateVal] = useState(date);
  const [startTime, setStartTime] = useState(time);
  const [spanVal, setSpanVal] = useState(5);
  const [loadedDataParams, setLoadedDataParams] = useState({
    date: dateVal,
    span: spanVal,
    startTime: startTime,
  });

  const onInputFocus = (str) => {
    isUpdating?.indexOf(str) === -1 && setIsUpdating([...isUpdating, str]);
  };

  const onInputChange = (e, setVal, str) => {
    if (e && e.target) {
      if (str === "span") {
        setVal(Number(e.target.value));
      } else {
        setVal(e.target.value);
      }
      isUpdating?.indexOf(str) === -1 && setIsUpdating([...isUpdating, str]);
    }
  };

  const onInputBlur = (str, value) => {
    const oldValue = loadedDataParams && loadedDataParams[str];
    if (value && oldValue && value === oldValue) {
      isUpdating && setIsUpdating(isUpdating.filter((e) => e !== str));
    }
  };

  const shouldDisableLoad = useMemo(() => {
    return isHistoryTimeInvalid(dateVal, startTime, spanVal, time);
  }, [dateVal, startTime, spanVal, time]);

  useEffect(() => {
    setDateVal(date);
    setStartTime(moment().tz(timezoneStr).format("HH:mm:ss"));
    setSpanVal(5);
  }, [selectedDevice?.id]);

  return (
    <div className="time-range-selection-wrapper">
      <DateInput
        inputClassName={isUpdating?.indexOf("date") > -1 ? "is-updating" : ""}
        label="Date:"
        value={dateVal}
        onChange={(e) => onInputChange(e, setDateVal, "date")}
        onFocus={() => onInputFocus("date")}
        onBlur={() => {
          onInputBlur("date", dateVal);
        }}
      />
      <TimeInput
        inputClassName={
          isUpdating?.indexOf("startTime") > -1 ? "is-updating" : ""
        }
        step={1}
        label="Start:"
        value={startTime}
        onChange={(e) => onInputChange(e, setStartTime, "startTime")}
        onFocus={() => onInputFocus("startTime")}
        onBlur={() => {
          onInputBlur("startTime", startTime);
        }}
      />
      <NumericInput
        inputClassName={`${spanVal > Number(maxValue) ? "error" : ""} ${
          isUpdating?.indexOf("span") > -1 ? "is-updating" : ""
        }`}
        label="Span:"
        maxlength={maxValue.length}
        value={Number(spanVal)}
        unit="Sec"
        onChange={(e) => onInputChange(e, setSpanVal, "span")}
        onFocus={() => onInputFocus("span")}
        onBlur={() => {
          onInputBlur("span", spanVal);
        }}
      />
      <AppButton
        className="load-history-btn"
        text="Load"
        onClick={() => {
          if (shouldDisableLoad) {
            return;
          }
          const dataToLoad = {
            sessionId: sessionId,
            startSec: `${dateVal}T${startTime}Z`,
            rangeSec: spanVal,
            projectTz: timezoneStr,
          };
          onLoadFunction(dataToLoad, spanVal, dateVal, startTime).then(
            (res) => {
              if (
                res &&
                res.length > 0 &&
                res.filter((response) => response?.status === 200).length > 0
              ) {
                setIsUpdating([]);
                setLoadedDataParams({
                  date: dateVal,
                  span: spanVal,
                  startTime: startTime,
                });
              }
            }
          );
        }}
        isDisabled={shouldDisableLoad || spanVal > Number(maxValue)}
      />
    </div>
  );
};

export default TimeRangeSelection;

TimeRangeSelection.propTypes = {
  onLoadFunction: PropTypes.func,
  maxValue: PropTypes.string,
};
