import {
  getVehicleImagePath,
  getVehicleSmallImagePath,
  getVehicleLogoImagePath,
} from "Utils/APIUtils";

const getDefaultVehicleRowData = () => {
  return {
    energy: {
      num: 0,
      txt: "kWh",
    },
    time: {
      num: 0,
      txt: "min",
    },
  };
};

const getInitialVehiclesTableData = () => {
  return {
    daily: getDefaultVehicleRowData(),
    monthly: getDefaultVehicleRowData(),
    sinceBeginning: getDefaultVehicleRowData(),
  };
};

export const getVehicleInitialData = (vehicle) => {
  return {
    id: vehicle.id,
    name: vehicle.name,
    barVal: 0,
    chargeValue: 0,
    speedValue: 0,
    powerGraphValue: 0,
    vehicleImg: getVehicleImagePath(vehicle.id),
    vehicleSmall: getVehicleSmallImagePath(vehicle.id),
    vehicleLogo: getVehicleLogoImagePath(vehicle.id),
    vehicleTableData: getInitialVehiclesTableData(),
    isOffline: true,
  };
};

export const combinedVehiclesData = (vehicle, filteredKeys) => ({
  [filteredKeys[0] || filteredKeys[1]]: {
    id: vehicle[filteredKeys[0]].id || vehicle[filteredKeys[1]].id,
    name: vehicle[filteredKeys[0]].name || vehicle[filteredKeys[1]].name,
    barVal: vehicle[filteredKeys[0]].barVal || vehicle[filteredKeys[1]].barVal,
    chargeValue:
      vehicle[filteredKeys[0]].chargeValue +
      vehicle[filteredKeys[1]].chargeValue,
    speedValue:
      vehicle[filteredKeys[0]].speedValue ||
      vehicle[filteredKeys[1]].speedValue,
    powerGraphValue:
      vehicle[filteredKeys[0]].powerGraphValue +
      vehicle[filteredKeys[1]].powerGraphValue,
    vehicleImg:
      vehicle[filteredKeys[0]].vehicleImg ||
      vehicle[filteredKeys[1]].vehicleImg,
    vehicleSmall:
      vehicle[filteredKeys[0]].vehicleSmall ||
      vehicle[filteredKeys[1]].vehicleSmall,
    vehicleLogo:
      vehicle[filteredKeys[0]].vehicleLogo ||
      vehicle[filteredKeys[1]].vehicleLogo,
    vehicleTableData:
      vehicle[filteredKeys[0]].vehicleTableData ||
      vehicle[filteredKeys[1]].vehicleTableData,
    isOffline:
      vehicle[filteredKeys[0]].isOffline && vehicle[filteredKeys[1]].isOffline,
    chargeMaxValue: 100,
    powerGaugeShowPercentage:
      !!vehicle[filteredKeys[0]].powerGaugeShowPercentage ||
      !!vehicle[filteredKeys[1]].powerGaugeShowPercentage,
  },
});

export const combinedGpsData = (gpsPositions, filteredKeys) => {
  if (Object.keys(gpsPositions)?.length) {
    if (!filteredKeys?.length) return {};
    return {
      [filteredKeys[0] || filteredKeys[1]]: {
        name:
          gpsPositions[filteredKeys[0]]?.name ||
          gpsPositions[filteredKeys[1]]?.name,
        label:
          gpsPositions[filteredKeys[0]]?.label ||
          gpsPositions[filteredKeys[1]]?.label,
        vehicleLogo:
          gpsPositions[filteredKeys[0]]?.vehicleLogo ||
          gpsPositions[filteredKeys[1]]?.vehicleLogo,
        vuId:
          gpsPositions[filteredKeys[0]]?.vuId ||
          gpsPositions[filteredKeys[1]]?.vuId,
        lng:
          gpsPositions[filteredKeys[0]]?.lng ||
          gpsPositions[filteredKeys[1]]?.lng,
        lat:
          gpsPositions[filteredKeys[0]]?.lat ||
          gpsPositions[filteredKeys[1]]?.lat,
        isCharging:
          gpsPositions[filteredKeys[0]]?.isCharging ||
          gpsPositions[filteredKeys[1]]?.isCharging,
      },
    };
  } else {
    return {};
  }
};
