import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react-lite";

//Components
import Header from "Components/Header/Header";
import ProjectCard from "Components/ProjectCard/ProjectCard";
import CreateProject from "Components/CreateProject/CreateProject";
import CustomLoader from "Components/Loader/Loader";

//Context
import MainContext from "Contexts/MainContext";
import { useAppStore } from "MobxStores/context";

//Hooks
import useRole from "CustomHooks/useRole";

//Utils
import { getProjects } from "Utils/APIUtils";
import "Screens/Dashboard/Dashboard.scss";
import { Redirect, useHistory } from "react-router-dom";

const Dashboard = () => {
  const { userScope, userRole, shouldAcquireToken, mainDispatch } =
    useContext(MainContext);
  const { canAddProject } = useRole(userRole);
  const [isLoading, setIsLoading] = useState(false);
  const { projectStore, userStore } = useAppStore();
  const { userData } = userStore;
  const { projectsList } = projectStore;
  const history = useHistory();

  const shouldHide = !canAddProject || userScope !== "*" ? "hidden" : "";

  useEffect(() => {
    if (shouldAcquireToken) {
      return;
    } else {
      setIsLoading(true);
      getProjects().then((res) => {
        setIsLoading(false);
        if (res && res.data) {
          projectStore.setProjectsList(res.data);
        }
      });
      mainDispatch({
        type: "SET_SELECTED_PROJECT",
        payload: { selectedProject: undefined, isLoading: false },
      });
    }
  }, [shouldAcquireToken]);

  if (userData?.isSingleProjectViewer()) {
    history.push(`/dashboard/live/project/${userScope}`);
  } else if (userData?.isSingleScoped()) {
    history.push(`/dashboard/live/project/${userScope}/inline`);
  }

  return (
    <div className="home-dashboard">
      <Header />
      <div className="home-dashboard-main">
        <div className="home-dashboard-active-tab">
          {!shouldHide && <CreateProject />}
          <div className="projects-cards">
            {projectsList &&
              projectsList.length &&
              projectsList.map((project, index) => {
                return <ProjectCard project={project} key={index} />;
              })}
          </div>
        </div>
        {isLoading && (
          <CustomLoader className="dashboard_loader" width={50} height={50} />
        )}
      </div>
    </div>
  );
};

export default observer(Dashboard);
