import React from "react";

const BookmarksContext = React.createContext({
  bookmarks: [],
  bookmarkData: {
    isBMOpen: false,
    BMStartTime: Date.now(),
    BMEndTime: Date.now(),
  },

  bookmarksDispatch: () => {},
});

export default BookmarksContext;
