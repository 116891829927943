import "./LsmuParkingSpots.scss";
import ParkingSpotControl from "./ParkingSpotControl";

const LsmuParkingSpots = ({}) => {
  const parkingSpots = [1, 2, 3, 4].reverse().map((p) => "Parking " + p);
  return (
    <div className="lsmu-parking-spots-wrapper">
      {parkingSpots.map((p) => (
        <ParkingSpotControl p={p} />
      ))}
    </div>
  );
};

export default LsmuParkingSpots;
