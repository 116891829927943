import "Screens/ProjectView/LsmuTab/ParkingSpotControl.scss";
import ContactorControl from "Components/ContactorControl/ContactorControl";
import DcSamplerSummary from "Components/DcSampler/DcSamplerSummary";
import OffIdleButtons from "Components/Buttons/OffIdleButtons/OffIdleButtons";
import ParkingStatusIndication from "Components/StatusIndication/ParkingStatusIndication";
import LsmuParkingControlButton from "Components/Buttons/LsmuParkingControlButton/LsmuParkingControlButton";
import MuIndicationLeds from "Components/StatusIndication/MuIndicationLeds";
import React from "react";

const ParkingSpotControl = ({ p }) => {
  const isParkingLocked = false;
  return (
    <div className="parking-spot-control-wrapper">
      <div className="parking-row">
        <div className="parking-header">
          <ParkingStatusIndication />
          <label className="parking-spot-label">{p}</label>
        </div>
        <OffIdleButtons
          offButtonTitle="Force Off Parking"
          offButtonOnClick={() => {}}
          isDisabled={isParkingLocked}
          idleButtonTitle="Idle Off Parking"
          idleButtonOnClick={() => {}}
        />
      </div>
      <div className="parking-row">
        <LsmuParkingControlButton />
        <ContactorControl />
        <MuIndicationLeds />
        <DcSamplerSummary />
      </div>
    </div>
  );
};
export default ParkingSpotControl;
