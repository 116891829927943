import moment from "moment";

export const getPlaceHolderActivityData = () => {
  return Array.from({ length: 37 }, (_, i) => {
    const timestamp = moment()
      .subtract(2, "day")
      .hour(18)
      .minute(0)
      .second(0)
      .add(i, "hour");
    return {
      timestamp: timestamp.clone().valueOf(),
      chargeActive: false,
    };
  });
};
