import React, { useContext } from "react";
import Popup from "reactjs-popup";
import { IoMdClose } from "react-icons/io";
import "Components/ThreeDotsMenu/ThreeDotsMenu.scss";
import PropTypes from "prop-types";

//Components
import Image from "Components/Image/Image";

//Hooks
import useRole from "CustomHooks/useRole";

//Context
import { useAppStore } from "MobxStores/context";
import MainContext from "Contexts/MainContext";

//Utils
import threeDots from "Utils/Images/GlobalIcons/three-dots.svg";
import { deleteProject, getProjects } from "Utils/APIUtils";

const wrapperOverlayStyle = { background: "rgba(0,0,0,0.5)" };
const wrapperContentStyle = {
  width: "250px",
  height: "80px",
  padding: "5px",
  border: "none",
  boxShadow: "2px 2px 5px 4px rgba(0, 0, 0, 0.5)",
  borderRadius: "3px",
  backgroundColor: "rgba(255,255,255,0.7)",
  margin: "-1.8% -3% -3% -4%",
  cursor: "pointer",
  fontSize: "20px",
};
const menuStyle = {
  width: "250px",
  height: "100px",
  padding: "5px",
  border: "black 1px solid",
  borderRadius: "3px",
  backgroundColor: "rgba(255,255,255)",
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "20px",
  color: "red",
  margin: "1.8% 0.03%",
};

const ThreeDotsMenu = ({ id }) => {
  const { userRole } = useContext(MainContext);
  const { canDeleteProject, canEditProject } = useRole(userRole);
  const { projectStore } = useAppStore();

  return (
    <div className="threeDotsMenu-wrapper">
      <Popup
        trigger={
          <div>
            <Image
              src={threeDots}
              alt="Three Dots Icon Button for menu"
              height={30}
              width={30}
              className="dotsMenuIcon"
            />
          </div>
        }
        position="bottom right left top"
        on="click"
        className="popup-menu-content"
        closeOnDocumentClick={false}
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        overlayStyle={wrapperOverlayStyle}
        contentStyle={wrapperContentStyle}
        arrow={false}
        id="popup-menu"
      >
        {(close) => (
          <div className="menu">
            <IoMdClose onClick={close} className="closeIcon" />
            <div className="menu-item">VIEW PROJECT DETAILS</div>
            {canEditProject && <div className="menu-item">EDIT</div>}
            {canDeleteProject && (
              <Popup
                trigger={<div className="menu-item delete">DELETE</div>}
                position=""
                closeOnDocumentClick={false}
                contentStyle={menuStyle}
                arrow={false}
                on="click"
              >
                <IoMdClose onClick={close} className="closeIcon" />
                <span> You Sure You Want To Delete This Project? </span>
                <div className="actions">
                  <button
                    className="btn confirmDelete"
                    onClick={() => {
                      deleteProject({ projectId: id })
                        .then((res) => {
                          return getProjects();
                        })
                        .then((res) => {
                          if (res.data) {
                            projectStore.setProjectsList(res.data);
                          }
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }}
                  >
                    YES!
                  </button>
                </div>
              </Popup>
            )}
          </div>
        )}
      </Popup>
    </div>
  );
};

export default ThreeDotsMenu;

ThreeDotsMenu.propTypes = {
  id: PropTypes.number,
};
