import { useContext, useState } from "react";

// Context
import MainContext from "Contexts/MainContext";

// Components
import SwitchButton from "Components/Buttons/SwitchButton/SwitchButton";
import StatusIndication from "Components/StatusIndication/StatusIndication";
import VtTooltip from "Components/Tooltip/VtTooltip";

const VuActivationMode = ({ debugMode, toggleDebug, isLocked, canDebug }) => {
  const { selectedDevice } = useContext(MainContext);
  const activationMode = selectedDevice?.activationMode || "";
  const [vtTooltip, setVtTooltip] = useState({
    isOpen: false,
    data: [
      {
        name: "Low",
        value: selectedDevice?.voltageLowThresholdValue,
        unit: "V",
      },
      {
        name: "High",
        value: selectedDevice?.voltageHighThresholdValue,
        unit: "V",
      },
      {
        name: "Hysterisis",
        value: selectedDevice?.voltageHysteresisValue,
        unit: "%",
      },
    ],
  });

  const handleVuReceiversMouseEnter = () => {
    if (activationMode !== "VOLTAGE_TRACKING") return;
    setVtTooltip({ ...vtTooltip, isOpen: true });
  };

  const handleVuReceiversMouseLeave = () => {
    if (activationMode !== "VOLTAGE_TRACKING") return;
    setVtTooltip({ ...vtTooltip, isOpen: false });
  };

  return (
    <div
      className="vu-receivers-debug-wrapper"
      onMouseEnter={handleVuReceiversMouseEnter}
      onMouseLeave={handleVuReceiversMouseLeave}
    >
      {activationMode === "VOLTAGE_TRACKING" ? (
        <>
          <StatusIndication
            statusText="VT MIN-MAX"
            borderStyle="solid thin #CCDDFF"
            backgroundColor="#ccdcff"
          />
          {vtTooltip.isOpen && <VtTooltip tooltipData={vtTooltip.data} />}
        </>
      ) : (
        <SwitchButton
          label="NORM"
          isChecked={debugMode}
          onChange={toggleDebug}
          isDisabled={isLocked || !canDebug}
        />
      )}
    </div>
  );
};

export default VuActivationMode;
