import VerticalTable from "Components/Tables/VerticalTable";
import { formatTableDataForDisplay } from "Utils/AnalyticUtils";
import { SummaryTableData } from "MobxStores/Types";

type SummaryTabeProps = {
  tableData: SummaryTableData;
  loading: boolean;
};

const AnalyticsSummaryTable: React.FC<SummaryTabeProps> = ({
  tableData,
  loading,
}) => {
  const tableDataForDisplay = formatTableDataForDisplay(tableData);

  return (
    <VerticalTable tableDataArray={tableDataForDisplay} loading={loading} />
  );
};

export default AnalyticsSummaryTable;
