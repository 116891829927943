import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import fanError from "Utils/Images/ProjectMaintanance/fan-high-icon.svg";
import fanWarning from "Utils/Images/ProjectMaintanance/fan-medium-icon.svg";
import fanIsOff from "Utils/Images/ProjectMaintanance/fan-off-icon.svg";
import fan from "Utils/Images/ProjectMaintanance/fan-low-icon.svg";
import ONFanIcon from "Utils/Images/ProjectMaintanance/on_fan_icon.svg";
import autoFanIcon from "Utils/Images/ProjectMaintanance/auto-icon.svg";
import SocketContext from "Contexts/SocketContext";
import { setFanOn } from "Utils/APIUtils";
import { fansMock } from "Utils/MockData/ProjectMockData";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import Image from "Components/Image/Image";
import MainContext from "Contexts/MainContext";
import useRole from "CustomHooks/useRole";
import "Screens/ProjectView/Devices/MU/FansView/FansView.scss";

const FansView = ({ selectedDrawer, MUId, isLocked, sessionId }) => {
  const { fans } = useContext(SocketContext);
  const { socketMessageData } = fans[MUId] || fansMock;

  const { userRole } = useContext(MainContext);
  const { canControlFans } = useRole(userRole);

  const fansNewData = useMemo(() => {
    return (
      !!socketMessageData &&
      Object.keys(socketMessageData).map((fanDrawer, index) => {
        const fanDataDrawer = Object.keys(socketMessageData[fanDrawer]).reduce(
          (accumulator, currentValue) => {
            let fansId = currentValue.split("_")[1];
            if (
              currentValue.indexOf("fanFoceOn") === -1 &&
              currentValue.indexOf("timestamp") === -1
            ) {
              if (accumulator[fansId] && accumulator[fansId]["fans"]) {
                accumulator[fansId]["fans"].push({
                  rpmStatus: socketMessageData[fanDrawer][currentValue],
                });
              } else {
                accumulator[fansId] = {
                  fans: [
                    {
                      rpmStatus: socketMessageData[fanDrawer][currentValue],
                    },
                  ],
                };
              }
            } else if (currentValue.indexOf("timestamp") === -1) {
              if (accumulator[fansId] && accumulator[fansId]["fans"]) {
                Object.keys(accumulator[fansId]).forEach((fan, index) => {
                  accumulator[fansId] = {
                    ...accumulator[fansId],
                    isForcedOn: socketMessageData[fanDrawer][currentValue],
                  };
                });
              }
            }
            return accumulator;
          },
          {}
        );

        return {
          [`fans_drawer_${index + 1}`]: fanDataDrawer,
        };
      })
    );
  }, [socketMessageData]);

  const fansDrawerData = useMemo(() => {
    return fansNewData[selectedDrawer - 1];
  }, [fansNewData, selectedDrawer]);

  return useMemo(() => {
    return (
      <>
        <div className="fans-view">
          {fansDrawerData &&
            fansDrawerData[`fans_drawer_${selectedDrawer}`] &&
            Object.values(fansDrawerData[`fans_drawer_${selectedDrawer}`])
              .slice(0)
              .reverse()
              .map((segmentRowFans, doubleFansIndex) => {
                const { fans, isForcedOn } = segmentRowFans;
                return (
                  doubleFansIndex < 6 && (
                    <div
                      className="segment-row-double-fans"
                      key={doubleFansIndex}
                    >
                      {fans &&
                        fans.map((fanData, fanIndex) => {
                          return !fanIndex ? (
                            <IndicationButton
                              key={fanIndex}
                              title="Fan Control"
                              isDisabled={isLocked || !canControlFans}
                              text=""
                              onClick={() =>
                                setFanOn({
                                  MUId,
                                  fan_drawer: selectedDrawer - 1,
                                  fan_cmd_type: isForcedOn ? 0 : 2,
                                  fan_segment_pair_id: 6 - doubleFansIndex,
                                  sessionId,
                                })
                              }
                              buttonIconUrl={
                                isForcedOn ? ONFanIcon : autoFanIcon
                              }
                            />
                          ) : (
                            ""
                          );
                        })}
                      <div className="two-fans-status">
                        {fans &&
                          fans.map((fanData, fanIndex) => {
                            const { rpmStatus } = fanData;
                            return (
                              <div className="segment-row-fan" key={fanIndex}>
                                <img
                                  src={
                                    rpmStatus === 0
                                      ? fanIsOff
                                      : rpmStatus === 3
                                      ? fanError
                                      : rpmStatus === 2
                                      ? fanWarning
                                      : fan
                                  }
                                  style={{ borderRadius: "50%" }}
                                  alt="fan-status"
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )
                );
              })}
        </div>

        <div className="back-fan">
          {fansDrawerData &&
            fansDrawerData[`fans_drawer_${selectedDrawer}`] &&
            Object.values(fansDrawerData[`fans_drawer_${selectedDrawer}`]).map(
              (segmentRowFans, doubleFansIndex) => {
                const { fans } = segmentRowFans;
                return (
                  doubleFansIndex === 0 && (
                    <div
                      className="segment-row-back-fan-force-all"
                      key={doubleFansIndex}
                    >
                      {fans &&
                        fans.map((fanData, fanIndex) => {
                          const { rpmStatus } = fanData;
                          return (
                            <div
                              className="segment-row-back-fan"
                              key={fanIndex}
                            >
                              <Image
                                src={
                                  rpmStatus === 0
                                    ? fanIsOff
                                    : rpmStatus === 3
                                    ? fanError
                                    : rpmStatus === 2
                                    ? fanWarning
                                    : fan
                                }
                                alt="fan-status"
                              />
                              <label>Rack Fan</label>
                            </div>
                          );
                        })}
                    </div>
                  )
                );
              }
            )}
        </div>
      </>
    );
  }, [fansDrawerData, selectedDrawer, MUId, sessionId]);
};

export default FansView;

FansView.propTypes = {
  selectedDrawer: PropTypes.number,
  MUId: PropTypes.string,
  isLocked: PropTypes.bool,
  sessionId: PropTypes.string,
};
