import UserStore from "MobxStores/UserStore";
import ProjectAnalyticsStore from "MobxStores/ProjectAnalyticsStore";
import ProjectStore from "MobxStores/ProjectStore";
import DepotStore from "./DepotStore";

class RootStore {
  userStore;
  projectAnalyticsStore;
  projectStore;

  constructor() {
    // for debugging purposes
    window["rootStore"] = this;

    this.userStore = new UserStore(this);
    this.projectAnalyticsStore = new ProjectAnalyticsStore(this);
    this.projectStore = new ProjectStore(this);
    this.depotStore = new DepotStore(this);
  }

  debug() {
    console.info("RootStore", JSON.parse(JSON.stringify(this)));
  }

  debugDepot() {
    console.table("DepotStore", JSON.parse(JSON.stringify(this.depotStore)));
  }
}

export default RootStore;
