import React from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

//Components
import ProjectDeviceHeader from "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader";
import IndicationButton from "Components/Buttons/IndicationButton/IndicationButton";
import MUMainView from "Components/MUMainView/MUMainView";
import ProjectControlPanel from "Screens/ProjectView/SetupTab/ControlPanel/ProjectControlPanel";
import LogsSidePanel from "Screens/ProjectView/LogsSidePanel/LogsSidePanel";
import SetupRackColumn from "Screens/ProjectView/SetupTab/RackSection/SetupRackColumn";
import LockButton from "Components/Buttons/LockButton/LockButton";
import RoundedProgress from "Components/ProgressComponents/RoundedProgress";
import SetupSegmentDriverConfig from "Screens/ProjectView/SetupTab/SetupSegmentDriverConfig";
import LsmuParkingSpots from "Screens/ProjectView/LsmuTab/LsmuParkingSpots";

//Utils
import { setAllMuFans, setFpgaCommandForceOffAll } from "Utils/APIUtils";
import { isForceOffAll } from "Utils/UtilsFunctions";
import autoFanIcon from "Utils/Images/ProjectMaintanance/auto-fan-icon.svg";
import ONFanIcon from "Utils/Images/ProjectMaintanance/on_fan_icon_2.svg";
import ForceOffIcon from "Utils/Images/ProjectMaintanance/segment-force-off-icon.svg";

//Hooks
import useSetupTab from "Screens/ProjectView/SetupTab/useSetupTab";
import useSegments from "CustomHooks/projectViewLogic/projectViewContextHooks/useSegments";

const SetupTab = ({
  setLocIntervalInstances,
  locIntervalInstances,
  selectedDrawer,
  setSelectedDrawer,
  selectedSegmentIndex, //todo : remove from project view and use on setup tab
  setSelectedSegmentIndex,
  sendCommMessage,
  sendForceOnMessage,
  sendPWMMessage,
  locTimeoutInstances,
  setModalState,
}) => {
  const { socketMessageDataSegments, selectedDrawerSegments } = useSegments(
    selectedDrawer,
    selectedSegmentIndex
  );

  const {
    selectedDevice,
    selectedDeviceId,
    selectedDrawerConfiguration,
    fotaProgress,
    sessionId,
    lockState,
    canControlFans,
    canControlIdleOff,
  } = useSetupTab(
    locTimeoutInstances,
    socketMessageDataSegments,
    selectedDrawer
  );
  const isLSMU =
    selectedDevice?.id === "AG0000151" ||
    selectedDevice?.id === "AG0000152" ||
    selectedDevice?.id === "AG0000200";

  return (
    <div className="project-device-setup">
      <LogsSidePanel buttonTitle="ACTIVITY" panelTitle="Activity Log" />

      <div className="project-device-setup-sections">
        <div className="project-device-setup-drawers setup-section">
          <div className="section-header title-indication-buttons">
            <div className="header-title">
              <ProjectDeviceHeader
                title={selectedDevice?.name}
                className="project-device-header-i"
                infoIconDataFor="device-tip"
              />
              <ReactTooltip
                id="device-tip"
                className="device-tip"
                getContent={() => (
                  <div>
                    <div className="mu-serial">ID: {selectedDeviceId}</div>
                    <div className="st-version">
                      ST FW Version:{" "}
                      {selectedDevice ? selectedDevice["softwareVersion"] : 0}
                    </div>
                    {selectedDevice && selectedDevice.hardwareVersion && (
                      <div className="hw-version">
                        Version: {selectedDevice.hardwareVersion}
                      </div>
                    )}
                  </div>
                )}
                effect="solid"
                delayHide={0}
                delayShow={0}
                delayUpdate={0}
                place="right"
                border
                type="light"
              />
              <LockButton
                device={selectedDevice}
                locIntervalInstances={locIntervalInstances}
                setLocIntervalInstances={setLocIntervalInstances}
                lockState={lockState}
              />
              <RoundedProgress
                valueMin="0"
                valueMax="100"
                valueNow={fotaProgress[selectedDeviceId]}
              />
            </div>
            {isLSMU ? (
              ""
            ) : (
              <div className="header-buttons">
                <div className="fan-force-all">
                  <IndicationButton
                    title={lockState?.isLocked ? "MU fans AUTO" : ""}
                    isDisabled={lockState?.setupDisabled || !canControlFans}
                    text=""
                    onClick={() => {
                      setAllMuFans({
                        MUId: selectedDeviceId,
                        fan_cmd_type: 1,
                        sessionId: sessionId,
                      }).then((res) => {
                        if (res && res.status !== 200) {
                          console.error(res);
                        }
                      });
                    }}
                    buttonIconUrl={autoFanIcon}
                  />
                  <IndicationButton
                    title={lockState?.isLocked ? "MU Fans force ON" : ""}
                    isDisabled={lockState?.setupDisabled || !canControlFans}
                    text=""
                    onClick={() => {
                      setAllMuFans({
                        MUId: selectedDeviceId,
                        fan_cmd_type: 2,
                        sessionId: sessionId,
                      }).then((res) => {
                        if (res && res.status !== 200) {
                          console.error(res);
                        }
                      });
                    }}
                    buttonIconUrl={ONFanIcon}
                  />
                </div>
                <IndicationButton
                  title="Force Off MU"
                  isDisabled={lockState?.setupDisabled || !canControlIdleOff}
                  onClick={() => {
                    [1, 2, 3, 4, 5].forEach((shelf) => {
                      isForceOffAll(shelf, socketMessageDataSegments) &&
                        setFpgaCommandForceOffAll({
                          MUId: selectedDeviceId,
                          shelfNumber: shelf - 1,
                          cmdType: "set_force_off_shelf",
                          val: 1,
                          sessionId: sessionId,
                        }).then((res) => {
                          // TODO: what to do on then?
                          if (res && res.status !== 200) {
                          }
                        });
                    });
                  }}
                  text=""
                  width={67}
                  height={36}
                  className="force-off-button"
                  isPressed={
                    !isForceOffAll(selectedDrawer, socketMessageDataSegments)
                  }
                  buttonIconUrl={ForceOffIcon}
                />
              </div>
            )}
          </div>
          {isLSMU ? (
            <LsmuParkingSpots />
          ) : (
            <div className="project-device-setup-section-drawers">
              <MUMainView
                selectedDrawer={selectedDrawer}
                setSelectedDrawer={setSelectedDrawer}
                socketMessageDataSegments={socketMessageDataSegments}
                setModalState={setModalState}
              />
            </div>
          )}
          <ProjectControlPanel
            isMUsPanel
            selectedDrawerConfiguration={selectedDrawerConfiguration}
            MUId={selectedDeviceId}
            selectedDrawer={selectedDrawer}
            lockState={lockState}
          />
        </div>
        <SetupRackColumn
          sessionId={sessionId}
          deviceId={selectedDeviceId}
          selectedDrawer={selectedDrawer}
          selectedDrawerConfiguration={selectedDrawerConfiguration}
          selectedDrawerSegments={selectedDrawerSegments}
          selectedSegmentIndex={selectedSegmentIndex}
          setSelectedSegmentIndex={setSelectedSegmentIndex}
          lockState={lockState}
          socketMessageDataSegments={socketMessageDataSegments}
        />
        <SetupSegmentDriverConfig
          selectedSegmentIndex={selectedSegmentIndex} //todo : remove from project view and use on setup tab
          selectedDrawer={selectedDrawer} //todo : remove from project view and use on setup tab
          locTimeoutInstances={locTimeoutInstances} //todo consider moving to mobx
          setModalState={setModalState}
          sendCommMessage={sendCommMessage} //todo consider moving to mobx
          sendForceOnMessage={sendForceOnMessage} //todo consider moving to mobx
          sendPWMMessage={sendPWMMessage} //todo consider moving to mobx
        />
      </div>
    </div>
  );
};

export default withRouter(SetupTab);

SetupTab.propTypes = {
  setLocIntervalInstances: PropTypes.func,
  locIntervalInstances: PropTypes.object,
  selectedDrawer: PropTypes.number,
  setSelectedDrawer: PropTypes.func,
  selectedSegmentIndex: PropTypes.number,
  setSelectedSegmentIndex: PropTypes.func,
  sendCommMessage: PropTypes.func,
  sendForceOnMessage: PropTypes.func,
  sendPWMMessage: PropTypes.func,
  locTimeoutInstances: PropTypes.object,
  setModalState: PropTypes.func,
};
