import "Components/Graphs/AdvancedParamsGraphs/AdvancedParamsGraphsWrapper.scss";
import { useRef, useEffect, Fragment } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";

import { useAppStore } from "MobxStores/context";
import useSelectedProjectData from "CustomHooks/useSelectedProject";

import LineGraph from "./LineGraph";
import DiscreteGraph from "./DiscreteGraph";
import {
  Box,
  Stack,
  Chip,
  useTheme,
  Avatar,
  Divider,
  alpha,
} from "@mui/material";
import * as Icons from "Utils/Images/GlobalIcons";
import ProjectAnalyticsStore from "MobxStores/ProjectAnalyticsStore";
import { MouseWheelEvent } from "Components/Graphs/AdvancedParamsGraphs";

const AdvancedParamsGraphsWrapper = () => {
  const graphRef = useRef<HTMLDivElement>(null);
  const mouseCoordsToGraph = useRef<number | null>(null);

  const theme = useTheme();
  const { projectAnalyticsStore } = useAppStore();
  const { timezoneStr } = useSelectedProjectData();

  const {
    advancedParamsData,
    removeAdvancedParam,
    removeParameterFromSelection,
    selectedTimespanValues,
    selectedActivityDevice,
    getDataForSelectedTimespan,
    setSelectedTimespan,
    deviceActivityData,
    zoomTimespan,
    emptyGraphs,
    removeEmptyParam,
    getSummaryTableData,
    selectedGraphDevices,
    maxPowerGraphValue,
    setTimeRulerBorder,
    isZooming,
    setIsZooming,
    setTimeValuesBeforeZoom,
    timeValuesBeforeZoom,
    updateActivityData,
  } = projectAnalyticsStore as ProjectAnalyticsStore;

  const { startTime, endTime } = selectedTimespanValues;

  if (!isZooming) {
    setTimeValuesBeforeZoom(startTime, endTime);
  }

  const sortedGraphData = advancedParamsData.slice().sort((a, b) => {
    if (!a?.deviceId || !b?.deviceId) return 0;
    if (a.param.deviceType === "VU" && b.param.deviceType === "MU") return -1;
    if (a.param.deviceType === "MU" && b.param.deviceType === "VU") return 1;
    return Number(a.deviceId.slice(-3)) - Number(b.deviceId.slice(-3));
  });

  const handleMouseZoom = (e: MouseWheelEvent | any) => {
    if (e.shiftKey) e.preventDefault();
    if (!mouseCoordsToGraph.current) mouseCoordsToGraph.current = 0;

    const direction = e.wheelDelta > 0 ? "up" : "down";

    const scale =
      moment(endTime).diff(startTime, "seconds") !== 0
        ? moment(endTime).diff(startTime, "seconds") * 0.3
        : 0.6;
    const rightScaler = 1 - mouseCoordsToGraph?.current;
    const leftScaler = 1 - rightScaler;

    if (e.shiftKey && rightScaler && leftScaler) {
      if (direction === "up") {
        const zoomedLeftDomain = moment(startTime).add(
          scale * leftScaler,
          "seconds"
        );
        const zoomedRightDomain = moment(endTime).subtract(
          scale * rightScaler,
          "seconds"
        );

        if (moment(zoomedRightDomain).isBefore(zoomedLeftDomain)) return;

        setIsZooming(true);
        setTimeRulerBorder(false, false);
        zoomTimespan(zoomedLeftDomain, zoomedRightDomain);
      } else if (direction === "down") {
        const zoomedLeftDomain = moment(startTime).subtract(
          scale * leftScaler,
          "seconds"
        );
        const zoomedRightDomain = moment(endTime).add(
          scale * rightScaler,
          "seconds"
        );

        if (
          moment(zoomedLeftDomain).isBefore(timeValuesBeforeZoom.start) &&
          !moment(timeValuesBeforeZoom.end).isBefore(zoomedRightDomain)
        ) {
          setTimeRulerBorder(true, false);
          zoomTimespan(startTime, zoomedRightDomain);
          return;
        }
        if (
          moment(timeValuesBeforeZoom.end).isBefore(zoomedRightDomain) &&
          !moment(zoomedLeftDomain).isBefore(timeValuesBeforeZoom.start)
        ) {
          setTimeRulerBorder(false, true);
          zoomTimespan(zoomedLeftDomain, endTime);
          return;
        }
        if (
          moment(zoomedLeftDomain).isBefore(timeValuesBeforeZoom.start) ||
          moment(timeValuesBeforeZoom.end).isBefore(zoomedRightDomain)
        ) {
          setIsZooming(false);
          setTimeRulerBorder(true, true);
          return;
        }

        setIsZooming(true);
        setTimeRulerBorder(false, false);
        zoomTimespan(zoomedLeftDomain, zoomedRightDomain);
      }
    }
  };

  useEffect(() => {
    const current = graphRef?.current;
    if (graphRef?.current) {
      current?.addEventListener("wheel", handleMouseZoom, {
        passive: false,
      });

      return () => current?.removeEventListener("wheel", handleMouseZoom);
    }
  }, [graphRef?.current, startTime, endTime]);

  return (
    <div ref={graphRef}>
      {sortedGraphData.length
        ? sortedGraphData.map((device, index) => {
            const {
              deviceId,
              param: { chartType, id, displayName },
              timeSeriesModel: { timeSeries },
            } = device;
            const isVoltageGraph = !!id?.match?.(/voltage/i);

            const isActivityDevice = selectedActivityDevice === deviceId;
            const isPowerDevice = !!id?.match?.(/power/i);
            const isPowerActivityDevice = isActivityDevice && isPowerDevice;
            return (
              <Fragment key={index}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    label={`${selectedGraphDevices[deviceId]?.device?.name} / ${displayName}`}
                    variant="outlined"
                    onDelete={() => {
                      removeAdvancedParam(id, deviceId);
                      removeParameterFromSelection(deviceId, id);
                      getSummaryTableData(timezoneStr);
                    }}
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.accent.green.main,
                          display: isPowerActivityDevice ? "flex" : "none",
                        }}
                      >
                        <Icons.PowerIcon fill={theme.palette.primary.main} />
                      </Avatar>
                    }
                  />
                </Stack>
                {chartType === "LINE"
                  ? timeSeries && (
                      <LineGraph
                        timeSeries={timeSeries}
                        index={index}
                        id="graph-sync-id"
                        graphId={id}
                        domainStart={startTime}
                        domainEnd={endTime}
                        yDomainFromDataMin={isVoltageGraph}
                        timezoneStr={timezoneStr}
                        getDataForSelectedTimespan={getDataForSelectedTimespan}
                        setSelectedTimespan={setSelectedTimespan}
                        deviceActivityData={deviceActivityData}
                        zoomTimespan={zoomTimespan}
                        isZooming={isZooming}
                        startTime={timeValuesBeforeZoom.start}
                        endTime={timeValuesBeforeZoom.end}
                        mouseCoordsToGraph={mouseCoordsToGraph}
                        getSummaryTableData={getSummaryTableData}
                        displayName={displayName}
                        maxPowerGraphValue={maxPowerGraphValue}
                        setTimeRulerBorder={setTimeRulerBorder}
                        updateActivityData={updateActivityData}
                      />
                    )
                  : timeSeries && (
                      <>
                        <Box sx={{ height: "25px" }} />
                        <DiscreteGraph
                          timeSeries={timeSeries}
                          index={index}
                          id="graph-sync-id"
                          domainStart={startTime.toString()}
                          domainEnd={endTime.toString()}
                        />
                      </>
                    )}
                <Divider
                  sx={{
                    mb: "22px",
                    borderColor: alpha(theme.palette.primary.main, 0.42),
                  }}
                />
              </Fragment>
            );
          })
        : null}
      {sortedGraphData.length && emptyGraphs.length
        ? emptyGraphs.map(({ deviceId, param, displayName }, index) => {
            const isActivityDevice = selectedActivityDevice === deviceId;
            const isPowerDevice = !!displayName?.match?.(/power/i);
            const isPowerActivityDevice = isActivityDevice && isPowerDevice;
            return (
              <Fragment key={index}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    label={`${selectedGraphDevices[deviceId]?.device?.name} / ${displayName}`}
                    variant="outlined"
                    onDelete={() => {
                      removeEmptyParam(param, deviceId);
                      removeParameterFromSelection(deviceId, param);
                      getSummaryTableData(timezoneStr);
                    }}
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.accent.green.main,
                          display: isPowerActivityDevice ? "flex" : "none",
                        }}
                      >
                        <Icons.PowerIcon fill={theme.palette.primary.main} />
                      </Avatar>
                    }
                  />
                </Stack>
                <Box sx={{ height: "25px" }} />
                <DiscreteGraph
                  timeSeries={[]}
                  index={index}
                  id="graph-sync-id"
                  domainStart={startTime.toString()}
                  domainEnd={endTime.toString()}
                  tooltip={false}
                />
                <Divider
                  sx={{
                    mb: "22px",
                    borderColor: alpha(theme.palette.primary.main, 0.42),
                  }}
                />
              </Fragment>
            );
          })
        : null}
    </div>
  );
};

export default observer(AdvancedParamsGraphsWrapper);
