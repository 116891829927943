import moment from "moment";
import {
  tableTemplateHeaders,
  ACTIVITY_GAP_THRESHOLD,
} from "Utils/DefaultValues/AnalyticsDefaultValues";
import { convertedSummaryTimeSpan } from "Utils/UtilsFunctions";

export const muColorsForGraph = [
  "#0000FF",
  "#89CFF0",
  "#088F8F",
  "#6F8FAF",
  "#6082B6",
  "#3F00FF",
  "#191970",
  "#1F51FF",
  "#A7C7E7",
  "#B6D0E2",
  "#87CEEB",
  "#4682B4",
  "#008080",
  "#0437F2",
];

export const formatDataForAnalyticsGraph = (data, dataKeys, type = "power") => {
  const tempObjectData = {};

  if (!data || !dataKeys?.length) {
    return [];
  }

  dataKeys.forEach((key) => {
    if (data[key]?.timeSeries?.length) {
      // handle formatting for VUs
      if (data[key]?.deviceType === "VU") {
        for (let i = 0; i < data[key].timeSeries.length; i++) {
          const dataPoint = data[key].timeSeries[i];
          if (!tempObjectData[dataPoint.timestamp]) {
            tempObjectData[dataPoint.timestamp] = {
              timestamp: data[key].timeSeries[i].timestamp,
            };
          }
          if (data[key].timeSeries[i].hasOwnProperty(type)) {
            tempObjectData[dataPoint.timestamp][`${key}${type}`] =
              data[key].timeSeries[i][type] / 1000;
          }
          if (data[key].timeSeries[i].hasOwnProperty("speed")) {
            tempObjectData[dataPoint.timestamp][`${key}speed`] =
              data[key].timeSeries[i]["speed"] || 0;
          }
        }
      }

      //handle formatting for MUs
      if (data[key]?.deviceType === "MU") {
        for (let i = 0; i < data[key].timeSeries.length; i++) {
          const dataPoint = data[key].timeSeries[i];
          if (!tempObjectData[dataPoint.timestamp]) {
            tempObjectData[dataPoint.timestamp] = {};
            tempObjectData[dataPoint.timestamp]["timestamp"] =
              data[key].timeSeries[i]?.timestamp;
          }
          tempObjectData[dataPoint.timestamp][`${key}${type}`] =
            data[key].timeSeries[i][type] / 1000;
        }
      }
    }
  });

  const tempSortedKeys = Object.keys(tempObjectData).sort((a, b) => a - b);

  return tempSortedKeys.map((timestamp) => {
    return {
      ...tempObjectData[timestamp],
    };
  });
};

export const getGraphTicks = (startTime, endTime) => {
  let ticksArray = [];
  if (startTime && endTime) {
    const startTimeMili = new Date(startTime).getTime();
    const endTimeMili = new Date(endTime).getTime();
    ticksArray[0] = startTimeMili;
    for (let i = 1; i < 8; i++) {
      ticksArray[i] =
        startTimeMili + Math.floor((endTimeMili - startTimeMili) / 8) * i;
    }
    ticksArray.push(endTimeMili);
  }
  return ticksArray;
};

export const getFormattedTableData = (
  tableData,
  selectedVuId,
  spanValue,
  selectedVuName
) => {
  const calcSpan = convertedSummaryTimeSpan(
    tableData[selectedVuId].startTime,
    tableData[selectedVuId].endTime
  );

  const resObject = {
    deviceId: selectedVuName || selectedVuId,
    start: moment(tableData[selectedVuId].startTime).format(
      "DD/MM/YY HH:mm:ss.SSS"
    ),
    end: moment(tableData[selectedVuId].endTime).format(
      "DD/MM/YY HH:mm:ss.SSS"
    ),
    time: calcSpan,
    SocStart:
      tableData[selectedVuId].stateOfCharge?.stateOfChargeStart === 0
        ? 0
        : tableData[selectedVuId].stateOfCharge?.stateOfChargeStart || null,
    SocEnd:
      tableData[selectedVuId].stateOfCharge?.stateOfChargeEnd === 0
        ? 0
        : tableData[selectedVuId].stateOfCharge?.stateOfChargeEnd || null,
    VuStart:
      tableData[selectedVuId].stateOfCharge?.voltageStart === 0
        ? 0
        : Math.round(tableData[selectedVuId].stateOfCharge?.voltageStart) ||
          null,
    VuEnd:
      tableData[selectedVuId].stateOfCharge?.voltageEnd === 0
        ? 0
        : Math.round(tableData[selectedVuId].stateOfCharge?.voltageEnd) || null,
    speed: Math.round(tableData[selectedVuId].averageGpsSpeed) || null,
    distance:
      calcRoundedDistance(tableData[selectedVuId].averageGpsSpeed, spanValue) ||
      null,
    receivers: tableData[selectedVuId].averageReceiversNumber,
    EnrgMu:
      tableData[selectedVuId].muEnergySummary?.totalEnergy?.toFixed(0) || null,
    EnrgVu:
      tableData[selectedVuId].vuEnergySummary?.totalEnergy?.toFixed(0) || null,
    PwrDensity: (tableData[selectedVuId].powerDensity / 1000 || null)?.toFixed(
      1
    ),
    PwrEfficiency: tableData[selectedVuId].efficiency || null,
    EnrgDist:
      Math.round(tableData[selectedVuId].vuTotalEnergyReceivedPerDistance) ||
      null,
  };

  return resObject;
};

export const formatTableDataForDisplay = (tableData) => {
  return tableTemplateHeaders.map((template) => ({
    header: template.header,
    accessor: template.accessor,
    border: template.border,
    pt: template.pt,
    values: [
      {
        value: tableData && tableData[template?.accessor],
        unit: template.headerUnits,
      },
    ],
  }));
};

export const copyTableDataToClipboard = (tableData) => {
  const tableDataForDisplay = formatTableDataForDisplay(tableData);
  const tableDataToCopy = tableDataForDisplay.reduce((prev, val, index) => {
    const header = val["header"];
    const value =
      val["values"][0]["value"] === null ? "" : val["values"][0]["value"];
    const units = value === "" ? "" : val["values"][0]["unit"] || "";
    return {
      ...prev,
      [header]: value + units,
    };
  }, {});
  navigator.clipboard.writeText(
    `Displayed Data: \n\n ${JSON.stringify(
      tableDataToCopy
    )} \n\n Original Data: \n ${JSON.stringify(tableData)}`
  );
};

/**
 * Function that receives "time" moment object and "span" parameters, returns:
 * - start time (time - span / 2)
 * - end time (time + span / 2) - if time + span / 2 is in the future, end time will be current time
 * - span (span)
 * @param {moment} time
 * @param {number} span
 * @returns {object} {startTime, endTime, span}
 */

export const getStartEndTimeSpan = ({ time, span }) => {
  const startTime = moment(time).subtract(span / 2, "milliseconds");
  const endTime = moment(time).add(span / 2, "milliseconds");
  const now = moment();

  return {
    startTime: startTime.format("YYYY-MM-DDTHH:mm:ss"),
    endTime: endTime.isAfter(now)
      ? now.format("YYYY-MM-DDTHH:mm:ss")
      : endTime.format("YYYY-MM-DDTHH:mm:ss"),
    span: span,
  };
};

/**
 * Receives an array of {timestamp: number, chargeActive: boolean} objects and returns an array of {timestamp: number, chargeActive: boolean} objects
 * Object timestamps are a minute apart, and the chargeActive value is the same for all objects in the minute
 * Fills the array with objects starting at a given time and ending at a given time
 * If an object for a given minute is not present in the array, the chargeActive value is set to false
 * @param {array} data
 * @param {moment} startTime
 * @param {moment} endTime
 * @returns {[{timestamp: number, chargeActive: boolean}]} data
 */

export const setCustomBrushHandleClassnames = () => {
  queueMicrotask(() => {
    const customBrushHandlers = document.querySelectorAll(
      ".custom-brush-handle"
    );
    customBrushHandlers.forEach((handle, index) => {
      handle.classList.add(
        `custom-brush-handle-${index === 0 ? "left" : "right"}`
      );
    });
  });
};

export function createTimeSeries(
  timezoneStr,
  data,
  activityDeviceId,
  startTime,
  { defaultStartTime, defaultEndTime },
  currentDate,
  isInitialActivityRequest = true
) {
  let initialActivityIndex;
  let finalActivityIndex;
  let fallbackStartIndex;
  let fallbackEndIndex;

  function createTimestamps(startTime) {
    const timestamps = Array.from({ length: 2161 }, (_, i) =>
      moment(startTime).tz(timezoneStr).add(i, "minutes").valueOf()
    );
    return timestamps;
  }

  const timestamps = createTimestamps(startTime);
  const offset = moment(startTime).utcOffset();
  const projectOffset = moment(startTime).tz(timezoneStr).utcOffset();

  const dataMap = new Map();

  if (data && data.length > 0) {
    for (const record of data) {
      // offset the timestamp to match graph being displayed in local time
      dataMap.set(
        moment(record.timestamp)
          .tz(timezoneStr)
          .subtract(offset, "minutes")
          .add(projectOffset, "minutes")
          .valueOf(),
        record
      );
    }
  }
  const filledTimeSeries = [];

  for (const timestamp of timestamps) {
    const matchingRecord = dataMap.get(timestamp);
    if (matchingRecord) {
      filledTimeSeries.push({
        ...matchingRecord,
        timestamp: timestamp,
      });

      const recordTime = moment(timestamp)
        .subtract(projectOffset, "minutes")
        .add(offset, "minutes")
        .valueOf();

      const isActivityDevice = matchingRecord.deviceId === activityDeviceId;
      const isRecordCharging = Boolean(matchingRecord.chargeActive);
      const isRecordInCurrentDate = moment(recordTime).isSame(
        currentDate,
        "day"
      );

      if (
        isActivityDevice &&
        isRecordCharging &&
        isRecordInCurrentDate &&
        isInitialActivityRequest
      ) {
        finalActivityIndex = filledTimeSeries.length - 1;

        const initialIndexCandidate =
          filledTimeSeries.length - ACTIVITY_GAP_THRESHOLD - 1; // 30 minutes earlier, assuming one record per minute
        if (initialIndexCandidate >= 0) {
          initialActivityIndex = initialIndexCandidate;
        }
      }
    } else {
      filledTimeSeries.push({
        timestamp: timestamp,
        value: null,
      });
    }

    if (moment(timestamp).isSame(defaultStartTime, "minute")) {
      fallbackStartIndex = filledTimeSeries.length - 1;
    }

    if (moment(timestamp).isSame(defaultEndTime, "minute")) {
      fallbackEndIndex = filledTimeSeries.length - 1;
    }
  }

  return {
    filledTimeSeries,
    initialActivityIndex,
    finalActivityIndex,
    fallbackStartIndex,
    fallbackEndIndex,
  };
}

export const getStartAndEndTime = (selectedDate) => {
  const date = moment(selectedDate);

  const startTime = date
    .clone()
    .subtract(1, "day")
    .hour(18)
    .minute(0)
    .second(0);

  return { startTime };
};

export const parseAdvancedParamsDataForExport = (data) => {
  let combinedData = [];
  data.forEach(({ timeSeriesModel: { deviceId, deviceType, timeSeries } }) => {
    timeSeries.forEach((timeSeriesRecord) => {
      combinedData.push({
        deviceId,
        deviceType,
        timestamp: moment(timeSeriesRecord.timestamp).format(
          "YYYY-MM-DD HH:mm:ss:SSS"
        ),
        value: timeSeriesRecord.value,
      });
    });
  });
  return combinedData;
};

/**
 * @typedef {import('../../src/MobxStores/Types/index.d').AdvancedParamsData} GraphData
 */

/**
 * Normalizes the lengths of the time series arrays in an array of graph data objects.
 * @function
 * @param {GraphData} graphData
 * @param {string} timezoneStr
 * @returns {{normalizedData: GraphData, maxPowerValue: number, timestampsArray: number[]}}
 */

export const normalizeTimeSeriesLengths = (
  graphData,
  selectedTimespanValues
) => {
  let maxPowerValue = 0;
  let minAccumulatedValue = 0;

  /** @type {Set<number>} */
  const allUniqueTimestamps = new Set();
  /** @type {(paramId: string) => boolean} */

  const isPowerGraph = (paramId) => paramId.includes("METER_POWER");
  const isAccumulatedGraph = (paramId) => paramId.includes("ACCUMULATED_POWER");

  // Collect all unique timestamps and find the maximum power value and minimal accumulated value
  for (const data of graphData) {
    if (isAccumulatedGraph(data.param.id)) {
      minAccumulatedValue = data.timeSeriesModel.timeSeries[0].value;
    }
    for (const timeSeries of data.timeSeriesModel.timeSeries) {
      allUniqueTimestamps.add(timeSeries.timestamp);
      if (isPowerGraph(data.param.id)) {
        maxPowerValue = Math.max(maxPowerValue, timeSeries.value);
      }
      if (isAccumulatedGraph(data.param.id)) {
        if (timeSeries.value < minAccumulatedValue) {
          minAccumulatedValue = timeSeries.value;
        }
      }
    }
  }

  const timestampsArray = [...allUniqueTimestamps].sort((a, b) => a - b);

  // Normalize the data
  const normalizedData = graphData.map((data) => {
    const timeSeriesMap = new Map(
      data.timeSeriesModel.timeSeries.map((serie) => [
        serie.timestamp,
        serie.value,
      ])
    );

    const timeSeriesModel = {
      deviceId: data.timeSeriesModel.deviceId,
      deviceType: data.timeSeriesModel.deviceType,
      timeSeries: timestampsArray.map((timestamp) => ({
        timestamp,
        // value: timeSeriesMap.get(timestamp) || null, // this seems to create unwanted gaps in the graph
        value:
          isAccumulatedGraph(data.param.id) && !!timeSeriesMap.get(timestamp)
            ? timeSeriesMap.get(timestamp) - minAccumulatedValue
            : timeSeriesMap.get(timestamp),
      })),
    };

    return {
      param: data.param,
      deviceId: data.deviceId,
      timeSeriesModel: timeSeriesModel,
    };
  });

  return { normalizedData, timestampsArray, maxPowerValue };
};

export const geFilteredIndexes = (graphData, start, end, timezoneStr) => {
  return graphData.reduce((acc, el, i) => {
    if (
      moment(el.timestamp).tz(timezoneStr).valueOf() ===
      moment(start).seconds(0).milliseconds(0).valueOf()
    ) {
      acc["startIndex"] = i;
    }
    if (
      moment(el.timestamp).tz(timezoneStr).valueOf() ===
      moment(end).seconds(0).milliseconds(0).valueOf()
    ) {
      acc["endIndex"] = i;
    }
    return acc;
  }, {});
};

export const calcRoundedDistance = (speed, span) => {
  return Math.round((((speed * 1000) / 60) * (span / 60)) / 5) * 5;
};

export const roundTimeUpToNearest = (roundTarget = "second", time) => {
  return moment(time).add(1, roundTarget).startOf(roundTarget);
};

export const roundTimeDownToNearest = (roundTarget = "second", time) => {
  return moment(time).startOf(roundTarget);
};

/**
 * @param {'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'} targetUnit
 * @param { Date | number | string } start
 * @param { Date | number | string } end
 * @returns
 */
export const padTimeTo = (targetUnit = "second", start, end) => {
  const startMoment = moment(start);
  const endMoment = moment(end);
  const startUnit = startMoment.get(targetUnit);
  const endUnit = endMoment.get(targetUnit);
  const startDiff = startUnit === 0 ? 0 : 1;
  const endDiff = endUnit === 0 ? 0 : 1;
  const newStart = startMoment.subtract(startDiff, targetUnit);
  const newEnd = endMoment.add(endDiff, targetUnit);
  return { newStart, newEnd };
};

export const getGraphUnit = (displayName) =>
  displayName.includes("Current")
    ? "A"
    : displayName.includes("Voltage")
    ? "V"
    : displayName.includes("Speed")
    ? "km/h"
    : displayName.includes("Temperature")
    ? "C"
    : displayName.includes("Accumulated")
    ? "wH"
    : "W";
