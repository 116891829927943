import React, { useContext } from "react";
import PropTypes from "prop-types";

//Components
import Segment from "Screens/ProjectView/Devices/MU/Segment/Segment";
import OffIdleButtons from "Components/Buttons/OffIdleButtons/OffIdleButtons";

//Hooks
import useLockState from "CustomHooks/useLockState";

//Context
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";

//Utils
import { getSessionIdProject, setFpgaCommand } from "Utils/APIUtils";
import { segmentsMock } from "Utils/MockData/ProjectMockData";
import {
  getSegmentValue,
  isForceOffAll,
  getIsDeviceDisconnected,
} from "Utils/UtilsFunctions";

const MUDrawers = ({
  isSettingButtons,
  socketMessageDataSegments,
  selectedDrawer,
  setSelectedDrawer,
}) => {
  const { selectedDevice } = useContext(MainContext);
  const { connectionState } = useContext(SocketContext);

  const sessionId = getSessionIdProject();
  const isDeviceOffline = getIsDeviceDisconnected(
    connectionState,
    selectedDevice
  );
  const lockState = useLockState(selectedDevice, null);
  const { setupDisabled } = lockState;

  return (
    <div className={`segments-drawer-data-wrapper`}>
      {[1, 2, 3, 4, 5].map((row, rowIndex) => {
        let rowSegments =
          socketMessageDataSegments &&
          socketMessageDataSegments[`fpga_${row - 1}`];
        if (!rowSegments) {
          rowSegments = segmentsMock["fpga_0"];
        }
        return (
          <div
            className={`segments-drawer-data ${
              row === 1 ? "first-drawer" : ""
            }`}
            key={rowIndex}
          >
            <div
              className={`segments-drawer ${
                selectedDrawer === row ? "selected" : ""
              }`}
              onClick={() => !isDeviceOffline && setSelectedDrawer(row)}
            >
              {rowSegments &&
                Object.keys(rowSegments).map((segmentKey, segmentIndex) => {
                  const segmentId =
                    row === 1
                      ? segmentIndex + 1
                      : segmentIndex + 1 + 12 * (row - 1);
                  if (segmentKey.indexOf("segment") > -1) {
                    return (
                      <Segment
                        onClick={() => {
                          const targetSD = document.getElementById(
                            `SD_${segmentId}`
                          );
                          targetSD && targetSD.scrollIntoView(false); // alignToTop = false
                        }}
                        key={segmentIndex}
                        value={getSegmentValue(row, segmentIndex)}
                        segmentStatus={
                          isDeviceOffline
                            ? 0
                            : rowSegments[segmentKey]?.segmentStatus
                        }
                      />
                    );
                  }
                })}
            </div>
            {isSettingButtons && (
              <div className="drawer-force-indication-buttons">
                <OffIdleButtons
                  offButtonTitle="Force Off Rack"
                  offButtonOnClick={() => {
                    if (isForceOffAll(row, socketMessageDataSegments)) {
                      setFpgaCommand({
                        MUId: selectedDevice.id,
                        shelfNumber: row - 1,
                        cmdType: "set_force_off_shelf",
                        segmentNumber: 0,
                        val: 1,
                        sessionId: sessionId,
                      }).then((res) => {
                        // TODO: what to do on then?
                        if (res && res.status !== 200) {
                        }
                      });
                    }
                  }}
                  isDisabled={setupDisabled}
                  idleButtonTitle="Idle Rack"
                  idleButtonOnClick={() => {
                    setFpgaCommand({
                      MUId: selectedDevice.id,
                      shelfNumber: row - 1,
                      segmentNumber: 0,
                      cmdType: "set_clear_shelf_status",
                      val: 1,
                      sessionId: sessionId,
                    }).then((res) => {
                      // TODO: what to do on then?
                      if (res && res.status !== 200) {
                      }
                    });
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MUDrawers;

MUDrawers.propTypes = {
  isSettingButtons: PropTypes.bool,
  socketMessageDataSegments: PropTypes.object,
  selectedDrawer: PropTypes.number,
  setSelectedDrawer: PropTypes.func,
};
