export const defaultRecievers = {
  Charging_Work_Mode: 0,
  Charging_Allowed_Ind: 0,
  RF_Boost_Level: 0,
  Receiver_1: null,
  Receiver_2: null,
  Receiver_3: null,
  Receiver_4: null,
  Receiver_5: null,
};

export const defaultReceiverFan = {
  receiver_fan_1: 0,
  receiver_fan_2: 0,
  receiver_fan_3: 0,
  receiver_fan_4: 0,
  receiver_fan_5: 0,
};

export const defaultReceiversSetstate = {
  offRegular: 0,
  autoRegular: 1,
  offDebug: 2,
  reqDebugOn: 3,
};

export const defaultReceiversState = {
  commOff: 0,
  commSetOn: 1,
  commIndOn: 2,
  cableDisconnected: 3,
  commError: 4,
};
