import React from "react";
import PropTypes from "prop-types";
import Image from "Components/Image/Image";
import { getSegmentDataByConnection } from "Utils/UtilsFunctions";
import "Screens/ProjectView/Devices/MU/Segment/Segment.scss";

const Segment = ({ segmentStatus, value, className = "", onClick }) => {
  const segmentDataByConnection = getSegmentDataByConnection(segmentStatus);
  const textColor = segmentDataByConnection.textColor || "#001133";
  const segmentBackgroundColorByConnection =
    segmentDataByConnection.backgroundColor || "#fff";
  const border = segmentDataByConnection.border || "1px solid transparent";
  const disabled = segmentDataByConnection.disabled ? "segment-disabled" : "";
  const segmentBackgroundIconByConnection = segmentDataByConnection.icon;

  return (
    <div
      className={`segment-square segment-square${segmentStatus} ${className} ${disabled}`}
      style={{
        backgroundColor: segmentBackgroundColorByConnection,
        color: textColor,
        border: border,
      }}
      onClick={onClick}
    >
      <Image src={`${segmentBackgroundIconByConnection}`} />
      <span className={"segment-number"}>{value}</span>
    </div>
  );
};

export default Segment;

Segment.propTypes = {
  segmentStatus: PropTypes.number,
  value: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
