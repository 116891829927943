const SocketReducer = (state, action) => {
  switch (action.type) {
    case "SET_DEVICE_DC_SAMPLER_AND_GRAPH_SOCKET_MESSAGES":
      return {
        ...state,
        socketMUMessageData: action.payload.MUGraphData,
        socketDcSamplerData: action.payload.dcSamplerData,
      };
    case "SET_MU_MESSAGE_DATA":
      return {
        ...state,
        socketMUMessageData: action.payload.muMessageData,
        socketDcSamplerData: action.payload.socketDcSamplerData,
      };
    case "SET_FANS_MESSAGE":
      return {
        ...state,
        fans: action.payload.fans,
      };
    case "SET_RECEIVERS_FANS_MESSAGE":
      return {
        ...state,
        receiversFans: action.payload.receiversFans,
      };
    case "SET_SOCKET_INSTANCE":
      return {
        ...state,
        socketInstance: action.payload.socketInstance || {},
      };
    case "SET_SOCKET_INSTANCE_PROJECT":
      return {
        ...state,
        socketInstanceProject: action.payload.socketInstanceProject || {},
      };
    case "SET_SOCKET_INSTANCES_PROJECT_DEVICES":
      return {
        ...state,
        socketInstancesProjectDevices:
          action.payload.socketInstancesProjectDevices || {},
      };
    case "SET_SOCKET_ACTIVE_VU_SOCKET_INSTANCE":
      return {
        ...state,
        socketInstanceActiveVU: action.payload.socketInstanceActiveVU || {},
      };
    case "SET_DISCRETES_MESSAGE":
      return {
        ...state,
        discretes: action.payload.discretes || {},
      };
    case "SET_LOCK_MESSAGE":
      return {
        ...state,
        lockInfo: action.payload.lockInfo || {},
      };
    case "SET_CONNECTION_STATE_MESSAGE":
      return {
        ...state,
        connectionState:
          { ...state.connectionState, ...action.payload.connectionState } || {},
      };
    case "RESET_CONNECTION_STATE_MESSAGE":
      return {
        ...state,
        connectionState: {},
      };
    case "SET_RECEIVERS_MESSAGE":
      return {
        ...state,
        receivers: action.payload.receivers || {},
      };
    case "SET_CU_UNIT_TEMP_MESSAGE":
      return {
        ...state,
        cuUnitTemp: action.payload.cuUnitTemp || {},
      };
    case "SET_HP_STATE_MESSAGE":
      return {
        ...state,
        HPStateSocket: action.payload.HPStateSocket || {},
      };
    case "SET_CHARGING_STATE":
      return {
        ...state,
        chargingStateSocket: action.payload.chargingStateSocket,
      };
    case "SET_DEVICES_INITIAL_STATE":
      return {
        ...state,
        devicesInitialStateSocket: action.payload.devicesInitialStateSocket,
      };
    default:
      return state;
  }
};

export default SocketReducer;
