import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import "Components/Buttons/SelectButton/SelectButton.scss";

const SelectButton = ({
  isDisabled = false,
  placeholder,
  defaultValue,
  options,
  onChange,
  noOptionsMessage,
  className,
  value,
}) => {
  const [selectTitle, setTitle] = useState(defaultValue?.label);

  const customTheme = (theme) => ({
    ...theme,
    borderRadius: "4px",
    colors: {
      ...theme.colors,
      primary25: "#CCDDFF",
      primary: "#CCDDFF",
      text: "#001133",
      neutral50: "#001133",
    },
    width: "150px",
  });

  return (
    <div
      className={`select-wrapper ${isDisabled ? "disabled" : ""}`}
      title={selectTitle}
    >
      <Select
        value={value}
        theme={customTheme}
        placeholder={placeholder}
        defaultValue={defaultValue}
        options={options}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? noOptionsMessage : "No results found"
        }
        onChange={(val) => {
          onChange(val.value);
          setTitle(val.label);
        }}
        className={`select-button ${className}`}
      />
    </div>
  );
};

export default SelectButton;

SelectButton.propTypes = {
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  noOptionsMessage: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.object,
};
