export const tableTemplateHeaders = [
  { header: "Start", headerUnits: "", accessor: "start" },
  { header: "End", headerUnits: "", accessor: "end" },
  { header: "Span", headerUnits: "", accessor: "time", border: true },
  { header: "VU", headerUnits: "", accessor: "deviceId", pt: true },
  { header: "Receivers #", headerUnits: "", accessor: "receivers" },
  { header: "VU SOC start", headerUnits: "%", accessor: "SocStart" },
  { header: "VU SOC End", headerUnits: "%", accessor: "SocEnd" },
  { header: "VU Voltage start", headerUnits: "V", accessor: "VuStart" },
  { header: "VU Voltage end", headerUnits: "V", accessor: "VuEnd" },
  { header: "VU Speed", headerUnits: "km/h", accessor: "speed" },
  // { header: "VU Distance", headerUnits: "m", accessor: "distance" },
  { header: "VU Power Density", headerUnits: "kW", accessor: "PwrDensity" },
  { header: "VU Energy", headerUnits: "Wh", accessor: "EnrgVu" },
  {
    header: "VU Energy / km",
    headerUnits: "Wh/km",
    accessor: "EnrgDist",
    border: true,
  },
  {
    header: "MUs Energy",
    headerUnits: "Wh",
    accessor: "EnrgMu",
    border: true,
    pt: true,
  },
  {
    header: "Efficiency",
    headerUnits: "%",
    accessor: "PwrEfficiency",
    pt: true,
  },
];

export const ACTIVITY_GAP_THRESHOLD = 30 as const;
