import "Screens/DepotDashboard/DepotDashboard.scss";

//Components
import Header from "Components/Header/Header";
import CustomLoader from "Components/Loader/Loader";
import DepotSummary from "Screens/DepotDashboard/DepotSummary/DepotSummary";
import DepotAquarium from "Screens/DepotDashboard/DepotAquarium/DepotAquarium";

//Utils
import { observer } from "mobx-react-lite";
import { useDepotSocketData } from "./Hooks/useDepotSocketData";
import DebugDialog from "./DebugDialog";
import useInitDepotStore from "./Hooks/useInitDepotStore";
import { useParams } from "react-router-dom";
import { Box, Stack, ThemeProvider, useTheme } from "@mui/material";
import DepotMainTables from "./DepotMainTables/DepotMainTables";
import PairingDebugButtons from "MobxStores/utils/DeubugPairButtons";

const DepotDashboard = () => {
  const depotStore = useInitDepotStore();
  const { projectId, depotId } = useParams();
  const theme = useTheme();

  const { socketMessages } = useDepotSocketData(projectId, depotId, true);

  return (
    <ThemeProvider theme={theme}>
      {/* <DebugDialog messages={socketMessages} /> */}
      {/* <PairingDebugButtons /> */}
      <Box width={"100%"} height={"100%"} sx={{ overflow: "hidden" }}>
        <Header />
        <Stack
          direction={"row"}
          justifyContent={"center"}
          width={"90%"}
          margin={"auto"}
          marginTop={"37px"}
          sx={{ gap: "3rem" }}
        >
          <DepotSummary store={depotStore} />
          <DepotMainTables store={depotStore} />
          <DepotAquarium vehiclesData={depotStore.connectedVehicles} />
          {depotStore.isLoading && (
            <CustomLoader className="dashboard-loader" />
          )}
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default observer(DepotDashboard);
