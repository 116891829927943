import { useState } from "react";

import { TextField, useTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, SxProps } from "@mui/material";
import { Moment } from "moment";

const defaultSx = {
  flexGrow: 1,
  maxWidth: 200,
};

const AdvancedParamsDatePicker = ({
  date,
  onChange = () => {},
  onSubmit = () => {},
  sx = defaultSx,
  label = "Date",
  loading,
}: {
  date: Moment;
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
  onSubmit: (value: Date | null) => void;
  sx?: SxProps;
  label?: string;
  loading: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "relative",
        "& .MuiInputAdornment-root button": {
          position: "absolute",
          right: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          // renderInput={(props) => (
          //   <TextField
          //     sx={sx}
          //     size="small"
          //     {...props}
          //     onKeyUp={(e) => {
          //       onChange(moment(e.target.value));
          //       if (e.key === "Enter") {
          //         onSubmit(moment(e.target.value));
          //       }
          //     }}
          //   />
          // )}
          renderInput={(props) => (
            <TextField
              inputProps={{ readonly: true }}
              sx={{
                ...sx,
                "& .MuiButtonBase-root:hover,.MuiButtonBase-root:active ": {
                  bgcolor: "transparent",
                  boxShadow: "none",
                },
              }}
              size="small"
              InputLabelProps={{
                style: {
                  color: theme.palette.accent.gray.main,
                },
              }}
              {...props}
            />
          )}
          label={label}
          value={date}
          onChange={onChange}
          onAccept={(e) => onSubmit(e)}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          maxDate={new Date()}
          components={{
            OpenPickerIcon: () => (
              <ArrowDropDownIcon
                sx={{
                  transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            ),
          }}
          disabled={loading}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default AdvancedParamsDatePicker;
