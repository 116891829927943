import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";
import "Components/SearchComponents/SearchBox.scss";

//Components
import Image from "Components/Image/Image";

//Utils
import searchIcon from "Utils/Images/GlobalIcons/search-icon.svg";
import { getSearchResults } from "Utils/APIUtils";

const SearchBox = ({ query, dispatch }) => {
  const [debounceSearchValue] = useDebounce(query, 300);

  useEffect(() => {
    if (debounceSearchValue) {
      getSearchResults({ searchText: debounceSearchValue })
        .then((res) => {
          if (res && res.length) {
            const searchResults = res.map((item) => {
              if (item) {
                const type = item.hasOwnProperty("deviceType")
                  ? item.deviceType
                  : "projects";
                const itemResults = item.hasOwnProperty("deviceType")
                  ? item.devices
                  : item.projects;
                const total = item.total;
                return {
                  itemsType: type,
                  items: itemResults,
                  itemsTotal: total,
                };
              }
              return null;
            });

            dispatch({
              type: "SET_SEARCH_RESULTS",
              payload: { searchResults },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [debounceSearchValue]);

  return (
    <div className="search-box">
      <div className="search-bar opened">
        <Image src={searchIcon} className="search-icon" />
        <input
          placeholder="Search"
          type="search"
          pattern=".*\S.*"
          required
          value={query}
          onInput={(e) => {
            dispatch({
              type: "SET_QUERY",
              payload: { query: e.target.value },
            });
          }}
          onFocus={() =>
            dispatch({
              type: "TOGGLE_SEARCH_BOX",
              payload: { isSearchOpened: true },
            })
          }
        />
      </div>
    </div>
  );
};

SearchBox.propTypes = {
  query: PropTypes.string,
  dispatch: PropTypes.func,
};

export default SearchBox;
