import React, { useContext } from "react";
import PropTypes from "prop-types";
import lockedByMeIcon from "Utils/Images/ProjectMaintanance/locked-by-me-icon.svg";
import lockedIcon from "Utils/Images/ProjectMaintanance/locked-icon.svg";
import Image from "Components/Image/Image";
import socketContext from "Contexts/SocketContext";
import useLockState from "CustomHooks/useLockState";

const VU = ({
  isVUSelected,
  id,
  name,
  onVUClick,
  tooltip,
  isDeviceConnected,
  vuDevice,
  locTimeoutInstances,
}) => {
  const { chargingStateSocket, devicesInitialStateSocket } =
    useContext(socketContext);

  const { treeMap } = Object.keys(devicesInitialStateSocket).length // From initial state devices message
    ? devicesInitialStateSocket["socketMessageData"]
    : { treeMap: {} };

  const initialChargingStatus =
    treeMap && id && treeMap[id] ? treeMap[id]["chargingState"] : 0;

  const {
    chargingStatus,
  } = // From chargingMessage
    chargingStateSocket && id && chargingStateSocket[id]
      ? chargingStateSocket[id]["socketMessageData"]
      : { chargingStatus: initialChargingStatus };

  const { isLockedByMe, isLocked, lockedBy } = useLockState(
    vuDevice,
    locTimeoutInstances
  );

  const appendedClass = isDeviceConnected ? "connected" : "disconnected";

  return (
    <div
      className={`vu-list-item ${
        isVUSelected ? "selected" : ""
      }  ${appendedClass}`}
      onClick={() => onVUClick()}
    >
      <div
        className={`list-item-name ${
          isDeviceConnected && chargingStatus ? "charging" : ""
        }`}
        title={tooltip}
      >
        {name}
      </div>
      <div className="list-item-icons">
        <div className={`connection-status ${appendedClass}`} />
        {isDeviceConnected && (isLockedByMe || isLocked) ? (
          <Image
            src={isLockedByMe ? lockedByMeIcon : isLocked ? lockedIcon : ""}
            alt="lock-icon"
            title={
              !isLocked
                ? ""
                : isLockedByMe
                ? ""
                : `Device is locked by ${lockedBy}`
            }
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default VU;

VU.propTypes = {
  isVUSelected: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onVUClick: PropTypes.func,
  tooltip: PropTypes.string,
  isDeviceConnected: PropTypes.bool,
  vuDevice: PropTypes.object,
  locTimeoutInstances: PropTypes.object,
};
