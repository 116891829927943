
// muId - usually will be in the format: AG0000116
// segmentNum - number of the segment in the MU (ignoring drawerNum)
// so if there are 5 drawers with 12 segments in each, the segmentNum will be 1-60
export function constructSegmentId(muId, drawerNumber, segmentNum) {
  return `${muId}_${drawerNumber}_${segmentNum}`;
}

export function buildFlatSegmentObj(muId, fpgaMsg) {
  const segmentsFlatObj = {};
  Object.entries(fpgaMsg).forEach(([fpgaId, fpgas]) => {
    // need to filter out objects that are not fpgas (such as chargingStatus)
    if (fpgaId.startsWith("fpga")) {
      // key is given in the format fpga_x - we need to split and take the actual id
      const drawerNum = parseInt(fpgaId.split("_")[1]);

      Object.entries(fpgas).forEach(([segmentNumStr, segment]) => {
        // key is given in the format segment_y - we need to split and take the actual id
        const segmentNum = parseInt(segmentNumStr.split("_")[1]);
        segmentsFlatObj[constructSegmentId(muId, drawerNum, segmentNum)] =
          !!segment.isSegmentCharging;
      });
    }
  });

  return segmentsFlatObj;
}
