import React from "react";
import { observer } from "mobx-react-lite";

//Hooks
import { useAppStore } from "MobxStores/context";

//Utils
import "Screens/AnalyticsTool/AnalyticsDataDisplay/AnalyticsDataDisplay.scss";
import AdvancedParamsGraphsWrapper from "Components/Graphs/AdvancedParamsGraphs/AdvancedParamsGraphsWrapper";
import { Skeleton } from "@mui/material";

const AnalyticsDataDisplay = () => {
  const { projectAnalyticsStore } = useAppStore();

  const { isLoadingGraphData, selectedParamCount } = projectAnalyticsStore;

  return (
    <div className="analytics-data-display-wrapper">
      {isLoadingGraphData ? (
        Array(selectedParamCount)
          .fill(0)
          .map((_, i) => {
            return (
              <div key={i}>
                <Skeleton
                  variant="circular"
                  width={32}
                  height={32}
                  sx={{ marginBlockEnd: "2px" }}
                />
                <Skeleton
                  height={165}
                  sx={{
                    transform: "none",
                    marginBlockEnd: "40px",
                  }}
                />
              </div>
            );
          })
      ) : (
        <AdvancedParamsGraphsWrapper />
      )}
    </div>
  );
};

export default observer(AnalyticsDataDisplay);
