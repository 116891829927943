import React, { useState } from "react";
import PropTypes from "prop-types";
import InfrastructureSection from "Screens/ProjectView/SideBar/InfrastructureSection";
import VehiclesSection from "Screens/ProjectView/SideBar/VehiclesSection";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";

const sectionTypes = ["INFRASTRUCTURE", "VEHICLES"];

const SideBar = ({ locTimeoutInstances }) => {
  const [sizes, setSizes] = useState(["70%", "30%", "auto"]);

  return (
    <div className="project-unit-list-view">
      <div className="project-unit-list-view-sections">
        <SplitPane
          split="horizontal"
          sizes={sizes}
          onChange={(sizes) => setSizes(sizes)}
          resizerSize={12}
        >
          {sectionTypes.map((section, index) => {
            return (
              <Pane minSize={100} key={index}>
                <div className="section-wrapper">
                  <div className="section-name">
                    <span className={section.toLowerCase() + "-icon"}></span>
                    {section}
                  </div>
                  {section === "INFRASTRUCTURE" ? (
                    <InfrastructureSection
                      locTimeoutInstances={locTimeoutInstances}
                    />
                  ) : (
                    <VehiclesSection
                      locTimeoutInstances={locTimeoutInstances}
                    />
                  )}
                </div>
              </Pane>
            );
          })}
        </SplitPane>
      </div>
    </div>
  );
};

export default SideBar;

SideBar.propTypes = {
  locTimeoutInstances: PropTypes.object,
};
