import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import PropTypes from "prop-types";
import AppButton from "Components/Buttons/AppButton/AppButton";
import "Components/AppModal/AppModal.scss";

const AppModal = ({
  isOpened,
  onCancelModal,
  onSubmitModal,
  message,
  submitText,
  cancelText,
}) => {
  return (
    <Modal
      open={isOpened}
      onClose={onCancelModal || onSubmitModal}
      center
      className="app-modal"
    >
      <h2>{message}</h2>
      <AppButton onClick={onSubmitModal} text={submitText} />
      {onCancelModal ? (
        <AppButton onClick={onCancelModal} text={cancelText} />
      ) : null}
    </Modal>
  );
};

export default AppModal;

AppModal.propTypes = {
  isOpened: PropTypes.bool,
  onCancelModal: PropTypes.func,
  onSubmitModal: PropTypes.func,
  message: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
};
