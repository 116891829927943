import React from "react";
import "Screens/ProjectView/Devices/ProjectDeviceHeader/ProjectDeviceHeader.scss";
import PropTypes from "prop-types";
import infoIcon from "Utils/Images/GlobalIcons/info_icon.svg";
import Image from "Components/Image/Image";

const ProjectDeviceHeader = ({ title, data, infoIconDataFor }) => (
  <div className="project-device-setup-section-header">
    <div className="project-device-setup-section-header-title">
      {title}
      {infoIconDataFor && (
        <Image
          src={infoIcon}
          className="project-device-header-i"
          dataFor={infoIconDataFor}
        />
      )}
    </div>
    {data}
  </div>
);

ProjectDeviceHeader.propTypes = {
  data: PropTypes.element,
  title: PropTypes.string,
  infoIconDataFor: PropTypes.string,
};

export default ProjectDeviceHeader;
