import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import ControlUnit from "Components/ControlUnit/ControlUnit";
import ReceiversView from "Components/Recievers/ReceiversView";
import MainContext from "Contexts/MainContext";
import SocketContext from "Contexts/SocketContext";
import { getDcSamplerSummaryNumbers } from "Utils/UtilsFunctions";

const VuPerformance = ({ setupDisabled }) => {
  const { selectedDevice } = useContext(MainContext);
  const { socketDcSamplerData } = useContext(SocketContext);

  const liveDCSamplerData =
    socketDcSamplerData[selectedDevice.id]?.socketMessageData;

  const { avgCurrentLiveValue } = useMemo(
    () => getDcSamplerSummaryNumbers(liveDCSamplerData),
    [liveDCSamplerData]
  );

  return (
    <div className="vu-performance">
      <div className="vu-performance-control-receivers">
        <ControlUnit
          isLocked={setupDisabled}
          avgCurrentLiveValue={avgCurrentLiveValue}
        />
        <ReceiversView
          isLocked={setupDisabled}
          avgCurrentLiveValue={avgCurrentLiveValue}
        />
      </div>
    </div>
  );
};
export default VuPerformance;

VuPerformance.propTypes = {
  setupDisabled: PropTypes.bool,
};
