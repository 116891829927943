import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const ProgressLine = ({ progress = 0, isInProgress }) => {
  const [opacity, setOpacity] = useState();

  useEffect(() => {
    if (!isInProgress) {
      setOpacity("0");
    }
    if (progress) {
      setOpacity("1");
    }
  }, [progress]);

  const progressLineStyle = {
    width: progress + "%",
    opacity: opacity,
  };
  return <div className="progress-line" style={progressLineStyle}></div>;
};

export default ProgressLine;

ProgressLine.propTypes = {
  progress: PropTypes.number,
  isInProgress: PropTypes.bool,
};
