import { Box } from "@mui/material";

export const DepotTableIconContainer = ({
  children,
  height,
  width,
  padding = "5px 0px",
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: padding || 0,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
