import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Image from "Components/Image/Image";
import Surface from "Utils/Images/GlobalIcons/aboveground-arrow.svg";
import UnderGround from "Utils/Images/GlobalIcons/underground-arrow.svg";
import StaticCharging from "Utils/Images/GlobalIcons/static-icon.svg";
import DynamicCharging from "Utils/Images/GlobalIcons/dynamic-search-result-icon.svg";
import CarIcon from "Utils/Images/GlobalIcons/car-search-result-icon.svg";
import TruckIcon from "Utils/Images/GlobalIcons/truck-icon.svg";
import BusIcon from "Utils/Images/ProjectMaintanance/bus-icon.svg";
import Lab from "Utils/Images/GlobalIcons/lab-icon.svg";
import "Components/ProjectCard/CardDetails/CardDetails.scss";

const CardDetails = ({ cardType, totalNumber, offlineNumber, iconsMap }) => {
  const getImageSource = useCallback((icon) => {
    switch (icon) {
      case "underground":
        return UnderGround;
      case "surface":
        return Surface;
      case "lab":
        return Lab;
      case "static":
        return StaticCharging;
      case "dynamic":
        return DynamicCharging;
      case "bus":
        return BusIcon;
      case "truck":
        return TruckIcon;
      case "car":
        return CarIcon;
      default:
        return UnderGround;
    }
  }, []);

  return !!totalNumber ? (
    <div className="card-details">
      <div className="card-titles">
        <div className="card-titles-main">
          {totalNumber} {cardType}s
        </div>
        {!!offlineNumber && (
          <div className="card-titles-sub">{offlineNumber} offline</div>
        )}
      </div>
      <div className="indication-icons">
        {iconsMap &&
          Object.keys(iconsMap).map((icon, index) => {
            return iconsMap[icon] ? (
              <div key={index}>
                <div className="icon-count">{iconsMap[icon]}</div>
                <Image
                  src={getImageSource(icon)}
                  width={30}
                  height={30}
                  alt={icon}
                  title={icon}
                />
              </div>
            ) : (
              ""
            );
          })}
      </div>
    </div>
  ) : (
    ""
  );
};

export default CardDetails;

CardDetails.propTypes = {
  cardType: PropTypes.string,
  totalNumber: PropTypes.number,
  offlineNumber: PropTypes.number,
  iconsMap: PropTypes.object,
};
