import * as React from "react";
import { dashboardReducer, reducerInitialValue } from "Screens/ProjectDashboard/lib/context/reducer";

const DashboardContext = React.createContext();

function DashboardProvider({ children }) {
  const [state, dispatch] = React.useReducer(
    dashboardReducer,
    reducerInitialValue
  );

  const value = { state, dispatch };
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}

function useDashboardContext() {
  const context = React.useContext(DashboardContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return context;
}

export { DashboardProvider, useDashboardContext };
