import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "Components/DataSummary/DataSummary.scss";

const DataSummary = ({ value, units, subtitle, isLeftBorder = true }) => {
  return useMemo(() => {
    return (
      <div
        className={`data-summary ${
          isLeftBorder ? "left-border" : "no-left-border"
        }`}
      >
        <div>
          <span className={"value"}> {value} </span>
          <span className={units.includes("C") ? "C-units" : "units"}>
            {" "}
            {units}{" "}
          </span>
        </div>
        <span className={"subtitle"}> {subtitle} </span>
      </div>
    );
  }, [value, subtitle, units.isLeftBorder]);
};

export default DataSummary;

DataSummary.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  units: PropTypes.string,
  subtitle: PropTypes.string,
  isLeftBorder: PropTypes.bool,
};
