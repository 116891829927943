import FileSaver from "file-saver";
import { useCallback } from "react";
import { useCurrentPng } from "recharts-to-png";

const useExportAsPng = () => {
  const [getPng, { ref, isLoading }] = useCurrentPng();

  const handleDownloadAsPng = useCallback(
    async (fileName) => {
      const png = await getPng();
      if (png) {
        FileSaver.saveAs(png, fileName ? `${fileName}.png` : "chart.png");
      }
    },
    [getPng]
  );

  return {
    ref,
    isLoading,
    handleDownloadAsPng,
  };
};
export default useExportAsPng;
