import React, { useContext, memo, useMemo } from "react";
import PropTypes from "prop-types";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import "Components/CreateBookmark/CreatBookmark.scss";
import BookmarksContext from "Contexts/BookmarksContext";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import createDecorator from "final-form-focus";
import { FaWindowClose } from "react-icons/fa";
import { IconContext } from "react-icons";
import { postBookmark } from "Utils/APIUtils";
import VUsContext from "Contexts/VUsContext";
import MainContext from "Contexts/MainContext";

const CreateBookmark = ({ graphData }) => {
  const { bookmarkData, bookmarksDispatch } = useContext(BookmarksContext);
  const { activeVU } = useContext(VUsContext);
  const { selectedDevice, selectedProject } = useContext(MainContext);
  const { BMStartTime, BMEndTime } = bookmarkData;
  const required = (value) => (value ? undefined : "Required");

  const myOptions = Object.keys(graphData).filter(
    (idKey) => idKey === selectedDevice?.id || idKey === activeVU?.id
  );
  const focusOnErrors = useMemo(() => createDecorator(), [createDecorator]);

  const onSubmit = async (values) => {
    postBookmark({
      name: values.BookmarkTitle,
      description: values.Bookmark,
      startTime: BMStartTime,
      endTime: BMEndTime,
      projectId: selectedProject?.id,
      deviceIds: values.devices,
    })
      .then((res) => {
        bookmarksDispatch({
          type: "ADD_BOOKMARK",
          payload: {
            bookmark: res,
          },
        });
      })
      .catch((err) => console.log(err));

    bookmarksDispatch({
      type: "SET_BOOKMARK_INIT_DATA",
      payload: {
        bookmarkData: {
          ...bookmarkData,
          isBMOpen: false,
        },
      },
    });
  };

  const Error = ({ name }) => (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span>{error}</span> : null
      }
    />
  );

  return (
    <>
      <div className="create-bookmark">
        <div className="BM_Modal">
          <Form
            onSubmit={onSubmit}
            decorators={[focusOnErrors]}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{
              BookmarkTitle: "",
              Bookmark: "",
              startDate: BMStartTime,
              endDate: BMEndTime,
              devices: myOptions,
            }}
            render={({ handleSubmit, form, submitting, pristine }) => (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <div
                  className="close-icon"
                  onClick={() => {
                    bookmarksDispatch({
                      type: "SET_BOOKMARK_INIT_DATA",
                      payload: {
                        bookmarkData: {
                          ...bookmarkData,
                          isBMOpen: false,
                        },
                      },
                    });
                  }}
                >
                  <IconContext.Provider value={{ className: "close" }}>
                    <FaWindowClose />
                  </IconContext.Provider>
                </div>
                <h3>Add your bookmark</h3>
                <section className="form-info">
                  <div className="checkbox-final-form-wrapper">
                    {myOptions &&
                      myOptions.map((deviceName, index) => {
                        return (
                          <div key={index} className="checkbox-final-form">
                            <Field
                              name="devices"
                              component="input"
                              type="checkbox"
                              value={deviceName}
                            />
                            <label className="label">{deviceName}</label>
                          </div>
                        );
                      })}
                  </div>
                  <Field name="BookmarkTitle" validate={required}>
                    {({ input, meta }) => (
                      <div className="bookmarkTitle">
                        <input
                          {...input}
                          type="text"
                          placeholder="Bookmark-Title"
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Error name="Bookmark-Title" />
                  <Field name="Bookmark" validate={required}>
                    {({ input, meta }) => (
                      <div className="bookmarkTextaria">
                        <textarea
                          {...input}
                          className="bookmarkText"
                          placeholder="Your Bookmark"
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Error name="Bookmark!!" />
                  <div className="buttons">
                    <button
                      className="btn submitBtn"
                      type="submit"
                      disabled={submitting || pristine}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      onClick={form.reset}
                      disabled={submitting || pristine}
                      className="btn resetBtn"
                    >
                      Reset
                    </button>
                  </div>
                </section>
              </form>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default memo(CreateBookmark);

CreateBookmark.propTypes = {
  graphData: PropTypes.object,
};
