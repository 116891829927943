import React from "react";
import PropTypes from "prop-types";

const LoginButton = ({ logIn, disabled = false }) => {
  return (
    <button
      id="authenticationButton"
      className="submit-login"
      type="submit"
      onClick={logIn}
      disabled={disabled}
    >
      Login
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default LoginButton;

LoginButton.propTypes = {
  logIn: PropTypes.func,
  disabled: PropTypes.bool,
};
